import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import theme from "../../styles/theme";
import { colors } from "../../styles/colors";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

interface IBasicTabsProps {
  tabs: any;
  tabContent: any;
  onChangeTab?: any;
  tabStyle?: any;
  tabValue?: number;
  setTabValue?: any;
}

export default function BasicTabs(props: IBasicTabsProps) {
  const { tabs, tabContent, onChangeTab, tabStyle, tabValue, setTabValue } =
    props;
  const matchesXs = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setTabValue && setTabValue(newValue);
    onChangeTab && onChangeTab(newValue);
  };

  React.useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
    } else {
      setValue(0);
    }
  }, [tabValue]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: tabStyle?.borderBottom ? tabStyle?.borderBottom : 1,
          borderColor: tabStyle?.borderColor
            ? tabStyle?.borderColor
            : colors.lightGreyBorder,
          color: tabStyle?.color ? tabStyle?.color : colors.invalidInput
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: tabStyle?.backgroundColor
                ? tabStyle?.backgroundColor
                : colors.invalidInput
            },
            "& .Mui-selected": {
              backgroundColor: tabStyle?.backgroundColor,
              borderRadius: "4px"
            }
          }}
        >
          {tabs &&
            tabs?.map((tab, index) => (
              <Tab
                key={index}
                icon={tab.icon}
                iconPosition="end"
                label={tab.label}
                {...a11yProps(index + 1)}
                sx={{
                  color: colors.lightGreyIcon,
                  fontWeight: 500,
                  fontSize: "14px"
                }}
              />
            ))}
        </Tabs>
      </Box>
      {tabContent &&
        tabContent?.map((content, index) => (
          <TabPanel value={value} index={index} key={index}>
            {content}
          </TabPanel>
        ))}
    </Box>
  );
}
