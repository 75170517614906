import React, { SetStateAction } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import OrangeGradientButton from "../OrangeGradientButton";

interface IModalProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  selectedUser: any;
  deleteUser: any;
}

const RevokeInviteModal = (props: IModalProps) => {
  const { open, setOpen, selectedUser, deleteUser } = props;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} scroll={"paper"}>
      <DialogTitle
        sx={{
          backgroundColor: "#FEF7EE",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
          Do you want to revoke the invite?
        </Typography>
        <IconButton size="small" onClick={() => setOpen(false)}>
          <Close fontSize="small" sx={{ color: "#3c3c3c" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{ backgroundColor: "#FEF7EE", pb: 10 }}
      >
        <Typography fontSize="14px" fontWeight={600} color="#3c3c3c">
          This will immediately cancel the invitation and the users will no
          longer be able to access the CMS through the invitation link.
        </Typography>
      </DialogContent>
      <DialogTitle
        sx={{
          backgroundColor: "#FFE4CB",
          display: "flex",
          alignItems: "center"
        }}
      >
        <OrangeGradientButton
          title="Revoke"
          size="small"
          style={{
            width: "128px",
            background: "linear-gradient(180deg, #FD635B 0%, #E63930 100%)"
          }}
          onClick={() => {
            deleteUser({
              variables: {
                id: selectedUser?.id
              }
            });
          }}
        />
        <Button
          variant="contained"
          size="small"
          disableElevation
          sx={{
            background: "#8C8E8F40",
            borderRadius: "36px",
            fontWeight: 600,
            fontSize: "16px",
            letterSpacing: "0.02em",
            color: "#FFFFFF",
            width: "128px",
            ml: 2
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogTitle>
    </Dialog>
  );
};

export default RevokeInviteModal;
