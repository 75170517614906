import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { colors } from "styles/colors";

interface IProps {
  startDate: any;
  endDate: any;
  onChangeStartDate: any;
  onChangeEndDate: any;
  noBackgroundColor?: boolean;
  disablePastForStart?: boolean;
  disableFutureForStart?: boolean;
  disablePastForEnd?: boolean;
  disableFutureForEnd?: boolean;
}

const DateFilter = (props: IProps) => {
  const {
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    noBackgroundColor,
    disablePastForStart,
    disableFutureForStart,
    disablePastForEnd,
    disableFutureForEnd
  } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: !noBackgroundColor
          ? `${colors.MysteryBoxLightOrange}55`
          : "#fff",
        py: 0.75,
        px: 1,
        borderRadius: "4px"
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={startDate}
          disablePast={disablePastForStart || false}
          disableFuture={disableFutureForStart || false}
          onChange={(newDate) => onChangeStartDate(newDate)}
          inputFormat="DD/MM/YYYY"
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
      <Typography fontSize="14px" color={colors.gray} sx={{ mx: 1 }}>
        to
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={endDate}
          disablePast={disablePastForEnd || false}
          disableFuture={disableFutureForEnd || false}
          onChange={(newDate) => onChangeEndDate(newDate)}
          inputFormat="DD/MM/YYYY"
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateFilter;
