import React from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  CircularProgress
} from "@mui/material";
import { colors } from "../../../styles/colors";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { DELETE_TOURNAMENT, UPDATE_TOURNAMENT } from "app/graphql/tournament";
import { useSnackbar } from "notistack";
import { getPageAction } from "utils/helpers";

const TournamentDeactivate = () => {
  const { tournamentId }: any = useParams();

  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const pageAction = getPageAction(location);

  const [textFieldValue, setTextFieldValue] = React.useState('');

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const [deleteTournament, { loading: deleteTournamentLoading }] = useMutation(
    DELETE_TOURNAMENT,
    {
      onCompleted: (data) => {
        console.log(data);
        const { deleteTournament } = data;
        const { status, message } = deleteTournament;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          history.push("/tournaments-configuration");
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  const [updateTournament, { loading: updateTournamentLoading }] = useMutation(
    UPDATE_TOURNAMENT,
    {
      onCompleted: (data) => {
        console.log(data);
        const { updateTournament } = data;
        const { status, message } = updateTournament;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          history.push("/tournaments-configuration");
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  return (
    <>
      {deleteTournamentLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "#FEF7EE",
          borderRadius: "15px",
          padding: 2,
          m: 1
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            color="#3C3C3C"
            fontSize="16px"
            fontWeight={600}
            sx={{ p: 1 }}
          >
            {` Are you sure you want to ${pageAction === "delete" ? "delete" : "deactivate"
              } the tournament?`}
          </Typography>
          <Typography
            color="#3C3C3C"
            fontSize="14px"
            fontWeight={400}
            sx={{ px: 1 }}
          >
            This will remove the tournaments from the GD app. Make sure there
            the tournament is not active.
          </Typography>
          <br />
        </Grid>
        
        <Grid
          mt={-3}
          container
          spacing={2}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
        >
            <Grid item xs={3} sx={{ mb: 2 }}>
              <Typography pt={1} mt={3} fontSize={15}>
                Mention a reason for deactivation
              </Typography>
            </Grid>
            <Grid item xs={9} sx={{ height: 40 }}>
              <TextField
                size="small"
                sx={{
                  width: "90%",
                  borderRadius: "4px",
                  backgroundColor: "#FFF1E4"
                }}
                variant="outlined"
                fullWidth
                onChange={handleTextFieldChange}
              />
            </Grid>
          
        </Grid>
        
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "12%",
              background: "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
              borderRadius: "36px",
              height: "38px",
              fontWeight: 400,
              fontSize: "16px",
              margin: "20px 0px",
              color: "#FFF9F2"
            }}
            onClick={() => {
    
                const data = { "detail": textFieldValue };
                const jsonString = JSON.stringify(data);

              if (pageAction === "delete") {
                deleteTournament({
                  variables: {
                    id: parseInt(tournamentId)
                  }
                });
              } else {
                console.log(jsonString)

                updateTournament({
                  variables: {
                    input: { id: parseInt(tournamentId), status: "InActive", details: jsonString },

                  }
                });
              }
            }}
          >
            {pageAction === "delete" ? "Delete" : "Deactivate"}
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "12%",
              background: "#bbb5af",
              borderRadius: "36px",
              height: "38px",
              fontWeight: 400,
              fontSize: "16px",
              margin: "20px 20px",
              color: "#FFF9F2"
            }}
            onClick={() => history.push("/tournaments-configuration")}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default TournamentDeactivate;
