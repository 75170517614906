import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Chip,
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  MenuItem,
  Divider,
  Popover
} from "@mui/material";
import DataTable from "../../Table";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { colors } from "styles/colors";
import images from "assets/images/images";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RewardsTable from "./RewardsComponents/RewardsTable";
import AddReward from "./RewardsComponents/AddReward";
import ViewRewardDetail from "./RewardsComponents/ViewRewardDetail";
import DeleteReward from "./RewardsComponents/DeleteReward";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_REWARD,
  GET_ALL_REWARDS,
  GET_REWARD_DETAIL
} from "app/graphql/rewards";
import { useSnackbar } from "notistack";

interface IRewardProps {
  rewardCategory: string;
}
const Rewards = (props: IRewardProps) => {
  const { rewardCategory } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [item, setItem] = React.useState("rewardsTable");

  const [rewards, setAllRewards] = useState([]);
  const [rewardDetail, setRewardDetail] = useState({});
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [action, setAction] = useState<any>("viewReward");
  const {
    data: allRewardsData,
    loading: allRewardsDataLoading,
    refetch: refetchAllRewardsData
  } = useQuery(GET_ALL_REWARDS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      allRewardsData &&
      allRewardsData?.rewards &&
      allRewardsData?.rewards?.status
    ) {
      console.log(allRewardsData);
      const data = allRewardsData?.rewards?.rewards;
      setAllRewards(data || []);
    }
  }, [allRewardsData]);

  const {
    data: rewardData,
    loading: rewardDataLoading,
    refetch: refetchRewardData
  } = useQuery(GET_REWARD_DETAIL, {
    skip: !selectedRow,
    variables: {
      id: selectedRow?.id
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      rewardData &&
      rewardData?.getRewardById &&
      rewardData?.getRewardById?.status
    ) {
      console.log(rewardData);
      const data = rewardData?.getRewardById?.reward;

      const brandDetails = rewardData?.getRewardById?.reward?.brand
        ? JSON.parse(rewardData?.getRewardById?.reward?.brand)
        : {};
      setRewardDetail({ ...data, ...brandDetails } || {});
      // if (selectedRow) {
      //   setItem("addReward");
      // }
      if (action === "viewReward") {
        setItem("viewRewardDetail");
      } else {
        setItem("addReward");
      }
    }
  }, [rewardData]);

  useEffect(() => {
    if (selectedRow) {
      refetchRewardData();
    }
  }, [selectedRow]);

  const [deleteReward, { loading: deleteRewardLoading }] = useMutation(
    DELETE_REWARD,
    {
      onCompleted: (data) => {
        console.log(data);
        const { deleteReward } = data;
        const { status, message } = deleteReward;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refetchAllRewardsData();
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  return (
    <>
      {item === "rewardsTable" && (
        <Grid xs={12} sm={12} md={12} lg={12}>
          <RewardsTable
            setItem={setItem}
            rewardCategory={rewardCategory}
            rewards={rewards}
            setSelectedRow={setSelectedRow}
            setRewardDetail={setRewardDetail}
            deleteReward={deleteReward}
            setAction={setAction}
          />
        </Grid>
      )}
      {item === "addReward" && (
        <Grid xs={12} sm={12} md={12} lg={12}>
          <AddReward
            setItem={setItem}
            rewardCategory={rewardCategory}
            rewardDetail={rewardDetail}
            refetchAllRewardsData={refetchAllRewardsData}
            setSelectedRow={setSelectedRow}
            setRewardDetail={setRewardDetail}
          />
        </Grid>
      )}
      {item === "viewRewardDetail" && (
        <Grid xs={12} sm={12} md={12} lg={12}>
          <ViewRewardDetail
            setItem={setItem}
            rewardDetail={rewardDetail}
            setSelectedRow={setSelectedRow}
          />
        </Grid>
      )}
      {item === "deleteReward" && (
        <Grid xs={12} sm={12} md={12} lg={12}>
          <DeleteReward setItem={setItem} />
        </Grid>
      )}
    </>
  );
};

export default Rewards;
