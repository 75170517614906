import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import DateFilter from "../DateFilterCharts";
import moment from "moment";

const UserSpendingAnalysis = (props) => {
  const {
    userSpendDetail,
    startDateForSpendData,
    endDateForSpendData,
    setStartDateForSpendData,
    setEndDateForSpendData
  } = props;

  const color = {
    0: colors.darkBlue,
    1: colors.yellow,
    2: colors.lightGreen,
    3: colors.palePurple,
    4: colors.paleBlue,
    5: colors.paleYellow
  };

  const type = userSpendDetail?.spendChart?.map((item, index) => ({
    title: item?.name,
    color: color[index]
  }));

  const pieChartData = userSpendDetail?.spendChart?.map((item, index) => ({
    name: item?.name || "",
    y: item?.averageMoneySpent || 0,
    color: color[index],
    boot: item?.averageMoneySpendBoot || 0,
    leaderboard: item?.averageMoneySpendLeaderboard || 0
  }));

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.white,
          borderRadius: "21px",
          px: 2.5,
          margin: "5px auto"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start"
          }}
        >
          <div>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              User spendings
            </Typography>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              Money spend on tournament and boot
            </Typography>
          </div>
          <div>
            <DateFilter
              startDate={startDateForSpendData}
              endDate={endDateForSpendData}
              onChangeStartDate={(newDate) => setStartDateForSpendData(newDate)}
              onChangeEndDate={(newDate) => setEndDateForSpendData(newDate)}
              disableFutureForStart={true}
              disableFutureForEnd={true}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <div style={{ textAlign: "center" }}>
            <DonutPieChart
              title={userSpendDetail?.avgMoneySpendAllGames || 0}
              subtitle="Avg. money spent"
              isGaming={false}
              data={pieChartData || []}
              partColors={[
                "#517DD1",
                "#FBCB20",
                "#9EDA89",
                "#C487E1",
                "#77E4C4",
                "#EAE459"
              ]}
              properties={{
                isUserSPending: true
              }}
            />
          </div>
          <div style={{ marginLeft: 100 }}>
            {type?.map((item) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", padding: 5 }}
                >
                  <div
                    style={{
                      width: 14,
                      height: 14,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></div>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item?.title}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <Typography
              color="#3C3C3C"
              fontSize="12px"
              fontWeight={400}
              sx={{ py: 0.5 }}
            >
              Multiplayer Boot
            </Typography>
            <Typography
              color="#3C3C3C"
              fontSize="14px"
              fontWeight={600}
              sx={{ py: 0.5 }}
            >
              {`${userSpendDetail?.avgMoneySpendBoot || 0} INR / player`}
            </Typography>
          </div>
          <div>
            <Typography
              color="#3C3C3C"
              fontSize="12px"
              fontWeight={400}
              sx={{ py: 0.5 }}
            >
              Tournament
            </Typography>
            <Typography
              color="#3C3C3C"
              fontSize="14px"
              fontWeight={600}
              sx={{ py: 0.5 }}
            >
              {`${userSpendDetail?.avgMoneySpendLeaderboard || 0} INR / player`}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserSpendingAnalysis;
