import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";

const TournamentSlot = (props) => {
  const { chartData, slotsFilled } = props;
  const type = [
    {
      title: "Registered but not played",
      color: " #FBCB20"
    },
    {
      title: "Game Played",
      color: "#9EDA89"
    },
    {
      title: "Slots Left",
      color: " #517DD1"
    }
  ];

  const pieChartData = chartData?.map((item, index) => ({
    name: item?.name || "",
    y: item?.value || 0,
    color:
      index === 0
        ? colors.yellow
        : index == 1
        ? colors.lightGreen
        : colors.darkBlue
  }));

  return (
    <Grid container>
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.white,
          borderRadius: "21px",
          padding: 2.5,
          height: "360px"
        }}
      >
        <Grid item md={8.5} lg={8.5}>
          <Box>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              Tournament Slots
            </Typography>
            <Typography color="#3C3C3C" fontSize="12px" fontWeight={400}>
              Total users for the tournament
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              mt: -4
            }}
          >
            <DonutPieChart
              title={slotsFilled || 0}
              subtitle="Slots Filled"
              isGaming={false}
              data={pieChartData}
              partColors={["#FBCB20", "#9EDA89", "#517DD1"]}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              pr: 3.5,
              mt: -2
            }}
          >
            <Typography color="#3C3C3C" fontSize="12px" fontWeight={400}>
              Slots available / Slots Filled
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3.5} lg={3.5}>
          <Box>
            {type.map((item) => {
              return (
                <Box
                  style={{ display: "flex", alignItems: "center", padding: 5 }}
                >
                  <Box
                    style={{
                      width: 14,
                      height: 12,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></Box>
                  <Typography
                    color="#464646"
                    fontSize="10px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TournamentSlot;
