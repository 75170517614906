import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query allUsers($filter: UserFilterInput!) {
    allUsers(filter: $filter) {
      status
      message
      users {
        id
        firstName
        lastName
        email
        role {
          id
          role
        }
      }
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query allRoles {
    allRoles {
      status
      message
      roles {
        id
        role
        users
        description
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      status
      message
    }
  }
`;

export const INVITE_USER = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUsers(input: $input) {
      status
      message
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      status
      message
    }
  }
`;

export const GET_ROLE_DETAILS = gql`
  query getRole($id: Int) {
    getRole(id: $id) {
      status
      message
      role {
        id
        role
        description
        details
      }
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation resendWelcomeEmail($id: Int!) {
    resendWelcomeEmail(id: $id) {
      status
      message
    }
  }
`;

export const GET_CONFIG_DATA = gql`
  query getConfig($key: String!) {
    getConfigDetails(key: $key) {
      status
      message
      config {
        key
        value
      }
    }
  }
`;

export const UPDATE_CONFIG_DATA = gql`
  mutation updateConfig($input: ConfigUpsertInput!) {
    addEditConfig(input: $input) {
      status
      message
    }
  }
`;

export const GET_ALGORITHM_DATA = gql`
  query getAlgorithm($key: String!, $isSpinTheWheel: Boolean!) {
    getSpinTheWheelAndMysterBoxAlgorithm(
      key: $key
      isSpinTheWheel: $isSpinTheWheel
    ) {
      status
      message
      algorithmDetails {
        key
        value
        algorithmHeaders
      }
    }
  }
`;
