import React, { useEffect } from "react";
import DynamicDateRangePicker from "./DynamicDateRangePicker";
import { Box, Grid, Menu, MenuItem } from "@mui/material";
import images from "assets/images/images";
import {
  GET_ALL_GAMES,
  GET_ALL_REGISTERED_USERS,
  GET_TOURNAMENTS
} from "app/graphql/exports";
import { useLazyQuery, useQuery } from "@apollo/client";
import { getJavascriptObject, clearSession } from "utils/helpers";
import { useHistory, useLocation } from "react-router-dom";

const AppHeaderIcons = (props) => {
  const { startDate, setStartDate, endDate, setEndDate } = props;

  const location = useLocation();

  const [filter, setFilter] = React.useState([
    "Normal",
    "Unlimited",
    "Free",
    "Triple",
    "Blitz"
  ]);

  const totalGames: string = sessionStorage.getItem("totalGames") || "0";
  const totalTournaments: string =
    sessionStorage.getItem("totalTournaments") || "0";
  const totalUsers: string = sessionStorage.getItem("totalUsers") || "0";
  const [getGamesData] = useLazyQuery(GET_ALL_GAMES, {
    variables: {
      page: 0,
      limit: parseInt(totalGames),
      search: ""
    },
    onCompleted: (responseData) => {
      console.log(responseData);
      const temp = responseData?.allGames?.games;
      const data = getJavascriptObject(temp, "GAMES");
      downloadCSV(data, "games.csv");
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const [getTournamentsData] = useLazyQuery(GET_TOURNAMENTS, {
    variables: {
      filter: {
        type: filter,
        title: ""
      },
      page: 0,
      limit: parseInt(totalTournaments)
    },
    onCompleted: (responseData) => {
      console.log(responseData);
      const temp = responseData?.allTournaments?.tournaments;
      const data = getJavascriptObject(temp, "TOURNAMENTS");
      downloadCSV(data, "tournaments.csv");
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const [getUsersData] = useLazyQuery(GET_ALL_REGISTERED_USERS, {
    variables: {
      page: 0,
      limit: parseInt(totalUsers)
    },
    onCompleted: (responseData) => {
      console.log(responseData);
      const temp = responseData?.registeredUsers?.users;
      const data = getJavascriptObject(temp, "USERS");
      downloadCSV(data, "users.csv");
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const handleFileDownload = () => {
    if (location.pathname.includes("/games")) {
      getGamesData();
    }
    if (
      location.pathname === "/tournaments-analytics" ||
      location.pathname === "/tournaments-configuration" ||
      location.pathname.includes("/tournaments/")
    ) {
      getTournamentsData();
    }
    if (location.pathname.includes("/users")) {
      getUsersData();
    }
  };

  const renderDownloadIconButtton = () => {
    if (
      location.pathname === "/games" ||
      location.pathname === "/tournaments-analytics" ||
      (location.pathname.includes("/tournaments/") &&
        !location.pathname.includes("/tournaments/add-edit/")) ||
      location.pathname === "/users" ||
      location.pathname === "/rewards"
    ) {
      return (
        <img
          src={images.download}
          alt="download"
          style={{ height: 23, cursor: "pointer", marginRight: "16px" }}
          onClick={handleFileDownload}
        />
      );
    }
  };

  const renderDateRangePicker = () => {
    if (location.pathname === "/games") {
      return (
        <DynamicDateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      );
    }
  };

  const history = useHistory();

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((item) => Object.values(item).join(",")).join("\n");
    return header + rows;
  };

  const profileImageRef = React.useRef(null);
  const [openLogout, setOpenLogout] = React.useState(false);
  const handelOpenLogout = () => {
    setOpenLogout(!openLogout);
  };
  const handelcloseLogout = () => {
    setOpenLogout(false);
  };

  const downloadCSV = (data, fileName) => {
    console.log(data);
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  };
  return (
    <Grid
      item
      xs={4}
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center"
      }}
    >
      {/* {renderDateRangePicker()} */}
      {renderDownloadIconButtton()}
      {/* <img
        src={images.search}
        alt="search"
        style={{ height: 18, cursor: "pointer" }}
      />
      <img
        src={images.notification}
        alt="notification"
        style={{ height: 22, cursor: "pointer" }}
      /> */}
      <img
        ref={profileImageRef}
        onClick={handelOpenLogout}
        src={images.profile_image}
        alt="profile"
        style={{ height: 40, cursor: "pointer" }}
      />
      <Menu
        id="campus-menu"
        // anchorEl={anchorEl}
        onClick={handelOpenLogout}
        anchorEl={profileImageRef.current}
        open={openLogout}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem
          onClick={() => {
            clearSession();
            history.push("/");
            handelcloseLogout();
            // handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default AppHeaderIcons;
