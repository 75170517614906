import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import { getDateInFormat } from "utils/helpers";

interface IStatisticsProps {
  data: any;
}

const Statistics = (props: IStatisticsProps) => {
  const { data } = props;

  function formatNumberToK(number, category) {
    if (number >= 1000) {
      const units = ["", " k", " M", " B", " T"];
      const magnitude = Math.floor(Math.log10(number) / 3);
      const roundedNumber = Math.ceil(number / Math.pow(1000, magnitude));
      return category === "money"
        ? "₹ " + roundedNumber + units[magnitude]
        : roundedNumber + units[magnitude];
    } else {
      return category === "money"
        ? "₹ " + number?.toString()
        : number?.toString();
    }
  }
  const dataModified = [
    {
      title: "Games",
      count: data?.games || 0,
      icon: images.games
      // percentage: 0,
    },
    {
      title: "Live Tournaments",
      count: data?.tournaments || 0,
      icon: images.tournaments
      // percentage: 0,
    },
    // {
    //   title: 'Downloads',
    //   count: '25,000',
    //   icon: images.download,
    //   percentage: -0.5,
    // },
    {
      title: "Users",
      count: data?.users ? formatNumberToK(data?.users, "users") : 0,
      icon: images.users,
      value: data?.liveUsers || 0
    },
    {
      title: "Total Deposits",
      count: data?.deposits ? formatNumberToK(data?.deposits, "money") : "₹ 0",
      icon: images.revenue
      // percentage: data?.perTotalRevenue,
    }
  ];

  const lastUpdatedInfo: any = getDateInFormat(
    parseInt(data?.lastUpdated),
    "DD/MM/YYYY hh:mm A",
    "changeFormat"
  );

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: "20px 10px",
          m: 1
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography
            color="#3C3C3C"
            fontSize="16px"
            fontWeight={600}
            sx={{ p: 1 }}
          >
            Statistics
          </Typography>
          <Typography
            color="#3C3C3C"
            fontSize="12px"
            fontWeight={400}
            sx={{ p: 1 }}
          >
            {`Last Updated on ${lastUpdatedInfo}`}
          </Typography>
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          {dataModified?.map((item) => {
            return (
              <div
                style={{
                  display: "flex"
                }}
              >
                <div
                  style={{
                    backgroundColor: "#FFF0E1",
                    height: 45,
                    width: 45,
                    borderRadius: "50%",
                    padding: "13px",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <img src={item.icon} alt="" style={{ fontSize: "10px" }} />
                </div>
                <div>
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    color="#1C1C1C"
                    fontSize="20px"
                    fontWeight={600}
                    sx={{ px: 1 }}
                  >
                    {item?.count}
                  </Typography>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item?.value && (
                      <img src={images.liveUser} alt="liveUser" />
                    )}
                    <Typography
                      color={"#3BB001"}
                      fontSize="12px"
                      fontWeight={700}
                      sx={{ px: 1 }}
                    >
                      {item?.value}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default Statistics;
