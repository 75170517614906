export const colors = {
  lightOrange: "#FBAF3A",
  lightBlue: "#A283FA",
  background: "#141414",
  backgroundLight: "#FFFAF4",
  invalidInput: "#FF8100",
  white: "#FFF9F2",
  blue: "#6573F6",
  green: "#2DBC66",
  red: "#EF0000",
  lightGreyIcon: "#3C3C3C",
  lighterGreyIcon: "#bdbdbd",
  redAsterisk: "#FF3B30",
  backgroundBoxLight: "#FEF7EE",
  FieldBackgroundLight: "#FFF1E4",
  TableHead: "#FFE4CB",
  TableHeadDark: "#FBB87A",
  lightGray: "#A8A8A8",
  lightGreyBorder: "#EFEFEF",
  TableRowPink: " #FF7E7E",
  TableRowYellow: " #E8D319",
  TableRowBlue: " #90D8F6",
  TableRowGreen: "#94F0B3",
  TabLightOrange: "#FFA447",
  MysteryBoxDarkPink: "#FF9292",
  MysteryBoxYellow: "#F0EA60",
  MysteryBoxLightBlue: "#90D8F6",
  MysteryBoxLightGreen: "#94F0B3",
  MysteryBoxLightOrange: "#FEC296",
  MysteryBoxPurple: "#FAB2F3",
  MysteryBoxBlue: "#B3BFFF",
  MysteryBoxGreen: "#D2EAA1",
  MysteryBoxLightPink: "#FDC3B1",
  purple: "#9F7FFC",
  orange: "#FF9D39",
  yellow: "#FBCB20",
  lightGreen: "#9EDA89",
  lighterGreen: "#43E06F",
  palePurple: "#C487E1",
  darkBlue: "#517DD1",
  paleBlue: "#77E4C4",
  paleYellow: "#EAE459",
  disabledFieldBackground: "#EFE4DA",

  primary: "#1B7EB3",
  primaryLight: "#F4F7FC",
  secondary: "#FFF9F2",
  success: "#11998E",
  warning: "#F09819",
  danger: "#FF5E62",
  fontPrimary: "#000000",
  tableHeader: "#8C8E8F",
  fontLight: "#555555",
  fontLighter: "rgba(85, 85, 85, 0.5)",
  // lightGray: '#585858',
  primaryFill: "rgba(27, 126, 179, 0.09)",
  successFill: "rgba(17, 153, 142, 0.15)",
  warningFill: "rgba(240, 152, 25, 0.15)",
  dangerFill: "rgba(255, 94, 98, 0.15)",
  chartTitle: "#464255",
  blueBackGround: "#E2EEF5",
  borderColor: "#E3E3E3",
  lightBgColor: "#F7F7F7",
  sucessGreen: "#20BC87",
  iconColor: "#949494",
  gray: "#444444",
  lighterGray: "#A4A5A8",
  strokeGray: "#F5F5F5"
};
