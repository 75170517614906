import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";

const GameAnalysis = (props) => {
  const { userDetails } = props;
  // const type = [
  //   {
  //     title: "Infinte Golf",
  //     color: "#517DD1"
  //   },
  //   {
  //     title: "Piano Tiles",
  //     color: "#FBCB20"
  //   },
  //   {
  //     title: "Stick Hero",
  //     color: "#9EDA89"
  //   },
  //   {
  //     title: "Zig Zag",
  //     color: "#C487E1"
  //   },
  //   {
  //     title: "Color Switch",
  //     color: "#77E4C4"
  //   },
  //   {
  //     title: "Doodle Jump",
  //     color: "#EAE459"
  //   }
  // ];

  const color = {
    0: colors.darkBlue,
    1: colors.yellow,
    2: colors.lightGreen,
    3: colors.palePurple,
    4: colors.paleBlue,
    5: colors.paleYellow
  };

  const type = userDetails?.gameClicksChart?.map((item, index) => ({
    title: item?.name,
    color: color[index]
  }));

  const pieChartData = userDetails?.gameClicksChart?.map((item, index) => ({
    name: item?.name || "",
    y: item?.value || 0,
    color: color[index]
  }));

  return (
    <Grid container>
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "21px",
          padding: 2.5,
          m: 1
        }}
      >
        <Grid item md={9} lg={9}>
          <Box>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              Game Analysis
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              maxHeight: "130px",
              paddingRight: 20
            }}
          >
            <Box style={{ textAlign: "end", justifyContent: "end" }}>
              <DonutPieChart
                title={userDetails?.averageGameClicks || 0}
                subtitle="Avg. Game Clicks"
                isGaming={false}
                data={pieChartData || []}
                partColors={[
                  "#517DD1",
                  "#FBCB20",
                  "#9EDA89",
                  "#C487E1",
                  "#77E4C4",
                  "#EAE459"
                ]}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} lg={3}>
          <Box>
            {type?.map((item) => {
              return (
                <Box
                  style={{ display: "flex", alignItems: "center", padding: 5 }}
                >
                  <Box
                    style={{
                      width: 14,
                      height: 12,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></Box>
                  <Typography
                    color="#464646"
                    fontSize="10px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item?.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GameAnalysis;
