import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { colors } from "../../styles/colors";

const PasswordResetConfirmation = (props) => {
  const history = useHistory();
  const { values, setFieldValue } = props;
  return (
    <Box
      sx={{
        py: { xs: 5, sm: 3 },
        px: { xs: 5, sm: 10 },
        backgroundColor: { xs: colors.background, sm: colors.background },
        minHeight: "110vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          borderRadius: "4px",
        }}
      >
        <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color="secondary" fontSize="20px" fontWeight={700}>
              FORGOT PASSWORD
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color="secondary" fontSize="20px" fontWeight={400}>
              A reset link is shared on the{" "}
              <span style={{ fontWeight: 800 }}>{values?.email || ""}</span>{" "}
              email ID
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color="secondary" fontSize="16px" fontWeight={500}>
              Didn’t receive link?
              <span
                style={{
                  color: "#FF8100",
                  padding: "0px 10px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFieldValue("emailConfirmationMessage", false);
                }}
              >
                Resend Link
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PasswordResetConfirmation;
