import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Grid,
  TextField,
  CircularProgress
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { colors } from "../../../../styles/colors";
import { Box } from "@mui/system";
import SpinTheWheelCriteria from "./AlgorithmComponents/SpinTheWheelCriteria";
import DurationConfigDropdown from "./AlgorithmComponents/DurationConfigDropdown";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import images from "assets/images/images";
import { useSnackbar } from "notistack";
import { UPDATE_CONFIG_DATA } from "app/graphql/userManagement";
import { useMutation } from "@apollo/client";
import { getJwtTokenDetails } from "utils/helpers";
import { FieldArray, withFormik } from "formik";
import * as Yup from "yup";
import DefaultDetailsForm from "./AlgorithmComponents/DefaultDetails";
interface IAlgoTableComponentProps {
  rewardCategory: string;
  algorithmConfigDetails: any;
  refetchConfigData: any;
}

const AlgorithmComponent = (props: any) => {
  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    isEditing,
    setIsEditing,
    isDurationEditing,
    setIsDurationEditing,
    rewardCategory,
    algorithmConfigDetails,
    levels,
    algorithmConfigKey,
    setAlgorithmConfigKey
  } = props;

  const { rolePermissions } = getJwtTokenDetails();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    handleSubmit();
  };

  return (
    <>
      <DefaultDetailsForm
        algorithmConfigKey={algorithmConfigKey}
        setAlgorithmConfigKey={setAlgorithmConfigKey}
      />
      <SpinTheWheelCriteria
        algorithmConfigKey={algorithmConfigKey}
        rewardCategory={rewardCategory}
        algorithmConfigDetails={algorithmConfigDetails}
      />
      <Box
        sx={{
          backgroundColor: colors.TableHead,
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          borderRadius: "4px 4px 0px 0px",
          pl: "20px,",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          p: 1
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            textAlign: "left",
            display: "inline-block",
            pl: 2
          }}
        >
          Reward Distribution Table
        </Typography>
        <Box sx={{ alignContent: "right" }}>
          {rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
            ?.VIEW_AND_EDIT && (
            <IconButton onClick={isEditing ? handleSave : handleEdit}>
              {isEditing ? (
                <DoneOutlinedIcon />
              ) : (
                <img src={images.EditIcon}></img>
              )}
            </IconButton>
          )}
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: colors.backgroundBoxLight,
          borderRadius: "0px 0px 4px 4px "
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: colors.TableHeadDark }}>
            <TableRow>
              <TableCell>TOURNAMENT TYPE</TableCell>
              <TableCell>CRITERIA</TableCell>
              <TableCell>{`LEVEL ${levels[0]}`}</TableCell>
              <TableCell>{`LEVEL ${levels[1]}`}</TableCell>
              <TableCell>{`LEVEL ${levels[2]}`}</TableCell>
              <TableCell>{`LEVEL ${levels[3]}`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              name="tableData"
              render={(arrayHelpers) => (
                <>
                  {values.tableData &&
                    values.tableData?.length > 0 &&
                    values.tableData?.map((row, index) => (
                      <>
                        <TableRow key={row.id}>
                          <TableCell>
                            {isEditing ? (
                              <>
                                {row.tournamentType !== 0 ? (
                                  <>
                                    <Grid container sx={{ width: "100%" }}>
                                      <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          mt: 1
                                        }}
                                      >
                                        <Typography fontSize={14}>
                                          {"Spend <="}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          mb: "4px"
                                        }}
                                      >
                                        <TextField
                                          sx={{
                                            backgroundColor:
                                              colors.backgroundBoxLight,
                                            color: "#020202",
                                            "& .MuiOutlinedInput-root": {
                                              "& fieldset": {
                                                border: "none",
                                                borderBottom: "2px solid",
                                                borderColor:
                                                  colors.lightGreyIcon,
                                                borderRadius: 0,
                                                width: "40px",
                                                ml: 1
                                              }
                                            },
                                            "& .MuiOutlinedInput-input": {
                                              height: "16px",
                                              paddingBottom: "4px"
                                            }
                                          }}
                                          value={row.maxSpend}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `tableData[${index}][maxSpend]`,
                                              parseInt(e.target.value)
                                            );
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                    {errors.tableData &&
                                      errors.tableData?.length > 0 &&
                                      errors?.tableData[index] &&
                                      errors?.tableData[index]?.maxSpend && (
                                        <Typography
                                          sx={{
                                            color: colors.danger,
                                            fontSize: "11px",
                                            fontWeight: 600,
                                            ml: 10
                                          }}
                                        >
                                          {errors?.tableData[index]?.maxSpend}
                                        </Typography>
                                      )}
                                  </>
                                ) : (
                                  "Free Tournament"
                                )}
                              </>
                            ) : (
                              <>
                                {row.maxSpend !== 0
                                  ? `Spend =< ${row.maxSpend}`
                                  : "Free Tournament"}
                              </>
                            )}
                          </TableCell>
                          <TableCell>
                            {isEditing ? (
                              <>
                                <TextField
                                  sx={{
                                    backgroundColor: colors.backgroundBoxLight,
                                    color: "#020202",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        border: "none",
                                        borderBottom: "2px solid",
                                        borderColor: colors.lightGreyIcon,
                                        borderRadius: 0,
                                        width: "40px",
                                        ml: 1
                                      }
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      height: "16px",
                                      paddingBottom: "4px"
                                    }
                                  }}
                                  value={row.criteria}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `tableData[${index}][criteria]`,
                                      parseInt(e.target.value)
                                    );
                                  }}
                                />
                                {errors.tableData &&
                                  errors.tableData?.length > 0 &&
                                  errors?.tableData[index] &&
                                  errors?.tableData[index]?.criteria && (
                                    <Typography
                                      sx={{
                                        color: colors.danger,
                                        fontSize: "11px",
                                        fontWeight: 600,
                                        ml: 1
                                      }}
                                    >
                                      {errors?.tableData[index]?.criteria}
                                    </Typography>
                                  )}
                              </>
                            ) : (
                              row.criteria
                            )}
                          </TableCell>
                          <TableCell>
                            {isEditing ? (
                              <>
                                <TextField
                                  sx={{
                                    backgroundColor: colors.backgroundBoxLight,
                                    color: "#020202",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        border: "none",
                                        borderBottom: "2px solid",
                                        borderColor: colors.lightGreyIcon,
                                        borderRadius: 0,
                                        width: "40px",
                                        ml: 1
                                      }
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      height: "16px",
                                      paddingBottom: "4px"
                                    }
                                  }}
                                  value={row.levels[0] * 100}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `tableData[${index}][levels][0]`,
                                      parseInt(e.target.value) / 100
                                    );
                                  }}
                                />
                                {errors.tableData &&
                                  errors.tableData?.length > 0 &&
                                  errors?.tableData[index] &&
                                  errors?.tableData[index]?.levels?.length >
                                    0 &&
                                  errors?.tableData[index]?.levels[0] && (
                                    <Typography
                                      sx={{
                                        color: colors.danger,
                                        fontSize: "11px",
                                        fontWeight: 600,
                                        ml: 1
                                      }}
                                    >
                                      {errors?.tableData[index]?.levels[0]}
                                    </Typography>
                                  )}
                              </>
                            ) : (
                              row.levels[0] * 100
                            )}
                          </TableCell>
                          <TableCell>
                            {isEditing ? (
                              <>
                                <TextField
                                  sx={{
                                    backgroundColor: colors.backgroundBoxLight,
                                    color: "#020202",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        border: "none",
                                        borderBottom: "2px solid",
                                        borderColor: colors.lightGreyIcon,
                                        borderRadius: 0,
                                        width: "40px",
                                        ml: 1
                                      }
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      height: "16px",
                                      paddingBottom: "4px"
                                    }
                                  }}
                                  value={row.levels[1] * 100}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `tableData[${index}][levels][1]`,
                                      parseInt(e.target.value) / 100
                                    );
                                  }}
                                />
                                {errors.tableData &&
                                  errors.tableData?.length > 0 &&
                                  errors?.tableData[index] &&
                                  errors?.tableData[index]?.levels?.length >
                                    0 &&
                                  errors?.tableData[index]?.levels[1] && (
                                    <Typography
                                      sx={{
                                        color: colors.danger,
                                        fontSize: "11px",
                                        fontWeight: 600,
                                        ml: 1
                                      }}
                                    >
                                      {errors?.tableData[index]?.levels[1]}
                                    </Typography>
                                  )}
                              </>
                            ) : (
                              row.levels[1] * 100
                            )}
                          </TableCell>
                          <TableCell>
                            {isEditing ? (
                              <>
                                <TextField
                                  sx={{
                                    backgroundColor: colors.backgroundBoxLight,
                                    color: "#020202",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        border: "none",
                                        borderBottom: "2px solid",
                                        borderColor: colors.lightGreyIcon,
                                        borderRadius: 0,
                                        width: "40px",
                                        ml: 1
                                      }
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      height: "16px",
                                      paddingBottom: "4px"
                                    }
                                  }}
                                  value={row.levels[2] * 100}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `tableData[${index}][levels][2]`,
                                      parseInt(e.target.value) / 100
                                    );
                                  }}
                                />
                                {errors.tableData &&
                                  errors.tableData?.length > 0 &&
                                  errors?.tableData[index] &&
                                  errors?.tableData[index]?.levels?.length >
                                    0 &&
                                  errors?.tableData[index]?.levels[2] && (
                                    <Typography
                                      sx={{
                                        color: colors.danger,
                                        fontSize: "11px",
                                        fontWeight: 600,
                                        ml: 1
                                      }}
                                    >
                                      {errors?.tableData[index]?.levels[2]}
                                    </Typography>
                                  )}
                              </>
                            ) : (
                              row.levels[2] * 100
                            )}
                          </TableCell>
                          <TableCell>
                            {isEditing ? (
                              <>
                                <TextField
                                  sx={{
                                    backgroundColor: colors.backgroundBoxLight,
                                    color: "#020202",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        border: "none",
                                        borderBottom: "2px solid",
                                        borderColor: colors.lightGreyIcon,
                                        borderRadius: 0,
                                        width: "40px",
                                        ml: 1
                                      }
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      height: "16px",
                                      paddingBottom: "4px"
                                    }
                                  }}
                                  value={row.levels[3] * 100}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `tableData[${index}][levels][3]`,
                                      parseInt(e.target.value) / 100
                                    );
                                  }}
                                />
                                {errors.tableData &&
                                  errors.tableData?.length > 0 &&
                                  errors?.tableData[index] &&
                                  errors?.tableData[index]?.levels?.length >
                                    0 &&
                                  errors?.tableData[index]?.levels[3] && (
                                    <Typography
                                      sx={{
                                        color: colors.danger,
                                        fontSize: "11px",
                                        fontWeight: 600,
                                        ml: 1
                                      }}
                                    >
                                      {errors?.tableData[index]?.levels[3]}
                                    </Typography>
                                  )}
                              </>
                            ) : (
                              row.levels[3] * 100
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </>
              )}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <DurationConfigDropdown
        algorithmDuration={algorithmConfigDetails?.algorithmDuration}
        isDurationEditing={isDurationEditing}
        setIsDurationEditing={setIsDurationEditing}
        handleSave={handleSave}
        {...props}
      />
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "130%",
            left: "57%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

const Algorithm = (props: any) => {
  const { algorithmConfigDetails, refetchConfigData, algorithmConfigKey } =
    props;

  console.log("algorithmConfigDetails", algorithmConfigDetails);

  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [isDurationEditing, setIsDurationEditing] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [duration, setDuration] = React.useState(null);

  const [durationCategory, setDurationCategory] = React.useState(null);

  React.useEffect(() => {
    setTableData(algorithmConfigDetails?.data?.distributionTable);
    setDuration(algorithmConfigDetails?.data?.algorithmDuration?.value);
    setDurationCategory(
      algorithmConfigDetails?.data?.algorithmDuration?.duration
    );
  }, [algorithmConfigDetails]);

  const [updateConfigData, { loading: updateConfigDataLoading }] = useMutation(
    UPDATE_CONFIG_DATA,
    {
      onCompleted: (data) => {
        const { addEditConfig } = data;
        const { status, message } = addEditConfig;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refetchConfigData();
          setIsEditing(false);
          setIsDurationEditing(false);
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  const AlgorithmWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        tableData: tableData || [{}],
        duration: duration || "",
        durationCategory: durationCategory || "",
        loading: updateConfigDataLoading
      };
    },

    validationSchema: Yup.object().shape({
      tableData: Yup.array().of(
        Yup.object().shape({
          maxSpend: Yup.number()
            .required("This is a required field")
            .integer("Decimals not allowed")
            .typeError("Please enter a valid number"),
          criteria: Yup.number()
            .required("This is a required field")
            .integer("Decimals not allowed")
            .typeError("Please enter a valid number"),
          levels: Yup.array().of(
            Yup.number()
              .required("This is a required field")
              .typeError("Please enter a valid number")
              .min(0, "Value cannot be less than 0")
              .max(100, "Value cannot be more than 100")
          )
        })
      ),
      duration: Yup.number()
        .required("This is a required field")
        .typeError("Please enter a valid number")
        .integer("Decimals not allowed")
        .min(1, 'Min. value " 1 " is required'),
      durationCategory: Yup.string()
        .required("This is a required field")
        .typeError("Please enter a valid number")
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);
      const value = {
        algorithmDuration: {
          value: values.duration,
          duration: values.durationCategory
        },
        distributionTable: values.tableData
      };
      updateConfigData({
        variables: {
          input: {
            key: algorithmConfigKey,
            value: JSON.stringify(value)
          }
        }
      });
    }
  })(AlgorithmComponent);
  return (
    <AlgorithmWrapped
      {...props}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      isDurationEditing={isDurationEditing}
      setIsDurationEditing={setIsDurationEditing}
      levels={algorithmConfigDetails?.levels}
    />
  );
};

export default Algorithm;
