import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation user($payload: LoginInput!) {
    login(input: $payload) {
      status
      message
      token
    }
  }
`;

export const CREATE_USER = gql`
  mutation user($payload: CreateUserInput!) {
    createUser(input: $payload) {
      status
      message
    }
  }
`;

export const GET_USER_DASHBOARD_DATA = gql`
  query userDashboard {
    userDashboard {
      status
      overallUserStatistics {
        onlineUsers
        dailyOnlineUsers
        weeklyOnlineUsers
        monthlyOnlineUsers
        offlineUsers
        zeroActivityUsers
      }
    }
  }
`;

export const GET_REGISTERED_USERS = gql`
  query registeredUsers(
    $page: Int
    $limit: Int
    $search: String
    $from: String
    $to: String
  ) {
    registeredUsers(
      page: $page
      limit: $limit
      from: $from
      to: $to
      search: $search
    ) {
      status
      count
      users {
        id
        firstName
        lastName
        joinedOn
        bootTournaments
        nickname
        leaderboardTournaments
        depositsBoot
        depositsLeaderboard
        winningsBoot
        winningsLeaderboard
        payout
        revenue
        status
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation user($input: UpdateUserInput!) {
    updateUser(input: $input) {
      status
      message
    }
  }
`;

export const GET_USERS_GROWTH_DASHBOARD_DATA = gql`
  query userGrowthDashboard($from: String!, $to: String!) {
    userGrowthDashboard(from: $from, to: $to) {
      growthDashboardStatistics {
        userTrends {
          name
          data {
            name
            value
          }
        }
      }
      registrationsPerMonth
      registrationsPerDay
      status
    }
  }
`;

export const GET_USERS_GAME_DASHBOARD_DATA = gql`
  query userGameDashboard($from: String!, $to: String!) {
    userGameDashboard(from: $from, to: $to) {
      gameDashboardStatistics {
        averageNumberOfGames
        gamesHistogram {
          name
          value
        }
      }
      status
    }
  }
`;

export const USER_DETAIL_HOURLY_DATA = gql`
  query userDetailsHourlyGraph($from: String!, $to: String!) {
    userDetailsHourlyGraph(from: $from, to: $to) {
      hourlyActivity {
        name
        value
      }
      status
    }
  }
`;

export const USER_DETAIL_SPEND_DATA = gql`
  query userDetailsSpend($from: String!, $to: String!) {
    userDetailsSpend(from: $from, to: $to) {
      status
      avgMoneySpendAllGames
      avgMoneySpendBoot
      avgMoneySpendLeaderboard
      spendChart {
        name
        averageMoneySpent
        users
        averageMoneySpendBoot
        averageMoneySpendLeaderboard
      }
    }
  }
`;

export const USER_DETAIL_DEMOGRAPHIC_DATA = gql`
  query userDetailsDemographic {
    userDetailsDemographic {
      status
      demographics {
        name
        value
      }
      avgWinnings
    }
  }
`;

export const SINGLE_USER_DETAILS = gql`
  query singleUserDetails($userId: Int!) {
    singleUserDetails(userId: $userId) {
      id
      mobileNo
      email
      dateOfJoining
      gamesPlayed
      tournamentsPlayed
      deposits
      winnings
      withdrawals
      revenue
      status
      profilePic
      gameClicks
      referrals
      referralEarnings
      averageGameClicks
      gameClicksChart {
        name
        value
      }
      firstName
      lastName
      nickname
    }
  }
`;

export const SINGLE_USER_TOURNAMENT_DETAILS = gql`
  query singleUserTournamentDetails($userId: Int!, $from: String, $to: String) {
    singleUserTournamentDetails(userId: $userId, from: $from, to: $to) {
      status
      tournaments {
        id
        game
        tournamentType
        dateAndTime
        prizePool
        position
        deposit
        winnings
      }
    }
  }
`;

export const SINGLE_USER_REFERRAL_DETAILS = gql`
  query singleUserReferralDetails($userId: Int!, $from: String, $to: String) {
    singleUserReferralDetails(userId: $userId, from: $from, to: $to) {
      status
      referrals {
        id
        nickname
        mobileNo
        joinedOn
        moneyEarned
        earnedThrough
      }
    }
  }
`;
