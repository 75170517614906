/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route, HashRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Backdrop, CircularProgress } from "@mui/material";
import { GlobalStyle } from "styles/global-styles";

// pages import
import Dashboard from "./pages/Dashboard";
import Games from "./pages/Games";
import GameDetails from "./pages/GameDetails";
import TournamentsAnalytics from "./pages/TournamentsAnalytics";
import TournamentsConfiguration from "./pages/TournamentsConfiguration";
import TournamentDetails from "./pages/TournamentDetails";
import TournamentBootDetails from "./pages/TournamentBootDetails";
import TournamentAddEdit from "./pages/TournamentAddEdit";
import Users from "./pages/Users";
import LoginForm from "./pages/LoginForm";
import SetNewPasswordForm from "./pages/SetNewPasswordForm";
import ResetPasswordConfirmationForm from "./pages/ResetPasswordConfirmationForm";
import PasswordResetlinkFrom from "./pages/PasswordResetLinkForm";
import UserDetailView from "./pages/UserAnalytics";
import UserInformation from "./pages/UserDetails";
import GameDeactivate from "./pages/GameDeactivate";
import TournamentDeactivate from "./pages/TournamentDeactivate";
import AddGames from "./pages/AddGames";
import EditGames from "./pages/EditGames";
import Rewards from "./pages/Rewards";
import AddRewards from "./pages/RewardsAddEdit";
import UserManagement from "./pages/UserManagement";

import AppApolloprovider from "./ApolloProvider";
import getStore from "store/zustandStore";
import ProtectedRoute from "./ProtectedRoute";
import { NotFoundPage } from "./pages/NotFoundPage/Loadable";

export function App() {
  const { i18n } = useTranslation();
  const { loading } = getStore();
  return (
    <HashRouter>
      <Helmet defaultTitle="GamesDuel" htmlAttributes={{ lang: i18n.language }}>
        <meta name="description" content="Admin Console" />
      </Helmet>
      <AppApolloprovider>
        <Switch>
          <Route exact path="/" component={LoginForm} />
          <Route exact path="/setpassword" component={SetNewPasswordForm} />
          <Route
            exact
            path="/get-reset-password-link"
            component={PasswordResetlinkFrom}
          />
          <ProtectedRoute
            exact
            path="/dashboard"
            component={Dashboard}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/games"
            component={Games}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/games/deactivate/:id"
            component={GameDeactivate}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/games/add"
            component={AddGames}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/games/edit/:id"
            component={EditGames}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/games/:id"
            component={GameDetails}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/tournaments/add-edit/:tournamentId"
            component={TournamentAddEdit}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/tournaments/deactivate/:tournamentId"
            component={TournamentDeactivate}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/tournaments/delete/:tournamentId"
            component={TournamentDeactivate}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/tournaments-analytics"
            component={TournamentsAnalytics}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/tournaments/:id"
            component={TournamentDetails}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/tournaments/boot/:id"
            component={TournamentBootDetails}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/tournaments-configuration"
            component={TournamentsConfiguration}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/users"
            component={Users}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/users/analytics"
            component={UserDetailView}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/users/:id"
            component={UserInformation}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/rewards"
            component={Rewards}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/rewards/add"
            component={AddRewards}
            authenticationPath="/"
          />
          <ProtectedRoute
            exact
            path="/user-management"
            component={UserManagement}
            authenticationPath="/"
          />
          <Route component={NotFoundPage} />
        </Switch>
      </AppApolloprovider>
      <GlobalStyle />
      <Backdrop
        open={loading}
        sx={{
          color: "#000000",
          opacity: "70%",
          zIndex: (theme) => theme.zIndex.drawer + 10001,
        }}
      >
        <CircularProgress />
      </Backdrop>
    </HashRouter>
  );
}
