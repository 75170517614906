import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import { useQuery } from "@apollo/client";
import { GET_ALL_GAMES_FOR_DROPDOWN } from "app/graphql/game";

const StatisticsChart = (props) => {
  const { gameStatistics, games } = props;

  console.log(gameStatistics);

  // const mostClickedGame = games?.find((item) => item?.id === 3);
  // const mostPaidOutGame = games?.find((item) => item?.id === 40);
  // const mostCommissionGame = games?.find((item) => item?.id === 41);

  function formatNumberToK(number, category) {
    if (number >= 1000) {
      const units = ["", " k", " M", " B", " T"];
      const magnitude = Math.floor(Math.log10(number) / 3);
      const roundedNumber = Math.ceil(number / Math.pow(1000, magnitude));
      return category === "money"
        ? "₹ " + roundedNumber + units[magnitude]
        : roundedNumber + units[magnitude];
    } else {
      return category === "money"
        ? "₹ " + number?.toString()
        : number?.toString();
    }
  }

  // const [statistics, setStatistics] = React.useState<any>([]);

  // React.useEffect(() => {
  //   const temp = [
  //     // {
  //     //   title: "Golf Duels",
  //     //   category: "Most played game",
  //     //   data: ""
  //     // },
  //     // {
  //     //   title: "Knife Hit",
  //     //   category: "Least Played Game",
  //     //   data: ""
  //     // },
  //     {
  //       title: gameStatistics?.mostClickedGame || "-",
  //       category: "Most clicked game",
  //       data: `${
  //         gameStatistics?.averageMostClickedGame
  //           ? formatNumberToK(gameStatistics?.averageMostClickedGame, "clicks")
  //           : 0
  //       } average clicks`
  //     },
  //     {
  //       title: gameStatistics?.leastClickedGame || "-",
  //       category: "Least clicked game",
  //       data: `${
  //         gameStatistics?.averageLeastClickedGame
  //           ? formatNumberToK(gameStatistics?.averageLeastClickedGame, "clicks")
  //           : 0
  //       } average clicks`
  //     },
  //     {
  //       title: gameStatistics?.mostPaidoutGame || "-",
  //       category: "Most paid out",
  //       data: `${
  //         gameStatistics?.averageMostPaidoutGame
  //           ? formatNumberToK(gameStatistics?.averageMostPaidoutGame, "money")
  //           : "₹ 0"
  //       } average pay out`
  //     },
  //     {
  //       title: gameStatistics?.mostCommissionGame || "-",
  //       category: "Highest Commission Game",
  //       data:
  //         `${
  //           gameStatistics?.averageMostCommissionGame
  //             ? formatNumberToK(
  //                 gameStatistics?.averageMostCommissionGame,
  //                 "money"
  //               )
  //             : "₹ 0"
  //         } average commission` || ""
  //     }
  //   ];
  //   setStatistics(temp);
  // }, [gameStatistics]);

  const statistics = [
    // {
    //   title: "Golf Duels",
    //   category: "Most played game",
    //   data: ""
    // },
    // {
    //   title: "Knife Hit",
    //   category: "Least Played Game",
    //   data: ""
    // },
    {
      title: gameStatistics?.mostClickedGame,
      category: "Most clicked game",
      data: `${
        gameStatistics?.averageMostClickedGame
          ? formatNumberToK(gameStatistics?.averageMostClickedGame, "clicks")
          : 0
      } average clicks`
    },
    {
      title: gameStatistics?.leastClickedGame,
      category: "Least clicked game",
      data: `${
        gameStatistics?.averageLeastClickedGame
          ? formatNumberToK(gameStatistics?.averageLeastClickedGame, "clicks")
          : 0
      } average clicks`
    },
    {
      title: gameStatistics?.mostPaidoutGame,
      category: "Most paid out",
      data: `${
        gameStatistics?.averageMostPaidoutGame
          ? formatNumberToK(gameStatistics?.averageMostPaidoutGame, "money")
          : "₹ 0"
      } average pay out`
    },
    {
      title: gameStatistics?.mostCommissionGame,
      category: "Highest Commission Game",
      data:
        `${
          gameStatistics?.averageMostCommissionGame
            ? formatNumberToK(
                gameStatistics?.averageMostCommissionGame,
                "money"
              )
            : "₹ 0"
        } average commission` || ""
    }
  ];

  return (
    <Grid container spacing={2}>
      <Grid item lg={2} md={2} sm={4} xs={6}>
        <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            px: 3,
            pb: 5,
            pt: 3,
            borderRadius: "4px"
          }}
        >
          <Typography
            fontSize="18px"
            fontWeight={600}
            color={colors.lightGreyIcon}
          >
            {gameStatistics?.totalActiveGames || 0}
          </Typography>
          <Typography
            fontSize="14px"
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Total Games
          </Typography>
        </Box>
      </Grid>
      {gameStatistics &&
        statistics?.map((x) => {
          if (x?.title) {
            return (
              <Grid key={x?.title} item lg={2.4} md={2.4} sm={4} xs={6}>
                <Box
                  sx={{
                    backgroundColor: colors.backgroundLight,
                    px: 3,
                    pt: 3,
                    pb: 5,
                    borderRadius: "4px",
                    position: "relative"
                  }}
                >
                  <Typography
                    fontSize="18px"
                    fontWeight={600}
                    color={colors.lightGreyIcon}
                  >
                    {x?.title}
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight={400}
                    color={colors.lightGreyIcon}
                    width={"max-content"}
                  >
                    {x?.category}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight={400}
                    color={colors.lightGreyIcon}
                    sx={{ position: "absolute", bottom: 3 }}
                  >
                    {x?.data}
                  </Typography>
                </Box>
              </Grid>
            );
          }
        })}
    </Grid>
  );
};

export default StatisticsChart;
