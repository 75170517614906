import { gql } from "@apollo/client";

export const CREATE_REWARD_CATEGORY = gql`
  mutation createRewardCategory($input: RewardCategoryCreateInput!) {
    createRewardCategory(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_REWARD_CATEGORY = gql`
  mutation updateRewardCategories($input: RewardCategoryUpdateInput!) {
    updateRewardCategory(input: $input) {
      status
      message
    }
  }
`;

export const GET_ALL_REWARD_CATEGORIES = gql`
  query getAllRewardCategories {
    rewardCategories {
      status
      message
      rewardCategories {
        id
        type
        brand
        status
        createdAt
      }
    }
  }
`;

export const GET_REWARD_CATEGORY_DETAIL = gql`
  query getRewardCategory($id: Int!) {
    getRewardCategoryById(id: $id) {
      status
      message
      rewardCategory {
        id
        type
        brand
      }
    }
  }
`;

export const GET_ALL_REWARDS = gql`
  query getAllRewards {
    rewards {
      status
      message
      rewards {
        id
        type {
          id
          type
        }
        value
        priority
        sliceDisplayText
        cardDisplayText
        subText
        brand
        status
        createdAt
        level
        expiryDate
        totalStock
        stockUsed
      }
    }
  }
`;

export const GET_ALL_REWARDS_FOR_DROPDOWN = gql`
  query getAllRewards {
    rewards {
      status
      message
      rewards {
        id
        sliceDisplayText
      }
    }
  }
`;

export const CREATE_REWARD = gql`
  mutation createReward(
    $input: RewardCreateInput!
    $logoCard1: Upload
    $logoCard2: Upload
  ) {
    createReward(input: $input, logoCard1: $logoCard1, logoCard2: $logoCard2) {
      status
      message
    }
  }
`;

export const UPDATE_REWARD = gql`
  mutation updateReward(
    $input: RewardUpdateInput!
    $logoCard1: Upload
    $logoCard2: Upload
  ) {
    updateReward(input: $input, logoCard1: $logoCard1, logoCard2: $logoCard2) {
      status
      message
    }
  }
`;

export const GET_REWARD_DETAIL = gql`
  query getReward($id: Int!) {
    getRewardById(id: $id) {
      status
      message
      reward {
        id
        type {
          id
          type
        }
        value
        priority
        sliceDisplayText
        cardDisplayText
        subText
        brand
        status
        expiryDate
        totalStock
        createdAt
      }
    }
  }
`;

export const DELETE_REWARD = gql`
  mutation deleteReward($id: Int!) {
    deleteReward(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_REWARD_CATEGORY = gql`
  mutation deleteRewardCategory($id: Int!) {
    deleteRewardCategory(id: $id) {
      status
      message
    }
  }
`;

export const REWARDS_DATA_ACCESS_AUTHENTICATION = gql`
  mutation checkPassword($password: String!) {
    checkPassword(password: $password) {
      status
      message
    }
  }
`;

export const REWARDS_DASHBOARD = gql`
  query rewardsDashboard {
    rewardsDashboard {
      lastUpdated
      status
      rewardsIssued
      rewardsIssuedValue
      spinTheWheelValue
      mysteryBoxValue
      acquiredUsersViaReferral
    }
  }
`;

export const REWARDS_BY_TIME = gql`
  query rewardsByTime($filter: String!) {
    rewardsByTime(filter: $filter) {
      rewardsByTime {
        name
        data {
          name
          value
        }
      }
    }
  }
`;

export const REWARDS_DASHBOARD_DETAILS = gql`
  query rewardsDashboardDetails($from: String!, $to: String!) {
    rewardsDashboardDetails(from: $from, to: $to) {
      rewardsByGameType {
        name
        value
      }
      rewardsClaimed
      status
    }
  }
`;

export const REWARDS_BY_TIME_SPAN = gql`
  query rewardsByTimeSpan($filter: String!) {
    rewardsByTimeSpan(filter: $filter) {
      rewardsByTimeSpan {
        name
        data {
          name
          value
        }
      }
      status
      mysteryBoxPercentage
      spinTheWheelPercentage
    }
  }
`;

export const REWARD_USERS = gql`
  query rewardUsers(
    $page: Int!
    $limit: Int!
    $from: String!
    $to: String!
    $search: String
  ) {
    rewardUsers(
      page: $page
      limit: $limit
      from: $from
      to: $to
      search: $search
    ) {
      status
      users {
        id
        nickname
        reward
        type
        brand
        received
        claimed
        amountSpent
        value
      }
      total
      lastUpdated
    }
  }
`;

export const REFERRAL_DASHBOARD_DETAILS = gql`
  query referralDashboardDetails($filter: String!) {
    referralDashboardDetails(filter: $filter) {
      costAcquisitionTrends {
        name
        value
      }
      totalAcquisitionCost
      status
    }
  }
`;

export const REFERRAL_TRENDS = gql`
  query referralDashboardDetails($filter: String!) {
    referralTrends(filter: $filter) {
      referralTrends {
        name
        value
      }
      status
    }
  }
`;

export const REFERRAL_USERS = gql`
  query referralUsers(
    $page: Int!
    $limit: Int!
    $from: String!
    $to: String!
    $search: String
  ) {
    referralUsers(
      page: $page
      limit: $limit
      from: $from
      to: $to
      search: $search
    ) {
      status
      users {
        id
        referrer
        referred
        signedOn
        referrerBonus
        referredBonus
        referrerDeposits
      }
      total
      lastUpdated
    }
  }
`;
