import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import Rewards from 'app/components/Rewards';

const RewardsPage = () => {
  const pageHeader = {
    title: 'Rewards Details',
    breadCrumb: 'Rewards',
  };
  return (
    <>
      <Helmet>
        <title>Rewards</title>
      </Helmet>
      <MiniDrawer
        content={<Rewards />}
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default RewardsPage;
