import React, { useMemo } from "react";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { FormikProps } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "styles/colors";
import { IAddGame } from ".";
import Table from "../Table";
import { GridColDef } from "@mui/x-data-grid";
import OrangeGradientButton from "../OrangeGradientButton";
import images from "assets/images/images";

const GameVideoTable = (props: FormikProps<IAddGame>) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = props;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "NAME",
        minWidth: 300,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ ml: 3 }}>
            <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
              {params?.value}
            </Typography>
            {Boolean(touched) &&
              touched.hasOwnProperty(params.row.id) &&
              Boolean(touched[params.row.id]) &&
              Boolean(errors) &&
              errors.hasOwnProperty(params.row.id) &&
              Boolean(errors[params.row.id]) && (
                <Typography fontSize="12px" color="red">
                  {Boolean(errors[params.row.id])
                    ? errors[params.row.id].toString()
                    : ""}
                </Typography>
              )}
          </Box>
        )
      },
      {
        field: "thumbnail",
        headerName: "THUMBNAIL",
        minWidth: 350,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          if (params.value === null) {
            return (
              <Box
                sx={{
                  backgroundColor: "#ffe4cb70",
                  height: "55px",
                  width: "187px",
                  borderRadius: "7px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography color="#8C8E8F" fontSize="10px">
                  No preview available
                </Typography>
              </Box>
            );
          } else {
            const url = URL.createObjectURL(values[params.row.id]);
            return (
              <video controls height="55px" width="187px">
                <source src={url} />
              </video>
            );
          }
        }
      },
      {
        field: "dimension",
        headerName: "DIMENSION REQ.",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={400}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.value}
          </Typography>
        )
      },
      {
        field: "size",
        headerName: "SIZE (MAX. 5 MB)",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={400}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.value}
          </Typography>
        )
      },
      {
        field: "reference",
        headerName: "REFERENCE",
        flex: 1,
        sortable: false,
        renderCell: (params) => <img src={params.value} width="60px" />
      },
      {
        field: "todo",
        headerName: "ACTIONS",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Upload
            accept=".mp4, .mov"
            onStart={() => setFieldTouched(params.row.id)}
            beforeUpload={(file: RcFile): Promise<void> => {
              setFieldTouched(params.row.id);
              if (file.size <= 5 * 1024 * 1024) {
                setFieldValue(params.row.id, file);
                return Promise.resolve();
              } else {
                alert("File size exceeds the maximum allowed (5MB)");
                return Promise.reject(
                  "File size exceeds the maximum allowed (5MB)"
                );
              }
            }}
          >
            <OrangeGradientButton
              title={values[params.row.id] !== null ? "Upload New" : "Upload"}
              style={{ marginLeft: 3, width: "122px" }}
            />
          </Upload>
        )
      }
    ],
    [values, errors, touched]
  );

  const rows = useMemo(
    () => [
      {
        id: "gameTutorial",
        name: "Game tutorial",
        thumbnail: values.gameTutorial,
        dimension: "343x275",
        size: values.gameTutorial
          ? `${(values.gameTutorial.size / (1024 * 1024)).toFixed(4)}MB`
          : "5 MB",
        reference: images.ref6
      }
      // {
      //   id: "tournamentScreen",
      //   name: "Tournament Screen",
      //   thumbnail: values.tournamentScreen,
      //   dimension: "343x275",
      //   size: values.tournamentScreen
      //     ? `${(values.tournamentScreen.size / (1024 * 1024)).toFixed(4)}MB`
      //     : "2MB",
      //   reference: images.ref5,
      // },
    ],
    [values]
  );

  return (
    <Box
      sx={{
        backgroundColor: colors.backgroundBoxLight,
        p: 3,
        borderRadius: "4px"
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            color={colors.lightGreyIcon}
            fontSize="16px"
            fontWeight={700}
          >
            ASSET DETAILS - VIDEOS <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Table
            rows={rows}
            columns={columns}
            headerStyle={{
              backgroundColor: "#FEF7EE",
              color: "#3c3c3c",
              fontSize: "14px",
              textAlign: "left",
              fontWeight: 600,
              cellBackground: "transparent",
              pl: 2
            }}
            disableSearch
            hideFooter
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GameVideoTable;
