import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import UserAnalytics from 'app/components/UserAnalytics';

const UserAnalyticsPage = () => {
  const pageHeader = {
    title: 'User Details',
    breadCrumb: 'Users / User analytics',
  };
  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <MiniDrawer
        content={<UserAnalytics />}
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default UserAnalyticsPage;
