import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import Users from 'app/components/Users';

const UsersPage = () => {
  const pageHeader = {
    title: 'User Details',
    breadCrumb: 'Users',
  };
  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <MiniDrawer content={<Users />} isHomepage={false} header={pageHeader} />
    </>
  );
};

export default UsersPage;
