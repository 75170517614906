import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors } from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.fontPrimary,
    },
  },
  typography: {
    fontFamily: 'Gilroy',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          fontWeight: 400,
          fontFamily: 'Gilroy'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '14px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: { fontSize: '14px' },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondary,
          borderRadius: '4px',
          '& .Mui-disabled': { backgroundColor: '#F4F7FC' },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
