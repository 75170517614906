import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import Introduction from "./introduction";
import Summary from "./Summary";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SINGLE_USER_DETAILS } from "app/graphql/users";

const UserDetails = () => {
  const uriParams: any = useParams();

  const [userDetails, setUserDetails] = React.useState({});

  const { loading } = useQuery(SINGLE_USER_DETAILS, {
    variables: { userId: parseInt(uriParams?.id) },
    skip: !uriParams?.id,
    onCompleted: (data) => {
      const { singleUserDetails } = data;
      setUserDetails(singleUserDetails);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12}>
        <Introduction userDetails={userDetails} />
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12}>
        <Summary userDetails={userDetails} userId={parseInt(uriParams?.id)} />
      </Grid>
    </Grid>
  );
};

export default UserDetails;
