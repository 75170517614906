import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import DateFilter from "../DateFilterCharts";
import moment from "moment";

interface IUserGameAnalysisProps {
  data: any;
  startDate?: any;
  endDate?: any;
  setStartDate?: any;
  setEndDate?: any;
}

const UserGameAnalysis = (props: IUserGameAnalysisProps) => {
  const { data, startDate, endDate, setStartDate, setEndDate } = props;

  const color = {
    0: colors.darkBlue,
    1: colors.yellow,
    2: colors.lightGreen,
    3: colors.palePurple,
    4: colors.paleBlue,
    5: colors.paleYellow
  };

  const type = data?.gamesHistogram?.map((item, index) => ({
    title: item?.name,
    color: color[index]
  }));

  const pieChartData = data?.gamesHistogram?.map((item, index) => ({
    name: item?.name || "",
    y: item?.value || 0,
    color: color[index]
  }));

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: 2,
          m: 1
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start"
          }}
        >
          <div>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              Game Analysis
            </Typography>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              Average user gaming hours
            </Typography>
          </div>
          <div>
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={(newDate) => setStartDate(newDate)}
              onChangeEndDate={(newDate) => setEndDate(newDate)}
              disableFutureForStart={true}
              disableFutureForEnd={true}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center"
          }}
        >
          {/* <div style={{ textAlign: "center" }}>
            <DonutPieChart
              title={`${data?.averageGameTime} hrs`}
              subtitle="Avg. Game time"
              isGaming={true}
              data={[
                {
                  y: 20,
                  title: "Infinte Golf",
                  color: "#517DD1",
                },
                {
                  y: 10,
                  title: "Piano Tiles",
                  color: "#FBCB20",
                },
                {
                  y: 10,
                  title: "Stick Hero",
                  color: "#9EDA89",
                },
                {
                  y: 40,
                  title: "Zig Zag",
                  color: "#C487E1",
                },
                {
                  y: 18,
                  title: "Color Switch",
                  color: "#77E4C4",
                },
                {
                  y: 12,
                  title: "Doodle Jump",
                  color: "#EAE459",
                },
              ]}
              partColors={[
                "#517DD1",
                "#FBCB20",
                "#9EDA89",
                "#C487E1",
                "#77E4C4",
                "#EAE459",
              ]}
            />
            <Typography
              color="#3C3C3C"
              fontSize="12px"
              fontWeight={400}
              sx={{ py: 1 }}
            >
              Average Game time per user
            </Typography>
          </div> */}
          <div style={{ textAlign: "center" }}>
            <DonutPieChart
              title={data?.averageNumberOfGames || 0}
              subtitle="Avg. No of games"
              isGaming={true}
              data={pieChartData || []}
              partColors={[
                "#517DD1",
                "#FBCB20",
                "#9EDA89",
                "#C487E1",
                "#77E4C4",
                "#EAE459"
              ]}
            />
            <Typography
              color="#3C3C3C"
              fontSize="12px"
              fontWeight={400}
              sx={{ py: 1 }}
            >
              Average no of games player per user
            </Typography>
          </div>
          <div>
            {type?.map((item) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", padding: 5 }}
                >
                  <div
                    style={{
                      width: 14,
                      height: 14,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></div>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item?.title}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserGameAnalysis;
