import React, { Dispatch, SetStateAction } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "styles/colors";
import OrangeGradientButton from "../OrangeGradientButton";
import { UPDATE_GAME } from "app/graphql/game";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";

interface IDeactivationReasonProps {
  setReasonViewDeleteDeactivate: Dispatch<SetStateAction<boolean>>;
  Gameid: any;
  refetch: any;
}

const DeactivationReason = (props: IDeactivationReasonProps) => {
  const { setReasonViewDeleteDeactivate , Gameid,refetch } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [textFieldValue, setTextFieldValue] = React.useState('');

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const [updateGame, { loading: updateGameLoading }] = useMutation(
    UPDATE_GAME,
    {
      onCompleted: (data) => {
        console.log(data);
        const { updateGame } = data;
        const { status, message } = updateGame;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          setReasonViewDeleteDeactivate(false),
          refetch()
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: colors.backgroundLight,
        p: 3,
        borderRadius: "4px",
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            color={colors.lightGreyIcon}
            fontSize="22px"
            fontWeight={600}
          >
            Are you sure you want to deactivate the game?
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            color={colors.lightGreyIcon}
            fontSize="18px"
            fontWeight={400}
          >
            This will remove the game and all related tournaments from the GD
            app. Make sure there are no active tournaments for the game.
          </Typography>
        </Grid>
        <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} ml={1.5} justifyContent="center" alignItems="center">
          <Grid item xs={3}>
            <Typography pt={1} ml={-3} fontSize={15}>
              Reason for deactivation
            </Typography>
          </Grid>
          <Grid item xs={9} sx={{ mt: 0.5 }}>
            <TextField
              size="small"
              sx={{
                width: "92%",
                borderRadius: "4px",
                backgroundColor: "#FFF1E4",
                ml:-18
              }}
              variant="outlined"
              fullWidth
              onChange={handleTextFieldChange}
            />
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 3 }}>
          <Box display="flex" alignItems="center">
            <OrangeGradientButton
              title="Deactivate"
              style={{ width: "133px" }}
              onClick={() => {

                const data = { "detail": textFieldValue };
                const jsonString = JSON.stringify(data);

                updateGame({
                  variables: {
                    input: { id: Gameid, status: "InActive", details: jsonString },

                  }
                });
              }}
            />
            <Button
              variant="contained"
              size="small"
              disableElevation
              sx={{
                background: "#8C8E8F40",
                borderRadius: "36px",
                fontWeight: 600,
                fontSize: "16px",
                letterSpacing: "0.02em",
                color: "#FFFFFF",
                width: "133px",
                ml: 2,
              }}
              onClick={() => setReasonViewDeleteDeactivate(false)}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeactivationReason;
