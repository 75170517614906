import React from "react";
import { Helmet } from "react-helmet-async";
import MiniDrawer from "app/components/Drawer";
import AddGame from "app/components/AddGames";

const AddGamePage = () => {
  const pageHeader = {
    title: "Add new Game",
    breadCrumb: "Games / Add new",
  };
  return (
    <>
      <Helmet>
        <title>Games</title>
      </Helmet>
      <MiniDrawer
        content={
          <>
            <AddGame />
          </>
        }
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default AddGamePage;
