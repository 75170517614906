export const tournamentTypes = [
  // {
  //   id: 11,
  //   name: "Normal"
  // },
  {
    id: 1,
    name: "Blitz",
  },
  {
    id: 2,
    name: "Unlimited",
  },
  {
    id: 3,
    name: "Free",
  },
  {
    id: 4,
    name: "Triple",
  },
];

export const frequencyTypes = [
  {
    id: 10,
    name: "Daily",
  },
  {
    id: 1,
    name: "Weekly",
  },
  {
    id: 2,
    name: "Monthly",
  },
];

export const acceptImageTypes =
  ".apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp";
