import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import CurvedGraph from "../CurvedGraph";
import ColumChart from "../ColumnLineChart";
import { formatNumberToK } from "utils/helpers";

interface IRevenueProps {
  data?: any;
  referralCostAcquisitionData: any;
  setReferralCostAcquisitionFilter: any;
}

const CostPerAcquisition = (props: IRevenueProps) => {
  const { referralCostAcquisitionData, setReferralCostAcquisitionFilter } =
    props;
  const chartProperties = {
    width: 1150,
    height: 300,
    backgroundColor: colors.backgroundLight
  };

  const xAxisLabels =
    referralCostAcquisitionData?.costAcquisitionTrends &&
    referralCostAcquisitionData?.costAcquisitionTrends?.length > 0
      ? referralCostAcquisitionData?.costAcquisitionTrends?.map(
          (item2) => item2?.name
        )
      : [];

  const xAxis = {
    categories: xAxisLabels,
    crosshair: true
  };

  const yAxis = [
    {
      title: {
        text: ""
      }
    },
    {
      title: {
        text: ""
      },
      opposite: true
    }
  ];

  const days = ["1D", "5D", "1M", "6M", "YTD"];

  const type = [
    {
      title: "Money Spend",
      color: colors.lightOrange
    }
    // {
    //   title: "Number of leads",
    //   color: colors.lightBlue
    // }
  ];

  const graphData = [
    {
      name: "Money Spent",
      type: "column",
      pointWidth: 30,
      yAxis: 0,
      zIndex: 1,
      data: referralCostAcquisitionData?.costAcquisitionTrends?.map((item) => ({
        name: item?.name || "",
        y: item?.value || 0,
        color: colors.orange
      }))
    }
    // {
    //   name: "Number of Leads",
    //   type: "line",
    //   color: colors.lightBlue,
    //   yAxis: 1,
    //   zIndex: 2,
    //   data: [
    //     {
    //       y: 5,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 15,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 15,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 25,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 10,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 7,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 10,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 22,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 24,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 10,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 18,
    //       color: colors.lightBlue
    //     },
    //     {
    //       y: 10,
    //       color: colors.lightBlue
    //     }
    //   ]
    // }
  ];

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: "20px 30px",
          m: 1
        }}
      >
        <Typography
          color="#3C3C3C"
          fontSize="16px"
          fontWeight={600}
          sx={{ p: 1 }}
        >
          Cost per Acquisition
        </Typography>
        <Typography
          color="#3C3C3C"
          fontSize="22px"
          fontWeight={700}
          sx={{ px: 1 }}
        >
          {referralCostAcquisitionData?.totalAcquisitionCost
            ? formatNumberToK(
                referralCostAcquisitionData?.totalAcquisitionCost,
                "money"
              )
            : "₹ 0"}
        </Typography>
        <Typography
          color="#3C3C3C"
          fontSize="13px"
          fontWeight={400}
          sx={{ px: 1 }}
        >
          Total acquisition cost
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {days?.map((item, index) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={600}
                    sx={{ px: 2.5, py: 1, cursor: "pointer" }}
                    onClick={() =>
                      setReferralCostAcquisitionFilter(item?.toLowerCase())
                    }
                  >
                    {item}
                  </Typography>
                  {index < days?.length - 1 && (
                    <Box
                      sx={{
                        height: 20,
                        width: 1.5,
                        backgroundColor: "rgba(168, 168, 168, 0.5)"
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {type?.map((item) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></Box>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item?.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        {graphData && (
          <ColumChart
            title={""}
            xAxis={xAxis}
            yAxis={yAxis}
            data={graphData}
            properties={chartProperties}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CostPerAcquisition;
