import React from "react";
import { Grid, Typography, Box, Divider } from "@mui/material";
import { colors } from "styles/colors";
import images from "assets/images/images";
import ColumChartStack from "../ColumnChartStack";
import { formatNumberToK } from "utils/helpers";

const TournamentAnalysisChart = (props) => {
  const { chartData, setFilter, gameDetails, totalTournaments } = props;

  const xAxisLabels = chartData ? chartData?.map((item) => item?.name) : [];

  const data = chartData?.map((item) => ({
    name: item?.name || "",
    y: item?.value || 0,
    color: colors.purple
  }));
  const graphData = [
    {
      name: "Torunaments Hosted",
      colorByPoint: true,
      pointWidth: 25,
      data: data
    }
  ];

  const duration = ["1D", "5D", "1M", "6M", "YTD"];

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            p: 3,
            borderRadius: "4px"
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography
                fontSize="18px"
                color={colors.lightGreyIcon}
                fontWeight={600}
              >
                {totalTournaments || ""}
              </Typography>
              <Typography
                fontSize="14px"
                color={colors.lightGreyIcon}
                fontWeight={400}
              >
                Total tournaments Hosted
              </Typography>
            </Box>
            <Box display="flex">
              {duration?.map((item, index) => {
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      color="#3C3C3C"
                      fontSize="13px"
                      fontWeight={600}
                      sx={{ px: 2.5, py: 1, cursor: "pointer" }}
                      onClick={() => setFilter(item?.toLowerCase())}
                    >
                      {item}
                    </Typography>
                    {index < duration.length - 1 && (
                      <Box
                        sx={{
                          height: 20,
                          width: 1.5,
                          backgroundColor: "rgba(168, 168, 168, 0.5)"
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
          <ColumChartStack
            properties={{ height: 285 }}
            xAxisLabels={xAxisLabels}
            data={graphData}
            partColors={["#9F7FFC"]}
          />
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            p: 3,
            borderRadius: "4px"
          }}
        >
          <Typography
            fontSize="14px"
            color={colors.lightGreyIcon}
            fontWeight={400}
          >
            Unique Tourn. Winners
          </Typography>
          <Typography
            fontSize="18px"
            color={colors.lightGreyIcon}
            fontWeight={600}
          >
            {gameDetails
              ? formatNumberToK(gameDetails?.uniqueLeaderboardWinners, "users")
              : 0}
          </Typography>
          {/* <Box display="flex" justifyContent="flex-end">
            <img src={images.upTrend} />
            <Typography
              color="#3BB001"
              fontSize="12px"
              fontWeight={700}
              sx={{ ml: 0.5 }}
            >
              23.4%
            </Typography>
          </Box> */}
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            p: 3,
            borderRadius: "4px"
          }}
        >
          <Typography
            fontSize="14px"
            color={colors.lightGreyIcon}
            fontWeight={400}
          >
            Unique Boot Winners
          </Typography>
          <Typography
            fontSize="18px"
            color={colors.lightGreyIcon}
            fontWeight={600}
          >
            {gameDetails
              ? formatNumberToK(gameDetails?.uniqueBootWinners, "users")
              : 0}
          </Typography>
          {/* <Box display="flex" justifyContent="flex-end">
            <img src={images.upTrend} />
            <Typography
              color="#3BB001"
              fontSize="12px"
              fontWeight={700}
              sx={{ ml: 0.5 }}
            >
              23.4%
            </Typography>
          </Box> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default TournamentAnalysisChart;
