import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import MiniDrawer from "app/components/Drawer";
import EditGame from "app/components/EditGames";
import { useParams } from "react-router-dom";
import { IGame } from "app/models/games";
import { useQuery } from "@apollo/client";
import { GET_GAME_DETAILS } from "app/graphql/game";
import getStore from "store/zustandStore";

const EditGamePage = () => {
  const uriParams: any = useParams();
  const { setLoading } = getStore();

  const [gameDetails, setGameDetails] = useState<IGame | null>(null);

  const { loading } = useQuery(GET_GAME_DETAILS, {
    variables: { id: parseInt(uriParams?.id), filter: "ytd" },
    skip: !uriParams?.id,
    onCompleted: (data) => {
      const { gameDashboardDetails: rawGameDetails } = data;
      const { status, game } = rawGameDetails;
      if (status) {
        setGameDetails(game);
      } else {
        setGameDetails(null);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const pageHeader = useMemo(
    () => ({
      title: "Game Details",
      breadCrumb: `Games / Edit ${gameDetails?.title || ""}`
    }),
    [gameDetails]
  );

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <>
      <Helmet>
        <title>EditGames</title>
      </Helmet>
      <MiniDrawer
        content={
          <>
            <EditGame gameDetails={gameDetails} />
          </>
        }
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default EditGamePage;
