import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { colors } from "styles/colors";
import images from "assets/images/images";
import DataTable from "../Table";
import GameAnalysis from "./gameAnalysis";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import Modal from "../Modal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DateFilter from "../DateFilter";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  SINGLE_USER_REFERRAL_DETAILS,
  SINGLE_USER_TOURNAMENT_DETAILS
} from "app/graphql/users";
import { getDateInFormat } from "utils/helpers";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Summary = (props) => {
  const { userDetails, userId } = props;

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("md"));

  const todayDate = new Date();
  const oneWeekAgo = new Date(todayDate);
  oneWeekAgo.setDate(todayDate.getDate() - 30);

  const [pageForTournaments, setPageForTournaments] = React.useState(0);
  const [pageSizeForTournaments, setPageSizeForTournaments] = React.useState(5);
  const [startDateForTournaments, setStartDateForTournaments] =
    React.useState(oneWeekAgo);
  const [endDateForTournaments, setEndDateForTournaments] =
    React.useState(todayDate);
  const [userTournamentDetails, setUserTournamentDetails] = React.useState([]);

  const { data: usersTournamentsData } = useQuery(
    SINGLE_USER_TOURNAMENT_DETAILS,
    {
      variables: {
        userId: userId,
        from: startDateForTournaments,
        to: endDateForTournaments
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  React.useEffect(() => {
    if (
      usersTournamentsData &&
      usersTournamentsData?.singleUserTournamentDetails?.status
    ) {
      console.log(usersTournamentsData);
      setUserTournamentDetails(
        usersTournamentsData?.singleUserTournamentDetails?.tournaments || []
      );
    }
  }, [usersTournamentsData]);

  const [pageForReferral, setPageForReferral] = React.useState(0);
  const [pageSizeForReferral, setPageSizeForReferral] = React.useState(5);
  const [startDateForReferral, setStartDateForReferral] =
    React.useState(oneWeekAgo);
  const [endDateForReferral, setEndDateForReferral] = React.useState(todayDate);
  const [userReferralDetails, setUserReferralDetails] = React.useState([]);

  const { data: usersReferralData } = useQuery(SINGLE_USER_REFERRAL_DETAILS, {
    variables: {
      userId: userId,
      from: startDateForReferral,
      to: endDateForReferral
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      usersReferralData &&
      usersReferralData?.singleUserReferralDetails?.status
    ) {
      console.log(usersReferralData);
      setUserReferralDetails(
        usersReferralData?.singleUserReferralDetails?.referrals || []
      );
    }
  }, [usersReferralData]);

  const columnsForOverview: any = React.useMemo(
    () => [
      {
        field: "game",
        headerName: "GAME",
        minWidth: 50,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.game}</Typography>
          </Box>
        )
      },
      {
        field: "type",
        headerName: "TYPE",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.tournamentType}
            </Typography>
          </Box>
        )
      },
      {
        field: "dateAndTime",
        headerName: "DATE & TIME",
        flex: 1.5,
        renderCell: (params) => {
          const DateAndTime: any = params?.row?.dateAndTime
            ? getDateInFormat(
                parseInt(params?.row?.dateAndTime),
                "DD/MM/YYYY",
                "changeFormat"
              )
            : null;
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "prizePool",
        headerName: "PRIZE POOL",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <CurrencyRupeeIcon fontSize="inherit" />
            <Typography fontSize={"14px"}>{params.row.prizePool}</Typography>
          </Box>
        )
      },
      // {
      //   field: "slot",
      //   headerName: "SLOT",
      //   flex: 1
      // },
      // {
      //   field: "position",
      //   headerName: "POSITION",
      //   flex: 1,
      //   renderCell: (params) => (
      //     <Box
      //       display={"flex"}
      //       justifyContent={"center"}
      //       alignItems={"baseline"}
      //       fontSize={"14px"}
      //     >
      //       <Typography fontSize={"14px"}>{params.row.position}</Typography>
      //     </Box>
      //   )
      // },
      {
        field: "deposit",
        headerName: "DEPOSIT",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <CurrencyRupeeIcon fontSize="inherit" />
            <Typography fontSize={"14px"}>{params.row.deposit}</Typography>
          </Box>
        )
      },
      {
        field: "winning",
        headerName: "WINNINGS",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <CurrencyRupeeIcon fontSize="inherit" />
            <Typography fontSize={"14px"}>{params.row.winnings}</Typography>
          </Box>
        )
      }
    ],
    []
  );
  const rowDataForOverview = [
    {
      id: "1",
      game: "Lorem Ipsum",
      type: "Tournament",
      dateAndTime: "10:00 PM, 10-03-2022",
      prizePool: "₹10000",
      slot: "100",
      position: "45",
      deposit: "₹100",
      winning: "₹10000"
    },
    {
      id: "2",
      game: "Lorem Ipsum",
      type: "Tournament",
      dateAndTime: "10:00 PM, 10-03-2022",
      prizePool: "₹10000",
      slot: "100",
      position: "45",
      deposit: "₹100",
      winning: "₹10000"
    },
    {
      id: "3",
      game: "Lorem Ipsum",
      type: "Tournament",
      dateAndTime: "10:00 PM, 10-03-2022",
      prizePool: "₹10000",
      slot: "100",
      position: "45",
      deposit: "₹100",
      winning: "₹10000"
    }
  ];

  const columnsForTickets: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "TICKET ID",
        minWidth: 50,
        flex: 1
      },
      {
        field: "type",
        headerName: "TYPE",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            onClick={() => setTicketModalOpen(true)}
          >
            <Typography fontSize={"14px"}>{params.row.type}</Typography>
          </Box>
        )
      },
      {
        field: "raisedOn",
        headerName: "RAISED ON",
        flex: 1
      },
      {
        field: "resolved",
        headerName: "RESOLVED ON",
        flex: 1
      },
      {
        field: "attachments",
        headerName: "ATTACHMENTS",
        flex: 1
      },
      {
        field: "status",
        headerName: "STATUS",
        flex: 1
      }
    ],
    []
  );
  const rowDataForTickets = [
    {
      id: "T01",
      type: "Lorem Ipsum",
      raisedOn: "10-03-2022",
      resolved: "11-03-2022",
      attachments: "Lorem Ipsum.jpg",
      status: "RESOLVED"
    },
    {
      id: "T01",
      type: "Lorem Ipsum",
      raisedOn: "10-03-2022",
      resolved: "11-03-2022",
      attachments: "Lorem Ipsum.jpg",
      status: "RESOLVED"
    },
    {
      id: "T01",
      type: "Lorem Ipsum",
      raisedOn: "10-03-2022",
      resolved: "11-03-2022",
      attachments: "Lorem Ipsum.jpg",
      status: "RESOLVED"
    }
  ];

  const columnsForReferrals: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "REF ID",
        minWidth: 50,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.id}</Typography>
          </Box>
        )
      },
      {
        field: "referredUser",
        headerName: "REFERRED USER",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.nickname}</Typography>
          </Box>
        )
      },
      {
        field: "mobile",
        headerName: "NUMBER",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.mobileNo}</Typography>
          </Box>
        )
      },
      // {
      //   field: "referredOn",
      //   headerName: "REFERRED ON",
      //   flex: 1
      // },
      {
        field: "joinedOn",
        headerName: "JOINED ON",
        flex: 1,
        renderCell: (params) => {
          const DateAndTime: any = params?.row?.joinedOn
            ? getDateInFormat(
                parseInt(params?.row?.joinedOn),
                "DD/MM/YYYY",
                "changeFormat"
              )
            : null;
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "moneyEarned",
        headerName: "MONEY EARNED",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <CurrencyRupeeIcon fontSize="inherit" />
            <Typography fontSize={"14px"}>{params.row.moneyEarned}</Typography>
          </Box>
        )
      },
      {
        field: "earnedThrough",
        headerName: "EARNED THROUGH",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.earnedThrough}
            </Typography>
          </Box>
        )
      }
    ],
    []
  );
  const rowDataForReferrals = [
    {
      id: "1",
      referredUser: "Lorem Ipsum",
      mobile: "9876543210",
      referredOn: "11-03-2022",
      joinedOn: "10-03-2022",
      moneyEarned: "₹100",
      earnedThrough: "Sign up"
    },
    {
      id: "2",
      referredUser: "Lorem Ipsum",
      mobile: "9876543210",
      referredOn: "11-03-2022",
      joinedOn: "10-03-2022",
      moneyEarned: "₹100",
      earnedThrough: "Sign up"
    },
    {
      id: "3",
      referredUser: "Lorem Ipsum",
      mobile: "9876543210",
      referredOn: "11-03-2022",
      joinedOn: "10-03-2022",
      moneyEarned: "₹100",
      earnedThrough: "Sign up"
    }
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [ticketModalOpen, setTicketModalOpen] = React.useState(false);
  const [allTicketsModalOpen, setAllTicketsModalOpen] = React.useState(false);

  const TicketModalContent = () => {
    return (
      <>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={14} fontWeight={400}>
              ID
            </Typography>
            <Typography fontSize={16} fontWeight={600}>
              T01
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
          >
            <Typography fontSize={14} fontWeight={400}>
              TYPE
            </Typography>
            <Typography fontSize={16} fontWeight={600}>
              Support Issue
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
          >
            <Typography fontSize={14} fontWeight={400}>
              RAISED ON
            </Typography>
            <Typography fontSize={16} fontWeight={600}>
              10-03-2022
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "start" }}>
              <Typography fontSize={14} fontWeight={400}>
                DETAILS
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                backgroundColor: colors.backgroundLight,
                borderRadius: "8px",
                mt: 1.5,
                p: 2
              }}
            >
              <Typography fontSize={14} fontWeight={400}>
                Ac et et sed bibendum fermentum morbi purus. Quam amet diam
                placerat nulla. Pulvinar nec sodales aenean aliquet in eget amet
                nam amet. Feugiat phasellus eleifend sit orci mauris ac. Quis
                sit ac nisl in. Facilisi venenatis tellus scelerisque malesuada
                id sit. Diam pellentesque kolestie ac morbi.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
          >
            <Typography fontSize={14} fontWeight={400}>
              ATTACHMENTS
            </Typography>
            <Typography fontSize={16} fontWeight={600}>
              Lorem Ipsum.jpg
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
          >
            <Typography fontSize={14} fontWeight={400}>
              STATUS
            </Typography>
            <Typography fontSize={16} fontWeight={600}>
              RESOLVED
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const allTickets = [
    {
      id: "T01",
      issue: "Support Issue",
      dateAndTime: "10-03-2022, 10:30 am",
      status: "Resolved"
    },
    {
      id: "T02",
      issue: "Support Issue",
      dateAndTime: "10-03-2022, 10:30 am",
      status: "Resolved"
    },
    {
      id: "T03",
      issue: "Support Issue",
      dateAndTime: "10-03-2022, 10:30 am",
      status: "Resolved"
    },
    {
      id: "T04",
      issue: "Support Issue",
      dateAndTime: "10-03-2022, 10:30 am",
      status: "Resolved"
    },
    {
      id: "T05",
      issue: "Support Issue",
      dateAndTime: "10-03-2022, 10:30 am",
      status: "Resolved"
    }
  ];

  const AllTicketsModalContent = () => {
    return (
      <>
        <TableContainer sx={{ maxHeight: "420px", overFlow: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ width: 50, px: 0.5, fontWeight: 500, fontSize: "12px" }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    width: 120,
                    px: 0.5,
                    fontWeight: 500,
                    fontSize: "12px"
                  }}
                >
                  Issue
                </TableCell>
                <TableCell
                  sx={{
                    width: 150,
                    px: 0.5,
                    fontWeight: 500,
                    fontSize: "12px"
                  }}
                >
                  Date And Time
                </TableCell>
                <TableCell
                  sx={{ width: 75, px: 0.5, fontWeight: 500, fontSize: "12px" }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTickets?.map((allTickets) => (
                <TableRow key={allTickets.id}>
                  <TableCell
                    sx={{
                      width: 50,
                      px: 0.5,
                      fontWeight: 500,
                      fontSize: "14px"
                    }}
                  >
                    {allTickets.id}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 120,
                      px: 0.5,
                      fontWeight: 500,
                      fontSize: "14px",
                      color: colors.invalidInput
                    }}
                    onClick={() => setTicketModalOpen(true)}
                  >
                    {allTickets.issue}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 150,
                      px: 0.5,
                      fontWeight: 500,
                      fontSize: "14px"
                    }}
                  >
                    {allTickets.dateAndTime}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 75,
                      px: 0.5,
                      fontWeight: 500,
                      fontSize: "14px"
                    }}
                  >
                    {allTickets.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <table>
          <tr>
            <th style={{width: '50px', padding:1}} >ID</th>
            <th >Issue</th>
            <th>Date And Time</th>
            <th>Status</th>
          </tr>
          <tr>
            <td >T01</td>
            <td>Support Issue</td>
            <td>10-03-2022, 10:30 am</td>
            <td>
              <Typography fontSize={14} fontWeight={500}>
                Resolved
              </Typography>
            </td>
          </tr>
          <tr>
            <td>T01</td>
            <td>Support Issue</td>
            <td>10-03-2022, 10:30 am</td>
            <td>Resolved</td>
          </tr>
          <tr>
            <td>T01</td>
            <td>Support Issue</td>
            <td>10-03-2022, 10:30 am</td>
            <td>Resolved</td>
          </tr>
          <tr>
            <td>T01</td>
            <td>Support Issue</td>
            <td>10-03-2022, 10:30 am</td>
            <td>Resolved</td>
          </tr>
          <tr>
            <td>T01</td>
            <td>Support Issue</td>
            <td>10-03-2022, 10:30 am</td>
            <td>Resolved</td>
          </tr>
          <tr>
            <td>T01</td>
            <td>Support Issue</td>
            <td>10-03-2022, 10:30 am</td>
            <td>Resolved</td>
          </tr>
          <tr>
            <td>T01</td>
            <td>Support Issue</td>
            <td>10-03-2022, 10:30 am</td>
            <td>Resolved</td>
          </tr>
          <tr>
            <td>T01</td>
            <td>Support Issue</td>
            <td>10-03-2022, 10:30 am</td>
            <td>Resolved</td>
          </tr>
        </table> */}
      </>
    );
  };

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12}>
        <Typography
          color="#3C3C3C"
          fontSize="20px"
          fontWeight={600}
          sx={{ px: 0.5, py: 0.5 }}
        >
          Summary
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} sx={{ color: colors.invalidInput }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: colors.invalidInput
            }
          }}
        >
          <Tab
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 500,
              fontSize: "14px"
            }}
            label="Overview"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 500,
              fontSize: "14px"
            }}
            label="Referrals"
            {...a11yProps(1)}
          />
          {/* <Tab
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 500,
              fontSize: '14px',
            }}
            label="Tickets"
            {...a11yProps(2)}
          /> */}
        </Tabs>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12}>
        <TabPanel value={value} index={0}>
          <Grid container>
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                mb: 3,
                px: 1,
                pt: 1,
                pb: 3,
                borderRadius: "15px"
              }}
            >
              {/* <Grid item container xs={12} sm={12} md={3.2} lg={3.2}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={12}
                  sx={{
                    backgroundColor: colors.invalidInput,
                    borderRadius: "15px"
                  }}
                >
                  <Box sx={{ m: 2 }}>
                    <Typography
                      color={colors.white}
                      fontSize="16px"
                      fontWeight={700}
                    >
                      Average time on the application
                    </Typography>
                    <Typography
                      color={colors.white}
                      fontSize="12px"
                      fontWeight={500}
                    >
                      per day
                    </Typography>
                  </Box>
                  <Box sx={{ m: 2, display: "flex" }}>
                    <Typography
                      color={colors.white}
                      fontSize="35px"
                      fontWeight={700}
                    >
                      3.1 hrs
                    </Typography>
                    <Box sx={{ ml: 5, mt: -2 }}>
                      <img
                        src={images.curve}
                        alt="curve"
                        style={{ height: 60, width: 120 }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={12}
                  sx={{
                    backgroundColor: colors.backgroundLight,
                    borderRadius: "15px",
                    mt: matchesSm ? 0 : 2,
                    // ml: matchesSm ? 1 : 0,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Box
                    sx={{
                      m: 1,
                      p: 1,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Box fontSize={"45px"}>
                      <AccessTimeIcon fontSize="inherit" />
                    </Box>

                    <Box ml={1}>
                      <Typography color="#3C3C3C" fontSize="12px">
                        Total time in the application
                      </Typography>
                      <Typography
                        color="#3C3C3C"
                        fontSize="16px"
                        fontWeight={600}
                      >
                        1023 hrs
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  backgroundColor: colors.backgroundLight,
                  borderRadius: "15px",
                  mx: matchesSm ? 0 : 2,
                  mt: matchesSm ? 2 : 0
                }}
              >
                <GameAnalysis userDetails={userDetails} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3.2}
                lg={3.2}
                sx={{
                  backgroundColor: colors.backgroundLight,
                  borderRadius: "15px",
                  height: "fit-content",
                  mt: matchesSm ? 2 : 0
                }}
              >
                <Box
                  sx={{
                    m: 1,
                    p: 1,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Box fontSize={"45px"}>
                    <AccessTimeIcon fontSize="inherit" />
                  </Box>

                  <Box ml={1}>
                    <Typography
                      color="#3C3C3C"
                      fontSize="12px"
                      fontWeight={400}
                    >
                      Total Game Clicks
                    </Typography>
                    <Typography
                      color="#3C3C3C"
                      fontSize="16px"
                      fontWeight={600}
                    >
                      {userDetails?.gameClicks || 0}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                backgroundColor: colors.backgroundLight,
                borderRadius: "15px"
              }}
            >
              <div style={{ margin: 5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between"
                    // marginBottom: 1.5
                  }}
                >
                  <Box>
                    <Typography
                      color="#3C3C3C"
                      fontSize="16px"
                      fontWeight={600}
                      sx={{ p: 1 }}
                    >
                      Tournament Details
                    </Typography>
                    <Typography
                      color="#3C3C3C"
                      fontSize="12px"
                      fontWeight={400}
                      sx={{ px: 1 }}
                    >
                      Details of all the tournaments and Boot played by the user
                    </Typography>
                  </Box>
                  <Box>
                    <DateFilter
                      startDate={startDateForTournaments}
                      endDate={endDateForTournaments}
                      onChangeStartDate={(newDate) =>
                        setStartDateForTournaments(newDate)
                      }
                      onChangeEndDate={(newDate) =>
                        setEndDateForTournaments(newDate)
                      }
                      disableFutureForStart={true}
                      disableFutureForEnd={true}
                    />
                  </Box>
                </Box>

                <div style={{ padding: 10 }}>
                  <DataTable
                    // getRowId={(row) => row?.game}
                    rows={userTournamentDetails}
                    columns={columnsForOverview}
                    paginationMode="client"
                    page={pageForTournaments}
                    onPageChange={(page) => setPageForTournaments(page)}
                    pageSize={pageSizeForTournaments}
                    onPageSizeChange={(pageSize) => {
                      setPageSizeForTournaments(pageSize);
                      setPageForTournaments(0);
                    }}
                    rowCount={userTournamentDetails?.length || 10}
                    loading={false}
                    pagination={true}
                    // checkboxSelection
                    disableSearch={true}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container>
            <Grid
              item
              lg={9}
              md={9}
              sm={12}
              xs={12}
              sx={{
                backgroundColor: colors.backgroundLight,
                borderRadius: "15px"
              }}
            >
              <div style={{ margin: 5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between"
                    // marginBottom: 2.5
                  }}
                >
                  <Box>
                    <Typography
                      color="#3C3C3C"
                      fontSize="16px"
                      fontWeight={600}
                      sx={{ p: 1 }}
                    >
                      Referral Details
                    </Typography>
                  </Box>
                  <Box>
                    <DateFilter
                      startDate={startDateForReferral}
                      endDate={endDateForReferral}
                      onChangeStartDate={(newDate) =>
                        setStartDateForReferral(newDate)
                      }
                      onChangeEndDate={(newDate) =>
                        setEndDateForReferral(newDate)
                      }
                      disableFutureForStart={true}
                      disableFutureForEnd={true}
                    />
                  </Box>
                </Box>
                <div style={{ padding: 10 }}>
                  <DataTable
                    // getRowId={(row) => row?.referralId}
                    rows={userReferralDetails || []}
                    columns={columnsForReferrals}
                    paginationMode="client"
                    page={pageForReferral}
                    onPageChange={(page) => setPageForReferral(page)}
                    pageSize={pageSizeForTournaments}
                    onPageSizeChange={(pageSize) => {
                      setPageSizeForReferral(pageSize);
                      setPageForReferral(0);
                    }}
                    rowCount={userReferralDetails?.length || 10}
                    loading={false}
                    pagination={true}
                    // checkboxSelection
                    disableSearch={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Box
                sx={{
                  backgroundColor: colors.backgroundLight,
                  mx: 1,
                  mt: matchesSm ? 2 : 0,
                  px: 1,
                  pt: 1,
                  pb: 3,
                  borderRadius: "15px"
                }}
              >
                <Typography
                  color="#3C3C3C"
                  fontSize="16px"
                  fontWeight={600}
                  sx={{ p: 1.5 }}
                >
                  Overview
                </Typography>
                <Box
                  sx={{
                    mt: 1.5,
                    mx: 1.5,
                    p: 1.5,
                    borderRadius: "5px",
                    backgroundColor: "#FFF0E1",
                    display: "flex"
                  }}
                >
                  <Box>
                    <PeopleAltOutlinedIcon fontSize="large" />
                  </Box>

                  <Box ml={1}>
                    <Typography color="#3C3C3C" fontSize="12px">
                      Total Referrals
                    </Typography>
                    <Typography
                      color="#3C3C3C"
                      fontSize="16px"
                      fontWeight={600}
                    >
                      {userDetails?.referrals || 0}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 1.5,
                    mx: 1.5,
                    p: 1.5,
                    borderRadius: "5px",
                    backgroundColor: "#FFF0E1",
                    display: "flex"
                  }}
                >
                  <Box>
                    <CurrencyRupeeIcon fontSize="large" />
                  </Box>

                  <Box ml={1}>
                    <Typography color="#3C3C3C" fontSize="12px">
                      Total Amount Earned
                    </Typography>
                    <Typography
                      color="#3C3C3C"
                      fontSize="16px"
                      fontWeight={600}
                    >
                      {userDetails?.referralEarnings || "₹ 0"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container>
            <Grid
              item
              lg={9}
              md={9}
              sm={12}
              xs={12}
              sx={{
                backgroundColor: colors.backgroundLight,
                borderRadius: "15px"
              }}
            >
              <div style={{ margin: 5 }}>
                <Typography
                  color="#3C3C3C"
                  fontSize="16px"
                  fontWeight={600}
                  sx={{ p: 1 }}
                >
                  Tickets List
                </Typography>
                <div style={{ padding: 10 }}>
                  <DataTable
                    rows={rowDataForTickets}
                    columns={columnsForTickets}
                    disableSearch={true}
                    hideFooterPagination={true}
                  />
                </div>
              </div>
              <Box>
                <Modal
                  open={ticketModalOpen}
                  setOpen={setTicketModalOpen}
                  zIndex={1000}
                  headerTitle={"Ticket Details"}
                  content={<TicketModalContent />}
                  actions={
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          background:
                            "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                          borderRadius: "36px",
                          height: "38px",
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#FFF9F2",
                          width: "130px",
                          mb: 3
                        }}
                        onClick={() => setTicketModalOpen(false)}
                      >
                        Close
                      </Button>
                    </Box>
                  }
                />
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Box
                sx={{
                  backgroundColor: colors.backgroundLight,
                  mx: 1,
                  mt: matchesSm ? 2 : 0,
                  px: 1,
                  pt: 1,
                  pb: 3,
                  borderRadius: "15px"
                }}
                onClick={() => setAllTicketsModalOpen(true)}
              >
                <Typography
                  color="#3C3C3C"
                  fontSize="16px"
                  fontWeight={600}
                  sx={{ p: 1.5 }}
                >
                  Overview
                </Typography>
                <Box
                  sx={{
                    mt: 1.5,
                    mx: 1.5,
                    p: 1.5,
                    borderRadius: "5px",
                    backgroundColor: "#FFF0E1",
                    display: "flex"
                  }}
                >
                  <Box>
                    <DescriptionOutlinedIcon fontSize="large" />
                  </Box>

                  <Box ml={1}>
                    <Typography color="#3C3C3C" fontSize="12px">
                      Tickets Raised
                    </Typography>
                    <Typography
                      color="#3C3C3C"
                      fontSize="16px"
                      fontWeight={600}
                    >
                      10
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 1.5,
                    mx: 1.5,
                    p: 1.5,
                    borderRadius: "5px",
                    backgroundColor: "#FFF0E1",
                    display: "flex"
                  }}
                >
                  <Box>
                    <DescriptionOutlinedIcon fontSize="large" />
                  </Box>

                  <Box ml={1}>
                    <Typography color="#3C3C3C" fontSize="12px">
                      Tickets Due
                    </Typography>
                    <Typography
                      color="#3C3C3C"
                      fontSize="16px"
                      fontWeight={600}
                    >
                      0
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    m: 1.5,
                    p: 1.5,
                    borderRadius: "5px",
                    backgroundColor: "#FFF0E1",
                    display: "flex"
                  }}
                >
                  <Box>
                    <TaskOutlinedIcon fontSize="large" />
                  </Box>

                  <Box ml={1}>
                    <Typography color="#3C3C3C" fontSize="12px">
                      Tickets Resolved
                    </Typography>
                    <Typography
                      color="#3C3C3C"
                      fontSize="16px"
                      fontWeight={600}
                    >
                      10
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Modal
                  open={allTicketsModalOpen}
                  setOpen={setAllTicketsModalOpen}
                  zIndex={1000}
                  headerTitle={"Ticket Details"}
                  content={<AllTicketsModalContent />}
                  actions={
                    // <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    //   <Button
                    //     size="small"
                    //     variant="contained"
                    //     sx={{
                    //       background:
                    //         'linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)',
                    //       borderRadius: '36px',
                    //       height: '38px',
                    //       fontWeight: 400,
                    //       fontSize: '16px',
                    //       color: '#FFF9F2',
                    //       width: '130px',
                    //       mb: 3,
                    //     }}
                    //     onClick={() => setTicketModalOpen(false)}
                    //   >
                    //     Close
                    //   </Button>
                    // </Box>
                    <></>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default Summary;
