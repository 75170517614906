import React, { useMemo, useState } from "react";
import {
  Box,
  Chip,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { colors } from "styles/colors";
import Table from "../Table";
import { Circle, Search } from "@mui/icons-material";
import DateFilter from "../DateFilter";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  GAME_BOOT_TOURNAMENTS,
  GAME_LEADERBOARD_TOURNAMENTS
} from "app/graphql/game";
import { getDateInFormat } from "utils/helpers";

const TableTabs = (props) => {
  const { gameId } = props;
  const [value, setValue] = useState(0);
  const todayDate = new Date();
  const oneWeekAgo = new Date(todayDate);
  oneWeekAgo.setDate(todayDate.getDate() - 30);

  const [leaderboardPage, setLeaderboardPage] = React.useState(0);
  const [leaderboardPageLimit, setLeaderboardPageLimit] = React.useState(10);
  const [leaderboardSearchText, setLeaderboardSearchText] = React.useState("");
  const [leaderboardSearchTextTemp, setLeaderboardSearchTextTemp] =
    React.useState("");
  const [leaderboardStartDate, setLeaderboardStartDate] = useState(oneWeekAgo);
  const [leaderboardEndDate, setLeaderboardEndDate] = useState(todayDate);
  const [leaderboardDetails, setLeaderboardDetails] = React.useState<any>({});

  const fireLeaderboardSearchAPICall = (searchValue) => {
    setLeaderboardSearchText(searchValue);
  };
  const [timer, setTimer] = React.useState<any>(null);

  const requestLeaderboardSearch = (searchValue: string) => {
    setLeaderboardSearchTextTemp(searchValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fireLeaderboardSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const { data: leaderboardData, loading: leaderboardDataLoading } = useQuery(
    GAME_LEADERBOARD_TOURNAMENTS,
    {
      variables: {
        gameId: gameId,
        page: leaderboardPage,
        limit: leaderboardPageLimit,
        from: leaderboardStartDate,
        to: leaderboardEndDate,
        search: leaderboardSearchText
      },
      skip: value !== 0,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  React.useEffect(() => {
    if (
      leaderboardData &&
      leaderboardData?.singleGameLeaderboardDetails &&
      leaderboardData?.singleGameLeaderboardDetails?.status
    ) {
      console.log(leaderboardData);
      const data = leaderboardData?.singleGameLeaderboardDetails;
      setLeaderboardDetails(data || {});
    }
  }, [leaderboardData]);

  const [bootPage, setBootPage] = React.useState(0);
  const [bootPageLimit, setBootPageLimit] = React.useState(10);
  // const [bootSearchText, setBootSearchText] = React.useState("");
  // const [bootSearchTextTemp, setBootSearchTextTemp] = React.useState("");
  const [bootStartDate, setBootStartDate] = useState(oneWeekAgo);
  const [bootEndDate, setBootEndDate] = useState(todayDate);
  const [bootDetails, setBootDetails] = React.useState<any>({});

  // const fireBootSearchAPICall = (searchValue) => {
  //   setBootSearchText(searchValue);
  // };
  // const requestBootSearch = (searchValue: string) => {
  //   setBootSearchTextTemp(searchValue);
  //   if (timer) {
  //     clearTimeout(timer);
  //     setTimer(null);
  //   }
  //   setTimer(
  //     setTimeout(() => {
  //       fireBootSearchAPICall(searchValue);
  //     }, 1000)
  //   );
  // };

  const { loading: bootDataLoading } = useQuery(GAME_BOOT_TOURNAMENTS, {
    variables: {
      gameId: gameId,
      page: bootPage,
      limit: bootPageLimit,
      from: bootStartDate,
      to: bootEndDate
      // search: bootSearchText
    },
    skip: value !== 1,
    onCompleted: (data) => {
      const dataTemp = data?.singleGameBootDetails;
      console.log("data", dataTemp);
      setBootDetails(dataTemp || {});
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  // React.useEffect(() => {
  //   if (
  //     bootData &&
  //     bootData?.singleGameBootDetails &&
  //     bootData?.singleGameBootDetails?.status
  //   ) {
  //     console.log("bootData", bootData);
  //     const data = bootData?.singleGameBootDetails;
  //     console.log("data", data);
  //     setBootDetails(data || {});
  //   }
  // }, [bootData]);

  const columnsForTournaments: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 0.5,
        headerAlign: "center",
        align: "center"
      },
      {
        field: "name",
        headerName: "NAME",
        flex: 2,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.title || 0}</Typography>
          </Box>
        )
      },
      {
        field: "startDate",
        headerName: "START DATE",
        flex: 1.8,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.start),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "endDate",
        headerName: "END DATE",
        flex: 1.8,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.end),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "prizePool",
        headerName: "PRIZE POOL",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.prizePool || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "entry",
        headerName: "ENTRY",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.entry || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "slot",
        headerName: "SLOT",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.slots || 0}</Typography>
          </Box>
        )
      },
      {
        field: "filled",
        headerName: "FILLED",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.filled || 0}</Typography>
          </Box>
        )
      },
      {
        field: "revenue",
        headerName: "REVENUE",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.revenue || 0
            }`}</Typography>
          </Box>
        )
      },
      // {
      //   field: "upj",
      //   headerName: "UPJ",
      //   minWidth: 150,
      //   flex: 1,
      //   renderCell: (params) => (
      //     <Box
      //       display={"flex"}
      //       justifyContent={"center"}
      //       alignItems={"baseline"}
      //       fontSize={"14px"}
      //     >
      //       <Typography fontSize={"14px"}>{`₹ ${
      //         params.row.upj || 0
      //       }`}</Typography>
      //     </Box>
      //   )
      // },
      {
        field: "winners",
        headerName: "WINNERS",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.winners || 0}</Typography>
          </Box>
        )
      },
      {
        field: "status",
        headerName: "STATUS",
        flex: 1.2,
        renderCell: (params) => (
          <Chip
            label={
              params.value === "Live" ? (
                <Box display="flex" alignItems="center">
                  <Circle sx={{ fontSize: 10, color: "#FF3B30", mr: 0.5 }} />
                  <Typography fontSize="12px" fontWeight={600}>
                    {params.value.toUpperCase()}
                  </Typography>
                </Box>
              ) : (
                <Typography fontSize="12px" fontWeight={600}>
                  {params.value.toUpperCase()}
                </Typography>
              )
            }
            size="small"
            sx={{
              width: "89px",
              height: "24px",
              borderRadius: "8px",
              color: params.value === "Live" ? "#FF3B30" : "#43E06F",
              backgroundColor:
                params.value === "Live" ? "#FF3B3010" : "#43E06F20"
            }}
          />
        )
      }
    ],
    []
  );

  const columnsForBoot: GridColDef[] = useMemo(
    () => [
      {
        field: "tournamentId",
        headerName: "TOURNAMENT ID",
        flex: 1.5,
        headerAlign: "center",
        align: "center"
      },
      // {
      //   field: "name",
      //   headerName: "NAME",
      //   minWidth: 200,
      //   flex: 1
      // },
      {
        field: "startDate",
        headerName: "START DATE",
        flex: 2,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.start),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "endDate",
        headerName: "END DATE",
        flex: 2,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.end),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "bootPool",
        headerName: "PRIZE POOL",
        flex: 1.5,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.pool || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "entry",
        headerName: "ENTRY",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.entry || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "players",
        headerName: "PLAYERS",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.players || 0}</Typography>
          </Box>
        )
      },
      {
        field: "commission",
        headerName: "GD COM.",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.commission || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "winners",
        headerName: "WINNERS",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.winners || 0}</Typography>
          </Box>
        )
      },
      {
        field: "winningAmount",
        headerName: "WIN AMT",
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.winningAmount || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "status",
        headerName: "STATUS",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              params.value === "Live" ? (
                <Box display="flex" alignItems="center">
                  <Circle sx={{ fontSize: 10, color: "#FF3B30", mr: 0.5 }} />
                  <Typography fontSize="12px" fontWeight={600}>
                    {params.value.toUpperCase()}
                  </Typography>
                </Box>
              ) : (
                <Typography fontSize="12px" fontWeight={600}>
                  {params.value.toUpperCase()}
                </Typography>
              )
            }
            size="small"
            sx={{
              width: "89px",
              height: "24px",
              borderRadius: "8px",
              color: params.value === "Live" ? "#FF3B30" : "#43E06F",
              backgroundColor:
                params.value === "Live" ? "#FF3B3010" : "#43E06F20"
            }}
          />
        )
      }
    ],
    []
  );

  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

  const handleRowOver = (e) => {
    const rowId = Number(e.currentTarget.dataset.id);
    setHoveredRow(rowId);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  // const tournamentsLastUpdatedInfo: any = getDateInFormat(
  //   parseInt(leaderboardDetails?.lastUpdated),
  //   "DD/MM/YYYY hh:mm A",
  //   "changeFormat"
  // );
  // const bootLastUpdatedInfo: any = getDateInFormat(
  //   parseInt(leaderboardDetails?.lastUpdated),
  //   "DD/MM/YYYY hh:mm A",
  //   "changeFormat"
  // );

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={(_, value) => setValue(value)}
        TabIndicatorProps={{ style: { backgroundColor: "#ff8100" } }}
      >
        <Tab
          label="Tournament Details"
          style={{
            color: value === 0 ? "#3c3c3c" : "#a8a8a8",
            fontSize: "15px",
            fontWeight: 500
          }}
        />
        <Tab
          label="Boot Details"
          style={{
            color: value === 1 ? "#3c3c3c" : "#a8a8a8",
            fontSize: "15px",
            fontWeight: 500
          }}
        />
      </Tabs>
      <Box
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "4px",
          p: 3
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={value === 0 ? 4 : 2}
        >
          <Box>
            <Typography
              fontSize="18px"
              fontWeight={600}
              color={colors.lightGreyIcon}
            >
              {value === 0 ? "Tournament Details" : "Boot Details"}
            </Typography>
            <Typography
              fontSize="14px"
              fontWeight={400}
              color={colors.lightGreyIcon}
            >
              {value === 0
                ? `Displaying details of ${
                    leaderboardDetails?.total || 0
                  } tournaments`
                : `Displaying details of ${
                    bootDetails?.total || 0
                  } tournaments`}
            </Typography>
          </Box>
          {value === 0 ? (
            <Box display="flex" alignItems="center">
              <DateFilter
                startDate={leaderboardStartDate}
                endDate={leaderboardEndDate}
                onChangeStartDate={(newDate) =>
                  setLeaderboardStartDate(newDate)
                }
                onChangeEndDate={(newDate) => setLeaderboardEndDate(newDate)}
                disableFutureForStart={true}
                disableFutureForEnd={true}
              />
            </Box>
          ) : (
            <DateFilter
              startDate={bootStartDate}
              endDate={bootEndDate}
              onChangeStartDate={(newDate) => setBootStartDate(newDate)}
              onChangeEndDate={(newDate) => setBootEndDate(newDate)}
              disableFutureForStart={true}
              disableFutureForEnd={true}
            />
          )}
        </Box>
        {value === 0 && (
          <Table
            // getRowId={(row) => row?.Id}
            // rows={
            //   (leaderboardDetails &&
            //     leaderboardDetails?.tournaments &&
            //     leaderboardDetails?.tournaments?.length > 0 &&
            //     leaderboardDetails?.tournaments?.map((row, index) => ({
            //       ...row,
            //       Id: index + 1
            //     }))) ||
            //   []
            // }
            rows={
              leaderboardDetails &&
              leaderboardDetails?.tournaments &&
              leaderboardDetails?.tournaments?.length > 0
                ? leaderboardDetails?.tournaments
                : []
            }
            columns={columnsForTournaments}
            paginationMode="server"
            page={leaderboardPage}
            onPageChange={(page) => setLeaderboardPage(page)}
            pageSize={leaderboardPageLimit}
            onPageSizeChange={(pageSize) => {
              setLeaderboardPageLimit(pageSize);
              setLeaderboardPage(0);
            }}
            rowCount={leaderboardDetails?.total || 5}
            loading={leaderboardDataLoading || false}
            pagination={true}
            searchText={leaderboardSearchTextTemp}
            requestSearch={requestLeaderboardSearch}
            disableSearch={false}
            placeholderTextForSearch="Search by name"
          />
        )}
        {value === 1 && (
          <Table
            // getRowId={(row) => row?.Id}
            rows={
              bootDetails &&
              bootDetails?.tournaments &&
              bootDetails?.tournaments?.length > 0
                ? bootDetails?.tournaments
                : []
            }
            columns={columnsForBoot}
            paginationMode="server"
            page={bootPage}
            onPageChange={(page) => setBootPage(page)}
            pageSize={bootPageLimit}
            onPageSizeChange={(pageSize) => {
              setBootPageLimit(pageSize);
              setBootPage(0);
            }}
            rowCount={bootDetails?.total || 5}
            loading={bootDataLoading || false}
            pagination={true}
            // searchText={bootSearchTextTemp}
            // requestSearch={requestBootSearch}
            disableSearch={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default TableTabs;
