import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import BarChartGraph from "../ColumnChartStack";
import { formatNumberToK } from "utils/helpers";

const BootDashboard = (props) => {
  const { tournamentStatistics, setBootGraphFilter } = props;
  const type = [
    {
      title: "Live Tournaments",
      color: colors.orange
    },
    {
      title: "Past Tournaments",
      color: colors.purple
    }
  ];

  const xAxisLabels =
    tournamentStatistics?.pastBootStatistics?.map((item) => item?.name) || [];

  const pastTournamentsData = tournamentStatistics?.pastBootStatistics?.map(
    (item) => ({
      name: item?.name || "",
      y: item?.value || 0,
      color: colors.purple
    })
  );

  const liveTournamentsData = tournamentStatistics?.liveBootStatistics?.map(
    (item) => ({
      name: item?.name || "",
      y: item?.value || 0,
      color: colors.orange
    })
  );

  const graphData = [
    {
      name: "Past Tournaments",
      colorByPoint: true,
      pointWidth: 25,
      data: pastTournamentsData
    },
    {
      name: "Live Tournaments",
      colorByPoint: true,
      pointWidth: 25,
      data: liveTournamentsData
    }
  ];

  const days = ["1D", "5D", "1M", "6M", "YTD"];

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: 2,
          m: 1
        }}
      >
        <Typography color="#3C3C3C" fontSize="16px" fontWeight={600}>
          Boot Dashboard
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: -1
          }}
        >
          <Box>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              Live Boot Updates
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {days?.map((item, index) => {
              return (
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={600}
                    sx={{ px: 2.5, py: 2, cursor: "pointer" }}
                    onClick={() => setBootGraphFilter(item?.toLowerCase())}
                  >
                    {item}
                  </Typography>
                  {index < days?.length - 1 && (
                    <Box
                      style={{
                        height: 20,
                        width: 1.5,
                        backgroundColor: "rgba(168, 168, 168, 0.5)"
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
        <BarChartGraph
          xAxisLabels={xAxisLabels}
          data={graphData}
          partColors={["#9F7FFC"]}
          properties={{
            width: 550
          }}
          // partColors={["#9F7FFC", "#FF9D39"]}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DonutPieChart
              title={""}
              subtitle=""
              hideToolTip={true}
              height={40}
              data={[
                {
                  y: 35,
                  name: "Active Users",
                  color: "#6CA7F8"
                },
                {
                  y: 100 - 35,
                  name: "Active Users",
                  color: "#EEEEEE"
                }
              ]}
              partColors={["#6CE084", "#EEEEEE"]}
            />
            <div>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ px: 1 }}
              >
                Total Revenue
              </Typography>
              <Typography
                color="#464646"
                fontSize="15px"
                fontWeight={800}
                sx={{ px: 1 }}
              >
                {tournamentStatistics?.bootPayin
                  ? formatNumberToK(tournamentStatistics?.bootPayin, "money")
                  : "₹ 0"}
              </Typography>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DonutPieChart
              title={""}
              subtitle=""
              hideToolTip={true}
              height={40}
              data={[
                {
                  y: 35,
                  name: "Active Users",
                  color: "#F8D96C"
                },
                {
                  y: 100 - 35,
                  name: "Active Users",
                  color: "#EEEEEE"
                }
              ]}
              partColors={["#F8D96C", "#EEEEEE"]}
            />
            <div>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ px: 1 }}
              >
                Total Pay Out
              </Typography>
              <Typography
                color="#464646"
                fontSize="15px"
                fontWeight={800}
                sx={{ px: 1 }}
              >
                {tournamentStatistics?.bootPayout
                  ? formatNumberToK(tournamentStatistics?.bootPayout, "money")
                  : "₹ 0"}
              </Typography>
            </div>
          </div>
          <div>
            {type?.map((item) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", padding: 5 }}
                >
                  <div
                    style={{
                      width: 14,
                      height: 14,
                      backgroundColor: item.color,
                      borderRadius: 2
                    }}
                  ></div>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item?.title}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default BootDashboard;
