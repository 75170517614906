import { gql } from "@apollo/client";

export const SEND_PASSWORD_REST_LINK = gql`
  mutation test($payload: ForgotPasswordRequest!) {
    forgotPassword(input: $payload) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation test($payload: ResetPasswordInput!) {
    resetPassword(input: $payload) {
      status
      message
    }
  }
`;
