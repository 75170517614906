import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_GAME_DETAILS } from "app/graphql/game";
import MiniDrawer from "app/components/Drawer";
import GameDetails from "app/components/GameDetails";
import { IGame } from "app/models/games";
import getStore from "store/zustandStore";

const GameDetailsPage = () => {
  // const uriParams: any = useParams();
  // const { setLoading } = getStore();

  // const [gameDetails, setGameDetails] = useState<IGame | null>(null);

  // const { loading } = useQuery(GET_GAME_DETAILS, {
  //   variables: { id: parseInt(uriParams?.id) },
  //   skip: !uriParams?.id,
  //   onCompleted: (data) => {
  //     const { gameDetails: rawGameDetails } = data;
  //     const { status, game } = rawGameDetails;
  //     if (status) {
  //       setGameDetails(game);
  //     } else {
  //       setGameDetails(null);
  //     }
  //   },
  //   fetchPolicy: "network-only",
  //   nextFetchPolicy: "network-only",
  // });

  // useEffect(() => {
  //   setLoading(loading);
  // }, [loading]);

  // const pageHeader = useMemo(
  //   () => ({
  //     title: "Game Details",
  //     breadCrumb: `Games / ${gameDetails?.title || ""}`,
  //   }),
  //   [gameDetails]
  // );

  return (
    <>
      <Helmet>
        <title>Games</title>
      </Helmet>
      <MiniDrawer
        content={<GameDetails />}
        // isHomepage={false}
        // header={pageHeader}
      />
    </>
  );
};

export default GameDetailsPage;
