import { gql } from "@apollo/client";

export const CREATE_TOURNAMENT = gql`
  mutation createTournament(
    $input: CreateTournamentInput!
    $bannerImage: Upload
    $thumbnailImage: Upload
    $bootCarousel1: Upload
    $bootCarousel2: Upload
    $bootCarousel3: Upload
  ) {
    createTournament(
      input: $input
      bannerImage: $bannerImage
      thumbnailImage: $thumbnailImage
      bootCarousel1: $bootCarousel1
      bootCarousel2: $bootCarousel2
      bootCarousel3: $bootCarousel3
    ) {
      status
      message
    }
  }
`;

export const UPDATE_TOURNAMENT = gql`
  mutation updateTournament(
    $input: UpdateTournamentInput!
    $bannerImage: Upload
    $thumbnailImage: Upload
    $bootCarousel1: Upload
    $bootCarousel2: Upload
    $bootCarousel3: Upload
  ) {
    updateTournament(
      input: $input
      bannerImage: $bannerImage
      thumbnailImage: $thumbnailImage
      bootCarousel1: $bootCarousel1
      bootCarousel2: $bootCarousel2
      bootCarousel3: $bootCarousel3
    ) {
      status
      message
    }
  }
`;

export const GET_PRIZE_DISTRIBUTION = gql`
  query getPrizeDistribution($payload: AllocatePrizesFilterInput!) {
    getPrizeDistribution(payload: $payload) {
      status
      message
      prizes
    }
  }
`;

// here input will be tournament Id

export const DELETE_TOURNAMENT = gql`
  mutation deleteTournament($id: Int!) {
    deleteTournament(input: $id) {
      status
      message
    }
  }
`;

// here input will be tournament Id

export const GET_TOURNAMENTS = gql`
  query allTournaments(
    $filter: AllTournamentsFilterInput
    $page: Int
    $limit: Int
    $from: String
    $to: String
  ) {
    allTournaments(
      filter: $filter
      page: $page
      limit: $limit
      from: $from
      to: $to
    ) {
      status
      message
      tournaments {
        id
        title
        status
        description
        type
        details
        bannerUrl
        tournamentThumbnailUrl
        thumbnailImageUrl
        game {
          id
          title
          gameCategory {
            id
            category
          }
        }
        end
        start
        filled
        revenue
        winners
        tournamentStatus
        pool
        entryFee
        players
        winningAmount
        slotsFilled
      }
    }
  }
`;

export const GET_TOURNAMENT_DETAILS = gql`
  query tournament($id: Int!) {
    getTournamentDetails(id: $id) {
      status
      message
      tournament {
        id
        title
        description
        type
        bannerUrl
        tournamentThumbnailUrl
        thumbnailImageUrl
        end
        start
        game {
          id
          description
        }
        details
      }
    }
  }
`;

export const GET_TOURNAMENT_STATISTICS = gql`
  query tournamentDashboard($bootFilter: String!, $leaderboardFilter: String!) {
    tournamentDashboard(
      bootFilter: $bootFilter
      leaderboardFilter: $leaderboardFilter
    ) {
      lastUpdated
      bootTournaments
      leaderboardTournaments
      liveBootStatistics {
        name
        value
      }
      pastBootStatistics {
        name
        value
      }
      liveLeaderboardStatistics {
        name
        value
      }
      pastLeaderboardStatistics {
        name
        value
      }
      leaderboardPayout
      leaderboardPayin
      bootPayout
      bootPayin
      status
    }
  }
`;

export const GET_TOURNAMENT_DETAILED_STATISTICS = gql`
  query tournamentDashboardDetails($id: Int!, $filter: String!) {
    tournamentDashboardDetails(id: $id, filter: $filter) {
      lastUpdated
      prizePool
      revenue
      commission
      payout
      slotTrend {
        name
        value
      }
      status
      slotsFilled
      bootTrends {
        name
        value
        revenue
      }
    }
  }
`;

export const GET_TOURNAMENT_STATISTICS_FOR_CONGIRATION_TABLE = gql`
  query tournamentDashboard($bootFilter: String!, $leaderboardFilter: String!) {
    tournamentDashboard(
      bootFilter: $bootFilter
      leaderboardFilter: $leaderboardFilter
    ) {
      lastUpdated
      bootTournaments
      leaderboardTournaments
      status
    }
  }
`;

export const GET_REGISTERED_USERS_OF_TOURNAMENT = gql`
  query registeredUsersInTournament(
    $id: Int!
    $page: Int!
    $limit: Int!
    $search: String
  ) {
    registeredUsersInTournament(
      id: $id
      page: $page
      limit: $limit
      search: $search
    ) {
      count
      users {
        id
        firstName
        lastName
        nickname
        mobileNo
        joinedOn
        playedOn
        entry
        position
        prize
        attempts
        status
        bootPool
        startTime
        endTime
      }
      lastUpdated
    }
  }
`;
