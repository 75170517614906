import * as React from "react";
import Highcharts, { Point, Series, Legend } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { colors } from "../../styles/colors";

interface ILegendInterface extends Legend {
  name: string;
  percentage: number;
}

interface IDonutPieChartProps {
  title: string;
  subtitle: string;
  hideToolTip?: boolean;
  data: any;
  partColors?: any;
  isGaming?: boolean;
  height?: number;
  properties?: any;
}

const DonutPieChart = (props: IDonutPieChartProps) => {
  const {
    title,
    subtitle,
    data,
    partColors,
    isGaming,
    height,
    properties,
    hideToolTip
  } = props;

  const tooltipOptions: Highcharts.TooltipOptions = {
    pointFormatter: function () {
      const point = this as any; // Explicitly cast 'this' to 'any' to access extra properties
      return (
        '<b style="color:' + point.color + '">' + "Count:" + point.y + "</b>"
      );
    },
    shared: false,
    useHTML: true
  };

  const tooltipOptionsForUsersSpending: Highcharts.TooltipOptions = {
    pointFormatter: function () {
      const point = this as any; // Explicitly cast 'this' to 'any' to access extra properties

      let tooltipHTML = "<div>";
      tooltipHTML +=
        '<span style="color:' +
        colors.lightGreyIcon +
        '">' +
        "Avg. Users:" +
        "<b>" +
        point.y +
        "</b>" +
        "</span><br/>";
      tooltipHTML +=
        '<span style="color:' +
        colors.lightGreyIcon +
        '">' +
        "Boot:" +
        "₹" +
        "<b>" +
        point.boot +
        "</b>" +
        "</span><br/>";
      tooltipHTML +=
        '<span style="color:' +
        colors.lightGreyIcon +
        '">' +
        "Tournament:" +
        "₹" +
        "<b>" +
        point.leaderboard +
        "</b>" +
        "</span>";
      tooltipHTML += "</div>";

      return tooltipHTML;
    },
    shared: false,
    useHTML: true
  };

  const options = {
    title: {
      text: title,
      align: "center",
      verticalAlign: "middle",
      y: isGaming ? 45 : 45,
      style: {
        color: colors.chartTitle,
        fontSize: isGaming ? "24px" : "26px",
        fontWeight: 800
      }
    },
    subtitle: {
      text: subtitle,
      align: "center",
      y: isGaming ? 180 : 170,
      style: {
        color: colors.chartTitle,
        fontSize: isGaming ? "11.5px" : "11.5px",
        fontWeight: 400
      }
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    tooltip: hideToolTip
      ? {
          enabled: false
        }
      : properties?.isUserSPending
      ? tooltipOptionsForUsersSpending
      : tooltipOptions,
    chart: {
      height: height
        ? 65
        : isGaming
        ? 290
        : properties?.height
        ? properties?.height
        : 260,
      width: height
        ? 65
        : isGaming
        ? 190
        : properties?.width
        ? properties?.width
        : 200,
      type: "pie",
      backgroundColor: "",
      marginTop: height ? 0 : isGaming ? 55 : 50,
      spacingTop: 0
    },
    legend: {
      labelFormatter: function (this: ILegendInterface): string {
        return "";
      }
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        dataLabels: {
          enabled: false
        },
        showInLegend: false,
        colors: partColors
      }
    },
    series: [
      {
        type: "pie",
        size: height || 150,
        innerSize: "75%",
        data: data
      }
    ]
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DonutPieChart;
