import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { IGame } from "app/models/games";
import Statistics from "./Statistics";
import PayChart from "./PayChart";
import Table from "./Table";
import TotalReturnsChart from "./TotalReturnsChart";
import TournamentAnalysisChart from "./TournamentAnalysisChart";
import { useQuery } from "@apollo/client";
import {
  GET_GAME_DETAILS,
  GET_GAME_HOSTED_TOURNAMENT_DETAILS,
  GET_GAME_REVENUE_DETAILS
} from "app/graphql/game";
import { useParams } from "react-router-dom";
import getStore from "store/zustandStore";

interface IGameDetailsProps {
  gameDetails: IGame | null;
}

const GameDetails = () => {
  const uriParams: any = useParams();
  const { setLoading } = getStore();

  const [filter, setFilter] = useState<String>("ytd");
  const [gameDetails, setGameDetails] = useState<any>(null);
  const [gameRevenueFilter, setGameRevenueFilter] = useState<String>("ytd");
  const [gameRevenueDetails, setGameRevenueDetails] = useState<any>([]);
  const [gameHostedTournamentFilter, setGameHostedTournamentFilter] =
    useState<String>("ytd");
  const [gameHostedTournamentDetails, setGameHostedTournamentDetails] =
    useState<any>([]);

  const { loading } = useQuery(GET_GAME_DETAILS, {
    variables: { id: parseInt(uriParams?.id), filter: filter },
    skip: !uriParams?.id,
    onCompleted: (data) => {
      const { gameDashboardDetails } = data;
      const { status } = gameDashboardDetails;
      if (status) {
        setGameDetails(gameDashboardDetails);
      } else {
        setGameDetails(null);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const { loading: gameRevenueDetailsLoading } = useQuery(
    GET_GAME_REVENUE_DETAILS,
    {
      variables: { id: parseInt(uriParams?.id), filter: gameRevenueFilter },
      skip: !uriParams?.id,
      onCompleted: (data) => {
        const { gameRevenueDetails } = data;
        const { status } = gameRevenueDetails;
        if (status) {
          setGameRevenueDetails(gameRevenueDetails);
        } else {
          setGameRevenueDetails(null);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  const { loading: gameHostedTournamentDetailsLoading } = useQuery(
    GET_GAME_HOSTED_TOURNAMENT_DETAILS,
    {
      variables: {
        id: parseInt(uriParams?.id),
        filter: gameHostedTournamentFilter
      },
      skip: !uriParams?.id,
      onCompleted: (data) => {
        const { gameHostedTournamentDetails } = data;
        const { status } = gameHostedTournamentDetails;
        if (status) {
          setGameHostedTournamentDetails(gameHostedTournamentDetails);
        } else {
          setGameHostedTournamentDetails(null);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  return (
    <Grid container spacing={2}>
      <Grid item lg={5} md={5} sm={6} xs={12}>
        <Statistics gameDetails={gameDetails} />
      </Grid>
      <Grid item lg={7} md={7} sm={6} xs={12}>
        <PayChart
          chartData={gameDetails?.payoutsTrend || []}
          setFilter={setFilter}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Table gameId={parseInt(uriParams?.id)} />
      </Grid>
      <Grid item lg={7} md={7} sm={6} xs={12}>
        <TotalReturnsChart
          chartData={gameRevenueDetails?.revenueTrend}
          bootTournamentIncome={gameRevenueDetails?.bootTournamentIncome}
          leaderboardTournamentIncome={
            gameRevenueDetails?.leaderboardTournamentIncome
          }
          setFilter={setGameRevenueFilter}
        />
      </Grid>
      <Grid item lg={5} md={5} sm={6} xs={12}>
        <TournamentAnalysisChart
          chartData={gameHostedTournamentDetails?.hostingTrend}
          setFilter={setGameHostedTournamentFilter}
          totalTournaments={gameHostedTournamentDetails?.totalTournaments}
          gameDetails={gameDetails}
        />
      </Grid>
    </Grid>
  );
};

export default GameDetails;
