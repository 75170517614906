import React from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material';
import { colors } from '../../styles/colors';

const options = ['Option 1', 'Option 2'];

const Deactivate = () => {
  const [value, setValue] = React.useState<string | null>(options[0]);

  const [showTextField, setShowTextField] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: '#FEF7EE',
          borderRadius: '15px',
          padding: 2,
          m: 1,
          pb: 15,
        }}
      >
        <Typography
          color="#3C3C3C"
          fontSize="16px"
          fontWeight={600}
          sx={{ p: 1 }}
        >
          Are you sure you want to deactivate the game?
        </Typography>
        <Typography
          color="#3C3C3C"
          fontSize="13px"
          fontWeight={400}
          sx={{ px: 1 }}
        >
          This will remove game and all related tournaments from the GD app.
          Make sure there are no active tournaments for the game.
        </Typography>
        <br />
        <div>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid mb={2} item xs={3}>
              <Typography pt={3}>Select a reason for deactivation*</Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                value={value}
                onChange={(event: any, newValue: string | null) => {
                  setValue(newValue);
                  if (newValue == 'Option 2') {
                    setShowTextField(true);
                  } else {
                    setShowTextField(false);
                  }
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={options}
                sx={{ width: '90%', backgroundColor: '#FFF1E4' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ backgroundColor: '#FFF1E4' }}
                    size="small"
                    variant="outlined"
                    placeholder="Choose..."
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14, height: 20 },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        {showTextField && (
          <Grid
            mt={-3}
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3} sx={{ mb: 2 }}>
              <Typography pt={1} mt={3}>
                If others, mention it here
              </Typography>
            </Grid>
            <Grid item xs={9} sx={{ height: 40 }}>
              <TextField
                size="small"
                sx={{
                  width: '90%',
                  borderRadius: '4px',
                  backgroundColor: '#FFF1E4',
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        )}
        <Button
          size="small"
          variant="contained"
          sx={{
            width: '12%',
            background: 'linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)',
            borderRadius: '36px',
            height: '38px',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.02em',
            margin: '20px 0px',
            color: '#FFF9F2',
          }}
        >
          Deactivate
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{
            width: '12%',
            background: '#bbb5af',
            borderRadius: '36px',
            height: '38px',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.02em',
            margin: '20px 20px',
            color: '#FFF9F2',
          }}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default Deactivate;
