import * as React from 'react';
import Highcharts, { Point, Series, Legend } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { colors } from '../../styles/colors';
import { Typography } from '@mui/material';

interface IDataLabelsInterface extends Point {
  name: string;
  percentage: number;
  point: any;
}

interface IDonutPieChartProps {
  title: string;
  subtitle: string;
  data: any;
  partColors: any;
  height?: number;
  properties?: any;
}

const PieChart = (props) => {
  const { title, subtitle, data, partColors, isGaming, height, properties } =
    props;
  const options = {
    chart: {
      height: properties?.height ? properties?.height : 260,
      width: properties?.width ? properties?.width : 200,
      type: 'pie',
      backgroundColor: '',
      marginTop: 15,
      marginRight: 15,
      spacingTop: 0,
    },
    title: {
      text: title,
      align: 'center',
      verticalAlign: 'middle',
      y: 45,
      style: {
        color: colors.chartTitle,
        fontSize: '26px',
        fontWeight: 800,
      },
    },
    subtitle: {
      text: subtitle,
      align: 'center',
      y: isGaming ? 180 : 170,
      style: {
        color: colors.chartTitle,
        fontSize: '11.5px',
        fontWeight: 400,
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          // format: '{point.number}',
          formatter: function (this: IDataLabelsInterface) {
            return (
              '<tspan style="fill: #ffffff; stroke: #ffffff; stroke-width: 0px;">' +
              this.point.label +
              '</tspan>'
              // <Typography>{this.point.label}</Typography>
            );
          },
          distance: properties?.dataLabelsDistance
            ? properties.dataLabelsDistance
            : -20,

          borderWidth: 0,
          style: {
            color: '#ffffff',
            fontSize: '10px',
            // rotate: '45deg',
            // fontWeight: 'bold',
            // rotate: function (this: any) {
            //   console.log(this.point.rotation);
            //   return this.point.rotation;
            // },
          },
        },
        showInLegend: false,
        colors: partColors || undefined,
      },
      states: {
        hover: {
          opacity: 1,
          enabled: false,
        },
      },
    },
    series: data,
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
