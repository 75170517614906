import { gql } from "@apollo/client";

export const GET_DASHBOARD_DATA = gql`
  query dashboard($filter: String!) {
    dashboard(filter: $filter) {
      deposits
      users
      tournaments
      liveUsers
      games
      newUsersThisMonth
      dailyActiveUsers
      rewardCount
      rewardValue
      usersCohort {
        name
        value
      }
      payoutsTrend {
        name
        data {
          name
          value
        }
      }
      status
      lastUpdated
    }
  }
`;
