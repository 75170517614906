import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import GameDeactivate from 'app/components/Deactivate';

const GameDeactivatePage = () => {
  const pageHeader = {
    title: 'Game Details',
    breadCrumb: 'Games',
  };

  return (
    <>
      <Helmet>
        <title>GameDeactivate</title>
      </Helmet>
      <MiniDrawer
        content={<GameDeactivate />}
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default GameDeactivatePage;
