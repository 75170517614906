import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import CurvedGraph from "../CurvedGraph";
import ColumChart from "../ColumnChart";

const days = ["1D", "5D", "1M", "6M", "YTD"];

const type = [
  {
    title: "Deposits",
    color: "#FF8100"
  },
  {
    title: "Pay Out",
    color: "#A283FA"
  },
  {
    title: "Commissions",
    color: "#5ADE7E"
  }
  // {
  //   title: 'Loss',
  //   color: '#F1483E',
  // },
];

const xAxis = {
  categories: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  crosshair: true
};

const yAxis = {
  title: {
    text: ""
  }
};

interface IRevenueProps {
  data: any;
  setFilter: any;
}

const Revenue = (props: IRevenueProps) => {
  const { data, setFilter } = props;

  const xAxis = {
    crosshair: true,
    categories:
      data?.payoutsTrend && data?.payoutsTrend?.length > 0
        ? data?.payoutsTrend[0]?.data?.map((item2) => item2?.name)
        : []
  };

  const dataModified =
    data?.payoutsTrend &&
    data?.payoutsTrend?.length > 0 &&
    data?.payoutsTrend?.map((item1, index) => ({
      name: item1?.name,
      pointWidth: 35,
      data: item1?.data?.map((item2) => ({
        name: item2?.name || "",
        y: item2?.value || 0,
        color: index === 0 ? "#FF8100" : index === 1 ? "#A283FA" : "#5ADE7E"
      }))
    }));
  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: "20px 30px",
          m: 1
        }}
      >
        <Typography
          color="#3C3C3C"
          fontSize="16px"
          fontWeight={600}
          sx={{ p: 1 }}
        >
          Total Pay out - Pay in Chart
        </Typography>
        <Typography
          color="#3C3C3C"
          fontSize="13px"
          fontWeight={400}
          sx={{ px: 1 }}
        >
          Sales analytics for revenue and expendituret
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {days?.map((item, index) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={600}
                    sx={{ px: 2.5, py: 1, cursor: "pointer" }}
                    onClick={() => setFilter(item?.toLowerCase())}
                  >
                    {item}
                  </Typography>
                  {index < days.length - 1 && (
                    <Box
                      sx={{
                        height: 20,
                        width: 1.5,
                        backgroundColor: "rgba(168, 168, 168, 0.5)"
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {type?.map((item) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></Box>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        {data &&
        data?.payoutsTrend &&
        data?.payoutsTrend?.length > 0 &&
        dataModified ? (
          <ColumChart
            title={""}
            xAxis={xAxis}
            yAxis={yAxis}
            data={dataModified}
          />
        ) : (
          <Box
            sx={{
              height: "100%",
              weight: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.backgroundLight
            }}
          >
            <Typography fontSize={13} fontWeight={400}>
              No Data Available
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Revenue;
