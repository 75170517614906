import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import BarChartGraph from "../ColumnChartStack";
import DateFilter from "../DateFilterCharts";
import moment from "moment";

interface IUserGrowthRateProps {
  data: any;
  startDate?: any;
  endDate?: any;
  setStartDate?: any;
  setEndDate?: any;
  registrationsPerMonth?: any;
  registrationsPerDay?: any;
}

const UserGrowthRate = (props: IUserGrowthRateProps) => {
  const {
    data,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    registrationsPerMonth,
    registrationsPerDay
  } = props;

  const type = [
    {
      title: "Existing Users",
      color: colors.orange
    },
    {
      title: "New Users",
      color: colors.purple
    }
  ];

  const xAxisLabels =
    data && data?.length > 0 && data[0]?.data && data[0]?.data?.length > 0
      ? data[0]?.data?.map((item) => item?.name)
      : [];

  const graph = data?.map((item, index) => ({
    name: item?.name,
    colorByPoint: true,
    pointWidth: 25,
    data: item?.data?.map((item) => {
      const temp = index;
      return {
        name: item?.name || "",
        y: item?.value || 0,
        color: temp === 0 ? colors.orange : colors.purple
      };
    })
  }));

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: 2,
          m: 1
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start"
          }}
        >
          <div>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              User growth rate
            </Typography>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              Month over month users growth rate
            </Typography>
          </div>
          <div>
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={(newDate) => setStartDate(newDate)}
              onChangeEndDate={(newDate) => setEndDate(newDate)}
              disableFutureForStart={true}
              disableFutureForEnd={true}
            />
          </div>
        </div>
        <BarChartGraph
          xAxisLabels={xAxisLabels}
          data={graph}
          partColors={["#9F7FFC", "#FF9D39"]}
          properties={{
            width: 550
          }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DonutPieChart
              title={""}
              subtitle=""
              hideToolTip={true}
              height={40}
              data={[
                {
                  y: 35,
                  name: "Active Users",
                  color: "#6CA7F8"
                },
                {
                  y: 100 - 35,
                  name: "Active Users",
                  color: "#EEEEEE"
                }
              ]}
              partColors={["#6CE084", "#EEEEEE"]}
            />
            <div>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ px: 1 }}
              >
                Monthly User Registrations
              </Typography>
              <Typography
                color="#464646"
                fontSize="15px"
                fontWeight={800}
                sx={{ px: 1 }}
              >
                {registrationsPerMonth || 0}
              </Typography>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DonutPieChart
              title={""}
              subtitle=""
              hideToolTip={true}
              height={40}
              data={[
                {
                  y: 35,
                  name: "Active Users",
                  color: "#F8D96C"
                },
                {
                  y: 100 - 35,
                  name: "Active Users",
                  color: "#EEEEEE"
                }
              ]}
              partColors={["#F8D96C", "#EEEEEE"]}
            />
            <div>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ px: 1 }}
              >
                Registrations per day
              </Typography>
              <Typography
                color="#464646"
                fontSize="15px"
                fontWeight={800}
                sx={{ px: 1 }}
              >
                {registrationsPerDay || 0}
              </Typography>
            </div>
          </div>
          <div>
            {type.map((item) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", padding: 5 }}
                >
                  <div
                    style={{
                      width: 14,
                      height: 14,
                      backgroundColor: item.color,
                      borderRadius: 2
                    }}
                  ></div>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item.title}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserGrowthRate;
