import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import DefaultDetails from "../DefaultDetails";
import Tabs from "../../Tabs";
import Rewards from "./Rewards";
import SliceDetails from "./SliceDetails";
import SliceApproved from "./SliceApproved";
import { colors } from "../../../../styles/colors";
import { GET_CONFIG_DATA } from "app/graphql/userManagement";
import { useQuery } from "@apollo/client";

const RewardDetails = () => {
  const [rewardCategory, setRewardCategory] = React.useState("spinTheWheel");
  const [tabValue, setTabValue] = React.useState(0);

  const [configDetails, setConfigDetails] = React.useState({});
  const [published, setPublished] = React.useState({});

  const {
    data: configData,
    loading: configDataLoading,
    refetch: refetchConfigData
  } = useQuery(GET_CONFIG_DATA, {
    variables: {
      key: "TEMP_SPIN_THE_WHEEL"
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      configData &&
      configData?.getConfigDetails &&
      configData?.getConfigDetails?.status
    ) {
      console.log("configData", configData);
      const temp = configData?.getConfigDetails?.config;

      let data: any;
      try {
        data = temp?.value ? JSON.parse(temp?.value) : "";
        console.log("data to be stringified", data);
      } catch (error) {
        console.error("An error occurred while parsing JSON:", error);
        alert(error);
        data = "";
      }
      setConfigDetails(data?.sliceDetails?.divisions || {});
      setPublished(data?.sliceDetails?.published);
    }
  }, [configData]);

  return (
    <Grid>
      {/* <Box>
        <DefaultDetails
          rewardCategory={rewardCategory}
          setRewardCategory={setRewardCategory}
        />
      </Box> */}
      {rewardCategory === "spinTheWheel" && (
        <Box>
          <Rewards rewardCategory={rewardCategory} />
          {/* <Tabs
            tabs={[
              {
                label: "STEP 1 : REWARDS"
              },
              {
                label: "STEP 2 : SLICE DETAILS"
              },
              {
                label: "STEP 3 : APPROVE REWARD"
              }
            ]}
            tabContent={[
              <Rewards rewardCategory={rewardCategory} />,
              <SliceDetails
                configDetails={configDetails}
                refetchConfigData={refetchConfigData}
              />,
              <SliceApproved
                configDetails={configDetails}
                setTabValue={setTabValue}
                published={published}
                refetchConfigData={refetchConfigData}
              />
            ]}
            tabStyle={{
              borderBottom: 4,
              borderColor: colors.TabLightOrange,
              backgroundColor: colors.TabLightOrange
            }}
            tabValue={tabValue}
            setTabValue={setTabValue}
          /> */}
        </Box>
      )}
      {rewardCategory === "mysteryBox" && (
        <Box>
          <Rewards rewardCategory={rewardCategory} />
        </Box>
      )}
    </Grid>
  );
};

export default RewardDetails;
