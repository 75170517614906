import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import CurvedGraph from "../CurvedGraph";
import ColumChart from "../ColumnChart";
import ColumChartStack from "../ColumnChartStack";
import BarChartGraph from "../ColumnChartStack";

const days = ["1D", "5D", "1M", "6M", "YTD"];

const type = [
  {
    title: "Acquired Users",
    color: colors.blue
  }
  // {
  //   title: "Referrals",
  //   color: "#FBAF3A"
  // }
];

// const xAxis = {
//   categories: [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec"
//   ],
//   crosshair: true
// };

// const data = [
//   {
//     name: "Acquired Users",
//     data: [
//       {
//         name: "Jan",
//         y: 25,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Feb",
//         y: 15,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Mar",
//         y: 15,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Apr",
//         y: 25,
//         color: " #FBAF3A"
//       },
//       {
//         name: "May",
//         y: 10,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Jun",
//         y: 0,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Jul",
//         y: 0,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Aug",
//         y: 0,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Sep",
//         y: 0,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Oct",
//         y: 0,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Nov",
//         y: 0,
//         color: " #FBAF3A"
//       },
//       {
//         name: "Dec",
//         y: 0,
//         color: " #FBAF3A"
//       }
//     ]
//   },
//   {
//     name: "Referrals",
//     data: [
//       {
//         name: "Jan",
//         y: 22,
//         color: "#4A6EE5"
//       },
//       {
//         name: "Feb",
//         y: 20,
//         color: "#4A6EE5"
//       },
//       {
//         name: "March",
//         y: 13,
//         color: "#4A6EE5"
//       },
//       {
//         name: "April",
//         y: 10,
//         color: "#4A6EE5"
//       },
//       {
//         name: "May",
//         y: 15,
//         color: "#4A6EE5"
//       },
//       {
//         name: "Jun",
//         y: 0,
//         color: "#4A6EE5"
//       },
//       {
//         name: "Jul",
//         y: 0,
//         color: "#4A6EE5"
//       },
//       {
//         name: "Aug",
//         y: 0,
//         color: "#4A6EE5"
//       },
//       {
//         name: "Sep",
//         y: 0,
//         color: "#4A6EE5"
//       },
//       {
//         name: "Oct",
//         y: 0,
//         color: "#4A6EE5"
//       },
//       {
//         name: "Nov",
//         y: 0,
//         color: "#4A6EE5"
//       },
//       {
//         name: "Dec",
//         y: 0,
//         color: "#4A6EE5"
//       }
//     ]
//   }
// ];

const chartProperties = {
  width: 1000,
  height: 200
};

const ReferralDetails = (props) => {
  const { referralTrendsData, setReferralTrendsFilter } = props;

  const xAxisLabels =
    referralTrendsData && referralTrendsData?.length > 0
      ? referralTrendsData?.map((item2) => item2?.name)
      : [];

  const graphData = [
    {
      name: "New Users",
      pointWidth: 30,
      data: referralTrendsData?.map((item) => ({
        name: item?.name || "",
        y: item?.value || 0,
        color: colors.blue
      }))
    }
  ];
  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: "0px 20px 20px 20px",
          m: 1
        }}
      >
        <Typography
          color="#3C3C3C"
          fontSize="16px"
          fontWeight={600}
          sx={{ p: 1 }}
        >
          Referral Details
        </Typography>
        <Typography
          color="#3C3C3C"
          fontSize="13px"
          fontWeight={400}
          sx={{ px: 1 }}
        >
          Referral and Acquired Users chart
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {days?.map((item, index) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={600}
                    sx={{ px: 2.5, py: 1, cursor: "pointer" }}
                    onClick={() => setReferralTrendsFilter(item?.toLowerCase())}
                  >
                    {item}
                  </Typography>
                  {index < days.length - 1 && (
                    <Box
                      sx={{
                        height: 20,
                        width: 1.5,
                        backgroundColor: "rgba(168, 168, 168, 0.5)"
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {type?.map((item) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></Box>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <BarChartGraph
          xAxisLabels={xAxisLabels}
          data={graphData}
          partColors={["#9F7FFC"]}
        />
      </Grid>
    </Grid>
  );
};

export default ReferralDetails;
