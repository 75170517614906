import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  Checkbox
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../../../../styles/colors";
import { Box } from "@mui/system";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import images from "assets/images/images";
import { useMutation } from "@apollo/client";
import { UPDATE_CONFIG_DATA } from "app/graphql/userManagement";
import { useSnackbar } from "notistack";
import { getJwtTokenDetails } from "utils/helpers";
import { FieldArray, withFormik } from "formik";
import * as Yup from "yup";
import { CheckBox } from "@mui/icons-material";

const data = [
  { id: 1, priority: "Priority 1", min: "₹3000", max: "₹10000" },
  { id: 2, priority: "Priority 2", min: "₹2000", max: "₹3000" },
  { id: 3, priority: "Priority 3", min: "₹1000", max: "₹2000" },
  { id: 4, priority: "Priority 4", min: "₹0", max: "₹1000" }
];

const PrioritizationComponent = (props) => {
  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    isEditing,
    setIsEditing
  } = props;

  const { rolePermissions } = getJwtTokenDetails();

  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    handleSubmit();
  };

  const handleSpinTheWheelChange = (event) => {
    const { name, checked } = event.target;
    const spinTheWheelCheckboxesArr = values.priorities?.map(
      (item) => item.spinTheWheel
    );
    const spinTheWheelCount = spinTheWheelCheckboxesArr.filter(
      (item) => item === true
    ).length;
    if (checked === true) {
      if (spinTheWheelCount <= 3) {
        setFieldValue(name, checked);
      } else {
        const message = "Only four levels can be selected";
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    } else {
      setFieldValue(name, checked);
    }
  };
  const handleMysteryBoxChange = (event) => {
    const { name, checked } = event.target;
    const mysteryBoxCheckboxesArr = values.priorities?.map(
      (item) => item.mysteryBox
    );
    const mysteryBoxCount = mysteryBoxCheckboxesArr.filter(
      (item) => item === true
    ).length;
    if (checked === true) {
      if (mysteryBoxCount <= 3) {
        setFieldValue(name, checked);
      } else {
        const message = "Only four levels can be selected";
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    } else {
      setFieldValue(name, checked);
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.TableHead,
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          borderRadius: "4px 4px 0px 0px",
          pl: "20px,",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          p: 1
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              textAlign: "left",
              display: "inline-block",
              pl: 2
            }}
          >
            Rewards Level Table
          </Typography>
          {/* <HelpOutlineIcon fontSize="small" sx={{ ml: 2, mt: "1px" }} /> */}
        </Box>
        <Box sx={{ alignContent: "right" }}>
          {rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
            ?.VIEW_AND_EDIT && (
            <IconButton onClick={isEditing ? handleSave : handleEdit}>
              {isEditing ? (
                <DoneOutlinedIcon />
              ) : (
                <img src={images.EditIcon}></img>
              )}
            </IconButton>
          )}
        </Box>
      </Box>
      {values?.priorities?.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: colors.backgroundBoxLight,
            borderRadius: "0px 0px 4px 4px "
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: colors.TableHeadDark }}>
              <TableRow>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      pl: 2
                    }}
                  >
                    Levels
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      pl: 2
                    }}
                  >
                    Min. reward value
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      pl: 2
                    }}
                  >
                    Max. reward value
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      pl: 2
                    }}
                  >
                    Spin the wheel
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      pl: 2
                    }}
                  >
                    Mystery box
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray
                name="priorities"
                render={(arrayHelpers) => (
                  <>
                    {values.priorities &&
                      values.priorities?.length > 0 &&
                      values.priorities?.map((row, index) => (
                        <>
                          <TableRow key={row.id}>
                            <TableCell>
                              <Box>
                                {isEditing ? (
                                  <>
                                    <Grid container sx={{ width: "100%" }}>
                                      <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          mt: 1
                                        }}
                                      >
                                        <Typography fontSize={14}>
                                          {`Level ${index} -`}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          mb: "4px"
                                        }}
                                      >
                                        <TextField
                                          // type="min"
                                          sx={{
                                            backgroundColor:
                                              colors.backgroundBoxLight,
                                            color: "#020202",
                                            "& .MuiOutlinedInput-root": {
                                              "& fieldset": {
                                                border: "none",
                                                borderBottom: "2px solid",
                                                borderColor:
                                                  colors.lightGreyIcon,
                                                borderRadius: 0,
                                                width: "50px",
                                                ml: 1
                                              }
                                            },
                                            "& .MuiOutlinedInput-input": {
                                              height: "16px",
                                              paddingBottom: "4px"
                                            }
                                          }}
                                          value={row.name}
                                          onChange={(e) => {
                                            if (index !== 0) {
                                              setFieldValue(
                                                `priorities[${index}][name]`,
                                                e.target.value
                                              );
                                            }
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                    {errors.priorities &&
                                      errors.priorities?.length > 0 &&
                                      errors?.priorities[index] &&
                                      errors?.priorities[index]?.name && (
                                        <Typography
                                          sx={{
                                            color: colors.danger,
                                            fontSize: "11px",
                                            fontWeight: 600,
                                            ml: 14
                                          }}
                                        >
                                          {errors?.priorities[index]?.name}
                                        </Typography>
                                      )}
                                  </>
                                ) : (
                                  <>{`Level ${index} - ${row.name}`}</>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                {isEditing ? (
                                  <>
                                    <TextField
                                      type="min"
                                      sx={{
                                        backgroundColor:
                                          colors.backgroundBoxLight,
                                        color: "#020202",
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            border: "none",
                                            borderBottom: "2px solid",
                                            borderColor: colors.lightGreyIcon,
                                            borderRadius: 0,
                                            width: "50px",
                                            ml: 1
                                          }
                                        },
                                        "& .MuiOutlinedInput-input": {
                                          height: "16px",
                                          paddingBottom: "4px"
                                        }
                                      }}
                                      value={row.minValue || null}
                                      onChange={(e) => {
                                        if (index !== 0) {
                                          setFieldValue(
                                            `priorities[${index}][minValue]`,
                                            parseInt(e.target.value)
                                          );
                                        }
                                      }}
                                    />
                                    {errors.priorities &&
                                      errors.priorities?.length > 0 &&
                                      errors?.priorities[index] &&
                                      errors?.priorities[index]?.minValue && (
                                        <Typography
                                          sx={{
                                            color: colors.danger,
                                            fontSize: "11px",
                                            fontWeight: 600,
                                            ml: 1
                                          }}
                                        >
                                          {errors?.priorities[index]?.minValue}
                                        </Typography>
                                      )}
                                  </>
                                ) : (
                                  <>{`₹ ${row.minValue}`}</>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                {isEditing ? (
                                  <>
                                    <TextField
                                      type="max"
                                      sx={{
                                        backgroundColor:
                                          colors.backgroundBoxLight,
                                        color: "#020202",
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            border: "none",
                                            borderBottom: "2px solid",
                                            borderColor: colors.lightGreyIcon,
                                            borderRadius: 0,
                                            width: "50px",
                                            ml: 1
                                          }
                                        },
                                        "& .MuiOutlinedInput-input": {
                                          height: "16px",
                                          paddingBottom: "4px"
                                        }
                                      }}
                                      value={row.maxValue || null}
                                      onChange={(e) => {
                                        if (index !== 0) {
                                          setFieldValue(
                                            `priorities[${index}][maxValue]`,
                                            parseInt(e.target.value)
                                          );
                                        }
                                      }}
                                    />
                                    {errors.priorities &&
                                      errors.priorities?.length > 0 &&
                                      errors?.priorities[index] &&
                                      errors?.priorities[index]?.maxValue && (
                                        <Typography
                                          sx={{
                                            color: colors.danger,
                                            fontSize: "11px",
                                            fontWeight: 600,
                                            ml: 1
                                          }}
                                        >
                                          {errors?.priorities[index]?.maxValue}
                                        </Typography>
                                      )}
                                  </>
                                ) : (
                                  <>{`₹ ${row.maxValue}`}</>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                {isEditing ? (
                                  <>
                                    <Checkbox
                                      checkedIcon={
                                        <CheckBox sx={{ color: "#ff8100" }} />
                                      }
                                      checked={
                                        values.priorities[index]["spinTheWheel"]
                                      }
                                      onChange={(e) => {
                                        if (index !== 0) {
                                          handleSpinTheWheelChange(e);
                                        }
                                      }}
                                      name={`priorities[${index}][spinTheWheel]`}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Checkbox
                                      checkedIcon={
                                        <CheckBox sx={{ color: "#ff8100" }} />
                                      }
                                      checked={
                                        values.priorities[index]["spinTheWheel"]
                                      }
                                      name={`priorities[${index}][spinTheWheel]`}
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                {isEditing ? (
                                  <>
                                    <Checkbox
                                      checkedIcon={
                                        <CheckBox sx={{ color: "#ff8100" }} />
                                      }
                                      checked={
                                        values.priorities[index]["mysteryBox"]
                                      }
                                      onChange={(e) => {
                                        if (index !== 0) {
                                          handleMysteryBoxChange(e);
                                        }
                                      }}
                                      name={`priorities[${index}][mysteryBox]`}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Checkbox
                                      checkedIcon={
                                        <CheckBox sx={{ color: "#ff8100" }} />
                                      }
                                      checked={
                                        values.priorities[index]["mysteryBox"]
                                      }
                                      name={`priorities[${index}][mysteryBox]`}
                                    />
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                  </>
                )}
              />
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "130%",
            left: "57%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

const Prioritization = (props: any) => {
  const { prioritizationConfigDetails, refetchConfigData } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [tableData, setTableData] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(false);

  React.useEffect(() => {
    setTableData(prioritizationConfigDetails);
  }, [prioritizationConfigDetails]);

  const [updateConfigData, { loading: updateConfigDataLoading }] = useMutation(
    UPDATE_CONFIG_DATA,
    {
      onCompleted: (data) => {
        const { addEditConfig } = data;
        const { status, message } = addEditConfig;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refetchConfigData();
          setIsEditing(false);
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  console.log(prioritizationConfigDetails);

  const PrioritizationWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        priorities: prioritizationConfigDetails || [],
        loading: updateConfigDataLoading
      };
    },

    validationSchema: Yup.object().shape({
      priorities: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("This is a required field"),
          minValue: Yup.number()
            .min(0, "Minumum possible value is 0")
            .max(100000, "Maximum possible value is 100000")
            .required("This is a required field")
            .typeError("Please enter a valid number")
            .test(
              "minimum",
              "Value must be less than or equal to maximum value",
              function (value: any) {
                const { maxValue } = this.parent;
                if (maxValue) {
                  return parseInt(value) <= parseInt(maxValue);
                } else {
                  return true;
                }
              }
            ),
          maxValue: Yup.number()
            .min(0, "Minumum possible value is 0")
            .max(100000, "Maximum possible value is 100000")
            .required("This is a required field")
            .typeError("Please enter a valid number")
            .test(
              "maximum",
              "Value must be greater than or equal to minimum value",
              function (value: any) {
                const { minValue } = this.parent;
                return parseInt(value) >= parseInt(minValue);
              }
            )
        })
      )
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);
      updateConfigData({
        variables: {
          input: {
            key: "REWARD_LEVELS",
            value: values?.priorities ? JSON.stringify(values?.priorities) : ""
          }
        }
      });
    }
  })(PrioritizationComponent);
  return (
    <PrioritizationWrapped
      {...props}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    />
  );
};

export default Prioritization;
