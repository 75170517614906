import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  Search
} from "@mui/icons-material";
import { colors } from "styles/colors";
import images from "assets/images/images";
import Table from "../Table";
import Statistics from "./Statistics";
import StatisticsChart from "./StatisticsChart";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_GAME,
  GET_ALL_GAMES,
  GET_GAME_STATISTICS
} from "app/graphql/game";
import { useSnackbar } from "notistack";
import getStore from "store/zustandStore";
import DeactivationReason from "./DeactivationReason";
import { getDateInFormat, getJwtTokenDetails } from "utils/helpers";
import DateFilter from "../DateFilter";

const Games = () => {
  const history = useHistory();
  const uriParams: any = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = getStore();
  const { rolePermissions } = getJwtTokenDetails();

  const todayDate = new Date();
  const oneWeekAgo = new Date(todayDate);
  oneWeekAgo.setDate(todayDate.getDate() - 30);
  const [selectedRequests, setSelectedRequests] = React.useState<number[]>([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [rows, setRows] = React.useState<any[]>([]);
  const [startDate, setStartDate] = React.useState(oneWeekAgo);
  const [endDate, setEndDate] = React.useState(todayDate);
  const [gameStatistics, setGameStatistics] = React.useState<any>({});
  const [reasonViewDeleteDeactivate, setReasonViewDeleteDeactivate] =
    React.useState(false);

  const [searchText, setSearchText] = React.useState("");
  const [searchTextTemp, setSearchTextTemp] = React.useState("");

  const fireRequestSearchAPICall = (searchValue) => {
    setSearchText(searchValue);
  };
  const [timer, setTimer] = React.useState<any>(null);

  const requestSearch = (searchValue: string) => {
    setSearchTextTemp(searchValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fireRequestSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const { loading, refetch } = useQuery(GET_ALL_GAMES, {
    variables: {
      page: page,
      limit: pageSize,
      search: searchText
    },
    onCompleted: (data) => {
      const { allGames } = data;
      const { status, games } = allGames;
      console.log(games);
      if (status) {
        setRows(games);
      } else {
        setRows([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const {
    loading: getGameStatisticsLoading,
    refetch: refetchGetGameStatistics
  } = useQuery(GET_GAME_STATISTICS, {
    variables: {
      from: startDate,
      to: endDate
    },
    onCompleted: (data) => {
      const { gameDashboard } = data;
      console.log(gameDashboard);
      const { status } = gameDashboard;
      if (status) {
        setGameStatistics(gameDashboard);
        sessionStorage.setItem("totalGames", gameDashboard?.games);
      } else {
        setGameStatistics({});
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const [Gameid, setGameid] = React.useState(null);

  const [fireDeleteGameApi, { loading: deleting }] = useMutation(DELETE_GAME, {
    onCompleted: (data) => {
      const { deleteGame } = data;
      const { status, message } = deleteGame;
      if (status) {
        refetch();
        enqueueSnackbar(message, { variant: "success" });
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    },
    fetchPolicy: "network-only"
  });

  const columns: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={500}
            color={
              params.row.status === "InActive"
                ? colors.lighterGreyIcon
                : colors.lightGreyIcon
            }
          >
            {params?.value || "-"}
          </Typography>
        )
      },
      {
        field: "title",
        headerName: "NAME",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={500}
            color={
              params.row.status === "InActive"
                ? colors.lighterGreyIcon
                : colors.lightGreyIcon
            }
          >
            {params?.value || "-"}
          </Typography>
        )
      },
      {
        field: "dateAdded",
        headerName: "DATE ADDED",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const DateAndTime: any = params?.row?.createdAt
            ? getDateInFormat(
                parseInt(params?.row?.createdAt),
                "DD/MM/YYYY",
                "changeFormat"
              )
            : null;
          return (
            <Typography
              fontSize="16px"
              fontWeight={500}
              color={
                params.row.status === "InActive"
                  ? colors.lighterGreyIcon
                  : colors.lightGreyIcon
              }
            >
              {DateAndTime || "-"}
            </Typography>
          );
        }
      },
      {
        field: "totalIn",
        headerName: "TOTAL GAME REVENUE",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={500}
            color={
              params.row.status === "InActive"
                ? colors.lighterGreyIcon
                : colors.lightGreyIcon
            }
          >
            {`₹ ${params?.row?.gameRevenue || 0}`}
          </Typography>
        )
      },
      {
        field: "bootOut",
        headerName: "TOTAL BOOT PAY OUT",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={500}
            color={
              params.row.status === "InActive"
                ? colors.lighterGreyIcon
                : colors.lightGreyIcon
            }
          >
            {`₹ ${params?.row?.bootPayout || 0}`}
          </Typography>
        )
      },
      {
        field: "tournOut",
        headerName: "TOTAL TOURN. PAY OUT",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={500}
            color={
              params.row.status === "InActive"
                ? colors.lighterGreyIcon
                : colors.lightGreyIcon
            }
          >
            {`₹ ${params?.row?.leaderboardPayout || 0}`}
          </Typography>
        )
      },
      {
        field: "bootHosted",
        headerName: "BOOT HOSTED",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={500}
            color={
              params.row.status === "InActive"
                ? colors.lighterGreyIcon
                : colors.lightGreyIcon
            }
          >
            {params?.row?.hostedBoots || 0}
          </Typography>
        )
      },
      {
        field: "tournHosted",
        headerName: "TOURN. HOSTED",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={500}
            color={
              params.row.status === "InActive"
                ? colors.lighterGreyIcon
                : colors.lightGreyIcon
            }
          >
            {params?.row?.hostedLeaderboardTournaments || 0}
          </Typography>
        )
      },
      {
        field: "tournActive",
        headerName: "TOURN. ACTIVE",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={500}
            color={
              params.row.status === "InActive"
                ? colors.lighterGreyIcon
                : colors.lightGreyIcon
            }
          >
            {params?.row?.activeLeaderboardTournaments || 0}
          </Typography>
        )
      },
      {
        field: "actions",
        type: "actions",
        headerName: "ACTIONS",
        minWidth: 170,
        flex: 1,
        sortable: false,
        getActions: (params) => {
          if (
            rolePermissions.GAMES.EDIT_GAMES?.VIEW_AND_EDIT &&
            params.row.status === "InActive"
          ) {
            return [
              <GridActionsCellItem
                label="Delete"
                showInMenu
                onClick={() =>
                  fireDeleteGameApi({ variables: { id: params.row.id } })
                }
              />
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<img src={images.EditIcon} alt="" />}
                onClick={() => {
                  history.push(`/games/edit/${params.row.id}`);
                }}
                label="Edit"
              />,
              <GridActionsCellItem
                icon={<VisibilityOutlinedIcon fontSize="small" />}
                onClick={() => {
                  sessionStorage.setItem("game", params.row.title);
                  history.push(`/games/${params.row.id}`);
                }}
                label="View"
              />,
              <GridActionsCellItem
                label="Deactivate"
                showInMenu
                onClick={() => {
                  setReasonViewDeleteDeactivate(true);
                  setGameid(params.row.id);
                }}
              />,
              <GridActionsCellItem
                label="Delete"
                showInMenu
                onClick={() =>
                  fireDeleteGameApi({ variables: { id: params.row.id } })
                }
              />
            ];
          }
          return [
            <GridActionsCellItem
              icon={<VisibilityOutlinedIcon fontSize="small" />}
              onClick={() => {
                sessionStorage.setItem("game", params.row.value);
                history.push(`/games/${params.row.id}`);
              }}
              label="View"
            />
          ];
        }
      }
    ],
    [rolePermissions]
  );

  useEffect(() => {
    setLoading(loading || deleting);
  }, [loading, deleting]);

  const allGamesBugReports = [
    {
      name: "Golf Duels",
      numberOfBugs: 12
    },
    {
      name: "Est eros",
      numberOfBugs: 5
    },
    {
      name: "Pulvinar urna",
      numberOfBugs: 7
    },
    {
      name: "Ut diam",
      numberOfBugs: 4
    },
    {
      name: "Velit a",
      numberOfBugs: 5
    },
    {
      name: "Facilisi risus",
      numberOfBugs: 6
    },
    {
      name: "Sit vehicula",
      numberOfBugs: 0
    },
    {
      name: "Massa urna",
      numberOfBugs: 10
    },
    {
      name: "Ipsum enim",
      numberOfBugs: "9"
    }
  ];

  if (reasonViewDeleteDeactivate) {
    return (
      <DeactivationReason
        setReasonViewDeleteDeactivate={setReasonViewDeleteDeactivate}
        Gameid={Gameid}
        refetch={refetch}
      />
    );
  }

  const lastUpdatedInfo: any = getDateInFormat(
    parseInt(gameStatistics?.lastUpdated),
    "DD/MM/YYYY hh:mm A",
    "changeFormat"
  );
  return (
    <Grid container spacing={2} sx={{ mt: 1, position: "relative" }}>
      {(rolePermissions.GAMES.GAMES_DASHBOARD.VIEW ||
        rolePermissions.GAMES.GAMES_DASHBOARD.VIEW_AND_EDIT) && (
        <>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mr: "100px",
              mt: "-110px",
              zIndex: 10002
            }}
          >
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={(newDate) => setStartDate(newDate)}
              onChangeEndDate={(newDate) => setEndDate(newDate)}
              noBackgroundColor={true}
              disableFutureForStart={true}
              disableFutureForEnd={true}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Statistics gameStatistics={gameStatistics} games={rows} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <StatisticsChart chartData={gameStatistics?.gamesHistogram} />
          </Grid>
          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box
              sx={{
                height: { sm: "372px", xs: "auto" },
                overflow: "auto",
                backgroundColor: colors.backgroundLight,
                borderRadius: "4px",
                p: 3,
              }}
            >
              <Typography
                fontSize="18px"
                fontWeight={600}
                color={colors.lightGreyIcon}
              >
                49 Bug reports
              </Typography>
              <Typography
                fontSize="14px"
                fontWeight={400}
                color={colors.lightGreyIcon}
              >
                All the game bug reports
              </Typography>
              {allGamesBugReports?.map((item: any) => {
                const lineWidth = (item.numberOfBugs / 49) * 100;
                return (
                  <Grid
                    container
                    spacing={0.5}
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        fontSize="12px"
                        fontWeight={500}
                        color={colors.lightGreyIcon}
                      >
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item md={6} lg={6}>
                      <Box
                        sx={{
                          height: "2px",
                          width: `${lineWidth}%`,
                          backgroundColor: "#A8A8A8",
                        }}
                      ></Box>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                      <Typography
                        fontSize="12px"
                        fontWeight={500}
                        color={colors.lightGreyIcon}
                        textAlign="right"
                      >
                        {item.numberOfBugs}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Grid> */}
        </>
      )}
      {(rolePermissions.GAMES.EDIT_GAMES.VIEW ||
        rolePermissions.GAMES.EDIT_GAMES.VIEW_AND_EDIT) && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              backgroundColor: colors.backgroundLight,
              borderRadius: "4px",
              p: 3
            }}
          >
            <Box display="flex" justifyContent="space-between" mb={4}>
              <Box>
                <Typography
                  fontSize="18px"
                  fontWeight={600}
                  color={colors.lightGreyIcon}
                >
                  Game Details
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight={400}
                  color={colors.lightGreyIcon}
                >
                  {`Displaying ${
                    gameStatistics?.totalActiveGames || 0
                  } game details`}
                </Typography>
              </Box>
              {rolePermissions.GAMES.EDIT_GAMES.VIEW_AND_EDIT && (
                <Box display="flex" alignItems="center">
                  <Typography
                    fontSize="14px"
                    fontWeight={400}
                    color={colors.lightGreyIcon}
                    mr={2}
                  >
                    {`Last Updated on ${lastUpdatedInfo}`}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    sx={{
                      width: "150px",
                      height: "38px",
                      background:
                        "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                      borderRadius: "5px",
                      fontWeight: 600,
                      fontSize: "16px",
                      letterSpacing: "0.02em",
                      marginRight: 2
                    }}
                    onClick={() => {
                      history.push("/games/add");
                    }}
                  >
                    Add new
                  </Button>
                </Box>
              )}
            </Box>
            {/* <Box display="flex" justifyContent="flex-end" sx={{ my: 2 }}>
              <TextField
                size="small"
                placeholder="Search an item"
                sx={{ width: "260px", backgroundColor: "white" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" sx={{ color: "#a8a8a8" }} />
                    </InputAdornment>
                  )
                }}
              />
            </Box> */}
            <Table
              rows={rows}
              columns={columns}
              paginationMode="server"
              page={page}
              onPageChange={(page) => setPage(page)}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize);
                setPage(0);
              }}
              rowCount={gameStatistics?.totalActiveGames || 10}
              loading={loading}
              // checkboxSelection
              selectedRows={selectedRequests}
              setSelectedRows={setSelectedRequests}
              searchText={searchTextTemp}
              requestSearch={requestSearch}
              disableSearch={false}
              // hideFooter
              placeholderTextForSearch="Search by name"
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default Games;
