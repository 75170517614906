import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import { colors } from "../../../../../styles/colors";
import Modal from "../../../Modal";
import images from "assets/images/images";
import { CalendarTodayOutlined, ImageOutlined } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMutation, useQuery } from "@apollo/client";
import { FieldArray, withFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import {
  CREATE_REWARD,
  GET_ALL_REWARD_CATEGORIES,
  UPDATE_REWARD
} from "app/graphql/rewards";
import { useSnackbar } from "notistack";
import AddBrandDetailForm from "./AddBrandDetail";
import { getDateInFormat, getUpsertRewardPayload } from "utils/helpers";
import OrangeGradientButton from "app/components/OrangeGradientButton";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { Editor } from "@tinymce/tinymce-react";
import { GET_CONFIG_DATA } from "app/graphql/userManagement";
import { withStyles } from "@mui/styles";

interface IAddRewardProps {
  setItem: any;
}

const rewardTypes = [
  {
    Id: 1,
    Name: "Coupon"
  },
  {
    Id: 2,
    Name: "Cashback"
  }
];

const AddRewardContent = (props: any) => {
  const {
    setItem,
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
    handleBlur,
    handleSubmit,
    setBrandDetailsModalOpen,
    allRewardCategories,
    setSelectedRow,
    rewardDetail,
    setRewardDetail,
    levels
  } = props;

  const OrangeRadio = withStyles((theme) => ({
    root: {
      color: theme.palette.grey[400],
      "&$checked": {
        color: colors.invalidInput
      }
    },
    checked: {}
  }))(Radio);

  const urlLogoCard1 =
    values["logoCard1"] !== null
      ? URL.createObjectURL(values["logoCard1"])
      : "";
  const urlLogoCard2 =
    values["logoCard2"] !== null
      ? URL.createObjectURL(values["logoCard2"])
      : "";

  const renderNumberOfCodeInputFields = () => {
    return (
      <FieldArray
        name="uniqueCouponCodes"
        render={(arrayHelpers) => (
          <>
            {values.uniqueCouponCodes?.map((c, index) => (
              <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    {`Coupon Code ${index + 1}`}
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <TextField
                    id={`uniqueCouponCodes[${index}]`}
                    size="small"
                    fullWidth
                    value={values.uniqueCouponCodes[index] || ""}
                    onChange={(e) => {
                      if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                        setFieldValue(
                          `uniqueCouponCodes[${index}]`,
                          e.target.value
                        );
                      }
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: colors.lightGreyIcon },
                      width: "90%"
                    }}
                    onBlur={handleBlur}
                  />
                  {touched.uniqueCouponCodes &&
                    touched.uniqueCouponCodes?.length > 0 &&
                    errors.uniqueCouponCodes &&
                    errors.uniqueCouponCodes?.length > 0 &&
                    touched?.uniqueCouponCodes[index] && (
                      <Typography
                        sx={{
                          color: colors.danger,
                          fontSize: "11px",
                          fontWeight: 600
                        }}
                      >
                        {errors?.uniqueCouponCodes[index]}
                      </Typography>
                    )}
                </Grid>
              </Grid>
            ))}
          </>
        )}
      />
    );
  };

  const level = values?.internalRewardCost
    ? levels?.find(
        (item) =>
          parseInt(values?.internalRewardCost) >= parseInt(item.minValue) &&
          parseInt(values?.internalRewardCost) <= parseInt(item.maxValue)
      )
    : {};

  React.useEffect(() => {
    if (values?.totalStock && values?.uniqueCouponCodes?.length === 0) {
      setFieldValue(
        "uniqueCouponCodes",
        Array.apply("", Array(values?.totalStock || 1))
      );
      values.uniqueCouponCodes.forEach((_, index) => {
        setFieldTouched(`uniqueCouponCodes[${index}]`, false);
      });
    }
  }, []);

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          backgroundColor: colors.backgroundBoxLight,
          borderRadius: "4px",
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          mt: 2
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            backgroundColor: colors.TableHead,
            px: 2,
            borderRadius: "4px 4px 0px 0px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
              py: 1
            }}
          >
            <Typography fontSize={16} fontWeight={600}>
              Add New Reward
            </Typography>
          </Box>
        </Grid>
        {values.id && (
          <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Reward No
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box
                  sx={{
                    backgroundColor: colors.disabledFieldBackground,
                    borderRadius: "4px",
                    height: 40,
                    width: "90%",
                    justifyContent: "start",
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    pl: 2
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={colors.lightGreyIcon}
                  >
                    {values.id}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Typography fontSize={15} fontWeight={400}>
                Category
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
              <Autocomplete
                options={allRewardCategories || []}
                getOptionLabel={(option) => option.type}
                disableClearable={true}
                value={allRewardCategories?.find(
                  (x: any) => x.id === values.rewardCategory
                )}
                onChange={(event: any, value: any) => {
                  if (value) {
                    setFieldValue("rewardCategory", value.id);
                    if (value.brand) {
                      const brandDetails = value?.brand
                        ? JSON.parse(value?.brand)
                        : {};
                      if (brandDetails?.brand === "Yes") {
                        setFieldValue("addBrandDetails", "Yes");
                      } else {
                        setFieldValue("addBrandDetails", "No");
                      }
                    }
                  }
                }}
                sx={{
                  width: "90%"
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="rewardCategory"
                    size="small"
                    variant="outlined"
                    placeholder="Choose..."
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14 }
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight
                    }}
                    onBlur={handleBlur}
                  />
                )}
              />
              {touched?.rewardCategory && errors?.rewardCategory && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  {errors?.rewardCategory}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Typography fontSize={15} fontWeight={400}>
                Internal Reward Cost
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
              <TextField
                id="internalRewardCost"
                size="small"
                fullWidth
                value={values.internalRewardCost}
                onChange={(e) => {
                  if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                    setFieldValue("internalRewardCost", e.target.value);
                  }
                }}
                sx={{
                  backgroundColor: colors.FieldBackgroundLight,
                  input: { color: colors.lightGreyIcon },
                  width: "90%"
                }}
                onBlur={handleBlur}
              />
              {touched?.internalRewardCost && errors?.internalRewardCost && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  {errors?.internalRewardCost}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12}>
          <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Levels
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box
                  sx={{
                    backgroundColor: colors.disabledFieldBackground,
                    borderRadius: "4px",
                    height: 40,
                    width: "90%",
                    justifyContent: "start",
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    pl: 2
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={colors.lightGreyIcon}
                  >
                    {level?.name || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Expiry Date
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast={true}
                    inputFormat={"DD/MM/YYYY"}
                    InputProps={{ style: { fontSize: 14 } }}
                    components={{
                      OpenPickerIcon: CalendarTodayOutlined
                    }}
                    value={values.expiryDate}
                    onChange={(val) => {
                      setFieldValue("expiryDate", val);
                      console.log(val);
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="expiryDate"
                        fullWidth={true}
                        size="small"
                        {...params}
                        sx={{
                          backgroundColor: colors.FieldBackgroundLight,
                          input: { color: colors.lightGreyIcon },
                          width: "90%"
                        }}
                        onBlur={handleBlur}
                      />
                    )}
                  />
                </LocalizationProvider>
                {touched?.expiryDate && errors?.expiryDate && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600
                    }}
                  >
                    {errors?.expiryDate}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Count
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="totalStock"
                  size="small"
                  fullWidth
                  value={values.totalStock}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("totalStock", e.target.value);
                      setFieldValue(
                        "uniqueCouponCodes",
                        Array.apply("", Array(parseInt(e.target.value) || 1))
                      );
                      values.uniqueCouponCodes.forEach((_, index) => {
                        setFieldTouched(`uniqueCouponCodes[${index}]`, false);
                      });
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={13}>{`${
                          values.totalStock?.length || 0
                        }/24`}</Typography>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%"
                  }}
                  onBlur={handleBlur}
                />
                {touched?.totalStock && errors?.totalStock && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600
                    }}
                  >
                    {errors?.totalStock}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={4.5} md={4.5} lg={4.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Add expiry date to reward?
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={7.5} md={7.5} lg={7.5} mt={-1}>
                <RadioGroup
                  row
                  id="addExpiryDateToReward"
                  defaultValue={2}
                  value={values.addExpiryDateToReward}
                  onChange={(event) => {
                    setFieldValue(
                      "addExpiryDateToReward",
                      parseInt(event.currentTarget.value)
                    );
                  }}
                >
                  <FormControlLabel
                    value={1}
                    control={<OrangeRadio size="small" />}
                    label={
                      <Typography fontSize={15} fontWeight={400}>
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={2}
                    control={<OrangeRadio size="small" />}
                    label={
                      <Typography fontSize={15} fontWeight={400}>
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Title
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="title"
                  size="small"
                  fullWidth
                  value={values.title}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("title", e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={13}>{`${
                          values.title?.length || 0
                        }/12`}</Typography>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%"
                  }}
                  onBlur={handleBlur}
                />
                {touched?.title && errors?.title && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600
                    }}
                  >
                    {errors?.title}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Sub-Title
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="subTitle"
                  size="small"
                  fullWidth
                  value={values.subTitle}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("subTitle", e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={13}>{`${
                          values.subTitle?.length || 0
                        }/24`}</Typography>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%"
                  }}
                  onBlur={handleBlur}
                />
                {touched?.subTitle && errors?.subTitle && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600
                    }}
                  >
                    {errors?.subTitle}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Sub-Heading
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="subHeading"
                  size="small"
                  fullWidth
                  value={values.subHeading}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("subHeading", e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={13}>{`${
                          values.subHeading?.length || 0
                        }/26`}</Typography>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%"
                  }}
                  onBlur={handleBlur}
                />
                {touched?.subHeading && errors?.subHeading && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600
                    }}
                  >
                    {errors?.subHeading}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Sub-Text
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="subText"
                  size="small"
                  fullWidth
                  value={values.subText}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("subText", e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={13}>{`${
                          values.subText?.length || 0
                        }/40`}</Typography>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%"
                  }}
                  onBlur={handleBlur}
                />
                {touched?.subText && errors?.subText && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600
                    }}
                  >
                    {errors?.subText}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} mt={5} px={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                border: "0.5px solid rgba(168, 168, 168, 0.5)",
                borderRadius: "4px",
                py: 1.5
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  Logo Card 1
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                {/* <Box
                  sx={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50px",
                    backgroundColor: colors.FieldBackgroundLight,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <ImageOutlined sx={{ color: "#8C8E8F" }} />
                </Box> */}
                {values?.logoCard1 === null && values?.logoCard1Url === null ? (
                  <Box
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50px",
                      backgroundColor: colors.FieldBackgroundLight,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <ImageOutlined sx={{ color: "#8C8E8F" }} />
                  </Box>
                ) : (
                  <img
                    src={urlLogoCard1 || values?.logoCard1Url}
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50px",
                      backgroundColor: colors.FieldBackgroundLight,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  80x80
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  1 MB
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <img
                  src={images.logoCard1}
                  alt="Game thumbnail"
                  width="50"
                  height="50"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Upload
                  accept=".gif, .jpg, .jpeg, .png, .svg"
                  onStart={() => setFieldTouched("logoCard1")}
                  beforeUpload={(file: RcFile): Promise<void> => {
                    setFieldTouched("logoCard1");
                    if (file.size <= 1024 * 1024) {
                      setFieldValue("logoCard1", file);
                      return Promise.resolve();
                    } else {
                      alert("File size exceeds the maximum allowed (1MB)");
                      return Promise.reject(
                        "File size exceeds the maximum allowed (1MB)"
                      );
                    }
                  }}
                >
                  <OrangeGradientButton
                    title={
                      values["logoCard1"] !== null ||
                      values.logoCard1Url !== null
                        ? "Upload New"
                        : "Upload"
                    }
                    style={{ marginLeft: 3, width: "122px" }}
                  />
                </Upload>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Add Brand Details
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5} mt={-1}>
                <RadioGroup
                  row
                  id="addBrandDetails"
                  defaultValue={2}
                  value={values.addBrandDetails}
                  onChange={(event) => {
                    setFieldValue(
                      "addBrandDetails",
                      parseInt(event.currentTarget.value)
                    );
                  }}
                >
                  <FormControlLabel
                    value={1}
                    control={<OrangeRadio size="small" />}
                    label={
                      <Typography fontSize={15} fontWeight={400}>
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={2}
                    control={<OrangeRadio size="small" />}
                    label={
                      <Typography fontSize={15} fontWeight={400}>
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        {values.addBrandDetails === "Yes" && (
          <>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2.5}
              px={2}
            >
              <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Brand name
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <TextField
                    id="brandName"
                    size="small"
                    fullWidth
                    value={values.brandName}
                    onChange={(e) => {
                      if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                        setFieldValue("brandName", e.target.value);
                      }
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: colors.lightGreyIcon },
                      width: "90%"
                    }}
                    onBlur={handleBlur}
                  />
                  {touched?.brandName && errors?.brandName && (
                    <Typography
                      sx={{
                        color: colors.danger,
                        fontSize: "11px",
                        fontWeight: 600
                      }}
                    >
                      {errors?.brandName}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {/* <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Expiry Date
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast={true}
                      inputFormat={"DD/MM/YYYY"}
                      InputProps={{ style: { fontSize: 14 } }}
                      components={{
                        OpenPickerIcon: CalendarTodayOutlined
                      }}
                      value={values.expiryDate}
                      onChange={(val) => {
                        setFieldValue("expiryDate", val);
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="expiryDate"
                          fullWidth={true}
                          size="small"
                          {...params}
                          sx={{
                            backgroundColor: colors.FieldBackgroundLight,
                            input: { color: colors.lightGreyIcon },
                            width: "90%"
                          }}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {touched?.expiryDate && errors?.expiryDate && (
                    <Typography
                      sx={{
                        color: colors.danger,
                        fontSize: "11px",
                        fontWeight: 600
                      }}
                    >
                      {errors?.expiryDate}
                    </Typography>
                  )}
                </Grid>
              </Grid> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
              <Grid item container xs={12} sm={8} md={8} lg={8}>
                <Grid item container xs={12} sm={2.5} md={2.5} lg={2.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Reward Summary
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <TextField
                    id="rewardSummary"
                    size="small"
                    fullWidth
                    rows={4}
                    value={values.rewardSummary}
                    onChange={(e) => {
                      if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                        setFieldValue("rewardSummary", e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography fontSize={13}>{`${
                            values.rewardSummary?.length || 0
                          }/74`}</Typography>
                        </InputAdornment>
                      )
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: colors.lightGreyIcon },
                      width: "90%",
                      ml: 1
                    }}
                    onBlur={handleBlur}
                  />
                  {touched?.rewardSummary && errors?.rewardSummary && (
                    <Typography
                      sx={{
                        color: colors.danger,
                        fontSize: "11px",
                        fontWeight: 600,
                        ml: 1
                      }}
                    >
                      {errors?.rewardSummary}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  border: "0.5px solid rgba(168, 168, 168, 0.5)",
                  borderRadius: "4px",
                  py: 1.5
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    Logo Card 2
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  {/* <Box
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "10px",
                      backgroundColor: colors.FieldBackgroundLight,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <ImageOutlined sx={{ color: "#8C8E8F" }} />
                  </Box> */}
                  {values?.logoCard2 === null &&
                  values?.logoCard2Url === null ? (
                    <Box
                      sx={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                        backgroundColor: colors.FieldBackgroundLight,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <ImageOutlined sx={{ color: "#8C8E8F" }} />
                    </Box>
                  ) : (
                    <img
                      src={urlLogoCard2 || values?.logoCard2Url}
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                        backgroundColor: colors.FieldBackgroundLight,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    80x80
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    1 MB
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <img
                    src={images.logoCard2}
                    alt="Game thumbnail"
                    width="50"
                    height="50"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <Upload
                    accept=".gif, .jpg, .jpeg, .png, .svg"
                    onStart={() => setFieldTouched("logoCard2")}
                    beforeUpload={(file: RcFile): Promise<void> => {
                      setFieldTouched("logoCard2");
                      if (file.size <= 1024 * 1024) {
                        setFieldValue("logoCard2", file);
                        return Promise.resolve();
                      } else {
                        alert("File size exceeds the maximum allowed (1MB)");
                        return Promise.reject(
                          "File size exceeds the maximum allowed (1MB)"
                        );
                      }
                    }}
                  >
                    <OrangeGradientButton
                      title={
                        values["logoCard2"] !== null ||
                        values.logoCard2Url !== null
                          ? "Upload New"
                          : "Upload"
                      }
                      style={{ marginLeft: 3, width: "122px" }}
                    />
                  </Upload>
                </Box>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
              <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Coupon IDs
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5} mt={-1}>
                  <RadioGroup
                    row
                    id="couponIdsType"
                    defaultValue={2}
                    value={values.couponIdsType}
                    onChange={(event) => {
                      setFieldValue(
                        "couponIdsType",
                        parseInt(event.currentTarget.value)
                      );
                      if (parseInt(event.currentTarget.value) === 2) {
                        // setFieldValue(
                        //   "uniqueCouponCodes",
                        //   Array.apply("", Array(values?.totalStock || 1))
                        // );
                        values.uniqueCouponCodes.forEach((_, index) => {
                          setFieldTouched(`uniqueCouponCodes[${index}]`, false);
                        });
                      } else {
                        // setFieldValue("uniqueCouponCodes", []);
                      }
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<OrangeRadio size="small" />}
                      label={
                        <Typography fontSize={15} fontWeight={400}>
                          Universal
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={2}
                      control={<OrangeRadio size="small" />}
                      label={
                        <Typography fontSize={15} fontWeight={400}>
                          Unique
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
            {values.couponIdsType === 1 && (
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={2.5}
                px={2}
              >
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                    <Typography fontSize={15} fontWeight={400}>
                      Coupon Code
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color={colors.redAsterisk}
                    >
                      *
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                    <TextField
                      id="universalCouponCode"
                      size="small"
                      fullWidth
                      value={values.universalCouponCode}
                      onChange={(e) => {
                        if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                          setFieldValue("universalCouponCode", e.target.value);
                        }
                      }}
                      sx={{
                        backgroundColor: colors.FieldBackgroundLight,
                        input: { color: colors.lightGreyIcon },
                        width: "90%"
                      }}
                      onBlur={handleBlur}
                    />
                    {touched?.universalCouponCode &&
                      errors?.universalCouponCode && (
                        <Typography
                          sx={{
                            color: colors.danger,
                            fontSize: "11px",
                            fontWeight: 600
                          }}
                        >
                          {errors?.universalCouponCode}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* {values.couponIdsType === 2 && (
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={2.5}
                px={2}
              >
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                    <Typography fontSize={15} fontWeight={400}>
                      No of Code
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color={colors.redAsterisk}
                    >
                      *
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                    <TextField
                      id="numberOfCouponCode"
                      size="small"
                      fullWidth
                      value={values.numberOfCouponCode}
                      onChange={(e) => {
                        if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                          setFieldValue("numberOfCouponCode", e.target.value);
                          setFieldValue(
                            "uniqueCouponCodes",
                            Array.apply("", Array(parseInt(e.target.value)))
                          );
                          values.uniqueCouponCodes.forEach((_, index) => {
                            setFieldTouched(`uniqueCouponCodes[${index}]`, false);
                          });
                        }
                      }}
                      sx={{
                        backgroundColor: colors.FieldBackgroundLight,
                        input: { color: colors.lightGreyIcon },
                        width: "90%"
                      }}
                      onBlur={handleBlur}
                    />
                    {touched?.numberOfCouponCode && errors?.numberOfCouponCode && (
                      <Typography
                        sx={{
                          color: colors.danger,
                          fontSize: "11px",
                          fontWeight: 600
                        }}
                      >
                        {errors?.numberOfCouponCode}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )} */}
            {values.couponIdsType === 2 && (
              <Grid item container xs={12} sm={12} md={12} lg={12} px={2}>
                {renderNumberOfCodeInputFields()}
              </Grid>
            )}
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2.5}
              px={2}
            >
              <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={3.5}
                  md={3.5}
                  lg={3.5}
                  sx={{ alignItems: "center" }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    Reward Details
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <Editor
                    id="rewardDetails"
                    initialValue={values.rewardDetailsTemp}
                    init={{
                      height: 175,
                      width: 382,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount"
                      ],
                      toolbar: "bullist numlist ",
                      content_style: `body { background-color: #FFF1E4;font-family:Helvetica,Arial,sans-serif; font-size:16px }
                        `,
                      branding: false,
                      statusbar: false
                    }}
                    onEditorChange={(content) => {
                      setFieldValue("rewardDetails", content);
                      // setRulesTemp(content);
                    }}
                    onBlur={handleBlur}
                  />
                  {touched?.rewardDetails && errors?.rewardDetails && (
                    <Typography
                      sx={{
                        color: colors.danger,
                        fontSize: "11px",
                        fontWeight: 600
                      }}
                    >
                      {errors?.rewardDetails}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          mt={4}
          px={2}
          mb={2}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box>
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: "150px",
                  height: "38px",
                  background:
                    "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                  borderRadius: "30px",
                  fontWeight: 600,
                  fontSize: "14px",
                  marginRight: 2
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: "150px",
                  height: "38px",
                  background: "rgba(168, 168, 168, 0.23)",
                  borderRadius: "30px",
                  fontWeight: 600,
                  fontSize: "14px",
                  marginRight: 2,
                  color: colors.lightGray
                }}
                onClick={() => {
                  setSelectedRow(null);
                  setRewardDetail(null);
                  setItem("rewardsTable");
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "130%",
            left: "55%",
            zIndex: 1005,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

const AddRewardForm = (props: any) => {
  const { setItem, rewardDetail, refetchAllRewardsData, setSelectedRow } =
    props;

  const { enqueueSnackbar } = useSnackbar();

  const [brandDetailsModalOpen, setBrandDetailsModalOpen] =
    React.useState(false);

  const [allRewardCategories, setAllRewardCategories] = React.useState<any>([]);
  const [levels, setLevels] = React.useState<any>([]);

  const {
    data: allRewardCategoriesData,
    loading: getAllRewardCategoriesDataLoading
  } = useQuery(GET_ALL_REWARD_CATEGORIES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      allRewardCategoriesData &&
      allRewardCategoriesData?.rewardCategories &&
      allRewardCategoriesData?.rewardCategories?.status
    ) {
      console.log(allRewardCategoriesData);
      const data = allRewardCategoriesData?.rewardCategories?.rewardCategories;
      setAllRewardCategories(data || []);
    }
  }, [allRewardCategoriesData]);

  const {
    data: rewardLevelConfigData,
    loading: rewardLevelConfigDataLoading,
    refetch: refetchRewardLevelConfigData
  } = useQuery(GET_CONFIG_DATA, {
    variables: {
      key: "REWARD_LEVELS"
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      rewardLevelConfigData &&
      rewardLevelConfigData?.getConfigDetails &&
      rewardLevelConfigData?.getConfigDetails?.status
    ) {
      const temp = rewardLevelConfigData?.getConfigDetails?.config;

      let data: any;
      try {
        data = temp?.value ? JSON.parse(temp?.value) : "";
      } catch (error) {
        alert(error);
        data = "";
      }
      setLevels(data || []);
    }
  }, [rewardLevelConfigData]);

  const [createReward, { loading: createRewardLoading }] = useMutation(
    CREATE_REWARD,
    {
      onCompleted: (data) => {
        console.log(data);
        const { createReward } = data;
        const { status, message } = createReward;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refetchAllRewardsData();
          setItem("rewardsTable");
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  const [updateReward, { loading: updateRewardLoading }] = useMutation(
    UPDATE_REWARD,
    {
      onCompleted: (data) => {
        console.log(data);
        const { updateReward } = data;
        const { status, message } = updateReward;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          setSelectedRow(null);
          refetchAllRewardsData();
          setItem("rewardsTable");
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  const rewardCategoryObj = allRewardCategories?.find((item) => {
    if (item?.id === rewardDetail?.type?.id) {
      return item;
    }
  });

  const brand = rewardCategoryObj?.brand
    ? JSON.parse(rewardCategoryObj?.brand)
    : {};

  const AddRewardFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        id: rewardDetail?.id || null,
        rewardCategory: rewardDetail?.type?.id || "",
        internalRewardCost: rewardDetail?.value || "",
        title: rewardDetail?.title || "",
        subTitle: rewardDetail?.sliceDisplayText || "",
        subHeading: rewardDetail?.cardDisplayText || "",
        subText: rewardDetail?.subText || "",
        logoCard1: null,
        logoCard1Url: rewardDetail?.logoCard1Url || null,
        addBrandDetails: brand?.brand || "No",
        brandName: rewardDetail?.brandName || "",
        totalStock: rewardDetail?.totalStock || "",
        expiryDate: rewardDetail?.expiryDate
          ? new Date(parseInt(rewardDetail?.expiryDate))
          : null,
        addExpiryDateToReward:
          rewardDetail?.addExpiryDateToReward === "Yes" ? 1 : 2,
        rewardSummary: rewardDetail?.rewardSummary || "",
        logoCard2: null,
        logoCard2Url: rewardDetail?.logoCard2Url || null,
        couponIdsType: rewardDetail?.couponIdsType || 2,
        universalCouponCode: rewardDetail?.universalCouponCode || "",
        // numberOfCouponCode: rewardDetail?.numberOfCouponCode || null,
        uniqueCouponCodes: rewardDetail?.uniqueCouponCodes || [],
        rewardDetailsTemp: rewardDetail?.rewardDetails || "",
        rewardDetails: rewardDetail?.rewardDetails || "",
        priority: 0,
        loading: createRewardLoading || updateRewardLoading
      };
    },

    validationSchema: Yup.object().shape({
      rewardCategory: Yup.string().required("This is a required field"),
      internalRewardCost: Yup.number()
        .required("This is a required field")
        .typeError("Please enter a valid number"),
      title: Yup.string()
        .required("This is a required field")
        .max(12, "Maximum 12 characters are allowed"),
      subTitle: Yup.string().max(24, "Maximum 24 characters are allowed"),
      subHeading: Yup.string().max(26, "Maximum 26 characters are allowed"),
      subText: Yup.string().max(40, "Maximum 40 characters are allowed"),
      totalStock: Yup.number()
        .required("This is a required field")
        .typeError("Please enter a valid number"),
      brandName: Yup.string().when("addBrandDetails", {
        is: (addBrandDetails) => addBrandDetails === "Yes",
        then: Yup.string()
          .required("This is a required field")
          .max(12, "Maximum 12 characters are allowed")
      }),
      rewardSummary: Yup.string().when("addBrandDetails", {
        is: (addBrandDetails) => addBrandDetails === "Yes",
        then: Yup.string()
          .required("This is a required field")
          .max(74, "Maximum 74 characters are allowed")
      }),
      expiryDate: Yup.date()
        .required("This is a required field")
        .typeError("Please enter a valid date"),
      // numberOfCouponCode: Yup.number()
      //   .when(["addBrandDetails", "couponIdsType"], {
      //     is: (addBrandDetails, couponIdsType) =>
      //       addBrandDetails === "Yes" && couponIdsType === 2,
      //     then: Yup.number()
      //       .required("This is a required field")
      //       .typeError("Please enter a valid number")
      //       .nullable()
      //   })
      //   .nullable(),
      universalCouponCode: Yup.string().when(
        ["addBrandDetails", "couponIdsType"],
        {
          is: (addBrandDetails, couponIdsType) =>
            addBrandDetails === "Yes" && couponIdsType === 1,
          then: Yup.string()
            .required("This is a required field")
            .typeError("Please enter a valid input")
        }
      ),
      uniqueCouponCodes: Yup.array().when(
        ["addBrandDetails", "couponIdsType", "totalStock"],
        {
          is: (addBrandDetails, couponIdsType, totalStock) => {
            return (
              addBrandDetails === "Yes" && couponIdsType === 2 && totalStock > 0
            );
          },
          then: Yup.array()
            .of(
              Yup.string()
                .required("This is a required field")
                .typeError("Please enter a valid input")
            )
            .min(1, "Atleast 1 prize is required")
        }
      )
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values);
      const input = getUpsertRewardPayload(values);
      console.log(input);
      if (
        values?.addBrandDetails === "Yes"
          ? (values?.logoCard1 && values?.logoCard2) ||
            (values?.logoCard1Url && values?.logoCard2Url)
          : values?.logoCard1 || values?.logoCard1Url
      ) {
        if (values?.id) {
          updateReward({
            variables: {
              input: input,
              logoCard1: values.logoCard1,
              logoCard2: values.logoCard2
            }
          });
        } else {
          createReward({
            variables: {
              input: input,
              logoCard1: values.logoCard1,
              logoCard2: values.logoCard2
            }
          });
        }
      } else {
        enqueueSnackbar("Please upload required files", {
          variant: "error"
        });
      }
    }
  })(AddRewardContent);
  return (
    <AddRewardFormWrapped
      {...props}
      setBrandDetailsModalOpen={setBrandDetailsModalOpen}
      allRewardCategories={allRewardCategories}
      levels={levels}
    />
  );
};

export default AddRewardForm;
