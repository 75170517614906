import React, { SetStateAction } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { Close, TripOrigin } from "@mui/icons-material";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import OrangeGradientButton from "../OrangeGradientButton";
import { colors } from "styles/colors";

const rolesArr = ["Super Admin", "Admin", "Designer", "Analyst"];

interface IModalProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setSuccessModal: React.Dispatch<SetStateAction<boolean>>;
  inviteUser: any;
  setInviteuserEmailId: any;
  roles: any;
}

interface IInviteFields {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  customMessage: string;
}

const InviteModalForm = (props: FormikProps<IInviteFields> & IModalProps) => {
  const {
    open,
    setOpen,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    roles
  } = props;

  console.log(roles);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} scroll={"paper"}>
      <DialogTitle
        sx={{
          backgroundColor: "#FFE4CB",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography fontSize="20px" fontWeight={600} color="#3c3c3c">
          Invite Users
        </Typography>
        <IconButton size="small" onClick={() => setOpen(false)}>
          <Close fontSize="small" sx={{ color: "#3c3c3c" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ backgroundColor: "#FEF7EE" }}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
              First Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              fullWidth
              sx={{ backgroundColor: "transparent" }}
            />
            {touched?.firstName && errors?.firstName && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.firstName}
              </Typography>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
              Last Name
              {/* <span style={{ color: "red" }}>*</span> */}
            </Typography>
            <TextField
              id="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              fullWidth
              sx={{ backgroundColor: "transparent" }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
              Email Address <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              fullWidth
              sx={{ backgroundColor: "transparent" }}
            />
            {touched?.email && errors?.email && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.email}
              </Typography>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
              Role <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box sx={{ maxHeight: 200, overflow: "auto" }}>
              <RadioGroup
                value={values.role}
                onChange={(e) => {
                  console.log(e);
                  console.log(e.target.value);
                  setFieldValue("role", e.target.value);
                }}
              >
                {roles?.map((role) => (
                  <div key={role?.id}>
                    <FormControlLabel
                      value={role?.id}
                      control={
                        <Radio
                          size="small"
                          checkedIcon={
                            <TripOrigin
                              fontSize="small"
                              sx={{ color: "#ff8100" }}
                            />
                          }
                        />
                      }
                      label={
                        <Typography
                          fontSize="16px"
                          fontWeight={500}
                          color="#3c3c3c3"
                        >
                          {role?.role}
                        </Typography>
                      }
                    />
                    <Typography
                      fontSize="14px"
                      fontWeight={400}
                      color="#3c3c3c3"
                    >
                      {role?.description}
                    </Typography>
                  </div>
                ))}
              </RadioGroup>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
                Custom Message
              </Typography>
              <Typography fontSize="14px" fontWeight={400} color="#8C8E8F">
                Optional
              </Typography>
            </Box>
            <TextField
              id="customMessage"
              value={values.customMessage}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Add additional custom message"
              size="small"
              fullWidth
              multiline
              minRows={3}
              inputProps={{
                maxLength: 150,
              }}
              sx={{ backgroundColor: "transparent" }}
            />
            <Typography
              textAlign="right"
              fontSize="12px"
              color={colors.fontLighter}
            >
              (Max Characters: 150)
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogTitle
        sx={{
          backgroundColor: "#FFE4CB",
          display: "flex",
          alignItems: "center"
        }}
      >
        <OrangeGradientButton
          title="Send Invite"
          size="small"
          style={{ width: "128px" }}
          onClick={() => handleSubmit()}
        />
        <Button
          variant="contained"
          size="small"
          disableElevation
          sx={{
            background: "#8C8E8F40",
            borderRadius: "36px",
            fontWeight: 600,
            fontSize: "16px",
            letterSpacing: "0.02em",
            color: "#FFFFFF",
            width: "128px",
            ml: 2
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogTitle>
    </Dialog>
  );
};

const InviteModal = (props: IModalProps) => {
  const { setOpen, setSuccessModal, inviteUser, setInviteuserEmailId } = props;

  const InviteModalWrapped = withFormik<IModalProps, IInviteFields>({
    mapPropsToValues: () => ({
      firstName: "",
      lastName: "",
      email: "",
      role: "1",
      customMessage: ""
    }),

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("This is a required field"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("This is a required field")
    }),

    handleSubmit: (values) => {
      setInviteuserEmailId(values?.email);
      console.log(values);
      inviteUser({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            role: parseInt(values?.role),
            message: values.customMessage
          }
        }
      });
    }
  })(InviteModalForm);

  return <InviteModalWrapped {...props} />;
};

export default InviteModal;
