import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './textfield.css';

import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { colors } from '../../styles/colors';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { SEND_PASSWORD_REST_LINK } from 'app/graphql/test';
import PasswordResetConfirmation from './passwordResetConfirmation';

const SendPasswordRestLinkFormContent = (props) => {
  const { values, setFieldValue, errors, touched, handleBlur, handleSubmit } =
    props;
  const history = useHistory();
  if (values.emailConfirmationMessage === true) {
    return <PasswordResetConfirmation {...props} />;
  } else {
    return (
      <Box
        sx={{
          py: { xs: 5, sm: 3 },
          px: { xs: 5, sm: 10 },
          backgroundColor: { xs: colors.background, sm: colors.background },
          minHeight: '110vh',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            borderRadius: '4px',
          }}
        >
          <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography color="secondary" fontSize="20px" fontWeight={700}>
                RESET PASSWORD LINK
              </Typography>
            </Grid>
            <TextField
              id="email"
              variant="outlined"
              fullWidth
              placeholder="Email address"
              className="inputRounded"
              sx={{
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  display: 'none',
                },
                border: '1px solid #333333',
                background: '#1C1C1C',
                margin: 2,
                borderRadius: '32px',
                input: { color: '#A8A8A8' },
              }}
              onChange={(e) => setFieldValue('email', e.target.value)}
              onBlur={handleBlur}
            />
            {touched?.email && errors?.email && (
              <Typography
                sx={{ color: colors.invalidInput, fontSize: '11px', pl: 3 }}
              >
                {errors?.email}
              </Typography>
            )}
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // sx={{ mt: 0.5 }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: '40%',
                  background:
                    'linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)',
                  boxShadow:
                    '-6px -6px 12px rgba(193, 193, 193, 0.08), 6px 6px 12px rgba(4, 4, 4, 0.45)',
                  borderRadius: '30px',
                  height: '48px',
                  fontWeight: 700,
                  fontSize: '16px',
                  letterSpacing: '0.02em',
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                SEND LINK
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
};

const SendPasswordRestLinkForm = (props: any) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [forgotPassword, { loading: forgotPasswordLoading }] = useMutation(
    SEND_PASSWORD_REST_LINK,
    {
      onCompleted: (data) => {
        console.log(data);
        const { forgotPassword } = data;
        const { message, status } = forgotPassword;
        if (status) {
          setConfirmationMessage(true);
        } else {
          alert(message);
        }
      },
      fetchPolicy: 'network-only',
    }
  );

  const SendPasswordRestLinkFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        email: email,
        emailConfirmationMessage: confirmationMessage,
      };
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Please provide a valid email')
        .required('Please enter the email address'),
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      // console.log(">>>>>>>>>>>>>values", values, props);
      setEmail(values.email);
      forgotPassword({
        variables: {
          payload: {
            email: values.email,
          },
        },
      });
    },
  })(SendPasswordRestLinkFormContent);
  return <SendPasswordRestLinkFormWrapped {...props} />;
};

export default SendPasswordRestLinkForm;
