import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import TournamentsConfiguration from 'app/components/TournamentsConfiguration';

const TournamentsConfigurationPage = () => {
  const pageHeader = {
    title: 'Tournament Details',
    breadCrumb: 'TournamentsAnalytics',
  };
  return (
    <>
      <Helmet>
        <title>TournamentsConfiguration</title>
      </Helmet>
      <MiniDrawer
        content={<TournamentsConfiguration />}
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default TournamentsConfigurationPage;
