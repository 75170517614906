import React from "react";
import { Helmet } from "react-helmet-async";
import MiniDrawer from "app/components/Drawer";
import TournamentDeactivate from "app/components/TournamentDeactivate";

const TournamentDeactivatePage = () => {
  const pageHeader = {
    title: "Tournament Details",
    breadCrumb: "Tournaments"
  };

  return (
    <>
      <Helmet>
        <title>Tournament Deactivate</title>
      </Helmet>
      <MiniDrawer
        content={<TournamentDeactivate />}
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default TournamentDeactivatePage;
