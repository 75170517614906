import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import AddTournament from 'app/components/TournamentAddEdit';

const AddTournamentPage = () => {
  const pageHeader = {
    title: 'Tournament Details',
    breadCrumb: 'Tournaments / Add new',
  };
  return (
    <>
      <Helmet>
        <title>AddTournament</title>
      </Helmet>
      <MiniDrawer
        content={
          <>
            <AddTournament />
          </>
        }
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default AddTournamentPage;
