import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { colors } from '../../styles/colors';

interface IScrollDialogProps {
  open: boolean;
  setOpen: any;
  zIndex: number;
  headerTitle?: string;
  content: React.ReactNode;
  actions?: React.ReactNode;
  properties?: any;
}

function ScrollDialog(props: IScrollDialogProps) {
  const { open, setOpen, zIndex, headerTitle, content, actions, properties } =
    props;

  const handleClose = (event, reason) => {
    // if (reason && reason == 'backdropClick') return;
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const useStyles = makeStyles((theme) => ({
    paper: properties?.paperStyle || {
      overflowY: 'unset',
      width: '470px',
      borderRadius: '18px',
      marginTop: '96px',
      boxShadow:
        '3px -4px 19px rgb(0 0 0 / 18%), -8px 7px 16px rgb(0 0 0 / 13%)',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: '-9%',
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      maxWidth={
        properties?.maxWidth
          ? properties?.maxWidth
          : properties?.maxWidth === false
          ? false
          : 'sm'
      }
      style={{
        zIndex: zIndex,
      }}
      BackdropProps={{
        style: {
          backgroundColor: '#ffffff',
          opacity: '50%',
        },
      }}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableEscapeKeyDown
      classes={{ paper: classes.paper }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'start',
          fontSize: '20px',
          fontWeight: '600',
          padding: properties.titlePadding === 0 ? 0 : '16px 24px',
        }}
      >
        {headerTitle || ''}
      </DialogTitle>
      <DialogContent
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: properties.contentPadding === 0 ? 0 : '20px 24px',
        }}
      >
        {content}
      </DialogContent>
      {actions && (
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
}

ScrollDialog.defaultProps = {
  open: false,
  headerTitle: '',
  content: '',
  actions: <div />,
};

export default ScrollDialog;
