import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import TournamentDetails from 'app/components/TournamentDetails';

const TournamentDetailsPage = () => {
  const pageHeader = {
    title: 'Tournament Details',
    breadCrumb: 'Tournaments / Knifu Whiz Details',
  };
  return (
    <>
      <Helmet>
        <title>Tournaments</title>
      </Helmet>
      <MiniDrawer
        content={<TournamentDetails />}
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default TournamentDetailsPage;
