import * as React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ICurvedGraphProps {
  xAxisLabels: string[];
  data: any;
  partColors: string[];
  height?: number;
}

const CurvedGraph = (props: ICurvedGraphProps) => {
  const { xAxisLabels, data, partColors, height } = props;

  const tooltipOptions: Highcharts.TooltipOptions = {
    pointFormatter: function () {
      const point = this as any; // Explicitly cast 'this' to 'any' to access extra properties
      return "<span>" + "Count:" + "<b>" + point.y + "</b>" + "</span>";
    },
    shared: false,
    useHTML: true
  };

  const options = {
    chart: {
      height: 320,
      type: "areaspline",
      backgroundColor: "",
      marginTop: 30,
      spacingTop: 0
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: xAxisLabels
    },
    yAxis: { min: 0, title: { text: "" } },
    plotOptions: {
      column: {
        pointWidth: 30
      },
      areaspline: {
        fillOpacity: 0.5
      },
      area: {
        pointStart: "Jan",
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2
        }
      }
    },
    tooltip: tooltipOptions,
    colors: partColors,
    series: data,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CurvedGraph;
