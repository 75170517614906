const images = {
  //Table
  EditIcon: require("./EditIcon.svg").default,
  Filter_Rectangle: require("./Filter_Rectangle.svg").default,
  DownArrow: require("./downarrow.svg").default,
  Delete_Icon: require("./delete_icon_gd.svg").default,

  // Login
  logo_small: require("./logo_small.svg").default,
  login_amico: require("./login_amico.svg").default,
  eye_closed_icon: require("./eye_closed_icon.svg").default,
  key_icon: require("./key_icon.svg").default,

  // Drawer icons
  uiElements: require("./ui-elements.svg").default,
  rewards: require("./rewards.svg").default,
  revenue: require("./revenue.svg").default,
  users: require("./users.svg").default,
  tournaments: require("./tournaments.svg").default,
  games: require("./games.svg").default,
  dashboard: require("./dashboard.svg").default,
  drawerLogo: require("./drawer-logo.svg").default,
  drawer_small_logo: require("./drawer_small_logo.svg").default,
  user_management: require("./user_management.svg").default,

  // Dashboard icons
  copyright: require("./copyright.svg").default,
  notification: require("./notification.svg").default,
  profile_image: require("./profile_image.svg").default,
  search: require("./search.svg").default,
  download: require("./download.svg").default,
  rupees: require("./rupees.svg").default,

  downarrow: require("./downarrow.svg").default,
  uparrow: require("./uparrow.svg").default,
  downTrend: require("./downTrend.svg").default,
  upTrend: require("./upTrend.svg").default,
  liveUser: require("./liveUser.svg").default,

  MobileGame: require("./MobileGame.svg").default,
  badge: require("./badge.svg").default,
  SwitchView: require("./SwitchView.svg").default,
  ExportIcon: require("./ExportIcon.svg").default,
  Profile: require("./Profile.svg").default,

  Raised: require("./Raised.svg").default,
  CheckFile: require("./CheckFile.svg").default,
  Refferal: require("./Refferal.svg").default,
  Time: require("./Time.svg").default,
  Task: require("./Task.svg").default,

  //User page icons
  curve: require("./curve.svg").default,

  //games page image
  GameProfilePic: require("./GameProfilePic.png"),

  //tournaments page images
  curve2: require("./curve2.svg").default,
  commissionIcon: require("./CommissionIcon.svg").default,

  //Game table thumbnail
  tn1: require("./tn1.png"),
  tn2: require("./tn2.png"),
  tn3: require("./tn3.png"),
  tn4: require("./tn4.png"),
  tn5: require("./tn5.png"),
  tn6: require("./tn6.png"),

  //Game table refrence
  ref1: require("./ref1.png"),
  ref2: require("./ref2.png"),
  ref3: require("./ref3.png"),
  ref4: require("./ref4.png"),
  ref5: require("./ref5.png"),
  ref6: require("./ref6.png"),

  //rewards
  logoCard1: require("./rewards_logo_card_ref_1.png"),
  logoCard2: require("./rewards_logo_card_ref_2.png"),

  //miscellaneous
  image_ref: require("./image_thumbnail_icon.svg").default
};

export default images;
