import React, { SetStateAction, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import OrangeGradientButton from "../OrangeGradientButton";
import { colors } from "styles/colors";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getCreateRoleDetails, getJwtTokenDetails } from "utils/helpers";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_ROLE,
  GET_ROLE_DETAILS,
  UPDATE_ROLE,
} from "app/graphql/userManagement";
import RemoveIcon from "@mui/icons-material/Remove";
import { checkAtleastOnePermission } from "./utils";

interface IAddEditNewRoleProps {
  setSuccessRoleModal: React.Dispatch<SetStateAction<boolean>>;
  setAddEditRole: React.Dispatch<SetStateAction<boolean>>;
  setSelectedRowId: React.Dispatch<SetStateAction<string>>;
  configDetails: any;
}

const AddEditNewRoleComponent = (props: any) => {
  const {
    values,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    setAddEditRole,
    setSelectedRowId,
  } = props;

  const { rolePermissions } = getJwtTokenDetails();

  const handleChange = (event) => {
    const { name, checked } = event.target;

    if (name === "overViewDasboard_View") {
      setFieldValue("overViewDasboard_View", checked);
    }
    if (name === "overViewDasboard_ViewAndEdit") {
      setFieldValue("overViewDasboard_ViewAndEdit", checked);
    }
    if (name === "editGames_View") {
      setFieldValue("editGames_View", checked);
    }
    if (name === "editGames_ViewAndEdit") {
      setFieldValue("editGames_ViewAndEdit", checked);
    }
    if (name === "overallGamesDashboard_View") {
      setFieldValue("overallGamesDashboard_View", checked);
    }
    if (name === "overallGamesDashboard_ViewAndEdit") {
      setFieldValue("overallGamesDashboard_ViewAndEdit", checked);
    }
    if (name === "referralsAnalytics_View") {
      setFieldValue("referralsAnalytics_View", checked);
    }
    if (name === "referralsAnalytics_ViewAndEdit") {
      setFieldValue("referralsAnalytics_ViewAndEdit", checked);
    }
    if (name === "rewardsAnalytics_View") {
      setFieldValue("rewardsAnalytics_View", checked);
    }
    if (name === "rewardsAnalytics_ViewAndEdit") {
      setFieldValue("rewardsAnalytics_ViewAndEdit", checked);
    }
    if (name === "addRewardsConfiguration_View") {
      setFieldValue("addRewardsConfiguration_View", checked);
    }
    if (name === "addRewardsConfiguration_ViewAndEdit") {
      setFieldValue("addRewardsConfiguration_ViewAndEdit", checked);
    }
    if (name === "rewardsDataConfiguration_View") {
      setFieldValue("rewardsDataConfiguration_View", checked);
    }
    if (name === "rewardsDataConfiguration_ViewAndEdit") {
      setFieldValue("rewardsDataConfiguration_ViewAndEdit", checked);
    }
    if (name === "tournamentAnalytics_View") {
      setFieldValue("tournamentAnalytics_View", checked);
    }
    if (name === "tournamentAnalytics_ViewAndEdit") {
      setFieldValue("tournamentAnalytics_ViewAndEdit", checked);
    }
    if (name === "tournamentConfigurationBoot_View") {
      setFieldValue("tournamentConfigurationBoot_View", checked);
    }
    if (name === "tournamentConfigurationBoot_ViewAndEdit") {
      setFieldValue("tournamentConfigurationBoot_ViewAndEdit", checked);
    }
    if (name === "tournamentConfigurationLeaderBoard_View") {
      setFieldValue("tournamentConfigurationLeaderBoard_View", checked);
    }
    if (name === "tournamentConfigurationLeaderBoard_ViewAndEdit") {
      setFieldValue("tournamentConfigurationLeaderBoard_ViewAndEdit", checked);
    }
    if (name === "usersAnalytics_View") {
      setFieldValue("usersAnalytics_View", checked);
    }
    if (name === "usersAnalytics_ViewAndEdit") {
      setFieldValue("usersAnalytics_ViewAndEdit", checked);
    }
    if (name === "editRoles_View") {
      setFieldValue("editRoles_View", checked);
    }
    if (name === "editRoles_ViewAndEdit") {
      setFieldValue("editRoles_ViewAndEdit", checked);
    }
    if (name === "editUsers_View") {
      setFieldValue("editUsers_View", checked);
    }
    if (name === "editUsers_ViewAndEdit") {
      setFieldValue("editUsers_ViewAndEdit", checked);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FEF7EE",
        borderRadius: "8px",
        p: { xs: 2, sm: 2.5, md: 3 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="16px" fontWeight={700} color="#3c3c3c">
            {values?.id
              ? !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                ? "ROLE DETAILS"
                : "EDIT ROLE"
              : "ADD NEW ROLE"}
          </Typography>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <Typography fontSize="14px" fontWeight={400} color="#3c3c3c">
            Name<span style={{ color: "red" }}> *</span>
          </Typography>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <TextField
            id="name"
            size="small"
            fullWidth
            value={values.name}
            onChange={(e) => {
              if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                setFieldValue("name", e.target.value);
              }
            }}
            sx={{
              backgroundColor: colors.FieldBackgroundLight,
              input: { color: colors.lightGreyIcon },
              width: "90%",
            }}
            onBlur={handleBlur}
            disabled={
              !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
            }
          />
          {touched?.name && errors?.name && (
            <Typography
              sx={{
                color: colors.danger,
                fontSize: "11px",
                fontWeight: 600,
              }}
            >
              {errors?.name}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          lg={6}
          md={3}
          sx={{ display: { xs: "none", md: "block" } }}
        />
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <Typography fontSize="14px" fontWeight={400} color="#3c3c3c">
            Description<span style={{ color: "red" }}> *</span>
          </Typography>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <TextField
            id="description"
            size="small"
            fullWidth
            multiline={true}
            rows={3}
            value={values.description}
            inputProps={{ maxLength: 150 }}
            onChange={(e) => {
              if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                setFieldValue("description", e.target.value);
              }
            }}
            sx={{
              backgroundColor: colors.FieldBackgroundLight,
              input: { color: colors.lightGreyIcon },
              width: "90%",
            }}
            onBlur={handleBlur}
            disabled={
              !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
            }
          />
          <Typography
            textAlign="right"
            fontSize="12px"
            mr={5}
            color={colors.fontLighter}
          >
            (Max Characters: 150)
          </Typography>
          {touched?.description && errors?.description && (
            <Typography
              sx={{
                color: colors.danger,
                fontSize: "11px",
                fontWeight: 600,
              }}
            >
              {errors?.description}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          lg={6}
          md={3}
          sx={{ display: { xs: "none", md: "block" } }}
        />
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 10 }}>
          <Box sx={{ border: "1px solid #ababab50", borderRadius: "18px" }}>
            <Grid container spacing={0} justifyContent="center">
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{ borderRight: "1px solid #ababab50", py: 2, px: 2.5 }}
              >
                <Typography fontSize="14px" fontWeight={700} color="#3c3c3c">
                  ALL PERMISSIONS
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{ borderRight: "1px solid #ababab50", py: 2 }}
              >
                <Typography
                  fontSize="14px"
                  fontWeight={700}
                  color="#3c3c3c"
                  textAlign="center"
                >
                  VIEW
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{ borderRight: "1px solid #ababab50", py: 2 }}
              >
                <Typography
                  fontSize="14px"
                  fontWeight={700}
                  color="#3c3c3c"
                  textAlign="center"
                >
                  VIEW & EDIT
                </Typography>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{ display: { xs: "none", md: "block" } }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  px: 2.5,
                  backgroundColor: "#FFE4CB",
                }}
              >
                <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
                  Dashboard
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Overview Dashboard
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.overViewDasboard_View}
                  onChange={handleChange}
                  name="overViewDasboard_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.overViewDasboard_ViewAndEdit}
                  onChange={handleChange}
                  name="overViewDasboard_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{ display: { xs: "none", md: "block" } }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  px: 2.5,
                  backgroundColor: "#FFE4CB",
                }}
              >
                <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
                  Games
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  borderBottom: "1px solid #ababab50",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Overall Games Dashboard
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid #ababab50",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.overallGamesDashboard_View}
                  onChange={handleChange}
                  name="overallGamesDashboard_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid #ababab50",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 18,
                    height: 18,
                    border: "2px solid rgba(0, 0, 0, 0.6)",
                    borderRadius: "15%",
                    backgroundColor: "#fff",
                    padding: 1,
                    fontSize: "16px",
                  }}
                >
                  <RemoveIcon fontSize="inherit" sx={{ color: "#ff8100" }} />
                </Box>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  borderBottom: "1px solid #ababab50",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  px: 4,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Edit Games
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.editGames_View}
                  onChange={handleChange}
                  name="editGames_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.editGames_ViewAndEdit}
                  onChange={handleChange}
                  name="editGames_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{ display: { xs: "none", md: "block" } }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  px: 2.5,
                  backgroundColor: "#FFE4CB",
                }}
              >
                <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
                  Tournaments
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  borderBottom: "1px solid #ababab50",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Tournament Analytics
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid #ababab50",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.tournamentAnalytics_View}
                  onChange={handleChange}
                  name="tournamentAnalytics_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid #ababab50",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 18,
                    height: 18,
                    border: "2px solid rgba(0, 0, 0, 0.6)",
                    borderRadius: "15%",
                    backgroundColor: "#fff",
                    padding: 1,
                    fontSize: "16px",
                  }}
                >
                  <RemoveIcon fontSize="inherit" sx={{ color: "#ff8100" }} />
                </Box>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  borderBottom: "1px solid #ababab50",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  borderBottom: "1px solid #ababab50",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Tournament Configuration - Leaderboard
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.tournamentConfigurationLeaderBoard_View}
                  onChange={handleChange}
                  name="tournamentConfigurationLeaderBoard_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={
                    values?.tournamentConfigurationLeaderBoard_ViewAndEdit
                  }
                  onChange={handleChange}
                  name="tournamentConfigurationLeaderBoard_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  borderBottom: "1px solid #ababab50",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Tournament Configuration - Boot
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.tournamentConfigurationBoot_View}
                  onChange={handleChange}
                  name="tournamentConfigurationBoot_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.tournamentConfigurationBoot_ViewAndEdit}
                  onChange={handleChange}
                  name="tournamentConfigurationBoot_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{ display: { xs: "none", md: "block" } }}
              />

              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  px: 2.5,
                  backgroundColor: "#FFE4CB",
                }}
              >
                <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
                  Rewards
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  borderBottom: "1px solid #ababab50",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Rewards Analytics
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid #ababab50",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.rewardsAnalytics_View}
                  onChange={handleChange}
                  name="rewardsAnalytics_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid #ababab50",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 18,
                    height: 18,
                    border: "2px solid rgba(0, 0, 0, 0.6)",
                    borderRadius: "15%",
                    backgroundColor: "#fff",
                    padding: 1,
                    fontSize: "16px",
                  }}
                >
                  <RemoveIcon fontSize="inherit" sx={{ color: "#ff8100" }} />
                </Box>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  borderBottom: "1px solid #ababab50",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  borderBottom: "1px solid #ababab50",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Referrals Analytics
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.referralsAnalytics_View}
                  onChange={handleChange}
                  name="referralsAnalytics_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.referralsAnalytics_ViewAndEdit}
                  onChange={handleChange}
                  name="referralsAnalytics_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  borderBottom: "1px solid #ababab50",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  borderBottom: "1px solid #ababab50",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Add Rewards Configuration
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.addRewardsConfiguration_View}
                  onChange={handleChange}
                  name="addRewardsConfiguration_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.addRewardsConfiguration_ViewAndEdit}
                  onChange={handleChange}
                  name="addRewardsConfiguration_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  borderBottom: "1px solid #ababab50",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{ borderRight: "1px solid #ababab50", py: 2, px: 4 }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Rewards Data Configuration
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.rewardsDataConfiguration_View}
                  onChange={handleChange}
                  name="rewardsDataConfiguration_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.rewardsDataConfiguration_ViewAndEdit}
                  onChange={handleChange}
                  name="rewardsDataConfiguration_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{ display: { xs: "none", md: "block" } }}
              />
              {/* <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  px: 2.5,
                  backgroundColor: "#FFE4CB"
                }}
              >
                <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
                  Revenue
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB"
                }}
              />
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB"
                }}
              />
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#FFE4CB"
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Revenue Dashboard
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{ display: { xs: "none", md: "block" } }}
              /> */}
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  px: 2.5,
                  backgroundColor: "#FFE4CB",
                }}
              >
                <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
                  Users
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Users Analytics
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.usersAnalytics_View}
                  onChange={handleChange}
                  name="usersAnalytics_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.usersAnalytics_ViewAndEdit}
                  onChange={handleChange}
                  name="usersAnalytics_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{ display: { xs: "none", md: "block" } }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 2,
                  px: 2.5,
                  backgroundColor: "#FFE4CB",
                }}
              >
                <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
                  User Permission
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#FFE4CB",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Edit Roles
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.editRoles_View}
                  onChange={handleChange}
                  name="editRoles_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  borderBottom: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.editRoles_ViewAndEdit}
                  onChange={handleChange}
                  name="editRoles_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{
                  display: { xs: "none", md: "block" },
                  borderBottom: "1px solid #ababab50",
                }}
              />
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  px: 4,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                  Edit Users
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.editUsers_View}
                  onChange={handleChange}
                  name="editUsers_View"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={4}
                xs={4}
                sx={{
                  borderRight: "1px solid #ababab50",
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checkedIcon={<CheckBox sx={{ color: "#ff8100" }} />}
                  checked={values?.editUsers_ViewAndEdit}
                  onChange={handleChange}
                  name="editUsers_ViewAndEdit"
                  disabled={
                    !rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT
                  }
                />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sx={{ display: { xs: "none", md: "block" } }}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "#ffe4cb",
            borderBottomRightRadius: "18px",
            borderBottomLeftRadius: "18px",
            py: 2,
            px: { xs: 2, sm: 2.5, md: 3 },
            display: "flex",
          }}
        >
          {rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT && (
            <OrangeGradientButton
              title={values?.id ? "Update Role" : "Add Role"}
              size="small"
              style={{ width: "128px" }}
              onClick={() => {
                handleSubmit();
              }}
            />
          )}
          <Button
            variant="contained"
            size="small"
            disableElevation
            sx={{
              background: "#8C8E8F40",
              borderRadius: "36px",
              fontWeight: 600,
              fontSize: "16px",
              letterSpacing: "0.02em",
              color: "#FFFFFF",
              width: "128px",
              ml: 2,
            }}
            onClick={() => {
              setSelectedRowId(null);
              setAddEditRole(false);
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const AddEditNewRole = (props: any) => {
  const {
    selectedRowId,
    setSuccessRoleModal,
    refetchAllRolesData,
    setAddEditRole,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [roleDetails, setRoleDetails] = React.useState<any>({});

  const { data: getRoleData, loading: getRoleDataLoading } = useQuery(
    GET_ROLE_DETAILS,
    {
      variables: {
        id: selectedRowId,
      },
      skip: !selectedRowId,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (getRoleData && getRoleData?.getRole && getRoleData?.getRole?.status) {
      const data = getRoleData?.getRole?.role;
      setRoleDetails(data || []);
    }
  }, [getRoleData]);

  const [createRole, { loading: createRoleLoading }] = useMutation(
    CREATE_ROLE,
    {
      onCompleted: (data) => {
        const { createRole } = data;
        const { status, message } = createRole;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          refetchAllRolesData();
          setSuccessRoleModal(true);
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
    }
  );

  const [updateRole, { loading: updateRoleLoading }] = useMutation(
    UPDATE_ROLE,
    {
      onCompleted: (data) => {
        const { updateRole } = data;
        const { status, message } = updateRole;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          refetchAllRolesData();
          setAddEditRole(false);
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
    }
  );

  const AddEditNewRoleFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      let details: any = {};
      try {
        details = roleDetails?.details ? JSON.parse(roleDetails?.details) : {};
      } catch (error) {
        console.error("An error occurred while parsing JSON:", error);
        alert(error);
        details = {};
      }

      return {
        id: selectedRowId ? selectedRowId : null,
        name: roleDetails?.role || "",
        description: roleDetails?.description || "",
        overViewDasboard_View:
          details?.DASHBOARD?.DASHBOARD_OVERVIEW?.VIEW || false,
        overViewDasboard_ViewAndEdit:
          details?.DASHBOARD?.DASHBOARD_OVERVIEW?.VIEW_AND_EDIT || false,
        editGames_View: details?.GAMES?.EDIT_GAMES?.VIEW || false,
        editGames_ViewAndEdit:
          details?.GAMES?.EDIT_GAMES?.VIEW_AND_EDIT || false,
        overallGamesDashboard_View:
          details?.GAMES?.GAMES_DASHBOARD?.VIEW || false,
        overallGamesDashboard_ViewAndEdit:
          details?.GAMES?.GAMES_DASHBOARD?.VIEW_AND_EDIT || false,
        referralsAnalytics_View:
          details?.REWARDS?.REFERRALS_ANALYTICS?.VIEW || false,
        referralsAnalytics_ViewAndEdit:
          details?.REWARDS?.REFERRALS_ANALYTICS?.VIEW_AND_EDIT || false,
        rewardsAnalytics_View:
          details?.REWARDS?.REWARDS_ANALYTICS?.VIEW || false,
        rewardsAnalytics_ViewAndEdit:
          details?.REWARDS?.REWARDS_ANALYTICS?.VIEW_AND_EDIT || false,
        addRewardsConfiguration_View:
          details?.REWARDS?.REWARDS_CONFIGURATION?.VIEW || false,
        addRewardsConfiguration_ViewAndEdit:
          details?.REWARDS?.REWARDS_CONFIGURATION?.VIEW_AND_EDIT || false,
        rewardsDataConfiguration_View:
          details?.REWARDS?.REWARDS_DATA_CONFIGURATION?.VIEW || false,
        rewardsDataConfiguration_ViewAndEdit:
          details?.REWARDS?.REWARDS_DATA_CONFIGURATION?.VIEW_AND_EDIT || false,
        tournamentAnalytics_View:
          details?.TOURNAMENTS?.TOURNAMENT_ANALYTICS?.VIEW || false,
        tournamentAnalytics_ViewAndEdit:
          details?.TOURNAMENTS?.TOURNAMENT_ANALYTICS?.VIEW_AND_EDIT || false,
        tournamentConfigurationBoot_View:
          details?.TOURNAMENTS?.TOURNAMENT_CONFIGURATION_BOOT?.VIEW || false,
        tournamentConfigurationBoot_ViewAndEdit:
          details?.TOURNAMENTS?.TOURNAMENT_CONFIGURATION_BOOT?.VIEW_AND_EDIT ||
          false,
        tournamentConfigurationLeaderBoard_View:
          details?.TOURNAMENTS?.TOURNAMENT_CONFIGURATION_LEADERBOARD?.VIEW ||
          false,
        tournamentConfigurationLeaderBoard_ViewAndEdit:
          details?.TOURNAMENTS?.TOURNAMENT_CONFIGURATION_LEADERBOARD
            ?.VIEW_AND_EDIT || false,
        usersAnalytics_View: details?.USERS?.USERS_ANALYTICS?.VIEW || false,
        usersAnalytics_ViewAndEdit:
          details?.USERS?.USERS_ANALYTICS?.VIEW_AND_EDIT || false,
        editRoles_View: details?.USERS_PERMISSION?.EDIT_ROLES?.VIEW || false,
        editRoles_ViewAndEdit:
          details?.USERS_PERMISSION?.EDIT_ROLES?.VIEW_AND_EDIT || false,
        editUsers_View: details?.USERS_PERMISSION?.EDIT_USERS?.VIEW || false,
        editUsers_ViewAndEdit:
          details?.USERS_PERMISSION?.EDIT_USERS?.VIEW_AND_EDIT || false,
      };
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("This is a required field"),
      description: Yup.string().required("This is a required field"),
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      const details = getCreateRoleDetails(values);
      if (checkAtleastOnePermission(values)) {
        if (values?.id) {
          updateRole({
            variables: {
              input: {
                id: values?.id,
                role: values.name,
                description: values.description,
                details: JSON.stringify(details),
              },
            },
          });
        } else {
          createRole({
            variables: {
              input: {
                role: values.name,
                description: values.description,
                details: JSON.stringify(details),
              },
            },
          });
        }
      } else {
        enqueueSnackbar("Please select atleast one screen permission", {
          variant: "error",
        });
      }
    },
  })(AddEditNewRoleComponent);
  return <AddEditNewRoleFormWrapped {...props} />;
};

export default AddEditNewRole;
