import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "styles/colors";
import GameDetails from "./GameDetails";
import GameImageTable from "./GameImageTable";
import GameVideoTable from "./GameVideoTable";
import OrangeGradientButton from "../OrangeGradientButton";
import { useMutation } from "@apollo/client";
import { ADD_GAME } from "app/graphql/game";
import { useSnackbar } from "notistack";
import getStore from "store/zustandStore";

export interface IAddGame {
  title: string;
  description: string;
  details?: string;
  category: string;
  gamePackage: any;
  homeScreen: any;
  gameTournamentScreen?: any;
  tournamentScreen: any;
  bootScreen: any;
  winnerScreen: any;
  gameTutorial: any;
  portrait: boolean;
  landscape: boolean;
}

const AddGamesForm = (props: FormikProps<IAddGame>) => {
  const history = useHistory();
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          fontSize="18px"
          fontWeight={600}
          color={colors.lightGreyIcon}
        >
          Files and Assets
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          color={colors.lightGreyIcon}
        >
          All fields must be filled out in order to add the game
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GameDetails {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GameImageTable {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GameVideoTable {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box display="flex" alignItems="center">
          <OrangeGradientButton
            title="Add Game"
            style={{ width: "133px" }}
            onClick={() => props.handleSubmit()}
          />
          <Button
            variant="contained"
            size="small"
            disableElevation
            sx={{
              background: "#8C8E8F40",
              borderRadius: "36px",
              fontWeight: 600,
              fontSize: "16px",
              letterSpacing: "0.02em",
              color: "#FFFFFF",
              width: "133px",
              ml: 2
            }}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const AddGames = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = getStore();

  const [fireAddGameApi, { loading }] = useMutation(ADD_GAME, {
    onCompleted: (data) => {
      const { createGame } = data;
      const { status, message } = createGame;
      if (status) {
        enqueueSnackbar(message, { variant: "success" });
        history.goBack();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const AddGamesWrapped = withFormik<any, IAddGame>({
    mapPropsToValues: () => ({
      title: "",
      description: "",
      category: "",
      gamePackage: null,
      homeScreen: null,
      tournamentScreen: null,
      bootScreen: null,
      winnerScreen: null,
      gameTutorial: null,
      // gameTournamentScreen: null,
      portrait: false,
      landscape: false
    }),

    validationSchema: Yup.object().shape({
      title: Yup.string().required("Please enter the name"),
      description: Yup.string().required("Please enter the description"),
      category: Yup.string().required("Please select the type"),
      gamePackage: Yup.string()
        .required("Please upload the game package")
        .typeError("Please upload the game package"),
      // winnerScreen: Yup.string()
      //   .required("Please upload the winner screen image")
      //   .typeError("Please upload the winner screen image"),
      homeScreen: Yup.string()
        .required("Please upload the home screen image")
        .typeError("Please upload the home screen image")
      // tournamentScreen: Yup.string()
      //   .required("Please upload the tournament screen video")
      //   .typeError("Please upload the tournament screen video"),
      // bootScreen: Yup.string()
      //   .required("Please upload the boot screen image")
      //   .typeError("Please upload the boot screen image"),
      // gameTutorial: Yup.string()
      //   .required("Please upload the game tutorial")
      //   .typeError("Please upload the game tutorial"),
      // gameTournamentScreen: Yup.string()
      //   .required("Please upload the game tournament screen image")
      //   .typeError("Please upload the game tournament screen image"),
    }),

    handleSubmit: (values) => {
      const {
        title,
        description,
        category,
        homeScreen,
        bootScreen,
        winnerScreen,
        tournamentScreen,
        gameTutorial,
        gamePackage,
        portrait
      } = values;
      fireAddGameApi({
        variables: {
          input: {
            title,
            description,
            category: category.toString(),
            orientation: portrait ? "Portrait" : "Landscape"
          },
          ...(homeScreen !== null && { homeScreen }),
          ...(bootScreen !== null && { bootScreen }),
          ...(tournamentScreen !== null && { tournamentScreen }),
          ...(winnerScreen !== null && { winnerScreen }),
          ...(gameTutorial !== null && { gameTutorial }),
          ...(gamePackage !== null && { gamePackage })
        }
      });
    }
  })(AddGamesForm);
  return <AddGamesWrapped />;
};

export default AddGames;
