import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "styles/colors";
import GameDetails from "./GameDetails";
import GameImageTable from "./GameImageTable";
import GameVideoTable from "./GameVideoTable";
import OrangeGradientButton from "../OrangeGradientButton";
import { useMutation } from "@apollo/client";
import { UPDATE_GAME } from "app/graphql/game";
import { useSnackbar } from "notistack";
import { IGame } from "app/models/games";
import getStore from "store/zustandStore";

export interface IEditGame {
  title: string;
  description: string;
  details?: string;
  category: string;
  gamePackage: any;
  homeScreen: any;
  tournamentScreen: any;
  bootScreen: any;
  winnerScreen: any;
  gameTutorial: any;
  gameTournamentScreen?: any;
  portrait: boolean;
  landscape: boolean;
}

interface IEditGameProps {
  gameDetails: IGame | null;
}

const EditGamesForm = (props: FormikProps<IEditGame>) => {
  const history = useHistory();

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          fontSize="18px"
          fontWeight={600}
          color={colors.lightGreyIcon}
        >
          Files and Assets
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          color={colors.lightGreyIcon}
        >
          All files and assets related to games
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GameDetails {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GameImageTable {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GameVideoTable {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box display="flex" alignItems="center">
          <OrangeGradientButton
            title="Save Game"
            style={{ width: "133px" }}
            onClick={() => props.handleSubmit()}
          />
          <Button
            variant="contained"
            size="small"
            disableElevation
            sx={{
              background: "#8C8E8F40",
              borderRadius: "36px",
              fontWeight: 600,
              fontSize: "16px",
              letterSpacing: "0.02em",
              color: "#FFFFFF",
              width: "133px",
              ml: 2,
            }}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const EditGames = (props: IEditGameProps) => {
  const { gameDetails } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = getStore();

  const [detailsJSON, setDetailsJSON] = useState<any>(null);

  const [fireEditGameApi, { loading }] = useMutation(UPDATE_GAME, {
    onCompleted: (data) => {
      const { updateGame } = data;
      const { status, message } = updateGame;
      if (status) {
        history.goBack();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (gameDetails && gameDetails?.details) {
      const tempJSON = gameDetails.details;
      setDetailsJSON(tempJSON);
    } else {
      setDetailsJSON(null);
    }
  }, [gameDetails]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const EditGamesWrapped = withFormik<any, IEditGame>({
    mapPropsToValues: () => ({
      title: gameDetails?.title || "",
      description: gameDetails?.description || "",
      category: gameDetails?.gameCategory?.category
        ? gameDetails?.gameCategory?.category
        : "",
      gamePackage: gameDetails?.bundleUrl || null,
      homeScreen: gameDetails?.thumbnailImageUrl || null,
      tournamentScreen:
        detailsJSON && detailsJSON?.tournamentScreenUrl
          ? detailsJSON?.tournamentScreenUrl
          : null,
      bootScreen:
        detailsJSON && detailsJSON?.bootScreenUrl
          ? detailsJSON?.bootScreenUrl
          : null,
      winnerScreen:
        detailsJSON && detailsJSON?.winnerScreenUrl
          ? detailsJSON?.winnerScreenUrl
          : null,
      gameTutorial: gameDetails?.tutorialUrl || null,
      // gameTournamentScreen:
      //   detailsJSON && detailsJSON?.gameTournamentScreenUrl
      //     ? detailsJSON?.gameTournamentScreenUrl
      //     : null,
      portrait: gameDetails?.orientation === "Portrait" ? true : false,
      landscape: gameDetails?.orientation === "Landscape" ? true : false,
    }),

    validationSchema: Yup.object().shape({
      title: Yup.string().required("Please enter the name"),
      description: Yup.string().required("Please enter the description"),
      category: Yup.string().required("Please select the type"),
      gamePackage: Yup.string()
        .required("Please upload the game package")
        .typeError("Please upload the game package"),
      // winnerScreen: Yup.string()
      //   .required("Please upload the winner screen image")
      //   .typeError("Please upload the winner screen image"),
      homeScreen: Yup.string()
        .required("Please upload the home screen image")
        .typeError("Please upload the home screen image"),
      // tournamentScreen: Yup.string()
      //   .required("Please upload the tournament screen video")
      //   .typeError("Please upload the tournament screen video"),
      // bootScreen: Yup.string()
      //   .required("Please upload the boot screen image")
      //   .typeError("Please upload the boot screen image"),
      // gameTutorial: Yup.string()
      //   .required("Please upload the game tutorial")
      //   .typeError("Please upload the game tutorial"),
      // gameTournamentScreen: Yup.string()
      //   .required("Please upload the game tournament screen image")
      //   .typeError("Please upload the game tournament screen image"),
    }),

    handleSubmit: (values) => {
      const {
        title,
        description,
        category,
        gamePackage,
        homeScreen,
        tournamentScreen,
        bootScreen,
        winnerScreen,
        gameTutorial,
        // gameTournamentScreen,
        portrait,
      } = values;
      fireEditGameApi({
        variables: {
          input: {
            title,
            description,
            category: category.toString(),
            id: gameDetails?.id,
            orientation: portrait ? "Portrait" : "Landscape",
          },
          ...(gamePackage &&
            typeof gamePackage !== "string" && { gamePackage }),
          ...(homeScreen && typeof homeScreen !== "string" && { homeScreen }),
          ...(tournamentScreen &&
            typeof tournamentScreen !== "string" && { tournamentScreen }),
          ...(bootScreen && typeof bootScreen !== "string" && { bootScreen }),
          ...(winnerScreen &&
            typeof winnerScreen !== "string" && { winnerScreen }),
          ...(gameTutorial &&
            typeof gameTutorial !== "string" && { gameTutorial }),
          // ...(gameTournamentScreen &&
          //   typeof gameTournamentScreen !== "string" && {
          //     gameTournamentScreen,
          //   }),
        },
      });
    },
  })(EditGamesForm);
  return <EditGamesWrapped />;
};

export default EditGames;
