import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import moment from "moment";
import DateFilter from "../DateFilterCharts";
import { formatNumberToK } from "utils/helpers";

const UserGameAnalysis = (props) => {
  const {
    rewardsByGameTypeData,
    rewardsByGameTypeStartDate,
    rewardsByGameTypeEndDate,
    setRewardsByGameTypeStartDate,
    setRewardsByGameTypeEndDate
  } = props;

  const type = [
    {
      title: "Spin the wheel",
      color: colors.orange
    },
    {
      title: "Mystery Box",
      color: colors.lightGreen
    }
    // {
    //   title: "Referrals",
    //   color: "#9EDA89"
    // }
  ];

  const chartProperties = {
    height: 150,
    width: 120
  };

  const graphData = rewardsByGameTypeData?.rewardsByGameType?.map(
    (item, index) => ({
      title: item?.name || "",
      y: item?.value || 0,
      color:
        index === 0
          ? colors.orange
          : index === 1
          ? colors.lightGreen
          : colors.blue,
      value: item?.value
    })
  );

  // const graphData = [
  //   {
  //     y: 25,
  //     title: "Spin the Wheel",
  //     color: "#517DD1",
  //     value: "₹100"
  //   },
  //   {
  //     y: 10,
  //     title: "Mystery Box",
  //     color: "#FBCB20",
  //     value: "₹100"
  //   },
  //   {
  //     y: 10,
  //     title: "Referrals",
  //     color: "#9EDA89",
  //     value: "₹100"
  //   }
  // ]

  return (
    <Grid container>
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: 2,
          m: 1
        }}
      >
        <Grid xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start"
            }}
          >
            <div>
              <Typography
                color="#3C3C3C"
                fontSize="16px"
                fontWeight={600}
                sx={{ py: 1 }}
              >
                Daily Claimed Rewards
              </Typography>
              <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                All active claimed rewards
              </Typography>
            </div>
            <div>
              <DateFilter
                startDate={rewardsByGameTypeStartDate}
                endDate={rewardsByGameTypeEndDate}
                onChangeStartDate={(newDate) =>
                  setRewardsByGameTypeStartDate(newDate)
                }
                onChangeEndDate={(newDate) =>
                  setRewardsByGameTypeEndDate(newDate)
                }
                disableFutureForStart={true}
                disableFutureForEnd={true}
              />
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center"
            }}
          >
            <div>
              <DonutPieChart
                title={
                  rewardsByGameTypeData?.rewardsClaimed
                    ? formatNumberToK(
                        rewardsByGameTypeData?.rewardsClaimed,
                        "money"
                      )
                    : 0
                }
                subtitle="Total Rewards"
                isGaming={true}
                data={graphData}
                partColors={[
                  "#517DD1",
                  "#FBCB20",
                  "#9EDA89",
                  "#C487E1",
                  "#77E4C4",
                  "#EAE459"
                ]}
                properties={chartProperties}
              />
            </div>
            <div>
              {type.map((item) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 5
                    }}
                  >
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: item.color,
                        borderRadius: 50
                      }}
                    ></div>
                    <Typography
                      color="#464646"
                      fontSize="10px"
                      fontWeight={400}
                      sx={{ px: 1 }}
                    >
                      {item.title}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserGameAnalysis;
