import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import Games from 'app/components/Games';

const GamesPage = () => {
  const pageHeader = {
    title: 'Game Details',
    breadCrumb: 'Games',
  };
  return (
    <>
      <Helmet>
        <title>Games</title>
      </Helmet>
      <MiniDrawer content={<Games />} isHomepage={false} header={pageHeader} />
    </>
  );
};

export default GamesPage;
