import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { colors } from "../../../styles/colors";
import { useMutation } from "@apollo/client";
import { LOGIN, CREATE_USER } from "app/graphql/users";
import { withFormik } from "formik";
import * as Yup from "yup";
import getStore from "store/zustandStore";
import { getRedirectPathAfterLogin } from "./utils";
import { CheckBox } from "@mui/icons-material";

const LoginFormContent = (props) => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    keepMeSignedIn,
    setKeepMeSignedIn
  } = props;

  const history = useHistory();

  const [register, setRegister] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  React.useEffect(() => {
    if (sessionStorage.getItem("token")) {
      history.push(
        getRedirectPathAfterLogin(sessionStorage.getItem("token") || "")
      );
    }
  }, [history]);

  const handleChange = (event) => {
    const { checked } = event.target;
    // setKeepMeSignedIn(checked);
    setFieldValue("keepMeSignedIn", checked);
  };

  return (
    <Box
      sx={{
        py: { xs: 5, sm: 3 },
        px: { xs: 5, sm: 10 },
        backgroundColor: { xs: colors.background, sm: colors.background },
        minHeight: "110vh",
        display: "flex",
        alignItems: "center"
      }}
    >
      <Box
        sx={{
          // backgroundColor: colors.background,
          borderRadius: "4px"
        }}
      >
        <Grid>
          {!register && (
            <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography color="secondary" fontSize="20px" fontWeight={700}>
                  ADMIN LOGIN
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="email"
                  variant="outlined"
                  fullWidth
                  placeholder="Email address"
                  className="inputRounded"
                  sx={{
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      display: "none"
                    },
                    border: "1px solid #333333",
                    background: "#1C1C1C",
                    margin: "10px 0px",
                    borderRadius: "32px",
                    input: { color: "#A8A8A8" }
                  }}
                  value={values?.email}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  onBlur={handleBlur}
                />
                {touched?.email && errors?.email && (
                  <Typography
                    sx={{ color: colors.invalidInput, fontSize: "11px", pl: 2 }}
                  >
                    {errors?.email}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="password"
                  type={showPassword2 ? "text" : "password"}
                  fullWidth
                  className="inputRounded"
                  sx={{
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      display: "none"
                    },
                    border: "1px solid #333333",
                    background: "#1C1C1C",
                    margin: "10px 0px",
                    borderRadius: "32px",
                    input: { color: "#A8A8A8" }
                  }}
                  placeholder="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setShowPassword2(!showPassword2)}
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  value={values?.password}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  onBlur={handleBlur}
                />
                {touched?.password && errors?.password && (
                  <Typography
                    sx={{ color: colors.invalidInput, fontSize: "11px", pl: 2 }}
                  >
                    {errors?.password}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: "40%",
                    background:
                      "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                    boxShadow:
                      "-6px -6px 12px rgba(193, 193, 193, 0.08), 6px 6px 12px rgba(4, 4, 4, 0.45)",
                    borderRadius: "30px",
                    height: "48px",
                    fontWeight: 700,
                    fontSize: "16px",
                    letterSpacing: "0.02em"
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  LOGIN
                </Button>
              </Grid>
            </Grid>
          )}
          {/* {register && (
            <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography color="secondary" fontSize="20px" fontWeight={700}>
                  ADMIN REGISTER
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="firstName"
                  variant="outlined"
                  fullWidth
                  placeholder="First Name"
                  className="inputRounded"
                  sx={{
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      display: "none"
                    },
                    border: "1px solid #333333",
                    background: "#1C1C1C",
                    mt: register ? 1 : 2,
                    borderRadius: "32px",
                    input: { color: "#A8A8A8" }
                  }}
                  onChange={(e) => setFieldValue("firstName", e.target.value)}
                  onBlur={handleBlur}
                />
                {touched?.firstName && errors?.firstName && (
                  <Typography
                    sx={{
                      color: colors.invalidInput,
                      fontSize: "11px",
                      pl: 2,
                      pt: 1
                    }}
                  >
                    {errors?.firstName}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="lastName"
                  variant="outlined"
                  fullWidth
                  placeholder="Last Name"
                  className="inputRounded"
                  sx={{
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      display: "none"
                    },
                    border: "1px solid #333333",
                    background: "#1C1C1C",
                    mt: register ? 1 : 2,
                    borderRadius: "32px",
                    input: { color: "#A8A8A8" }
                  }}
                  onChange={(e) => setFieldValue("lastName", e.target.value)}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="email"
                  variant="outlined"
                  fullWidth
                  placeholder="Email address"
                  className="inputRounded"
                  sx={{
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      display: "none"
                    },
                    border: "1px solid #333333",
                    background: "#1C1C1C",
                    mt: register ? 1 : 2,
                    borderRadius: "32px",
                    input: { color: "#A8A8A8" }
                  }}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  onBlur={handleBlur}
                />
                {touched?.email && errors?.email && (
                  <Typography
                    sx={{
                      color: colors.invalidInput,
                      fontSize: "11px",
                      pl: 2,
                      pt: 1
                    }}
                  >
                    {errors?.email}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="role"
                  variant="outlined"
                  fullWidth
                  placeholder="Role (Eg: Admin)"
                  className="inputRounded"
                  sx={{
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      display: "none"
                    },
                    border: "1px solid #333333",
                    background: "#1C1C1C",
                    mt: register ? 1 : 2,
                    borderRadius: "32px",
                    input: { color: "#A8A8A8" }
                  }}
                  onChange={(e) => setFieldValue("role", e.target.value)}
                  onBlur={handleBlur}
                />
                {touched?.role && errors?.role && (
                  <Typography
                    sx={{
                      color: colors.invalidInput,
                      fontSize: "11px",
                      pl: 2,
                      pt: 1
                    }}
                  >
                    {errors?.role}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="password"
                  type={showPassword2 ? "text" : "password"}
                  fullWidth
                  className="inputRounded"
                  sx={{
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      display: "none"
                    },
                    border: "1px solid #333333",
                    background: "#1C1C1C",
                    mt: register ? 1 : 2,
                    borderRadius: "32px",
                    input: { color: "#A8A8A8" }
                  }}
                  placeholder="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setShowPassword2(!showPassword2)}
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  onBlur={handleBlur}
                />
                {touched?.password && errors?.password && (
                  <Typography
                    sx={{
                      color: colors.invalidInput,
                      fontSize: "11px",
                      pl: 2,
                      pt: 1
                    }}
                  >
                    {errors?.password}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: "40%",
                    background:
                      "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                    boxShadow:
                      "-6px -6px 12px rgba(193, 193, 193, 0.08), 6px 6px 12px rgba(4, 4, 4, 0.45)",
                    borderRadius: "30px",
                    height: "48px",
                    fontWeight: 700,
                    fontSize: "16px",
                    letterSpacing: "0.02em"
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          )} */}
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              mt: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            {/* <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={
                  <Typography color="#A8A8A8" fontSize="16px" fontWeight={400}>
                    Keep me signed in
                  </Typography>
                }
              />
            </FormGroup> */}
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: colors.lightGray }}
                  checkedIcon={<CheckBox sx={{ color: colors.invalidInput }} />}
                  checked={values.keepMeSignedIn}
                  onChange={handleChange}
                />
              }
              label={
                <Typography
                  color={colors.lightGray}
                  fontSize="16px"
                  fontWeight={400}
                >
                  Keep me signed in
                </Typography>
              }
              labelPlacement="end"
            />
            <Grid display={"flex"}>
              {/* <Typography
                color="#FFF9F2"
                fontSize="16px"
                fontWeight={400}
                sx={{ textDecoration: "underline", mr: 2, cursor: "pointer" }}
                onClick={() => {
                  setRegister(!register);
                  setFieldValue("register", !register);
                }}
              >
                {register ? "Login" : "Register"}
              </Typography> */}
              <Typography
                color="#FFF9F2"
                fontSize="16px"
                fontWeight={400}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  history.push("/get-reset-password-link");
                }}
              >
                Forgot Password?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const LoginForm = (props: any) => {
  const history = useHistory();
  const { setLoading } = getStore();
  const [keepMeSignedIn, setKeepMeSignedIn] = React.useState(true);

  // React.useEffect(() => {
  //   if (token) {
  //     console.log(token);
  // const redirectPath = getRedirectPathAfterLogin(token);
  //     console.log(redirectPath);
  //     history.push(redirectPath);
  //   }
  // }, [token]);

  const [login, { loading: loginLoading }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      console.log(data);
      const { login } = data;
      const { message, status, token } = login;
      if (status) {
        if (keepMeSignedIn) {
          localStorage.setItem("authToken", token);
        }
        sessionStorage.setItem("token", token);
        history.push(getRedirectPathAfterLogin(token));
      } else {
        alert(message);
      }
    },
    fetchPolicy: "network-only"
  });

  const [createUser, { loading: createUserLoading }] = useMutation(
    CREATE_USER,
    {
      onCompleted: (data) => {
        console.log(data);
        const { createUser } = data;
        const { message, status } = createUser;
        if (status) {
          alert(message);
        } else {
          alert(message);
        }
      },
      fetchPolicy: "network-only"
    }
  );
  React.useEffect(() => {
    const authToken: any = localStorage.getItem("authToken");
    if (Boolean(authToken)) {
      sessionStorage.setItem("token", authToken);
    }
    const redirectPath = Boolean(authToken)
      ? getRedirectPathAfterLogin(authToken)
      : null;
    if (redirectPath) {
      history.push(redirectPath);
    }
  }, []);

  useEffect(() => {
    setLoading(loginLoading || createUserLoading);
  }, [loginLoading, createUserLoading]);

  const LoginFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        role: "",
        register: false,
        keepMeSignedIn: true
      };
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please provide a valid email")
        .required("Please enter the email address"),
      password: Yup.string().required("Please enter the password"),
      firstName: Yup.string().when("register", {
        is: true,
        then: Yup.string().required("Please enter your first name")
      }),
      role: Yup.string().when("register", {
        is: true,
        then: Yup.string().required("Please enter your role")
      })
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      // console.log(">>>>>>>>>>>>>values", values, props)
      setKeepMeSignedIn(values.keepMeSignedIn);
      if (values.firstName) {
        createUser({
          variables: {
            payload: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              role: values.role,
              password: values.password
            }
          }
        });
      } else {
        login({
          variables: {
            payload: {
              email: values.email,
              password: values.password
            }
          }
        });
      }
    }
  })(LoginFormContent);
  return (
    <LoginFormWrapped
      {...props}
      keepMeSignedIn={keepMeSignedIn}
      setKeepMeSignedIn={setKeepMeSignedIn}
    />
  );
};

export default LoginForm;
