import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./textfield.css";

import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";

import { withFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "app/graphql/test";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { colors } from "../../styles/colors";
import queryString from "query-string";
import { useSnackbar } from "notistack";

const SetNewPasswordFormContent = (props) => {
  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    props;

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  return (
    <Box
      sx={{
        py: { xs: 5, sm: 3 },
        px: { xs: 5, sm: 10 },
        backgroundColor: { xs: colors.background, sm: colors.background },
        minHeight: "110vh",
        display: "flex",
        alignItems: "center"
      }}
    >
      <Box
        sx={{
          // backgroundColor: colors.background,
          borderRadius: "4px"
        }}
      >
        <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color="secondary" fontSize="20px" fontWeight={700}>
              SET NEW PASSWORD
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              type={showPassword1 ? "text" : "password"}
              className="inputRounded"
              fullWidth
              sx={{
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  display: "none"
                },
                border: "1px solid #333333",
                background: "#1C1C1C",
                margin: "10px 0px",
                borderRadius: "32px",
                input: { color: "#A8A8A8" }
              }}
              placeholder="New password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 ? (
                        <Visibility sx={{ color: "#FFFFFF" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#FFFFFF" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {touched.password && errors.password && (
              <Typography
                fontSize="11px"
                color={colors.invalidInput}
                sx={{ pl: 1 }}
              >
                {errors.password}
              </Typography>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              type={showPassword2 ? "text" : "password"}
              fullWidth
              className="inputRounded"
              sx={{
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  display: "none"
                },
                border: "1px solid #333333",
                background: "#1C1C1C",
                margin: "10px 0px",
                borderRadius: "32px",
                input: { color: "#A8A8A8" }
              }}
              placeholder="Confirm new password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setShowPassword2(!showPassword2)}
                    >
                      {showPassword2 ? (
                        <Visibility sx={{ color: "#FFFFFF" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#FFFFFF" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <Typography
                fontSize="11px"
                color={colors.invalidInput}
                sx={{ pl: 1 }}
              >
                {errors.confirmPassword}
              </Typography>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <Button
              size="small"
              variant="contained"
              sx={{
                width: "40%",
                background:
                  "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                boxShadow:
                  "-6px -6px 12px rgba(193, 193, 193, 0.08), 6px 6px 12px rgba(4, 4, 4, 0.45)",
                borderRadius: "30px",
                height: "48px",
                fontWeight: 700,
                fontSize: "16px",
                letterSpacing: "0.02em"
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              SUBMIT
            </Button>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              mt: 1
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={
                  <Typography color="#A8A8A8" fontSize="16px" fontWeight={400}>
                    Keep me signed in
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const SetNewPasswordForm = (props) => {
  const uriParams: any = useParams();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [queryStringParsed, setQueryStringParsed] = useState("");
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    if (id) {
      setQueryStringParsed(id);
    }
  }, [location]);
  const [setNewPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      const { resetPassword } = data;
      const { message, status } = resetPassword;
      if (status) {
        enqueueSnackbar(message, {
          variant: "success"
        });
        setTimeout(() => {
          history.push("/");
        }, 200);
      } else {
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }
  });

  const SetNewPasswordFormWrapped = withFormik({
    mapPropsToValues: () => ({
      oldPassword: "",
      password: "",
      confirmPassword: ""
    }),

    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Please enter the new password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Password must contain 1 capital letter, 1 number & 1 special character and min. 8 characters"
        ),
      confirmPassword: Yup.string()
        .required("Please confirm your new password")
        .oneOf([Yup.ref("password"), null], "Passwords did not match")
    }),

    handleSubmit(values, { props }) {
      console.log(values);
      console.log(queryStringParsed);
      setNewPassword({
        variables: {
          payload: {
            token: queryStringParsed,
            password: values.password
          }
        }
      });
    }
  })(SetNewPasswordFormContent);

  return <SetNewPasswordFormWrapped />;
};

export default SetNewPasswordForm;
