import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import Dashboard from 'app/components/Dashboard';

const DashboardPage = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <MiniDrawer content={<Dashboard />} isHomepage={true} header={''} />
    </>
  );
};

export default DashboardPage;
