import React, { useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { CalendarMonthOutlined, Close } from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { colors } from "styles/colors";

interface IProps {
  startDate: any;
  endDate: any;
  onChangeStartDate: any;
  onChangeEndDate: any;
  disableFutureForStart?: boolean;
  disablePastForStart?: boolean;
  disableFutureForEnd?: boolean;
  disablePastForEnd?: boolean;
}

const DateFilterCharts = (props: IProps) => {
  const {
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    disableFutureForStart,
    disableFutureForEnd,
    disablePastForStart,
    disablePastForEnd
  } = props;

  const [showDateFilter, setShowDateFilter] = useState(false);

  return (
    <Box>
      {!showDateFilter && (
        <IconButton onClick={() => setShowDateFilter(true)}>
          <CalendarMonthOutlined />
        </IconButton>
      )}
      {showDateFilter && (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            backgroundColor: `${colors.MysteryBoxLightOrange}55`,
            py: 0.75,
            px: 1,
            borderRadius: "4px"
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disablePast={disablePastForStart || false}
              disableFuture={disableFutureForStart || false}
              value={startDate}
              onChange={(newDate) => onChangeStartDate(newDate)}
              inputFormat="DD/MM/YYYY"
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <Typography fontSize="14px" color={colors.gray} sx={{ mx: 1 }}>
            to
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disablePast={disablePastForEnd || false}
              disableFuture={disableFutureForEnd || false}
              value={endDate}
              onChange={(newDate) => onChangeEndDate(newDate)}
              inputFormat="DD/MM/YYYY"
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <IconButton size="small" onClick={() => setShowDateFilter(false)}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default DateFilterCharts;
