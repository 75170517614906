import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import Tabs from "../Tabs";
import RewardDetails from "./RewardsDetails";
import RewardsData from "./RewardData";
import { getJwtTokenDetails } from "utils/helpers";

const AddRewards = () => {
  const { rolePermissions } = getJwtTokenDetails();

  const tabs = useMemo(() => {
    if (
      (rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW_AND_EDIT) &&
      (rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW_AND_EDIT)
    ) {
      return [
        {
          label: "Rewards Details",
        },
        {
          label: "Rewards Data",
        },
      ];
    }
    if (
      !(
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW_AND_EDIT
      ) &&
      (rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW_AND_EDIT)
    ) {
      return [
        {
          label: "Rewards Data",
        },
      ];
    }
    if (
      (rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW_AND_EDIT) &&
      !(
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW_AND_EDIT
      )
    ) {
      return [
        {
          label: "Rewards Details",
        },
      ];
    }
    return [];
  }, [rolePermissions]);

  const tabContent = useMemo(() => {
    if (
      (rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW_AND_EDIT) &&
      (rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW_AND_EDIT)
    ) {
      return [<RewardDetails />, <RewardsData />];
    }
    if (
      !(
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW_AND_EDIT
      ) &&
      (rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW_AND_EDIT)
    ) {
      return [<RewardsData />];
    }
    if (
      (rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW_AND_EDIT) &&
      !(
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW_AND_EDIT
      )
    ) {
      return [<RewardDetails />];
    }
    return [];
  }, [rolePermissions]);

  return (
    <Grid>
      <Tabs tabs={tabs} tabContent={tabContent} />
    </Grid>
  );
};

export default AddRewards;
