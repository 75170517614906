import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import images from "../../assets/images/images";

const LoginBackground = () => {
  return (
    <Grid
      container
      spacing={4}
      sx={{ pt: 3, px: { sm: 3, md: 5 } }}
    >
      <Grid item lg={12} md={12} sm={12}>
        <div style={{ position: "absolute", top: 2 }}>
          <img
            src={images.logo_small}
          />
        </div>
      </Grid>
      <Grid item lg={12} md={12} sm={12} sx={{ mt: 10 }}>
        <Typography color="secondary" fontSize="35px" fontWeight={800}>
          Analyze and <span style={{ color: '#FF8100' }}>Improve</span>
        </Typography>
        <Typography color="secondary" fontSize="35px" fontWeight={800}>
          the customer experience
        </Typography>
        <Typography color='#A8A8A8' fontSize="16px" fontWeight={400}>
          Analyze user behaviour across GD app
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} />
      <Grid item lg={12} md={12} sm={12} display="flex" justifyContent="center">
        <div style={{ position: "absolute", bottom: -10, left: 70 }}>
          <img
            src={images.login_amico}
            height={330}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginBackground;
