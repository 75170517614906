import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Chip,
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  MenuItem,
  Divider,
  Popover
} from "@mui/material";
import Table from "../../../Table";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { colors } from "styles/colors";
import images from "assets/images/images";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getDateInFormat, getJwtTokenDetails } from "utils/helpers";
import { useQuery } from "@apollo/client";
import { GET_REWARD_DETAIL } from "app/graphql/rewards";

interface IRewardsTableProps {
  setItem: any;
  rewardCategory: string;
  rewards: any;
  setSelectedRow: any;
  setRewardDetail: any;
  deleteReward: any;
  setAction: any;
}

const RewardsTable = (props: IRewardsTableProps) => {
  const {
    setItem,
    rewardCategory,
    rewards,
    setSelectedRow,
    setRewardDetail,
    deleteReward,
    setAction
  } = props;
  const history = useHistory();
  const { rolePermissions } = getJwtTokenDetails();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);

  const columnsForSpinTheWheel: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            ID
          </Typography>
        ),
        minWidth: 50,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.id}</Typography>
          </Box>
        )
      },
      {
        field: "type",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            CATEGORY
          </Typography>
        ),
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"} ml={1}>
              {params.row.type?.type}
            </Typography>
          </Box>
        )
      },
      {
        field: "brand",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            BRAND DETAILS
          </Typography>
        ),
        flex: 1.25,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          const brandDetails = params.row.brand
            ? JSON.parse(params.row.brand)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>
                {brandDetails && brandDetails?.brandName
                  ? brandDetails?.brandName
                  : "-"}
              </Typography>
            </Box>
          );
        }
      },
      {
        field: "value",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            INTERNAL REWARD COST
          </Typography>
        ),
        flex: 1.55,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${params.row.value}`}</Typography>
          </Box>
        )
      },
      {
        field: "level",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            LEVELS
          </Typography>
        ),
        flex: 0.7,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.level}</Typography>
          </Box>
        )
      },
      {
        field: "dateAdded",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            DATE ADDED
          </Typography>
        ),
        flex: 1.25,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const date: any = params.row.createdAt
            ? getDateInFormat(
                parseInt(params.row.createdAt),
                "DD/MM/YYYY ",
                "changeFormat"
              )
            : "-";
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{date}</Typography>
            </Box>
          );
        }
      },
      {
        field: "expiry",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            EXPIRY DATE
          </Typography>
        ),
        flex: 1.25,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          // const brandDetails = params.row.brand
          //   ? JSON.parse(params.row.brand)
          //   : {};

          const date: any = params.row?.expiryDate
            ? getDateInFormat(
                parseInt(params.row?.expiryDate),
                "DD/MM/YYYY ",
                "changeFormat"
              )
            : "-";

          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{date}</Typography>
            </Box>
          );
        }
      },
      {
        field: "count",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            TOTAL COUNT
          </Typography>
        ),
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.totalStock}</Typography>
          </Box>
        )
      },
      {
        field: "countLeft",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            COUNT LEFT
          </Typography>
        ),
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.totalStock - params.row?.stockUsed}
            </Typography>
          </Box>
        )
      },
      {
        field: "actions",
        type: "actions",
        headerName: (
          <Typography fontSize={"13px"} fontWeight={600}>
            ACTIONS
          </Typography>
        ),
        minWidth: 170,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };

          const handleClose = () => {
            setAnchorEl(null);
          };

          const open = Boolean(anchorEl);
          const id = open ? "simple-popover" : undefined;
          if (rolePermissions.REWARDS.REWARDS_CONFIGURATION?.VIEW_AND_EDIT) {
            return [
              <GridActionsCellItem
                icon={
                  <span
                    style={{
                      display: "flex",
                      alignContent: "center",
                      marginRight: "5px",
                      marginLeft: "5px"
                    }}
                  >
                    <img
                      src={images.EditIcon}
                      alt=""
                      onClick={() => {
                        handleEdit(params.row);
                      }}
                    />
                  </span>
                }
                label="Edit"
              />,
              <GridActionsCellItem
                icon={
                  <span
                    style={{
                      display: "flex",
                      alignContent: "center",
                      marginRight: "5px"
                    }}
                  >
                    <img
                      src={images.Delete_Icon}
                      alt=""
                      onClick={() => {
                        // setItem("deleteReward");
                        deleteReward({
                          variables: {
                            id: params.row.id
                          }
                        });
                      }}
                    />
                  </span>
                }
                label="View"
              />,
              <Grid item md={1}>
                <IconButton
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MoreVertOutlinedIcon
                    fontSize="small"
                    sx={{ color: colors.lightGreyIcon }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 30,
                    horizontal: -40
                  }}
                  sx={{ maxHeight: "none" }}
                >
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon
                    }}
                    // onClick={() => setItem("viewRewardDetail")}
                    onClick={() => {
                      handleAdd(params.row);
                      // setItem("viewRewardDetail");
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      View
                    </Typography>
                  </MenuItem>
                </Popover>
              </Grid>
            ];
          }
          return [
            <GridActionsCellItem
              onClick={() => {
                handleAdd(params.row);
                // setItem("viewRewardDetail");
              }}
              icon={<VisibilityOutlinedIcon fontSize="small" />}
              label="View"
            />
          ];
        }
      }
    ],
    [rolePermissions]
  );

  const columnsForMysteryBox: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.id}</Typography>
          </Box>
        )
      },
      {
        field: "type",
        headerName: "TYPE",
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.type?.type}</Typography>
          </Box>
        )
      },
      {
        field: "value",
        headerName: "VALUE",
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.value}</Typography>
          </Box>
        )
      },
      {
        field: "dateAdded",
        headerName: "DATE ADDED",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const date: any = getDateInFormat(
            parseInt(params.row.createdAt),
            "DD/MM/YYYY",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{date}</Typography>
            </Box>
          );
        }
      },
      {
        field: "brand",
        headerName: "BRAND",
        flex: 1.5,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          const brandDetails = params.row.brand
            ? JSON.parse(params.row.brand)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>
                {brandDetails && brandDetails?.brandName
                  ? brandDetails?.brandName
                  : "-"}
              </Typography>
            </Box>
          );
        }
      },
      {
        field: "cardText",
        headerName: "CARD TEXT",
        flex: 1.25,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.cardDisplayText}
            </Typography>
          </Box>
        )
      },
      {
        field: "subText",
        headerName: "SUB TEXT",
        flex: 2.25,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.subText}</Typography>
          </Box>
        )
      },
      {
        field: "actions",
        type: "actions",
        headerName: "ACTIONS",
        minWidth: 170,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };

          const handleClose = () => {
            setAnchorEl(null);
          };

          const open = Boolean(anchorEl);
          const id = open ? "simple-popover" : undefined;
          if (rolePermissions.REWARDS.REWARDS_CONFIGURATION?.VIEW_AND_EDIT) {
            return [
              <GridActionsCellItem
                icon={
                  <span
                    style={{
                      display: "flex",
                      alignContent: "center",
                      marginRight: "5px",
                      marginLeft: "5px"
                    }}
                  >
                    <img
                      src={images.EditIcon}
                      alt=""
                      onClick={() => {
                        handleEdit(params.row);
                      }}
                    />
                  </span>
                }
                label="Edit"
              />,
              <GridActionsCellItem
                icon={
                  <span
                    style={{
                      display: "flex",
                      alignContent: "center",
                      marginRight: "5px"
                    }}
                  >
                    <img
                      src={images.Delete_Icon}
                      alt=""
                      onClick={() => {
                        // setItem("deleteReward");
                        deleteReward({
                          variables: {
                            id: params.row.id
                          }
                        });
                      }}
                    />
                  </span>
                }
                label="View"
              />,
              <Grid item md={1}>
                <IconButton
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MoreVertOutlinedIcon
                    fontSize="small"
                    sx={{ color: colors.lightGreyIcon }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 30,
                    horizontal: -40
                  }}
                  sx={{ maxHeight: "none" }}
                >
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon
                    }}
                    // onClick={() => setItem("viewRewardDetail")}
                    onClick={() => {
                      handleAdd(params.row);
                      // setItem("viewRewardDetail");
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      View
                    </Typography>
                  </MenuItem>
                </Popover>
              </Grid>
            ];
          }
          return [
            <GridActionsCellItem
              onClick={() => {
                handleAdd(params.row);
                // setItem("viewRewardDetail");
              }}
              icon={<VisibilityOutlinedIcon fontSize="small" />}
              label="View"
            />
          ];
        }
      }
    ],
    [rolePermissions]
  );

  const handleEdit = (data) => {
    setAction("editReward");
    setSelectedRow(data);
  };

  const handleAdd = (data) => {
    setAction("viewReward");
    setSelectedRow(data);
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.backgroundLight,
        my: 1.5,
        py: 2,
        borderRadius: "15px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 1,
          py: 1,
          borderRadius: "4px 4px 0px 0px",
          backgroundColor: colors.TableHead
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            color="#3C3C3C"
            fontSize="16px"
            fontWeight={700}
            sx={{ py: 1 }}
          >
            ALL AVAILABLE REWARDS
          </Typography>
          {/* <HelpOutlineIcon fontSize="small" sx={{ ml: 1, mt: "1px" }} /> */}
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {rolePermissions.REWARDS.REWARDS_CONFIGURATION?.VIEW_AND_EDIT && (
            <Button
              size="small"
              variant="contained"
              sx={{
                width: "150px",
                height: "38px",
                background:
                  "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                // boxShadow:
                //   '-6px -6px 12px rgba(193, 193, 193, 0.08), 6px 6px 12px rgba(4, 4, 4, 0.45)',
                borderRadius: "30px",
                fontWeight: 600,
                fontSize: "14px",
                marginRight: 2
              }}
              onClick={() => {
                setSelectedRow(null);
                setRewardDetail(null);
                setItem("addReward");
              }}
            >
              Add New Reward
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <Table
          rows={rewards || []}
          columns={
            rewardCategory === "spinTheWheel"
              ? columnsForSpinTheWheel
              : columnsForMysteryBox
          }
          paginationMode="client"
          page={page}
          onPageChange={(page) => setPage(page)}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
            setPage(0);
          }}
          rowCount={rewards?.length || 0}
          disableSearch={true}
          loading={false}
          pagination={true}
          headerStyle={{
            backgroundColor: colors.TableHeadDark,
            color: colors.lightGreyIcon
          }}
        />
      </Box>
    </Box>
  );
};

export default RewardsTable;
