import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  IconButton
} from "@mui/material";
import { colors } from "../../../../../styles/colors";
import { Box } from "@mui/system";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import images from "assets/images/images";
import { getJwtTokenDetails } from "utils/helpers";

const durationsArr = [
  {
    id: 1,
    name: "Weeks"
  },
  {
    id: 2,
    name: "Months"
  },
  {
    id: 3,
    name: "Years"
  }
];

const Dropdown = (props) => {
  const {
    values,
    setFieldValue,
    errors,
    algorithmDuration,
    isDurationEditing,
    setIsDurationEditing,
    handleSave
  } = props;

  const { rolePermissions } = getJwtTokenDetails();

  const handleEdit = () => {
    setIsDurationEditing(true);
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: colors.TableHead,
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          borderRadius: "4px 4px 0px 0px",
          pl: "20px,",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          p: 1
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              textAlign: "left",
              display: "inline-block",
              pl: 2
            }}
          >
            Duration Configuration
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              textAlign: "left",
              display: "inline-block"
            }}
          >
            (Used to set the duration for tracking user spend and calculate the
            rewards accordingly)
          </Typography>
        </Box>
        <Box sx={{ alignContent: "right" }}>
          {rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
            ?.VIEW_AND_EDIT && (
            <IconButton onClick={isDurationEditing ? handleSave : handleEdit}>
              {isDurationEditing ? (
                <DoneOutlinedIcon />
              ) : (
                <img src={images.EditIcon}></img>
              )}
            </IconButton>
          )}
        </Box>
      </Box>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "#FEF7EE",
            borderRadius: "0px 0px 4px 4px "
          }}
        >
          <div>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid mb={2} item xs={3}>
                <Box p={2} mt={1}>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: 400
                    }}
                  >
                    Reward Algorithm Duration
                  </Typography>
                  <Typography
                    sx={{ color: colors.redAsterisk, display: "inline-block" }}
                  >
                    *
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={9}>
                {isDurationEditing ? (
                  <Box
                    style={{ fontSize: 14, height: 36 }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      border: "0.5px solid rgba(168, 168, 168, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "4px",
                      width: "90%"
                    }}
                  >
                    <Grid container>
                      <Grid item xs={0.7} md={0.7} lg={0.7} ml={2}>
                        <TextField
                          variant="standard"
                          value={values.duration || ""}
                          onChange={(event: any) => {
                            setFieldValue(
                              "duration",
                              parseInt(event.target.value)
                            );
                          }}
                          sx={{
                            border: "none",
                            backgroundColor: colors.FieldBackgroundLight,
                            width: "40px",
                            input: {
                              color: colors.lightGreyIcon,
                              fontWeight: 500,
                              fontSize: "14px"
                            }
                          }}
                        />
                      </Grid>
                      {errors.duration && (
                        <Typography
                          sx={{
                            color: colors.danger,
                            fontSize: "11px",
                            fontWeight: 600,
                            ml: 1,
                            mr: 2,
                            mt: "auto"
                          }}
                        >
                          {errors?.duration}
                        </Typography>
                      )}
                      <Grid item xs={2} md={2} lg={2}>
                        <Autocomplete
                          options={durationsArr}
                          getOptionLabel={(option: any) => option.name}
                          value={durationsArr?.find(
                            (item) => item?.name === values.durationCategory
                          )}
                          onChange={(event: any, value: any) => {
                            setFieldValue("durationCategory", value?.name);
                          }}
                          id="disable-clearable"
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{
                                border: "none",
                                backgroundColor: colors.FieldBackgroundLight,
                                width: "120px",
                                input: {
                                  color: colors.lightGreyIcon,
                                  fontWeight: 500,
                                  fontSize: "14px"
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {errors.durationCategory && (
                        <Typography
                          sx={{
                            color: colors.danger,
                            fontSize: "11px",
                            fontWeight: 600,
                            ml: 1,
                            mt: "auto"
                          }}
                        >
                          {errors?.durationCategory}
                        </Typography>
                      )}
                    </Grid>
                  </Box>
                ) : (
                  <Box
                    style={{ fontSize: "14px", height: 36 }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      border: "0.5px solid rgba(168, 168, 168, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "4px",
                      width: "90%"
                    }}
                  >
                    <Typography pl={2} fontSize={14} fontWeight={400}>
                      {values.duration || algorithmDuration?.value || ""}
                    </Typography>
                    <Typography pl={2} fontSize={14} fontWeight={400}>
                      {values.durationCategory ||
                        algorithmDuration?.duration ||
                        ""}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dropdown;
