import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";

interface IUsersProps {
  data: any;
}

const Users = (props: IUsersProps) => {
  const { data } = props;

  function formatNumberToK(number, category) {
    if (number >= 1000) {
      const units = ["", " k", " M", " B", " T"];
      const magnitude = Math.floor(Math.log10(number) / 3);
      const roundedNumber = Math.ceil(number / Math.pow(1000, magnitude));
      return category === "money"
        ? "₹ " + roundedNumber + units[magnitude]
        : roundedNumber + units[magnitude];
    } else {
      return category === "money"
        ? "₹ " + number?.toString()
        : number?.toString();
    }
  }

  const activeUsers = formatNumberToK(data?.users, "users");

  const abc = 4;
  const type = [
    {
      title: "Online users",
      color: "#6CE084"
    },
    // {
    //   title: "Daily Active Users",
    //   color: "#FCD23F",
    // },
    {
      title: "Offline Users",
      color: "#B5BEDF"
    }
  ];

  const rewards = [
    {
      title: "Rewards issued",
      count: data?.rewardCount || 0,
      // percentage: data?.percRewardsIssued,
      currency: false
    },
    // {
    //   title: 'Rewards claimed',
    //   count: data?.rewardsClaimed,
    //   percentage: data?.percRewardsClaimed,
    //   currency: false,
    // },
    {
      title: "Issued value",
      count: data?.rewardValue
        ? formatNumberToK(data?.rewardValue, "money")
        : "₹ 0",
      // percentage: data?.percRewardsIssuedValue,
      currency: true
    }
    // {
    //   title: 'Claimed value',
    //   count: data?.rewardsClaimedValue,
    //   percentage: data?.percRewardsClaimedValue,
    //   currency: true,
    // },
  ];

  const usersPieChartData = data?.usersCohort?.map((item, index) => ({
    name: item?.name || "",
    y: item?.value || 0,
    color: index === 0 ? "#6CE084" : "#B5BEDF"
  }));

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: 2,
          m: 1,
          display: "flex"
        }}
      >
        <div>
          <Typography
            color="#3C3C3C"
            fontSize="16px"
            fontWeight={600}
            sx={{ py: 1 }}
          >
            Users
          </Typography>
          <Typography
            color="#3C3C3C"
            fontSize="13px"
            fontWeight={400}
            sx={{ padding: "40px 0px 0px 0px" }}
          >
            New users this month
          </Typography>
          <Typography color="#3C3C3C" fontSize="16px" fontWeight={600}>
            {data?.newUsersThisMonth || 0}
          </Typography>
          <Typography
            color="#3C3C3C"
            fontSize="13px"
            fontWeight={400}
            sx={{ padding: "40px 0px 0px 0px" }}
          >
            Daily Active Users
          </Typography>
          <Typography color="#3C3C3C" fontSize="16px" fontWeight={600}>
            {data?.dailyActiveUsers || 0}
          </Typography>
          {/* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px 0px 0px 0px',
            }}
          >
            {abc > 0 ? (
              <img
                src={images.uparrow}
                alt="uparrow"
                style={{ paddingBottom: 5 }}
              />
            ) : (
              <img
                src={images.downarrow}
                alt="uparrow"
                style={{ paddingBottom: 5 }}
              />
            )}
            <Typography
              fontSize="13px"
              fontWeight={600}
              color={abc > 0 ? '#3BB001' : '#DC3545'}
              sx={{ pr: 0.5, pl: 1 }}
            >{`${data?.percNewUsers} %`}</Typography>
            <Typography
              color="#808080"
              fontSize="13px"
              fontWeight={400}
              sx={{ px: 0.2 }}
            >
              more users than
            </Typography>
          </div>
          <Typography
            color="#808080"
            fontSize="13px"
            fontWeight={400}
            sx={{ pr: 1, pl: 2, pb: 3 }}
          >
            last month.
          </Typography> */}
        </div>
        <div>
          <DonutPieChart
            title={activeUsers || 0}
            subtitle="Active Users"
            data={usersPieChartData}
            // partColors={["#6CE084", "#B5BEDF", "#FCD23F"]}
          />
        </div>
        <div style={{ marginTop: 100 }}>
          {type?.map((item) => {
            return (
              <div
                style={{ display: "flex", alignItems: "center", padding: 5 }}
              >
                <div
                  style={{
                    width: 14,
                    height: 14,
                    backgroundColor: item.color,
                    borderRadius: 2
                  }}
                ></div>
                <Typography
                  color="#464646"
                  fontSize="13px"
                  fontWeight={400}
                  sx={{ px: 1 }}
                >
                  {item?.title}
                </Typography>
              </div>
            );
          })}
        </div>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: 1,
          m: 1
        }}
      >
        <Typography
          color="#3C3C3C"
          fontSize="16px"
          fontWeight={600}
          sx={{ p: 1 }}
        >
          Rewards
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {rewards?.map((item) => {
            return (
              <div
                style={{
                  padding: 10,
                  backgroundColor: "rgba(255, 240, 225, 0.8)",
                  borderRadius: "5px",
                  margin: 5,
                  width: "50%"
                }}
              >
                <Typography
                  color="#3C3C3C"
                  fontSize="13px"
                  fontWeight={400}
                  sx={{ py: 1, height: "40px" }}
                >
                  {item.title}
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    color="#3C3C3C"
                    fontSize="16px"
                    fontWeight={600}
                    sx={{ py: 1 }}
                  >
                    {item.count}
                  </Typography>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  <img src={images.upTrend} alt="uptrend" />
                  <Typography
                    color="#3BB001"
                    fontSize="11px"
                    fontWeight={700}
                    sx={{ px: 1 }}
                  >
                    23.4%
                  </Typography>
                </div> */}
              </div>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default Users;
