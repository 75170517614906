import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import CurvedGraph from "../CurvedGraph";
import LineChart from "../LineChart";

const days = ["1D", "5D", "1M", "6M", "YTD"];

const type = [
  {
    title: "Claimed Rewards",
    color: "#4A6EE5"
  },
  {
    title: "Mystery Box",
    color: "#FBAF3A"
  },
  {
    title: "Spin the wheel",
    color: "#1CE855"
  }
];

const xAxis = {
  categories: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  crosshair: true
};

const yAxis = {
  min: 0,
  title: {
    text: ""
  }
};

const data = [
  {
    name: "Claimed Rewards",
    data: [
      {
        name: "Jan",
        y: 14,
        color: " #FBAF3A"
      },
      {
        name: "Feb",
        y: 15,
        color: " #FBAF3A"
      },
      {
        name: "Mar",
        y: 15,
        color: " #FBAF3A"
      },
      {
        name: "Apr",
        y: 25,
        color: " #FBAF3A"
      },
      {
        name: "May",
        y: 13,
        color: " #FBAF3A"
      },
      {
        name: "Jun",
        y: 18,
        color: " #FBAF3A"
      },
      {
        name: "Jul",
        y: 2,
        color: " #FBAF3A"
      },
      {
        name: "Aug",
        y: 5,
        color: " #FBAF3A"
      },
      {
        name: "Sep",
        y: 11,
        color: " #FBAF3A"
      },
      {
        name: "Oct",
        y: 16,
        color: " #FBAF3A"
      },
      {
        name: "Nov",
        y: 6,
        color: " #FBAF3A"
      },
      {
        name: "Dec",
        y: 10,
        color: " #FBAF3A"
      }
    ]
  },
  {
    name: "Mystery Box",
    data: [
      {
        name: "Jan",
        y: 11,
        color: "#4A6EE5"
      },
      {
        name: "Feb",
        y: 6,
        color: "#4A6EE5"
      },
      {
        name: "March",
        y: 13,
        color: "#4A6EE5"
      },
      {
        name: "April",
        y: 7,
        color: "#4A6EE5"
      },
      {
        name: "May",
        y: 15,
        color: "#4A6EE5"
      },
      {
        name: "Jun",
        y: 14,
        color: "#4A6EE5"
      },
      {
        name: "Jul",
        y: 17,
        color: "#4A6EE5"
      },
      {
        name: "Aug",
        y: 28,
        color: "#4A6EE5"
      },
      {
        name: "Sep",
        y: 26,
        color: "#4A6EE5"
      },
      {
        name: "Oct",
        y: 22,
        color: "#4A6EE5"
      },
      {
        name: "Nov",
        y: 19,
        color: "#4A6EE5"
      },
      {
        name: "Dec",
        y: 8,
        color: "#4A6EE5"
      }
    ]
  },
  {
    name: "Spin the wheel",
    data: [
      {
        name: "Jan",
        y: 15,
        color: "#1CE855"
      },
      {
        name: "Feb",
        y: 12,
        color: "#1CE855"
      },
      {
        name: "March",
        y: 19,
        color: "#1CE855"
      },
      {
        name: "April",
        y: 10,
        color: "#1CE855"
      },
      {
        name: "May",
        y: 15,
        color: "#1CE855"
      },
      {
        name: "Jun",
        y: 10,
        color: "#1CE855"
      },
      {
        name: "Jul",
        y: 7,
        color: "#1CE855"
      },
      {
        name: "Aug",
        y: 4,
        color: "#1CE855"
      },
      {
        name: "Sep",
        y: 25,
        color: "#1CE855"
      },
      {
        name: "Oct",
        y: 26,
        color: "#1CE855"
      },
      {
        name: "Nov",
        y: 12,
        color: "#1CE855"
      },
      {
        name: "Dec",
        y: 16,
        color: "#1CE855"
      }
    ]
  }
];

const RewardsClaimChart = (props) => {
  const { rewardsByTimeData, setRewardsByTimeFilter } = props;

  console.log("rewardsByTimeData graph data", rewardsByTimeData);

  const xAxisLabels =
    rewardsByTimeData && rewardsByTimeData?.length > 0
      ? rewardsByTimeData[0]?.data?.map((item2) => item2?.name)
      : [];

  const xAxis = {
    categories: xAxisLabels,
    crosshair: true
  };

  const yAxis = {
    min: 0,
    title: {
      text: ""
    }
  };

  const graphData =
    rewardsByTimeData &&
    rewardsByTimeData?.length > 0 &&
    rewardsByTimeData?.map((item1, index) => ({
      name: item1?.name,
      pointWidth: 20,
      data: item1?.data?.map((item2) => ({
        name: item2?.name || "",
        y: item2?.value || 0,
        color:
          index === 1
            ? colors.orange
            : index === 2
            ? colors.blue
            : index === 3
            ? colors.lightGreen
            : colors.purple
      }))
    }));

  const chartProperties = {
    width: 700,
    height: 200
  };

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: "0px 20px 20px 20px",
          m: 1
        }}
      >
        <Typography
          color="#3C3C3C"
          fontSize="16px"
          fontWeight={600}
          sx={{ p: 1 }}
        >
          Rewards Claim Chart
        </Typography>
        <Typography
          color="#3C3C3C"
          fontSize="13px"
          fontWeight={400}
          sx={{ px: 1 }}
        >
          Visual representation of rewards claimed by user
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 2.5 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {days.map((item, index) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={600}
                    sx={{ px: 2.5, py: 1, cursor: "pointer" }}
                    onClick={() => setRewardsByTimeFilter(item?.toLowerCase())}
                  >
                    {item}
                  </Typography>
                  {index < days.length - 1 && (
                    <Box
                      sx={{
                        height: 20,
                        width: 1.5,
                        backgroundColor: "rgba(168, 168, 168, 0.5)"
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {type.map((item) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></Box>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <LineChart
          title={""}
          xAxis={xAxis}
          yAxis={yAxis}
          data={graphData}
          properties={chartProperties}
        />
      </Grid>
    </Grid>
  );
};

export default RewardsClaimChart;
