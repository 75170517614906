import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Divider,
  Popover
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Statistics from "./statistics";
import RewardsClaimChart from "./RewardsClaimChart";
import DailyClaimedRewards from "./DailyClaimedRewards";
import RewardsClaimDetailedChart from "./RewardsClaimDetailedChart";
import ReferralDetails from "./ReferralDetails";
import ReferralKeyMetrics from "./ReferralKeyMetrics";
import CostPerAcquisition from "./CostPerAcquisition";
import Table from "../Table";
import { colors } from "styles/colors";
import images from "assets/images/images";
import { getDateInFormat, getJwtTokenDetails } from "utils/helpers";
import DateFilter from "../DateFilter";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  REFERRAL_DASHBOARD_DETAILS,
  REWARDS_BY_TIME,
  REWARDS_DASHBOARD,
  REWARDS_BY_TIME_SPAN,
  REWARDS_DASHBOARD_DETAILS,
  REFERRAL_TRENDS,
  REWARD_USERS,
  REFERRAL_USERS
} from "app/graphql/rewards";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Rewards = () => {
  const history = useHistory();
  const { rolePermissions } = getJwtTokenDetails();

  const todayDate = new Date();
  const oneWeekAgo = new Date(todayDate);
  oneWeekAgo.setDate(todayDate.getDate() - 30);

  const [rewardsByTimeFilter, setRewardsByTimeFilter] = useState("ytd");
  const [rewardsByGameTypeStartDate, setRewardsByGameTypeStartDate] =
    useState(oneWeekAgo);
  const [rewardsByGameTypeEndDate, setRewardsByGameTypeEndDate] =
    useState(todayDate);
  const [rewardsByTimeSpanFilter, setRewardsByTimeSpanFilter] = useState("ytd");
  const [rewardsUserTableStartDate, setRewardsUserTableStartDate] =
    useState(oneWeekAgo);
  const [rewardsUserTableEndDate, setRewardsUserTableEndDate] =
    useState(todayDate);
  const [referralTrendsFilter, setReferralTrendsFilter] = useState("5d");
  const [referralCostAcquisitionFilter, setReferralCostAcquisitionFilter] =
    useState("5d");

  const [rewardStatistics, setRewardStatistics] = React.useState({});
  const [rewardsByTimeData, setRewardsByTimeData] = React.useState({});
  const [rewardsByGameTypeData, setRewardsByGameTypeData] = React.useState({});
  const [rewardsByTimeSpanData, setRewardsByTimeSpanData] = React.useState({});
  const [referralTrendsData, setReferralTrendsData] = React.useState({});
  const [referralCostAcquisitionData, setReferralCostAcquisitionData] =
    React.useState({});

  const {
    data: rewardsDashboardData,
    loading: rewardsDashboardDataLoading,
    refetch: refetchRewardsDashboardData
  } = useQuery(REWARDS_DASHBOARD, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      rewardsDashboardData &&
      rewardsDashboardData?.rewardsDashboard &&
      rewardsDashboardData?.rewardsDashboard?.status
    ) {
      console.log("rewardsDashboardData", rewardsDashboardData);
      setRewardStatistics(rewardsDashboardData?.rewardsDashboard);
    }
  }, [rewardsDashboardData]);

  const {
    data: rewardsByTime,
    loading: rewardsByTimeLoading,
    refetch: refetchRewardsByTime
  } = useQuery(REWARDS_BY_TIME, {
    variables: {
      filter: rewardsByTimeFilter
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      rewardsByTime &&
      rewardsByTime?.rewardsByTime &&
      rewardsByTime?.rewardsByTime?.rewardsByTime
    ) {
      console.log("rewardsByTime", rewardsByTime);
      setRewardsByTimeData(rewardsByTime?.rewardsByTime?.rewardsByTime);
    }
  }, [rewardsByTime]);

  const {
    data: rewardsByGameType,
    loading: rewardsDashboardDetailsLoading,
    refetch: refetchRewardsDashboardDetails
  } = useQuery(REWARDS_DASHBOARD_DETAILS, {
    variables: {
      from: rewardsByGameTypeStartDate,
      to: rewardsByGameTypeEndDate
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      rewardsByGameType &&
      rewardsByGameType?.rewardsDashboardDetails &&
      rewardsByGameType?.rewardsDashboardDetails?.status
    ) {
      console.log("rewardsByGameType", rewardsByGameType);
      setRewardsByGameTypeData(rewardsByGameType?.rewardsDashboardDetails);
    }
  }, [rewardsByGameType]);

  const {
    data: rewardsByTimeSpan,
    loading: rewardsByTimeSpanLoading,
    refetch: refetchRewardsByTimeSpan
  } = useQuery(REWARDS_BY_TIME_SPAN, {
    variables: {
      filter: rewardsByTimeSpanFilter
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      rewardsByTimeSpan &&
      rewardsByTimeSpan?.rewardsByTimeSpan &&
      rewardsByTimeSpan?.rewardsByTimeSpan?.status
    ) {
      console.log("rewardsByTimeSpan", rewardsByTimeSpan);
      setRewardsByTimeSpanData(rewardsByTimeSpan?.rewardsByTimeSpan);
    }
  }, [rewardsByTimeSpan]);

  const {
    data: referralCostAcquisition,
    loading: referralCostAcquisitionLoading,
    refetch: refetchReferralCostAcquisition
  } = useQuery(REFERRAL_DASHBOARD_DETAILS, {
    variables: {
      filter: referralCostAcquisitionFilter
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      referralCostAcquisition &&
      referralCostAcquisition?.referralDashboardDetails &&
      referralCostAcquisition?.referralDashboardDetails?.status
    ) {
      console.log("referralCostAcquisition", referralCostAcquisition);
      setReferralCostAcquisitionData(
        referralCostAcquisition?.referralDashboardDetails
      );
    }
  }, [referralCostAcquisition]);

  const {
    data: referralTrends,
    loading: referralTrendsLoading,
    refetch: refetchReferralTrends
  } = useQuery(REFERRAL_TRENDS, {
    variables: {
      filter: referralTrendsFilter
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      referralTrends &&
      referralTrends?.referralTrends &&
      referralTrends?.referralTrends?.status
    ) {
      console.log("referralTrends", referralTrends);
      setReferralTrendsData(referralTrends?.referralTrends?.referralTrends);
    }
  }, [referralTrends]);

  const [rewardUserPage, setRewardUserPage] = React.useState(0);
  const [rewardUserPageLimit, setRewardUserPageLimit] = React.useState(10);
  const [rewardUserSearchText, setRewardUserSearchText] = React.useState("");
  const [rewardUserSearchTextTemp, setRewardUserSearchTextTemp] =
    React.useState("");
  const [rewardUsersStartDate, setRewardUsersStartDate] = useState(oneWeekAgo);
  const [rewardUsersEndDate, setRewardUsersEndDate] = useState(todayDate);
  const [rewardUserDetails, setRewardUserDetails] = React.useState<any>({});

  const fireRewardUserSearchAPICall = (searchValue) => {
    setRewardUserSearchText(searchValue);
  };
  const [timer, setTimer] = React.useState<any>(null);

  const requestRewardUserSearch = (searchValue: string) => {
    setRewardUserSearchTextTemp(searchValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fireRewardUserSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const { data: rewardUsersData } = useQuery(REWARD_USERS, {
    variables: {
      page: rewardUserPage,
      limit: rewardUserPageLimit,
      from: rewardUsersStartDate,
      to: rewardUsersEndDate,
      search: rewardUserSearchText
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      rewardUsersData &&
      rewardUsersData?.rewardUsers &&
      rewardUsersData?.rewardUsers?.status
    ) {
      console.log(rewardUsersData);
      const data = rewardUsersData?.rewardUsers;
      setRewardUserDetails(data || []);
    }
  }, [rewardUsersData]);

  const [referralUserPage, setReferralUserPage] = React.useState(0);
  const [referralUserPageLimit, setReferralUserPageLimit] = React.useState(10);
  const [referralUserSearchText, setReferralUserSearchText] =
    React.useState("");
  const [referralUserSearchTextTemp, setReferralUserSearchTextTemp] =
    React.useState("");
  const [referralUsersStartDate, setReferralUsersStartDate] =
    useState(oneWeekAgo);
  const [referralUsersEndDate, setReferralUsersEndDate] = useState(todayDate);
  const [referralUserDetails, setReferralUserDetails] = React.useState<any>({});

  const fireReferralUserSearchAPICall = (searchValue) => {
    setReferralUserSearchText(searchValue);
  };
  const [timerForReferral, setTimerForReferral] = React.useState<any>(null);

  const requestReferralUserSearch = (searchValue: string) => {
    setReferralUserSearchTextTemp(searchValue);
    if (timerForReferral) {
      clearTimeout(timerForReferral);
      setTimerForReferral(null);
    }
    setTimer(
      setTimeout(() => {
        fireReferralUserSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const { data: referralUsersData } = useQuery(REFERRAL_USERS, {
    variables: {
      page: referralUserPage,
      limit: referralUserPageLimit,
      from: referralUsersStartDate,
      to: referralUsersEndDate,
      search: referralUserSearchText
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      referralUsersData &&
      referralUsersData?.referralUsers &&
      referralUsersData?.referralUsers?.status
    ) {
      console.log(referralUsersData);
      const data = referralUsersData?.referralUsers;
      setReferralUserDetails(data || []);
    }
  }, [referralUsersData]);

  const columnForRewardUsers: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 25,
        flex: 0.5,
        headerAlign: "center",
        align: "center"
      },
      {
        field: "name",
        headerName: "NAME",
        minWidth: 100,
        flex: 1.5,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            // onClick={() => history.push(`/users/${params.row.id}`)}
          >
            <Typography fontSize={"14px"}>
              {params.row.nickname || ""}
            </Typography>
          </Box>
        )
      },

      {
        field: "reward",
        headerName: "REWARD",
        minWidth: 100,
        flex: 1.5
      },
      {
        field: "type",
        headerName: "TYPE",
        // minWidth: 200,
        flex: 1
      },
      {
        field: "brand",
        headerName: "BRAND",
        // minWidth: 200,
        flex: 1
      },
      {
        field: "receivedAt",
        headerName: "RECEIVED AT",
        minWidth: 150,
        flex: 1.5,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.received),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "dateOfClaim",
        headerName: "DATE OF CLAIM",
        minWidth: 150,
        flex: 1.5,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.claimed),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      // {
      //   field: "amountSpent",
      //   headerName: "AMT SPENT",
      //   minWidth: 100,
      //   flex: 1,
      //   renderCell: (params) => (
      //     <Box
      //       display={"flex"}
      //       justifyContent={"center"}
      //       alignItems={"baseline"}
      //       fontSize={"14px"}
      //     >
      //       <Typography fontSize={"14px"}>{`₹ ${
      //         params.row.amountSpent || 0
      //       }`}</Typography>
      //     </Box>
      //   )
      // },
      {
        field: "value",
        headerName: "VALUE",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.value || 0
            }`}</Typography>
          </Box>
        )
      }
    ],
    []
  );

  const columnForReferralUsers: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 25,
        flex: 0.5,
        headerAlign: "center",
        align: "center"
      },
      {
        field: "referrer",
        headerName: "REFERRER",
        minWidth: 100,
        flex: 1.5,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
          >
            <Typography
              fontSize={"14px"}
              fontStyle={"underline"}
              color={colors.invalidInput}
            >
              {params.row.referrer}
            </Typography>
          </Box>
        )
      },

      {
        field: "referee",
        headerName: "REFEREE",
        minWidth: 100,
        flex: 1.5,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
          >
            <Typography
              fontSize={"14px"}
              fontStyle={"underline"}
              color={colors.invalidInput}
            >
              {params.row.referred}
            </Typography>
          </Box>
        )
      },
      // {
      //   field: "invitedOn",
      //   headerName: "INVITED ON",
      //   // minWidth: 200,
      //   flex: 1.5
      // },
      {
        field: "signedUpOn",
        headerName: "SIGNED UP ON",
        // minWidth: 200,
        flex: 1.5,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.signedOn),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "referreeBonus",
        headerName: "REFEREE BONUS",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.referredBonus || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "referrerDeposits",
        headerName: "REFEREE DEPOSITS",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.referrerDeposits || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "referrerBonus",
        headerName: "REFERRER BONUS",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.referrerBonus || 0
            }`}</Typography>
          </Box>
        )
      }
    ],
    []
  );
  const [value, setValue] = React.useState(0);
  const [selectedTab, setSelectedTab] = React.useState("REWARDS");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setSelectedTab("REWARDS");
    } else if (newValue === 1) {
      setSelectedTab("REFERRALS");
    }
  };

  const [selectedRequests, setSelectedRequests] = React.useState<number[]>([]);
  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

  const handleRowOver = (e) => {
    const rowId = Number(e.currentTarget.dataset.id);
    setHoveredRow(rowId);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const rewardUsersLastUpdatedInfo: any = getDateInFormat(
    parseInt(rewardUserDetails?.lastUpdated),
    "DD/MM/YYYY hh:mm A",
    "changeFormat"
  );
  const referralUsersLastUpdatedInfo: any = getDateInFormat(
    parseInt(referralUserDetails?.lastUpdated),
    "DD/MM/YYYY hh:mm A",
    "changeFormat"
  );
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Statistics rewardStatistics={rewardStatistics} />
        </Grid>
      </Grid>
      <Grid lg={12} md={12} sm={12} xs={12} sx={{ color: colors.invalidInput }}>
        <Tabs
          textColor="inherit"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: colors.invalidInput
            }
          }}
          value={value}
          onChange={handleChange}
        >
          {(rolePermissions.REWARDS.REWARDS_ANALYTICS.VIEW ||
            rolePermissions.REWARDS.REWARDS_ANALYTICS.VIEW_AND_EDIT) && (
            <Tab
              sx={{
                color: colors.lightGreyIcon,
                fontWeight: 500,
                fontSize: "14px"
              }}
              label="Rewards Details"
              {...a11yProps(0)}
            />
          )}
          {(rolePermissions.REWARDS.REFERRALS_ANALYTICS.VIEW ||
            rolePermissions.REWARDS.REFERRALS_ANALYTICS.VIEW_AND_EDIT) && (
            <Tab
              sx={{
                color: colors.lightGreyIcon,
                fontWeight: 500,
                fontSize: "14px"
              }}
              label="Referral Details"
              {...a11yProps(1)}
            />
          )}
        </Tabs>
      </Grid>
      <Grid lg={12} md={12} sm={12} xs={12}>
        {selectedTab === "REWARDS" && (
          <Grid container>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <RewardsClaimChart
                rewardsByTimeData={rewardsByTimeData}
                setRewardsByTimeFilter={setRewardsByTimeFilter}
              />
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <DailyClaimedRewards
                rewardsByGameTypeData={rewardsByGameTypeData}
                rewardsByGameTypeStartDate={rewardsByGameTypeStartDate}
                rewardsByGameTypeEndDate={rewardsByGameTypeEndDate}
                setRewardsByGameTypeStartDate={setRewardsByGameTypeStartDate}
                setRewardsByGameTypeEndDate={setRewardsByGameTypeEndDate}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <RewardsClaimDetailedChart
                rewardsByTimeSpanData={rewardsByTimeSpanData}
                setRewardsByTimeSpanFilter={setRewardsByTimeSpanFilter}
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                backgroundColor: colors.backgroundLight,
                // m: 1.5,
                p: 3,
                borderRadius: "15px"
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  color="#3C3C3C"
                  fontSize="13px"
                  fontWeight={400}
                  mb={1}
                >
                  {rewardUsersLastUpdatedInfo}
                </Typography>
              </Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 30
                }}
              >
                <div>
                  <Typography
                    color="#3C3C3C"
                    fontSize="16px"
                    fontWeight={600}
                    sx={{ py: 1 }}
                  >
                    User Details
                  </Typography>
                  <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                    {`Displaying details of ${
                      rewardUserDetails?.total || 0
                    } Users`}
                  </Typography>
                </div>
                <DateFilter
                  startDate={rewardUsersStartDate}
                  endDate={rewardUsersEndDate}
                  onChangeStartDate={(newDate) =>
                    setRewardUsersStartDate(newDate)
                  }
                  onChangeEndDate={(newDate) => setRewardUsersEndDate(newDate)}
                  disableFutureForStart={true}
                  disableFutureForEnd={true}
                />
              </div>
              {/* <div style={{ marginTop: 15 }}>
                <span style={{ margin: 7 }}>
                  <IconButton
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <img src={images.Filter_Rectangle} alt="" />
                    <img
                      src={images.DownArrow}
                      alt=""
                      style={{ marginLeft: "5px" }}
                    />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 10,
                      horizontal: -50
                    }}
                    sx={{ maxHeight: "none" }}
                  >
                    <MenuItem
                      sx={{
                        color: colors.lightGreyIcon
                      }}
                    >
                      <Typography fontSize={16} color={colors.fontPrimary}>
                        Option 1
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      sx={{
                        color: colors.lightGreyIcon,
                        mt: -0.5,
                        mb: 1
                      }}
                    >
                      <Typography fontSize={16} color={colors.fontPrimary}>
                        Option 2
                      </Typography>
                    </MenuItem>
                  </Popover>
                </span>
                <span style={{ margin: 15 }}>
                  <img src={images.ExportIcon} alt="" />
                </span>
              </div> */}
              <Table
                rows={rewardUserDetails?.users || []}
                columns={columnForRewardUsers}
                paginationMode="server"
                page={rewardUserPage}
                onPageChange={(page) => setRewardUserPage(page)}
                pageSize={rewardUserPageLimit}
                onPageSizeChange={(pageSize) => {
                  setRewardUserPageLimit(pageSize);
                  setRewardUserPage(0);
                }}
                rowCount={rewardUserDetails?.total || 5}
                loading={false}
                pagination={true}
                // checkboxSelection
                selectedRows={selectedRequests}
                setSelectedRows={setSelectedRequests}
                onRowHover={handleRowOver}
                onRowLeave={handleRowLeave}
                searchText={rewardUserSearchTextTemp}
                requestSearch={requestRewardUserSearch}
                disableSearch={false}
                placeholderTextForSearch="Search by name"
              />
            </Grid>
          </Grid>
        )}
        {selectedTab === "REFERRALS" && (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ReferralDetails
                referralTrendsData={referralTrendsData}
                setReferralTrendsFilter={setReferralTrendsFilter}
              />
            </Grid>
            {/* <Grid item lg={4} md={4} sm={4} xs={12}>
              <ReferralKeyMetrics referralDetails={referralDetails} />
            </Grid> */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CostPerAcquisition
                referralCostAcquisitionData={referralCostAcquisitionData}
                setReferralCostAcquisitionFilter={
                  setReferralCostAcquisitionFilter
                }
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                backgroundColor: colors.backgroundLight,
                // m: 1.5,
                p: 3,
                borderRadius: "15px"
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  color="#3C3C3C"
                  fontSize="13px"
                  fontWeight={400}
                  mb={1}
                >
                  {referralUsersLastUpdatedInfo}
                </Typography>
              </Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 30
                }}
              >
                <div>
                  <Typography
                    color="#3C3C3C"
                    fontSize="16px"
                    fontWeight={600}
                    sx={{ py: 1 }}
                  >
                    Referral User details
                  </Typography>
                  <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                    {`Displaying details of ${
                      referralUserDetails?.total || 0
                    } Users`}
                  </Typography>
                </div>
                <DateFilter
                  startDate={referralUsersStartDate}
                  endDate={referralUsersEndDate}
                  onChangeStartDate={(newDate) =>
                    setReferralUsersStartDate(newDate)
                  }
                  onChangeEndDate={(newDate) =>
                    setReferralUsersEndDate(newDate)
                  }
                  disableFutureForStart={true}
                  disableFutureForEnd={true}
                />
              </div>
              {/* <div style={{ marginTop: 15 }}>
                <span style={{ margin: 7 }}>
                  <IconButton
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <img src={images.Filter_Rectangle} alt="" />
                    <img
                      src={images.DownArrow}
                      alt=""
                      style={{ marginLeft: "5px" }}
                    />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 10,
                      horizontal: -50
                    }}
                    sx={{ maxHeight: "none" }}
                  >
                    <MenuItem
                      sx={{
                        color: colors.lightGreyIcon
                      }}
                    >
                      <Typography fontSize={16} color={colors.fontPrimary}>
                        Option 1
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      sx={{
                        color: colors.lightGreyIcon,
                        mt: -0.5,
                        mb: 1
                      }}
                    >
                      <Typography fontSize={16} color={colors.fontPrimary}>
                        Option 2
                      </Typography>
                    </MenuItem>
                  </Popover>
                </span>
                <span style={{ margin: 15 }}>
                  <img src={images.ExportIcon} alt="" />
                </span>
              </div> */}
              <Table
                // getRowId={(row) => row.referrer}
                rows={referralUserDetails?.users || []}
                columns={columnForReferralUsers}
                paginationMode="server"
                page={referralUserPage}
                onPageChange={(page) => setReferralUserPage(page)}
                pageSize={referralUserPageLimit}
                onPageSizeChange={(pageSize) => {
                  setReferralUserPageLimit(pageSize);
                  setReferralUserPage(0);
                }}
                rowCount={referralUserDetails?.total || 5}
                loading={false}
                pagination={true}
                // checkboxSelection
                selectedRows={selectedRequests}
                setSelectedRows={setSelectedRequests}
                onRowHover={handleRowOver}
                onRowLeave={handleRowLeave}
                searchText={referralUserSearchTextTemp}
                requestSearch={requestReferralUserSearch}
                disableSearch={false}
                placeholderTextForSearch="Search by name"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Rewards;
