import { gql } from "@apollo/client";

export const GET_ALL_GAMES = gql`
  query getAllGames($page: Int, $limit: Int, $search: String) {
    allGames(page: $page, limit: $limit, search: $search) {
      status
      message
      games {
        id
        title
        gameRevenue
        leaderboardPayout
        bootPayout
        hostedBoots
        hostedLeaderboardTournaments
        activeLeaderboardTournaments
        createdAt
      }
    }
  }
`;

export const GET_TOURNAMENTS = gql`
  query allTournaments(
    $filter: AllTournamentsFilterInput
    $page: Int
    $limit: Int
  ) {
    allTournaments(filter: $filter, page: $page, limit: $limit) {
      status
      message
      tournaments {
        id
        title
        description
        type
        details
        bannerUrl
        tournamentThumbnailUrl
        thumbnailImageUrl
        game {
          id
          title
          gameCategory {
            id
            category
          }
        }
        end
        start
        filled
        revenue
        winners
        tournamentStatus
        pool
        entryFee
        players
        winningAmount
      }
    }
  }
`;

export const GET_ALL_REGISTERED_USERS = gql`
  query registeredUsers(
    $page: Int
    $limit: Int
    $search: String
    $from: String
    $to: String
  ) {
    registeredUsers(
      page: $page
      limit: $limit
      from: $from
      to: $to
      search: $search
    ) {
      status
      count
      users {
        id
        firstName
        lastName
        joinedOn
        bootTournaments
        nickname
        leaderboardTournaments
        depositsBoot
        depositsLeaderboard
        winningsBoot
        winningsLeaderboard
        payout
        revenue
        status
      }
    }
  }
`;
