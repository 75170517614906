import React from "react";
import { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Chip,
  Grid,
  Typography,
  Box,
  Button,
  MenuItem,
  Divider,
  Popover,
  IconButton
} from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "../Table";
import { colors } from "styles/colors";
import images from "assets/images/images";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import TournamentSlot from "./TournamentSlot";
import EarningsReport from "./EarningsReport";
import PrizeDistribution from "./PrizeDistribution";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  GET_REGISTERED_USERS_OF_TOURNAMENT,
  GET_TOURNAMENT_DETAILED_STATISTICS,
  GET_TOURNAMENT_DETAILS
} from "app/graphql/tournament";
import { getDateInFormat } from "utils/helpers";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Tournaments = () => {
  const history = useHistory();
  const uriParams: any = useParams();

  const [tournamentDetails, setTournamentDetails] = useState<any>(null);
  const [tournamentUserDetails, setTournamentUserDetails] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchText, setSearchText] = React.useState("");
  const [searchTextTemp, setSearchTextTemp] = React.useState("");

  const fireRequestSearchAPICall = (searchValue) => {
    setSearchText(searchValue);
  };
  const [timer, setTimer] = React.useState<any>(null);

  const requestSearch = (searchValue: string) => {
    setSearchTextTemp(searchValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fireRequestSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const { loading: tournamentDetailsLoading } = useQuery(
    GET_TOURNAMENT_DETAILED_STATISTICS,
    {
      variables: { id: parseInt(uriParams?.id), filter: "Leaderboard" },
      skip: !uriParams?.id,
      onCompleted: (data) => {
        const { tournamentDashboardDetails } = data;
        const { status } = tournamentDashboardDetails;
        if (status) {
          setTournamentDetails(tournamentDashboardDetails);
        } else {
          setTournamentDetails(null);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  const { loading: registeredUsersInTournamentLoading } = useQuery(
    GET_REGISTERED_USERS_OF_TOURNAMENT,
    {
      variables: {
        id: parseInt(uriParams?.id),
        page: page,
        limit: pageSize,
        search: searchText
      },
      skip: !uriParams?.id,
      onCompleted: (data) => {
        const { registeredUsersInTournament } = data;
        setTournamentUserDetails(registeredUsersInTournament);
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  const requestMapping = {
    Ready: {
      name: "Ready",
      fill: colors.backgroundBoxLight,
      text: colors.invalidInput
    },
    Started: {
      name: "Started",
      fill: colors.backgroundBoxLight,
      text: colors.green
    },
    Aborted: {
      name: "Aborted",
      fill: colors.backgroundBoxLight,
      text: colors.red
    }
  };

  const columnsForRegisteredUsers: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 0.5
      },
      {
        field: "name",
        headerName: "PLAYER NAME",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            onClick={() => history.push(`/users/${params.row.id}`)}
          >
            <Typography fontSize={"14px"}>
              {params.row.nickname || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "mobile",
        headerName: "NUMBER",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.mobileNo || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "joinDateAndTime",
        headerName: "JOIN D&T",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const DateAndTime: any = params?.row?.joinedOn
            ? getDateInFormat(
                parseInt(params?.row?.joinedOn),
                "DD/MM/YYYY hh:mm A",
                "changeFormat"
              )
            : null;
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "playedDateAndTime",
        headerName: "PLAYED D&T",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const DateAndTime: any = params?.row?.playedOn
            ? getDateInFormat(
                parseInt(params?.row?.playedOn),
                "DD/MM/YYYY hh:mm A",
                "changeFormat"
              )
            : null;
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "entry",
        headerName: "ENTRY",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.entry || "-"}</Typography>
          </Box>
        )
      },
      {
        field: "position",
        headerName: "POSITION",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.position || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "prize",
        headerName: "PRIZE",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.prize || "-"}</Typography>
          </Box>
        )
      },
      {
        field: "attempts",
        headerName: "ATTEMPTS",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.attempts || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "status",
        headerName: "STATUS",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Typography
            fontSize={"12px"}
            fontWeight={600}
            color={requestMapping[params.value]?.text}
          >
            {requestMapping[params.value]?.name.toUpperCase()}
          </Typography>
          // <Chip
          //   label={
          //     <Typography fontSize={'11px'} fontWeight={600}>
          //       {requestMapping[params.value].name.toUpperCase()}
          //     </Typography>
          //   }
          //   size="small"
          //   sx={{
          //     width: '90px',
          //     height: '26px',
          //     borderRadius: '8px',
          //     color: requestMapping[params.value].text,
          //     backgroundColor: requestMapping[params.value].fill,
          //   }}
          // />
        )
      }
    ],
    []
  );

  const [viewRequestDetail, setViewRequestDetail] = React.useState(false);
  const [selectedRequests, setSelectedRequests] = React.useState<number[]>([]);
  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

  const handleRowOver = (e) => {
    const rowId = Number(e.currentTarget.dataset.id);
    setHoveredRow(rowId);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  // const [rows, setRows] = React.useState<any[]>(rowsDataForRegisteredUsers);

  // function escapeRegExp(value: string): string {
  //   return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  // }

  // const requestSearch = (searchValue: string) => {
  //   setSearchText(searchValue);
  //   const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
  //   const filteredRows = rowsDataForRegisteredUsers.filter((row: any) => {
  //     return Object.keys(row).some((field: any) => {
  //       return searchRegex.test(row[field].toString());
  //     });
  //   });
  //   setRows(filteredRows);
  // };

  // const [value, setValue] = React.useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const lastUpdatedInfo: any = tournamentDetails?.lastUpdated
    ? getDateInFormat(
        parseInt(tournamentDetails?.lastUpdated),
        "DD/MM/YYYY hh:mm A",
        "changeFormat"
      )
    : null;

  return (
    <Grid container>
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "21px",
          padding: 2.5
        }}
      >
        <Grid item lg={4.4} md={4.4} sm={12} xs={12}>
          <TournamentSlot
            chartData={tournamentDetails?.slotTrend}
            slotsFilled={tournamentDetails?.slotsFilled}
          />
        </Grid>
        <Grid item lg={7.4} md={7.4} sm={12} xs={12}>
          <EarningsReport tournamentDetails={tournamentDetails} />
        </Grid>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          m: 1.5,
          p: 3,
          borderRadius: "15px"
        }}
      >
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              Registered Users
            </Typography>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              {`Displaying details of ${
                tournamentUserDetails?.count || 0
              } users`}
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400} mb={1}>
              {`Last Updated on ${lastUpdatedInfo}`}
            </Typography>
          </Box>
        </Box>
        {/* <div style={{ marginTop: 15 }}>
          <span style={{ margin: 15 }}>
            <img src={images.ExportIcon} alt="" />
          </span>
        </div> */}
        <Table
          rows={tournamentUserDetails?.users || []}
          columns={columnsForRegisteredUsers}
          paginationMode="client"
          page={page}
          onPageChange={(page) => setPage(page)}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
            setPage(0);
          }}
          rowCount={tournamentUserDetails.count}
          loading={false}
          pagination={true}
          // checkboxSelection
          selectedRows={selectedRequests}
          setSelectedRows={setSelectedRequests}
          onRowHover={handleRowOver}
          onRowLeave={handleRowLeave}
          searchText={searchTextTemp}
          requestSearch={requestSearch}
          placeholderTextForSearch={"Search by name"}
          disableSearch={false}
        />
      </Grid>
      {/* <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        mt={1}
        sx={{ color: colors.invalidInput }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: colors.invalidInput,
            },
          }}
        >
          <Tab
            label="User Details"
            {...a11yProps(0)}
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 500,
              fontSize: '14px',
            }}
          />
          <Tab
            label="Prize Distribution"
            {...a11yProps(1)}
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 500,
              fontSize: '14px',
            }}
          />
        </Tabs>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TabPanel value={value} index={0}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: colors.backgroundLight,
              m: 1.5,
              p: 3,
              borderRadius: '15px',
            }}
          >
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography
                  color="#3C3C3C"
                  fontSize="16px"
                  fontWeight={600}
                  sx={{ py: 1 }}
                >
                  Registered Users
                </Typography>
                <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                  Displaying details of 25 users
                </Typography>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                  {' '}
                  Updated 23 seconds ago
                </Typography>
              </Box>
            </Box>
            <div style={{ marginTop: 15 }}>
              <span style={{ margin: 15 }}>
                <img src={images.ExportIcon} alt="" />
              </span>
            </div>
            <Table
              rows={rowsDataForRegisteredUsers}
              columns={columnsForRegisteredUsers}
              paginationMode="client"
              page={page}
              onPageChange={(page) => setPage(page)}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize);
                setPage(0);
              }}
              rowCount={rows.length}
              loading={false}
              pagination={true}
              checkboxSelection
              selectedRows={selectedRequests}
              setSelectedRows={setSelectedRequests}
              onRowHover={handleRowOver}
              onRowLeave={handleRowLeave}
              searchText={searchText}
              requestSearch={requestSearch}
              disableSearch={false}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: colors.backgroundLight,
              m: 1.5,
              p: 3,
              borderRadius: '15px',
            }}
          >
            <PrizeDistribution />
          </Grid>
        </TabPanel>
      </Grid> */}
    </Grid>
  );
};

export default Tournaments;
