import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface IOrangeGradientButtonProps extends ButtonProps {
  title: string;
}

const OrangeGradientButton = (props: IOrangeGradientButtonProps) => {
  const { title, ...rest } = props;
  return (
    <Button
      size="small"
      variant="contained"
      disableElevation
      sx={{
        background: "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
        borderRadius: "36px",
        fontWeight: 400,
        fontSize: "16px",
        letterSpacing: "0.02em",
        color: "#FFF9F2",
      }}
      {...rest}
    >
      {title}
    </Button>
  );
};

export default OrangeGradientButton;
