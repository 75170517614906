import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import BarChartGraph from "../ColumnChartStack";
import { formatNumberToK } from "utils/helpers";

const TournamentDashboard = (props) => {
  const { tournamentStatistics, setLeaderboardGraphFilter } = props;

  const type = [
    {
      title: "Live Tournaments",
      color: colors.orange
    },
    {
      title: "Past Tournaments",
      color: colors.purple
    }
  ];

  const pastTournamentsData =
    tournamentStatistics?.pastLeaderboardStatistics?.map((item) => ({
      name: item?.name || "",
      y: item?.value || 0,
      color: colors.purple
    }));

  const liveTournamentsData =
    tournamentStatistics?.liveLeaderboardStatistics?.map((item) => ({
      name: item?.name || "",
      y: item?.value || 0,
      color: colors.orange
    }));

  const xAxisLabels =
    tournamentStatistics?.pastLeaderboardStatistics?.map(
      (item) => item?.name
    ) || [];
  const graphData = [
    {
      name: "Past Tournaments",
      colorByPoint: true,
      pointWidth: 25,
      data: pastTournamentsData
    },
    {
      name: "Live Tournaments",
      colorByPoint: true,
      pointWidth: 25,
      data: liveTournamentsData
    }
  ];

  // const xAxisLabels = [
  //   "Golf Duels",
  //   "Est eros",
  //   "Mi iaculis",
  //   "Pulvinar urna",
  //   "Ut diam",
  //   "Velit a",
  //   "Facilisi risus",
  //   "Sit vehicula",
  //   "Massa urna",
  //   "Sed viverra",
  //   "Ipsum enim"
  // ];

  // const graphData = [
  //   {
  //     name: "Past Tournaments",
  //     colorByPoint: true,
  //     data: [
  //       {
  //         name: "Golf Duels",
  //         y: 3,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Est eros",
  //         y: 6,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Mi iaculis",
  //         y: 9,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Pulvinar",
  //         y: 10,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Ut diam",
  //         y: 5,
  //         color: "#9F7FFC"
  //       },

  //       {
  //         name: "Velit",
  //         y: 7,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Facilisi",
  //         y: 5,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Sit vehicula",
  //         y: 2,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Massa",
  //         y: 12,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Sed viverra",
  //         y: 3,
  //         color: "#9F7FFC"
  //       },
  //       {
  //         name: "Ipsum enim",
  //         y: 15,
  //         color: "#9F7FFC"
  //       }
  //     ]
  //   },
  //   {
  //     name: "Live Tournaments",
  //     colorByPoint: true,
  //     data: [
  //       {
  //         name: "Golf Duels",
  //         y: 3,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Est eros",
  //         y: 6,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Mi iaculis",
  //         y: 9,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Pulvinar",
  //         y: 10,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Ut diam",
  //         y: 5,
  //         color: "#FF9D39"
  //       },

  //       {
  //         name: "Velit",
  //         y: 7,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Facilisi",
  //         y: 5,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Sit vehicula",
  //         y: 2,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Massa",
  //         y: 12,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Sed viverra",
  //         y: 3,
  //         color: "#FF9D39"
  //       },
  //       {
  //         name: "Ipsum enim",
  //         y: 15,
  //         color: "#FF9D39"
  //       }
  //     ]
  //   }
  // ];

  const days = ["1D", "5D", "1M", "6M", "YTD"];

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: 2,
          m: 1
        }}
      >
        <Typography color="#3C3C3C" fontSize="16px" fontWeight={600}>
          Tournament Dashboard
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: -1
          }}
        >
          <Box>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              Live Tournament Updates
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {days?.map((item, index) => {
              return (
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={600}
                    sx={{ px: 2.5, py: 2, cursor: "pointer" }}
                    onClick={() =>
                      setLeaderboardGraphFilter(item?.toLowerCase())
                    }
                  >
                    {item}
                  </Typography>
                  {index < days?.length - 1 && (
                    <Box
                      style={{
                        height: 20,
                        width: 1.5,
                        backgroundColor: "rgba(168, 168, 168, 0.5)"
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
        <BarChartGraph
          xAxisLabels={xAxisLabels}
          data={graphData}
          properties={{
            width: 550
          }}
          partColors={["#9F7FFC", "#FF9D39"]}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DonutPieChart
              title={""}
              subtitle=""
              height={40}
              hideToolTip={true}
              data={[
                {
                  y: 35,
                  name: "Active Users",
                  color: "#6CA7F8"
                },
                {
                  y: 100 - 35,
                  name: "Active Users",
                  color: "#EEEEEE"
                }
              ]}
              partColors={["#6CE084", "#EEEEEE"]}
            />
            <div>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ px: 1 }}
              >
                Total Revenue
              </Typography>
              <Typography
                color="#464646"
                fontSize="15px"
                fontWeight={800}
                sx={{ px: 1 }}
              >
                {tournamentStatistics?.leaderboardPayin
                  ? formatNumberToK(
                      tournamentStatistics?.leaderboardPayin,
                      "money"
                    )
                  : "₹ 0"}
              </Typography>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DonutPieChart
              title={""}
              subtitle=""
              hideToolTip={true}
              height={40}
              data={[
                {
                  y: 35,
                  name: "Active Users",
                  color: "#F8D96C"
                },
                {
                  y: 100 - 35,
                  name: "Active Users",
                  color: "#EEEEEE"
                }
              ]}
              partColors={["#F8D96C", "#EEEEEE"]}
            />
            <div>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ px: 1 }}
              >
                Total Pay Out
              </Typography>
              <Typography
                color="#464646"
                fontSize="15px"
                fontWeight={800}
                sx={{ px: 1 }}
              >
                {tournamentStatistics?.leaderboardPayout
                  ? formatNumberToK(
                      tournamentStatistics?.leaderboardPayout,
                      "money"
                    )
                  : "₹ 0"}
              </Typography>
            </div>
          </div>
          <div>
            {type?.map((item) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", padding: 5 }}
                >
                  <div
                    style={{
                      width: 14,
                      height: 14,
                      backgroundColor: item.color,
                      borderRadius: 2
                    }}
                  ></div>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item?.title}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default TournamentDashboard;
