import React from "react";
import { Grid, Typography } from "@mui/material";
import images from "assets/images/images";
import { formatNumberToK } from "utils/helpers";

const Statistics = (props) => {
  const { rewardStatistics } = props;

  // console.log("rewardStatistics", rewardStatistics);

  const data = [
    {
      title: "Issued Rewards",
      count: rewardStatistics?.rewardsIssued || 0,
      percentage: 0.1
    },
    {
      title: "Issued Value",
      count: rewardStatistics?.rewardsIssuedValue
        ? formatNumberToK(rewardStatistics?.rewardsIssuedValue, "money")
        : "₹ 0",
      percentage: 0
    },
    {
      title: "Spin the wheel Issued Value",
      count: rewardStatistics?.spinTheWheelValue
        ? formatNumberToK(rewardStatistics?.spinTheWheelValue, "money")
        : "₹ 0",
      percentage: -0.5
    },
    {
      title: "Mystery Box Issued Value",
      count: rewardStatistics?.mysteryBoxValue
        ? formatNumberToK(rewardStatistics?.mysteryBoxValue, "money")
        : "₹ 0",
      percentage: 0.1
    },
    // {
    //   title: "Total Referrals",
    //   count: "1799",
    //   percentage: 0,
    // },
    {
      title: "Acquired Users",
      count: rewardStatistics?.acquiredUsersViaReferral
        ? formatNumberToK(rewardStatistics?.acquiredUsersViaReferral, "users")
        : 0,
      percentage: 0
    }
  ];

  return (
    <Grid container>
      <Grid
        xs={12}
        sx={{ borderTop: "0.5px solid rgba(168, 168, 168, 0.5)", py: 1 }}
      ></Grid>
      {data?.map((item, index) => {
        return (
          <Grid xs={4} lg={2} md={3} sx={{ padding: 0.5, display: "flex" }}>
            <div style={{ width: "100%" }}>
              <Typography
                color="#3C3C3C"
                fontSize="13px"
                fontWeight={400}
                sx={{ px: 0.5, py: 0.5 }}
              >
                {item?.title}
              </Typography>
              <Typography
                color="#1C1C1C"
                fontSize="20px"
                fontWeight={600}
                sx={{ px: 0.5, py: 0.5 }}
              >
                {item?.count}
              </Typography>
              {/* <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 10,
                }}
              >
                {item.percentage > 0 ? (
                  <img
                    src={images.uparrow}
                    alt="uparrow"
                    style={{ paddingBottom: 5 }}
                  />
                ) : (
                  <img
                    src={images.downarrow}
                    alt="uparrow"
                    style={{ paddingBottom: 5 }}
                  />
                )}
                <Typography
                  color={item.percentage > 0 ? '#3BB001' : '#DC3545'}
                  fontSize="12px"
                  fontWeight={700}
                  sx={{ px: 1 }}
                >
                  {item.percentage + '%'}
                </Typography>
              </div> */}
            </div>
            {index !== data?.length - 1 && (
              <div
                style={{
                  height: 60,
                  borderRight: "0.5px solid rgba(168, 168, 168, 0.5)",
                  margin: "30px 10px 10px 10px"
                }}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Statistics;
