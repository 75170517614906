import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { colors } from "../../../styles/colors";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import AssetTable from "./AssetTable";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_GAMES } from "app/graphql/game";
import { frequencyTypes, tournamentTypes } from "utils/constants";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { useHistory } from "react-router-dom";
import { FieldArray } from "formik";
import AddIcon from "@mui/icons-material/Add";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Editor } from "@tinymce/tinymce-react";
import { useSnackbar } from "notistack";

export default function TournamentDetails(props) {
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    gamesArr,
  } = props;

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [useMegaContestCard, setUseMegaContestCard] = useState(
    values?.thumbnailImageUrl || values?.thumbnailImage
      ? 1
      : values?.useMegaCard
      ? values?.useMegaCard
      : 2
  );
  const currentDateAndTime = new Date();
  const startDateAndTime = new Date(values?.startDateAndTime);
  const endDateAndTime = new Date(values?.endDateAndTime);

  const [startDateDisabled, setStartDateDisabled] = React.useState(
    startDateAndTime > currentDateAndTime ? false : true
  );

  const [endDateDisabled, setEndDateDisabled] = React.useState(
    endDateAndTime > currentDateAndTime ? false : true
  );

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: colors.backgroundBoxLight,
          borderRadius: "8px",
          pt: 4,
          pb: 3,
          mt: 1,
          mb: 1,
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            backgroundColor: colors.TableHead,
            borderRadius: "4px 4px 0px 0px",
            mt: -4,
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              textAlign: "left",
              p: 1,
            }}
          >
            Add New Tournament
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Typography fontSize={15} fontWeight={400}>
                Game
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
              <Autocomplete
                options={gamesArr || []}
                getOptionLabel={(option: any) => option?.title}
                disableClearable={true}
                value={
                  values.game
                    ? gamesArr?.find((x: any) => x.id === values.game)
                    : {
                        title: "",
                      }
                }
                onChange={(event: any, value: any) => {
                  if (value) {
                    setFieldValue("game", value.id);
                    setFieldValue("bootCount", value.bootCount);
                    setFieldValue("tournamentType", {
                      name: "",
                    });
                  }
                }}
                sx={{
                  width: "90%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="game"
                    size="small"
                    variant="outlined"
                    placeholder="Choose..."
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14 },
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                    }}
                  />
                )}
              />
              {touched?.game && errors?.game && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600,
                  }}
                >
                  {errors?.game}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Typography fontSize={15} fontWeight={400}>
                Tournament Type
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
              <Autocomplete
                options={tournamentTypes || []}
                getOptionLabel={(option: any) => option?.name}
                disableClearable={true}
                value={
                  values.tournamentType
                    ? tournamentTypes?.find(
                        (x: any) => x.name === values.tournamentType
                      )
                    : {
                        name: "",
                      }
                }
                onChange={(event: any, value: any) => {
                  if (value.name === "Blitz") {
                    if (values.bootCount === 0) {
                      setFieldValue("tournamentType", value.name);
                    } else {
                      setFieldValue("tournamentType", "");
                      enqueueSnackbar(
                        "A game can only have one boot and it's been already configured",
                        {
                          variant: "error",
                        }
                      );
                    }
                  } else {
                    if (value) {
                      setFieldValue("tournamentType", value.name);
                    }
                  }
                }}
                sx={{
                  width: "90%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="tournamentType"
                    size="small"
                    variant="outlined"
                    placeholder="Choose..."
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14 },
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                    }}
                  />
                )}
              />
              {touched?.tournamentType && errors?.tournamentType && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600,
                  }}
                >
                  {errors?.tournamentType}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        {values.tournamentType &&
          (values.tournamentType === "Unlimited" ||
            values.tournamentType === "Triple") && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                    <Typography fontSize={13} fontWeight={600}>
                      GENERAL INFORMATION
                    </Typography>
                    <Typography
                      fontSize={13}
                      fontWeight={400}
                      color={colors.redAsterisk}
                    >
                      *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                    <Typography fontSize={15} fontWeight={400}>
                      Use Mega Card
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color={colors.redAsterisk}
                    >
                      *
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                    <RadioGroup
                      row
                      defaultValue={1}
                      value={useMegaContestCard}
                      onChange={(event) => {
                        setUseMegaContestCard(
                          parseInt(event.currentTarget.value)
                        );
                        setFieldValue(
                          "useMegaCard",
                          parseInt(event.currentTarget.value)
                        );
                      }}
                      sx={{ mt: -1 }}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio size="small" />}
                        label={
                          <Typography fontSize={16} fontWeight={400} mt={-1}>
                            Yes
                          </Typography>
                        }
                        defaultChecked={false}
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" />}
                        label={
                          <Typography fontSize={16} fontWeight={400} mt={-1}>
                            No
                          </Typography>
                        }
                        defaultChecked={true}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Title
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <TextField
                    id="title"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography fontSize={13}>{`${
                            values.title?.length || 0
                          }/8`}</Typography>
                        </InputAdornment>
                      ),
                    }}
                    value={values.title}
                    onChange={(e) => {
                      // if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      //   setFieldValue("title", e.target.value);
                      // }
                      setFieldValue("title", e.target.value);
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: colors.lightGreyIcon },
                      width: "90%",
                    }}
                    onBlur={handleBlur}
                  />
                  {touched?.title && errors?.title && (
                    <Typography
                      sx={{
                        color: colors.danger,
                        fontSize: "11px",
                        fontWeight: 600,
                      }}
                    >
                      {errors?.title}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Slots
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <TextField
                    id="slots"
                    size="small"
                    fullWidth
                    value={values.slots}
                    onChange={(e) => {
                      if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                        setFieldValue("slots", e.target.value);
                      }
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: colors.lightGreyIcon },
                      width: "90%",
                    }}
                    onBlur={handleBlur}
                  />
                  {touched?.slots && errors?.slots && (
                    <Typography
                      sx={{
                        color: colors.danger,
                        fontSize: "11px",
                        fontWeight: 600,
                      }}
                    >
                      {errors?.slots}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Start Date & Time
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      inputFormat="DD/MM/YYYY hh:mm a"
                      views={["day", "hours", "minutes"]}
                      disablePast={true}
                      // disabled={startDateAndTime > currentDateAndTime ? true : false}
                      InputProps={{
                        style: { fontSize: 14 },
                      }}
                      closeOnSelect={false}
                      components={{
                        OpenPickerIcon: CalendarTodayOutlinedIcon,
                      }}
                      value={values.startDateAndTime}
                      onChange={(value) => {
                        setFieldValue("startDateAndTime", value, true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="startDateAndTime"
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                          {...params}
                          sx={{
                            backgroundColor: colors.FieldBackgroundLight,
                            input: { color: colors.lightGreyIcon },
                            width: "90%",
                          }}
                          onBlur={() => {
                            handleBlur();
                            // setStartDateDisabled(
                            //   startDateAndTime > currentDateAndTime
                            //     ? false
                            //     : true
                            // );
                          }}
                        />
                      )}
                    />
                    {touched?.startDateAndTime && errors?.startDateAndTime && (
                      <Typography
                        sx={{
                          color: colors.danger,
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                      >
                        {errors?.startDateAndTime}
                      </Typography>
                    )}
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    End Date & Time
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      inputFormat="DD/MM/YYYY hh:mm a"
                      views={["day", "hours", "minutes"]}
                      disablePast={true}
                      // disabled={
                      //   currentDateAndTime > endDateAndTime ? true : false
                      // }
                      InputProps={{
                        style: { fontSize: 14 },
                      }}
                      closeOnSelect={false}
                      components={{
                        OpenPickerIcon: CalendarTodayOutlinedIcon,
                      }}
                      value={values.endDateAndTime}
                      onChange={(value) => {
                        setFieldValue("endDateAndTime", value, true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="endDateAndTime"
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                          {...params}
                          sx={{
                            backgroundColor: colors.FieldBackgroundLight,
                            input: { color: colors.lightGreyIcon },
                            width: "90%",
                          }}
                          onBlur={() => {
                            handleBlur();
                            // setEndDateDisabled(
                            //   endDateAndTime > currentDateAndTime ? false : true
                            // );
                          }}
                        />
                      )}
                    />
                    {touched?.endDateAndTime && errors?.endDateAndTime && (
                      <Typography
                        sx={{
                          color: colors.danger,
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                      >
                        {errors?.endDateAndTime}
                      </Typography>
                    )}
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Entry Fee
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <TextField
                    id="entryFee"
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                        </InputAdornment>
                      ),
                    }}
                    value={values.entryFee}
                    onChange={(e) => {
                      if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                        setFieldValue("entryFee", e.target.value);
                      }
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: colors.lightGreyIcon },
                      width: "90%",
                    }}
                    onBlur={handleBlur}
                  />
                  {touched?.entryFee && errors?.entryFee && (
                    <Typography
                      sx={{
                        color: colors.danger,
                        fontSize: "11px",
                        fontWeight: 600,
                      }}
                    >
                      {errors?.entryFee}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Prize Pool
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <TextField
                    id="prizePool"
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                        </InputAdornment>
                      ),
                    }}
                    value={values.prizePool}
                    onChange={(e) => {
                      if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                        setFieldValue("prizePool", e.target.value);
                      }
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: colors.lightGreyIcon },
                      width: "90%",
                    }}
                    onBlur={handleBlur}
                  />
                  {touched?.prizePool && errors?.prizePool && (
                    <Typography
                      sx={{
                        color: colors.danger,
                        fontSize: "11px",
                        fontWeight: 600,
                      }}
                    >
                      {errors?.prizePool}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {/* <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={2.5}
                px={2}
              >
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                    <Typography fontSize={15} fontWeight={400}>
                      Type
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color={colors.redAsterisk}
                    >
                      *
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                    <Autocomplete
                      options={frequencyTypes || []}
                      getOptionLabel={(option: any) => option.name}
                      disableClearable={true}
                      value={frequencyTypes?.find(
                        (x) => x.name === values.frequencyType
                      )}
                      onChange={(event: any, value: any) => {
                        if (value) {
                          setFieldValue("frequencyType", value.name);
                        }
                      }}
                      sx={{
                        width: "90%"
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="frequencyType"
                          size="small"
                          variant="outlined"
                          placeholder="Daily, Weekly,...."
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: 14 }
                          }}
                          sx={{
                            backgroundColor: colors.FieldBackgroundLight
                          }}
                        />
                      )}
                    />
                    {touched?.frequencyType && errors?.frequencyType && (
                      <Typography
                        sx={{
                          color: colors.danger,
                          fontSize: "11px",
                          fontWeight: 600
                        }}
                      >
                        {errors?.frequencyType}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={2.5}
                px={2}
              >
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                    <Typography fontSize={15} fontWeight={400}>
                      Rules
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color={colors.redAsterisk}
                    >
                      *
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                    <Editor
                      id="rules"
                      initialValue={values.rulesTemp}
                      init={{
                        height: 175,
                        width: 382,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "bold italic | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist ",
                        content_style: `body { background-color: #FFF1E4;font-family:Helvetica,Arial,sans-serif; font-size:16px }
                        `,
                        branding: false,
                        statusbar: false,
                      }}
                      onEditorChange={(content) => {
                        setFieldValue("rules", content);
                        // setRulesTemp(content);
                      }}
                      onBlur={handleBlur}
                    />
                    {touched?.rules && errors?.rules && (
                      <Typography
                        sx={{
                          color: colors.danger,
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                      >
                        {errors?.rules}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {useMegaContestCard === 1 && (
                <Grid item container xs={12} sm={12} md={12} lg={12} mt={1}>
                  <AssetTable tournamentType={"Other"} {...props} />
                </Grid>
              )}
            </>
          )}
        {values.tournamentType && values.tournamentType === "Free" && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
              <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={13} fontWeight={600}>
                    GENERAL INFORMATION
                  </Typography>
                  <Typography
                    fontSize={13}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Title
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="title"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={13}>{`${
                          values.title?.length || 0
                        }/8`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                  value={values.title}
                  onChange={(e) => {
                    // if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                    //   setFieldValue("title", e.target.value);
                    // }
                    setFieldValue("title", e.target.value);
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%",
                  }}
                  onBlur={handleBlur}
                />
                {touched?.title && errors?.title && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                    }}
                  >
                    {errors?.title}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Sub Title
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="subTitle"
                  size="small"
                  fullWidth
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <Typography fontSize={13}>{`${
                  //         values.title?.length || 0
                  //       }/8`}</Typography>
                  //     </InputAdornment>
                  //   )
                  // }}
                  value={values.subTitle}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("subTitle", e.target.value);
                    }
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%",
                  }}
                  onBlur={handleBlur}
                />
                {/* {touched?.subTitle && errors?.subTitle && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600
                    }}
                  >
                    {errors?.subTitle}
                  </Typography>
                )} */}
              </Grid>
            </Grid>
          </>
        )}
        {values.tournamentType && values.tournamentType === "Blitz" && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
              <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={13} fontWeight={600}>
                    GENERAL INFORMATION
                  </Typography>
                  <Typography
                    fontSize={13}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5} px={2}>
              <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    Include Free Play
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <RadioGroup
                    row
                    defaultValue={1}
                    value={values.includeFreePlay}
                    onChange={(event) => {
                      setFieldValue(
                        "includeFreePlay",
                        parseInt(event.currentTarget.value)
                      );
                      // setUseMegaContestCard(
                      //   parseInt(event.currentTarget.value)
                      // );
                    }}
                    sx={{ mt: -1 }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio size="small" />}
                      label={
                        <Typography fontSize={16} fontWeight={400} mt={-1}>
                          Yes
                        </Typography>
                      }
                      defaultChecked={false}
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio size="small" />}
                      label={
                        <Typography fontSize={16} fontWeight={400} mt={-1}>
                          No
                        </Typography>
                      }
                      defaultChecked={true}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Players Limit
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="slots"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={13}>min 2 - max 5</Typography>
                      </InputAdornment>
                    ),
                  }}
                  value={values.slots}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("slots", e.target.value);
                    }
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%",
                  }}
                  onBlur={handleBlur}
                />
                {touched?.slots && errors?.slots && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                    }}
                  >
                    {errors?.slots}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  GD Commission
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  id="gdCommission"
                  size="small"
                  fullWidth
                  value={values.gdCommission}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("gdCommission", e.target.value);
                    }
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%",
                  }}
                  onBlur={handleBlur}
                />
                {touched?.gdCommission && errors?.gdCommission && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                    }}
                  >
                    {errors?.gdCommission}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Boot Amount 1
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                <TextField
                  fullWidth={true}
                  size="small"
                  value={values.bootEntry1}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue(`bootEntry1`, e.target.value);
                    }
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: colors.lightGreyIcon },
                    width: "90%",
                  }}
                  onBlur={handleBlur}
                />
                {touched.bootEntry1 && errors.bootEntry1 && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                    }}
                  >
                    {errors?.bootEntry1}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <FieldArray
              name="bootEntries"
              render={(arrayHelpers) => (
                <>
                  {values.bootEntries &&
                    values.bootEntries?.length > 0 &&
                    values.bootEntries?.map((c, index) => (
                      <>
                        <Grid
                          component="div"
                          key={index}
                          item
                          container
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          mt={2.5}
                          pr={index % 2 === 0 ? 2 : 0}
                          pl={index % 2 === 1 ? 2 : 0}
                        >
                          <Grid
                            item
                            container
                            xs={12}
                            sm={3.5}
                            md={3.5}
                            lg={3.5}
                          >
                            <Typography fontSize={15} fontWeight={400}>
                              {`Boot Amount ${index + 2}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                            <TextField
                              fullWidth={true}
                              size="small"
                              value={values.bootEntries[index]}
                              onChange={(e) => {
                                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                                  setFieldValue(
                                    `bootEntries[${index}]`,
                                    e.target.value
                                  );
                                }
                              }}
                              sx={{
                                backgroundColor: colors.FieldBackgroundLight,
                                input: { color: colors.lightGreyIcon },
                                width: "90%",
                              }}
                              onBlur={handleBlur}
                            />
                            {touched.bootEntries &&
                              touched.bootEntries?.length > 0 &&
                              errors.bootEntries &&
                              errors.bootEntries?.length > 0 &&
                              touched?.bootEntries[index] && (
                                <Typography
                                  sx={{
                                    color: colors.danger,
                                    fontSize: "11px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {errors?.bootEntries[index]}
                                </Typography>
                              )}
                          </Grid>
                        </Grid>
                        {index === values.bootEntries?.length - 1 && (
                          <Grid
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            mt={3}
                            pl={2}
                          >
                            <Typography
                              fontSize={15}
                              fontWeight={400}
                              color={colors.invalidInput}
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add new Boot Amount +
                            </Typography>
                          </Grid>
                        )}
                      </>
                    ))}
                </>
              )}
            />
            <Grid item container xs={12} sm={12} md={12} lg={12} mt={1}>
              <AssetTable tournamentType={"Blitz"} {...props} />
            </Grid>
          </>
        )}
        {values.tournamentType &&
          values.tournamentType !== "Blitz" &&
          values.tournamentType?.name !== "" && (
            <Grid container mt={values.tournamentType === "Free" ? 3 : 0}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: { lg: "25%", md: "25%", sm: "25%", xs: "50%" },
                  background:
                    "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                  borderRadius: "36px",
                  height: "38px",
                  fontWeight: 400,
                  fontSize: "16px",
                  margin: "20px 0px",
                  ml: "20px",
                  color: "#FFF9F2",
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {values?.tournamentType === "Free" && values?.id === null
                  ? "Add Free Tournament"
                  : values?.tournamentType === "Free" && values?.id !== null
                  ? "Update Free Tournament"
                  : "Save & Go to Prize Distribution"}
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: { lg: "15%", md: "19%", sm: "23%", xs: "23%" },
                  background: "#bbb5af",
                  borderRadius: "36px",
                  height: "38px",
                  fontWeight: 400,
                  fontSize: "16px",
                  margin: "20px 20px",
                  color: "#FFF9F2",
                }}
                onClick={() => history.push("/tournaments-configuration")}
              >
                Cancel
              </Button>
            </Grid>
          )}
        {values.tournamentType && values.tournamentType === "Blitz" && (
          <Grid container>
            <Button
              size="small"
              variant="contained"
              sx={{
                width: { lg: "25%", md: "25%", sm: "25%", xs: "50%" },
                background:
                  "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                borderRadius: "36px",
                height: "38px",
                fontWeight: 400,
                fontSize: "16px",
                margin: "20px 0px",
                ml: "20px",
                color: "#FFF9F2",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {values?.id ? "Update Boot Tournament" : "Add Boot Tournament"}
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                width: { lg: "15%", md: "19%", sm: "23%", xs: "23%" },
                background: "#bbb5af",
                borderRadius: "36px",
                height: "38px",
                fontWeight: 400,
                fontSize: "16px",
                margin: "20px 20px",
                color: "#FFF9F2",
              }}
              onClick={() => history.push("/tournaments-configuration")}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
