import React, { SetStateAction, useEffect, useMemo } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DeleteOutline, DoneOutlined, EditOutlined } from "@mui/icons-material";
import Table from "../Table";
import OrangeGradientButton from "../OrangeGradientButton";
import images from "assets/images/images";
import { getJwtTokenDetails } from "utils/helpers";
interface IUserManagementProps {
  roles: any;
  activeUsers: any;
  inActiveUsers: any;
  selectedUser: any;
  setSelectedUser: any;
  setInviteModal: React.Dispatch<SetStateAction<boolean>>;
  setSuccessInviteModal: React.Dispatch<SetStateAction<boolean>>;
  setDeleteUserModal: React.Dispatch<SetStateAction<boolean>>;
  setResendInviteModal: React.Dispatch<SetStateAction<boolean>>;
  setRevokeInviteModal: React.Dispatch<SetStateAction<boolean>>;
  updateUserRole: any;
}

const Users = (props: IUserManagementProps) => {
  const {
    roles,
    activeUsers,
    inActiveUsers,
    selectedUser,
    setSelectedUser,
    setInviteModal,
    setDeleteUserModal,
    setResendInviteModal,
    setRevokeInviteModal,
    updateUserRole,
  } = props;

  const [editRow, setEditRow] = React.useState(-1);
  const [userRole, setUserRole] = React.useState<any>(null);
  const { rolePermissions } = getJwtTokenDetails();

  React.useEffect(() => {
    if (userRole) {
      updateUserRole({
        variables: {
          input: {
            id: selectedUser?.id,
            role: userRole?.id,
          },
        },
      });
    }
  }, [editRow]);

  const activeUsersColumn: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "NAME",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="14px"
            fontWeight={700}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.row?.firstName}
          </Typography>
        ),
      },
      {
        field: "email",
        headerName: "EMAIL",
        flex: 2,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="14px"
            fontWeight={400}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.row?.email}
          </Typography>
        ),
      },
      {
        field: "role",
        headerName: "USER ROLE",
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const [userRoleTemp, setUserRoleTemp] = React.useState<any>(null);

          const handleRoleChange = (value) => {
            setUserRoleTemp(value);
            setUserRole(value);
          };
          if (editRow === params.row.id) {
            return (
              <Autocomplete
                options={roles || []}
                getOptionLabel={(option: any) => option?.role}
                value={
                  userRoleTemp
                    ? userRoleTemp
                    : roles?.find(
                        (item: any) =>
                          parseInt(item?.id) === params.row?.role?.id
                      )
                }
                sx={{ ml: 3 }}
                size="small"
                fullWidth
                disableClearable
                onChange={(e, value) => {
                  handleRoleChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ backgroundColor: "#FFF1E4" }}
                    size="small"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14, height: 20 },
                    }}
                  />
                )}
              />
            );
          } else {
            return (
              <Chip
                variant="filled"
                label={params.row?.role?.role}
                sx={{
                  ml: 3,
                  backgroundColor: "#F9CD5E",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#3c3c3c3",
                  minWidth: "130px",
                }}
              />
            );
          }
        },
      },
      {
        field: "todo",
        headerName: "ACTIONS",
        flex: 1,
        sortable: false,
        hide: !rolePermissions.USERS_PERMISSION.EDIT_USERS?.VIEW_AND_EDIT,
        renderCell: (params) => (
          <Box display="flex" sx={{ ml: 3 }}>
            {editRow === params.row.id ? (
              <IconButton
                size="small"
                onClick={() => {
                  setEditRow(-1);
                }}
              >
                <DoneOutlined fontSize="small" sx={{ color: "#ff8100" }} />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={() => {
                  setUserRole(null);
                  setSelectedUser(params.row);
                  setEditRow(params.row.id);
                }}
              >
                <img src={images.EditIcon} alt="" />
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => {
                setSelectedUser(params.row);
                setDeleteUserModal(true);
              }}
            >
              <DeleteOutline fontSize="small" sx={{ color: "#3c3c3c" }} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [editRow, rolePermissions]
  );

  const pendingInvitesColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "NAME",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="14px"
            fontWeight={700}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.row?.firstName}
          </Typography>
        ),
      },
      {
        field: "email",
        headerName: "EMAIL",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="14px"
            fontWeight={400}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.row?.email}
          </Typography>
        ),
      },
      {
        field: "role",
        headerName: "USER ROLE",
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <Chip
              variant="filled"
              label={params.row?.role?.role}
              sx={{
                ml: 3,
                backgroundColor: "#F9CD5E",
                fontSize: "16px",
                fontWeight: 500,
                color: "#3c3c3c3",
                minWidth: "130px",
              }}
            />
          );
        },
      },
      {
        field: "todo",
        headerName: "ACTIONS",
        flex: 1,
        sortable: false,
        hide: !rolePermissions.USERS_PERMISSION.EDIT_USERS?.VIEW_AND_EDIT,
        renderCell: (params) => (
          <Box display="flex" sx={{ ml: 3 }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#ff8100",
                color: "#ff8100",
                backgroundColor: "#ff810010",
              }}
              onClick={() => {
                setSelectedUser(params.row);
                setResendInviteModal(true);
              }}
            >
              Resend Invite
            </Button>
            <Button
              variant="outlined"
              sx={{
                ml: 2,
                borderColor: "#FF3B30",
                color: "#FF3B30",
                backgroundColor: "#FF3B3010",
              }}
              onClick={() => {
                setSelectedUser(params.row);
                setRevokeInviteModal(true);
              }}
            >
              Revoke Invite
            </Button>
          </Box>
        ),
      },
    ],
    [rolePermissions]
  );

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            backgroundColor: "#FEF7EE",
            width: "100%",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            p: 2,
          }}
        >
          <Grid container alignItems="center">
            <Grid item lg={10} md={9} sm={6} xs={12}>
              <Typography color="#3c3c3c" fontWeight={700} fontSize="16px">
                ALL USERS
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              sm={6}
              xs={12}
              display="flex"
              justifyContent={{ sm: "flex-end", xs: "flex-start" }}
            >
              {rolePermissions.USERS_PERMISSION.EDIT_USERS?.VIEW_AND_EDIT && (
                <OrangeGradientButton
                  title="Invite Users"
                  style={{ width: "180px" }}
                  onClick={() => setInviteModal(true)}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Table
          rows={activeUsers && activeUsers?.length > 0 ? activeUsers : []}
          columns={activeUsersColumn}
          headerStyle={{
            backgroundColor: "#FFE4CB",
            color: "#3c3c3c",
            fontSize: "14px",
            textAlign: "left",
            fontWeight: 700,
            cellBackground: "#FEF7EE",
            pl: 2,
          }}
          disableSearch
          hideFooter
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            width: "100%",
            borderBottom: 1,
            borderColor: "#ababab50",
            my: 3,
          }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            backgroundColor: "#FEF7EE",
            width: "100%",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            p: 2,
          }}
        >
          <Typography color="#3c3c3c" fontWeight={700} fontSize="16px">
            PENDING INVITES
          </Typography>
        </Box>
        <Table
          rows={inActiveUsers && inActiveUsers?.length > 0 ? inActiveUsers : []}
          columns={pendingInvitesColumns}
          headerStyle={{
            backgroundColor: "#FFE4CB",
            color: "#3c3c3c",
            fontSize: "14px",
            textAlign: "left",
            fontWeight: 700,
            cellBackground: "#FEF7EE",
            pl: 2,
          }}
          disableSearch
          hideFooter
        />
      </Grid>
    </Grid>
  );
};

export default Users;
