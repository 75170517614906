import React from "react";
import { Grid, Typography } from "@mui/material";
import { colors } from "styles/colors";
import images from "assets/images/images";
import BarChartGraph from "../ColumnChartStack";

const UserDemographics = (props) => {
  const { userDemographicDetail } = props;

  const xAxisLabels =
    (userDemographicDetail &&
      userDemographicDetail?.demographics &&
      userDemographicDetail?.demographics?.length > 0 &&
      userDemographicDetail?.demographics?.map((item) => item?.name || "")) ||
    [];
  const data =
    (userDemographicDetail &&
      userDemographicDetail?.demographics &&
      userDemographicDetail?.demographics?.length > 0 &&
      userDemographicDetail?.demographics?.map((item) => ({
        name: item?.name || "",
        y: item?.value || 0,
        color: colors.purple
      }))) ||
    [];
  const graphData = [
    {
      name: "No. of Users",
      colorByPoint: true,
      pointWidth: 30,
      data: data
    }
  ];

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              backgroundColor: colors.white,
              padding: 20,
              borderRadius: 15,
              width: "45%",
              margin: 15
            }}
          >
            <img src={images.badge} />
            <div style={{ margin: "0px 10px" }}>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ p: 0.2 }}
              >
                Average winnings
              </Typography>
              <Typography
                color="#464646"
                fontSize="16px"
                fontWeight={600}
                sx={{ p: 0.2 }}
              >
                {`${userDemographicDetail?.avgWinnings || 0} INR / player`}
              </Typography>
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              backgroundColor: colors.white,
              padding: 20,
              borderRadius: 15,
              width: "45%",
              margin: 15
            }}
          >
            <img src={images.MobileGame} />
            <div style={{ margin: "0px 10px" }}>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ p: 0.2 }}
              >
                Average gaming hours
              </Typography>
              <Typography
                color="#464646"
                fontSize="16px"
                fontWeight={600}
                sx={{ p: 0.2 }}
              >
                06 hrs / Day
              </Typography>
            </div>
          </div> */}
        </div>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.white,
          padding: "20px",
          borderRadius: 5,
          margin: "0px 10px"
        }}
      >
        <Typography color="#464646" fontSize="16px" fontWeight={600}>
          User demographics
        </Typography>
        <BarChartGraph
          xAxisLabels={xAxisLabels}
          data={graphData}
          partColors={["#9F7FFC"]}
          properties={{
            width: 1150
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UserDemographics;
