import React from "react";
import { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Chip,
  Grid,
  Typography,
  Box,
  Button,
  MenuItem,
  Divider,
  Popover,
  IconButton
} from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "../Table";
import { colors } from "styles/colors";
import images from "assets/images/images";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import TournamentAnalysisChart from "./TournamentSlot";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  GET_REGISTERED_USERS_OF_TOURNAMENT,
  GET_TOURNAMENT_DETAILED_STATISTICS,
  GET_TOURNAMENT_DETAILS
} from "app/graphql/tournament";
import { getDateInFormat } from "utils/helpers";

const TournamentBootDetails = () => {
  const history = useHistory();
  const uriParams: any = useParams();

  const [tournamentDetails, setTournamentDetails] = useState<any>(null);
  const [tournamentUserDetails, setTournamentUserDetails] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchText, setSearchText] = React.useState("");
  const [searchTextTemp, setSearchTextTemp] = React.useState("");

  const fireRequestSearchAPICall = (searchValue) => {
    setSearchText(searchValue);
  };
  const [timer, setTimer] = React.useState<any>(null);

  const requestSearch = (searchValue: string) => {
    setSearchTextTemp(searchValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fireRequestSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const { loading: tournamentDetailsLoading } = useQuery(
    GET_TOURNAMENT_DETAILED_STATISTICS,
    {
      variables: { id: parseInt(uriParams?.id), filter: "Blitz" },
      skip: !uriParams?.id,
      onCompleted: (data) => {
        const { tournamentDashboardDetails } = data;
        const { status } = tournamentDashboardDetails;
        if (status) {
          setTournamentDetails(tournamentDashboardDetails);
        } else {
          setTournamentDetails(null);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  const { loading: registeredUsersInTournamentLoading } = useQuery(
    GET_REGISTERED_USERS_OF_TOURNAMENT,
    {
      variables: {
        id: parseInt(uriParams?.id),
        page: page,
        limit: pageSize,
        search: searchText
      },
      skip: !uriParams?.id,
      onCompleted: (data) => {
        const { registeredUsersInTournament } = data;
        setTournamentUserDetails(registeredUsersInTournament);
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  const requestMapping = {
    Ready: {
      status: "Ready",
      fill: colors.backgroundBoxLight,
      color: colors.invalidInput
    },
    Started: {
      status: "Started",
      fill: colors.backgroundBoxLight,
      color: colors.green
    },
    Completed: {
      status: "Completed",
      fill: colors.backgroundBoxLight,
      color: colors.green
    }
  };

  const columnsForRegisteredUsers: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 0.5
      },
      {
        field: "name",
        headerName: "NAME",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            onClick={() => history.push(`/users/${params.row.id}`)}
          >
            <Typography fontSize={"14px"}>
              {params.row.nickname || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "startDateAndTime",
        headerName: "START D&T",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const DateAndTime: any = params?.row?.startTime
            ? getDateInFormat(
                parseInt(params?.row?.startTime),
                "DD/MM/YYYY hh:mm A",
                "changeFormat"
              )
            : null;
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "endDateAndTime",
        headerName: "END D&T",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const DateAndTime: any = params?.row?.endTime
            ? getDateInFormat(
                parseInt(params?.row?.endTime),
                "DD/MM/YYYY hh:mm A",
                "changeFormat"
              )
            : null;
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "bootPool",
        headerName: "BOOT POOL",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.bootPool || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "entry",
        headerName: "ENTRY",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.entry || "-"}</Typography>
          </Box>
        )
      },
      // {
      //   field: "players",
      //   headerName: "PLAYERS",
      //   // minWidth: 200,
      //   flex: 1,
      //   renderCell: (params) => (
      //     <Box
      //       display={"flex"}
      //       justifyContent={"center"}
      //       alignItems={"baseline"}
      //       fontSize={"14px"}
      //     >
      //       <Typography fontSize={"14px"}>{params.row.entry|| "-"}</Typography>
      //     </Box>
      //   )
      // },
      // {
      //   field: "gdCommission",
      //   headerName: "GD COMMISSION",
      //   // minWidth: 200,
      //   flex: 1.2,
      //   renderCell: (params) => (
      //     <Box
      //       display={"flex"}
      //       justifyContent={"center"}
      //       alignItems={"baseline"}
      //       fontSize={"14px"}
      //     >
      //       <Typography fontSize={"14px"}>{params.row.entry|| "-"}</Typography>
      //     </Box>
      //   )
      // },
      {
        field: "winningAmount",
        headerName: "WIN AMT",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.winningAmount || "-"}
            </Typography>
          </Box>
        )
      }
      // {
      //   field: "status",
      //   headerName: "STATUS",
      //   minWidth: 100,
      //   flex: 1,
      //   renderCell: (params) => (
      //     <Typography
      //       fontSize={"12px"}
      //       fontWeight={600}
      //       color={requestMapping[params.value]?.color}
      //     >
      //       {requestMapping[params.value]?.status.toUpperCase()}
      //     </Typography>
      //     // <Chip
      //     //   label={
      //     //     <Typography fontSize={'11px'} fontWeight={600}>
      //     //       {requestMapping[params.value].name.toUpperCase()}
      //     //     </Typography>
      //     //   }
      //     //   size="small"
      //     //   sx={{
      //     //     width: '90px',
      //     //     height: '26px',
      //     //     borderRadius: '8px',
      //     //     color: requestMapping[params.value].text,
      //     //     backgroundColor: requestMapping[params.value].fill,
      //     //   }}
      //     // />
      //   )
      // }
    ],
    []
  );

  const rowsDataForRegisteredUsers = [
    {
      id: 1,
      Id: 1,
      name: "Lorem Ipsum",
      mobile: "Lorem Ipsum",
      joinDateAndTime: "10-03-2023, 9 AM",
      attemptDateAndTime: "10-03-2023, 9 AM",
      entry: "₹100",
      position: "30",
      prize: "₹100",
      attempts: "27",
      status: "Played"
    },
    {
      id: 2,
      Id: 2,
      name: "Lorem Ipsum",
      mobile: "Lorem Ipsum",
      joinDateAndTime: "10-03-2023, 9 AM",
      attemptDateAndTime: "10-03-2023, 9 AM",
      entry: "₹100",
      position: "30",
      prize: "₹100",
      attempts: "27",
      status: "Played"
    },
    {
      id: 3,
      Id: 3,
      name: "Lorem Ipsum",
      mobile: "Lorem Ipsum",
      joinDateAndTime: "10-03-2023, 9 AM",
      attemptDateAndTime: "10-03-2023, 9 AM",
      entry: "₹100",
      position: "30",
      prize: "₹100",
      attempts: "27",
      status: "Played"
    },
    {
      id: 4,
      Id: 4,
      name: "Lorem Ipsum",
      mobile: "Lorem Ipsum",
      joinDateAndTime: "10-03-2023, 9 AM",
      attemptDateAndTime: "10-03-2023, 9 AM",
      entry: "₹100",
      position: "30",
      prize: "₹100",
      attempts: "27",
      status: "Played"
    },
    {
      id: 5,
      Id: 5,
      name: "Lorem Ipsum",
      mobile: "Lorem Ipsum",
      joinDateAndTime: "10-03-2023, 9 AM",
      attemptDateAndTime: "10-03-2023, 9 AM",
      entry: "₹100",
      position: "30",
      prize: "₹100",
      attempts: "27",
      status: "Played"
    },
    {
      id: 6,
      Id: 6,
      name: "Lorem Ipsum",
      mobile: "Lorem Ipsum",
      joinDateAndTime: "10-03-2023, 9 AM",
      attemptDateAndTime: "10-03-2023, 9 AM",
      entry: "₹100",
      position: "30",
      prize: "₹100",
      attempts: "27",
      status: "Played"
    },
    {
      id: 7,
      Id: 7,
      name: "Lorem Ipsum",
      mobile: "Lorem Ipsum",
      joinDateAndTime: "10-03-2023, 9 AM",
      attemptDateAndTime: "10-03-2023, 9 AM",
      entry: "₹100",
      position: "30",
      prize: "₹100",
      attempts: "27",
      status: "Played"
    }
  ];

  const [viewRequestDetail, setViewRequestDetail] = React.useState(false);
  const [selectedRequests, setSelectedRequests] = React.useState<number[]>([]);
  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

  const handleRowOver = (e) => {
    const rowId = Number(e.currentTarget.dataset.id);
    setHoveredRow(rowId);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const lastUpdatedInfo: any = tournamentDetails?.lastUpdated
    ? getDateInFormat(
        parseInt(tournamentDetails?.lastUpdated),
        "DD/MM/YYYY hh:mm A",
        "changeFormat"
      )
    : null;
  return (
    <Grid container>
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "21px",
          padding: 2.5
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TournamentAnalysisChart chartData={tournamentDetails?.bootTrends} />
        </Grid>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          m: 1.5,
          p: 3,
          borderRadius: "15px"
        }}
      >
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              Registered Users
            </Typography>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              Displaying details of 25 users
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400} mb={1}>
              {`Last Updated on ${lastUpdatedInfo}`}
            </Typography>
          </Box>
        </Box>
        {/* <div style={{ marginTop: 15 }}>
          <span style={{ margin: 15 }}>
            <img src={images.ExportIcon} alt="" />
          </span>
        </div> */}
        <Table
          rows={tournamentUserDetails?.users || []}
          columns={columnsForRegisteredUsers}
          paginationMode="client"
          page={page}
          onPageChange={(page) => setPage(page)}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
            setPage(0);
          }}
          rowCount={tournamentUserDetails.count}
          loading={false}
          pagination={true}
          // checkboxSelection
          selectedRows={selectedRequests}
          setSelectedRows={setSelectedRequests}
          onRowHover={handleRowOver}
          onRowLeave={handleRowLeave}
          searchText={searchTextTemp}
          requestSearch={requestSearch}
          disableSearch={false}
          placeholderTextForSearch="Search by name"
        />
      </Grid>
    </Grid>
  );
};

export default TournamentBootDetails;
