import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  IconButton
} from "@mui/material";
import { colors } from "../../../../../styles/colors";
import Modal from "../../../Modal";
import images from "assets/images/images";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDateInFormat } from "utils/helpers";
import { GET_CONFIG_DATA } from "app/graphql/userManagement";
import { useQuery } from "@apollo/client";

interface IViewRewardDetailProps {
  setItem: any;
  rewardDetail: any;
  setSelectedRow: any;
}

const rewardTypes = [
  {
    Id: 1,
    Name: "Coupon"
  },
  {
    Id: 2,
    Name: "Cashback"
  }
];

const ViewRewardDetail = (props: IViewRewardDetailProps) => {
  const { setItem, rewardDetail, setSelectedRow } = props;

  const brandDetail = rewardDetail?.brand
    ? JSON.parse(rewardDetail?.brand)
    : {};
  console.log(brandDetail);
  const rewardData = { ...rewardDetail, ...brandDetail };

  console.log("rewardData", rewardData);

  const expiryDate = rewardData?.expiryDate
    ? getDateInFormat(
        parseInt(rewardData?.expiryDate),
        "DD/MM/YYYY",
        "changeFormat"
      )
    : null;

  console.log(expiryDate);

  const [levels, setLevels] = React.useState<any>([]);

  const {
    data: rewardLevelConfigData,
    loading: rewardLevelConfigDataLoading,
    refetch: refetchRewardLevelConfigData
  } = useQuery(GET_CONFIG_DATA, {
    variables: {
      key: "REWARD_LEVELS"
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      rewardLevelConfigData &&
      rewardLevelConfigData?.getConfigDetails &&
      rewardLevelConfigData?.getConfigDetails?.status
    ) {
      const temp = rewardLevelConfigData?.getConfigDetails?.config;

      let data: any;
      try {
        data = temp?.value ? JSON.parse(temp?.value) : "";
      } catch (error) {
        alert(error);
        data = "";
      }
      setLevels(data || []);
    }
  }, [rewardLevelConfigData]);

  const level = rewardData?.value
    ? levels?.find(
        (item) =>
          parseInt(rewardData?.value) >= parseInt(item.minValue) &&
          parseInt(rewardData?.value) <= parseInt(item.maxValue)
      )
    : {};

  const htmlString = rewardData?.rewardDetails;
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        backgroundColor: colors.backgroundBoxLight,
        borderRadius: "4px",
        border: "0.5px solid rgba(168, 168, 168, 0.5)",
        mt: 2
      }}
    >
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          backgroundColor: colors.TableHead,
          px: 2,
          py: 1.3,
          borderRadius: "4px 4px 0px 0px",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography fontSize={16} fontWeight={600}>
            Reward Detail
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "120px",
              height: "38px",
              background: "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
              borderRadius: "30px",
              fontWeight: 600,
              fontSize: "14px",
              marginRight: 2
            }}
            onClick={() => {
              setSelectedRow(null);
              setItem("rewardsTable");
            }}
          >
            Close
          </Button>
        </Box>
      </Grid>
      <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
        <Grid item container xs={12} sm={6} md={6} lg={6}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography fontSize={15} fontWeight={400}>
                Reward No
              </Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Box
              sx={{
                width: "90%",
                borderRadius: "4px",
                backgroundColor: colors.FieldBackgroundLight,
                color: colors.lightGreyIcon,
                height: "40px",
                padding: "10px"
              }}
            >
              <Typography fontSize={15} fontWeight={400}>
                {rewardData?.id || ""}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
        <Grid item container xs={12} sm={6} md={6} lg={6}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography fontSize={15} fontWeight={400}>
                Reward Category
              </Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Box
              sx={{
                width: "90%",
                borderRadius: "4px",
                backgroundColor: colors.FieldBackgroundLight,
                color: colors.lightGreyIcon,
                height: "40px",
                padding: "10px"
              }}
            >
              <Typography fontSize={15} fontWeight={400}>
                {rewardData?.type?.type}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography fontSize={15} fontWeight={400}>
                Internal Reward Cost
              </Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Box
              sx={{
                width: "90%",
                borderRadius: "4px",
                backgroundColor: colors.FieldBackgroundLight,
                color: colors.lightGreyIcon,
                height: "40px",
                padding: "10px"
              }}
            >
              <Typography fontSize={15} fontWeight={400}>
                {rewardDetail?.value || ""}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12}>
        <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  Level
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
              <Box
                sx={{
                  width: "90%",
                  borderRadius: "4px",
                  backgroundColor: colors.FieldBackgroundLight,
                  color: colors.lightGreyIcon,
                  height: "40px",
                  padding: "10px"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  {level?.name || ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  Expiry Date
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
              <Box
                sx={{
                  width: "90%",
                  borderRadius: "4px",
                  backgroundColor: colors.FieldBackgroundLight,
                  color: colors.lightGreyIcon,
                  height: "40px",
                  padding: "10px"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  {expiryDate || ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  Count
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
              <Box
                sx={{
                  width: "90%",
                  borderRadius: "4px",
                  backgroundColor: colors.FieldBackgroundLight,
                  color: colors.lightGreyIcon,
                  height: "40px",
                  padding: "10px"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  {rewardData?.totalStock || ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ backgroundColor: colors.backgroundBoxLight, p: 2 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    Add expiry date to reward?
                  </Typography>
                </Box>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box
                  sx={{
                    width: "90%",
                    borderRadius: "4px",
                    backgroundColor: colors.FieldBackgroundLight,
                    color: colors.lightGreyIcon,
                    height: "40px",
                    padding: "10px"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    {rewardData?.addExpiryDateToReward || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} mt={2.5}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    Title
                  </Typography>
                </Box>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box
                  sx={{
                    width: "90%",
                    borderRadius: "4px",
                    backgroundColor: colors.FieldBackgroundLight,
                    color: colors.lightGreyIcon,
                    height: "40px",
                    padding: "10px"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    {rewardData?.title || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    Sub-Title
                  </Typography>
                </Box>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box
                  sx={{
                    width: "90%",
                    borderRadius: "4px",
                    backgroundColor: colors.FieldBackgroundLight,
                    color: colors.lightGreyIcon,
                    height: "40px",
                    padding: "10px"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    {rewardData?.sliceDisplayText || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    Sub-Heading
                  </Typography>
                </Box>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box
                  sx={{
                    width: "90%",
                    borderRadius: "4px",
                    backgroundColor: colors.FieldBackgroundLight,
                    color: colors.lightGreyIcon,
                    height: "40px",
                    padding: "10px"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    {rewardData?.cardDisplayText || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    Sub-Text
                  </Typography>
                </Box>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box
                  sx={{
                    width: "90%",
                    borderRadius: "4px",
                    backgroundColor: colors.FieldBackgroundLight,
                    color: colors.lightGreyIcon,
                    height: "40px",
                    padding: "10px"
                  }}
                >
                  <Typography fontSize={15} fontWeight={400}>
                    {rewardData?.subText || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                border: "0.5px solid rgba(168, 168, 168, 0.5)",
                borderRadius: "4px",
                py: 1.5
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  Logo Card 1
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Box
                  sx={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50px",
                    backgroundColor: colors.FieldBackgroundLight,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img
                    src={rewardData?.logoCard1Url || images.logoCard1}
                    alt="Game thumbnail"
                    style={{
                      height: rewardData?.logoCard2Url ? "50px" : "20px",
                      width: "50px",
                      borderRadius: "10px",
                      backgroundColor: colors.FieldBackgroundLight,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  80x80
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Typography fontSize={15} fontWeight={400}>
                  250KB
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <img
                  src={images.logoCard1}
                  alt="Game thumbnail"
                  width="50"
                  height="50"
                />
              </Box>
            </Box>
          </Grid>
          {rewardData?.addBrandDetails === "Yes" && (
            <>
              <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5}>
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Typography fontSize={15} fontWeight={400}>
                      Brand Name
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <Box
                    sx={{
                      width: "90%",
                      borderRadius: "4px",
                      backgroundColor: colors.FieldBackgroundLight,
                      color: colors.lightGreyIcon,
                      height: "40px",
                      padding: "10px"
                    }}
                  >
                    <Typography fontSize={15} fontWeight={400}>
                      {rewardData?.brandName || ""}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5} mt={2.5}>
                <Grid item container xs={12} sm={2.5} md={2.5} lg={2.5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Typography fontSize={15} fontWeight={400}>
                      Reward Summary
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container xs={12} sm={8} md={8} lg={8}>
                  <Box
                    sx={{
                      width: "90%",
                      borderRadius: "4px",
                      backgroundColor: colors.FieldBackgroundLight,
                      color: colors.lightGreyIcon,
                      height: "40px",
                      padding: "10px"
                    }}
                  >
                    <Typography fontSize={15} fontWeight={400}>
                      {rewardData?.rewardSummary || ""}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5}>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography fontSize={15} fontWeight={400}>
                        Coupon IDs Type
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                    <Box
                      sx={{
                        width: "90%",
                        borderRadius: "4px",
                        backgroundColor: colors.FieldBackgroundLight,
                        color: colors.lightGreyIcon,
                        height: "40px",
                        padding: "10px"
                      }}
                    >
                      <Typography fontSize={15} fontWeight={400}>
                        {rewardData?.couponIdsType === 1
                          ? "Universal"
                          : "Unique"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {rewardData?.couponIdsType === 1 ? (
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5}>
                  <Grid item container xs={12} sm={6} md={6} lg={6}>
                    <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Typography fontSize={15} fontWeight={400}>
                          Coupon Code
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                      <Box
                        sx={{
                          width: "90%",
                          borderRadius: "4px",
                          backgroundColor: colors.FieldBackgroundLight,
                          color: colors.lightGreyIcon,
                          height: "40px",
                          padding: "10px"
                        }}
                      >
                        <Typography fontSize={15} fontWeight={400}>
                          {rewardData?.universalCouponCode}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {rewardData?.uniqueCouponCodes?.length > 0 &&
                    rewardData?.uniqueCouponCodes.map((item, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={6} mt={2.5}>
                        <Grid item container xs={12} sm={12} md={12} lg={12}>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={3.5}
                            md={3.5}
                            lg={3.5}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <Typography fontSize={15} fontWeight={400}>
                                {`Coupon Code ${index + 1}`}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={8.5}
                            md={8.5}
                            lg={8.5}
                          >
                            <Box
                              sx={{
                                width: "90%",
                                borderRadius: "4px",
                                backgroundColor: colors.FieldBackgroundLight,
                                color: colors.lightGreyIcon,
                                height: "40px",
                                padding: "10px"
                              }}
                            >
                              <Typography fontSize={15} fontWeight={400}>
                                {item}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5}>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        pt: 0.5
                      }}
                    >
                      <Typography fontSize={15} fontWeight={400}>
                        Reward Details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                    <Box
                      sx={{
                        width: "90%",
                        borderRadius: "4px",
                        backgroundColor: colors.FieldBackgroundLight,
                        color: colors.lightGreyIcon,
                        height: "150px",
                        padding: "10px"
                      }}
                    >
                      {/* <Typography fontSize={15} fontWeight={400}>
                        {rewardData?.rewardDetails || ""}
                      </Typography> */}
                      <Typography
                        variant="body1"
                        fontSize={15}
                        fontWeight={400}
                        dangerouslySetInnerHTML={{ __html: htmlString }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    border: "0.5px solid rgba(168, 168, 168, 0.5)",
                    borderRadius: "4px",
                    py: 1.5
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Typography fontSize={15} fontWeight={400}>
                      Logo Card 2
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Box
                      sx={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                        backgroundColor: colors.FieldBackgroundLight,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <img
                        src={brandDetail?.logoCard2Url || images.logoCard2}
                        alt="Game thumbnail"
                        style={{
                          height: brandDetail?.logoCard2Url ? "50px" : "20px",
                          width: "50px",
                          borderRadius: "10px",
                          backgroundColor: colors.FieldBackgroundLight,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Typography fontSize={15} fontWeight={400}>
                      34x34
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Typography fontSize={15} fontWeight={400}>
                      250KB
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <img
                      src={images.logoCard2}
                      alt="Game thumbnail"
                      width="50"
                      height="50"
                    />
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewRewardDetail;
