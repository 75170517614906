import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import CurvedGraph from "../CurvedGraph";
import ColumChart from "../ColumnChart";

const days = ["1D", "5D", "1M", "6M", "YTD"];

const type = [
  {
    title: "Cashback",
    color: "#FBAF3A"
  },
  {
    title: "Coupons",
    color: "#15132E"
  },
  {
    title: "Brand Gifts",
    color: "#57BCBD"
  },
  {
    title: "Free Tourn.",
    color: "#4A6EE5"
  }
];

const xAxis = {
  categories: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  crosshair: true
};

const yAxis = {
  min: 0,
  title: {
    text: ""
  }
};

const RewardsClaimDetailedChart = (props) => {
  const { rewardsByTimeSpanData: data, setRewardsByTimeSpanFilter } = props;

  const rewardsByTimeSpanData = data?.rewardsByTimeSpan || [];
  const mysteryBoxPercentage = data?.mysteryBoxPercentage || 0;
  const spinTheWheelPercentage = data?.spinTheWheelPercentage || 0;

  const color = {
    0: colors.purple,
    1: colors.orange,
    2: colors.blue,
    3: colors.lightGreen,
    4: colors.paleBlue,
    5: colors.paleYellow
  };

  const type =
    rewardsByTimeSpanData && rewardsByTimeSpanData?.length > 0
      ? rewardsByTimeSpanData?.map((item, index) => ({
          title: item?.name,
          color: color[index]
        }))
      : [];

  const xAxisLabels =
    rewardsByTimeSpanData && rewardsByTimeSpanData?.length > 0
      ? rewardsByTimeSpanData[0]?.data?.map((item2) => item2?.name)
      : [];

  const xAxis = {
    categories: xAxisLabels,
    crosshair: true
  };

  const yAxis = {
    min: 0,
    title: {
      text: ""
    }
  };

  const graphData =
    rewardsByTimeSpanData &&
    rewardsByTimeSpanData?.length > 0 &&
    rewardsByTimeSpanData?.map((item1, index) => ({
      name: item1?.name,
      pointWidth: 30,
      data: item1?.data?.map((item2) => ({
        name: item2?.name || "",
        y: item2?.value || 0,
        color:
          index === 1
            ? colors.orange
            : index === 2
            ? colors.blue
            : index === 3
            ? colors.lightGreen
            : colors.purple
      })),
      color:
        index === 1
          ? colors.orange
          : index === 2
          ? colors.blue
          : index === 3
          ? colors.lightGreen
          : colors.purple
    }));

  const chartProperties = {
    width: 1100,
    height: 250,
    groupPadding: 0,
    pointPadding: 0
  };

  return (
    <Grid
      container
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{
        backgroundColor: colors.backgroundLight,
        borderRadius: "15px",
        padding: "20px 30px"
      }}
    >
      <Grid item xs={12} sm={8} md={8} lg={8}>
        <Typography
          color="#3C3C3C"
          fontSize="16px"
          fontWeight={600}
          sx={{ p: 1 }}
        >
          Rewards Claim detailed Chart
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {days.map((item, index) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={600}
                    sx={{ px: 2.5, py: 1, cursor: "pointer" }}
                    onClick={() =>
                      setRewardsByTimeSpanFilter(item?.toLowerCase())
                    }
                  >
                    {item}
                  </Typography>
                  {index < days.length - 1 && (
                    <Box
                      sx={{
                        height: 20,
                        width: 1.5,
                        backgroundColor: "rgba(168, 168, 168, 0.5)"
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end"
          }}
        >
          <div>
            {type?.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 5
                  }}
                >
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      backgroundColor: item.color,
                      borderRadius: 50
                    }}
                  ></div>
                  <Typography
                    color="#464646"
                    fontSize="10px"
                    fontWeight={400}
                    sx={{ px: 1 }}
                  >
                    {item?.title}
                  </Typography>
                </div>
              );
            })}
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ColumChart
          title={""}
          xAxis={xAxis}
          yAxis={yAxis}
          data={graphData}
          properties={chartProperties}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} mt={2} mb={-2}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DonutPieChart
              title={""}
              subtitle=""
              hideToolTip={true}
              height={40}
              data={[
                {
                  y: 35,
                  name: "Mystery Box",
                  color: "#FF8100"
                },
                {
                  y: 100 - 35,
                  name: "Mystery Box",
                  color: "#EEEEEE"
                }
              ]}
              partColors={["#FF8100", "#EEEEEE"]}
            />
            <div>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ px: 1 }}
              >
                Mystery Box
              </Typography>
              <Typography
                color="#464646"
                fontSize="15px"
                fontWeight={800}
                sx={{ px: 1 }}
              >
                {`${mysteryBoxPercentage}%`}
              </Typography>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DonutPieChart
              title={""}
              subtitle=""
              hideToolTip={true}
              height={40}
              data={[
                {
                  y: 35,
                  name: "Spin the Wheel",
                  color: "#7C87E8"
                },
                {
                  y: 100 - 35,
                  name: "Spin the Wheel",
                  color: "#EEEEEE"
                }
              ]}
              partColors={["#7C87E8", "#EEEEEE"]}
            />
            <div>
              <Typography
                color="#464646"
                fontSize="12px"
                fontWeight={400}
                sx={{ px: 1 }}
              >
                Spin the Wheel
              </Typography>
              <Typography
                color="#464646"
                fontSize="15px"
                fontWeight={800}
                sx={{ px: 1 }}
              >
                {`${spinTheWheelPercentage}%`}
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default RewardsClaimDetailedChart;
