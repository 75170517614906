import React, { useMemo } from "react";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { FormikProps } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "styles/colors";
import { IAddGame } from ".";
import Table from "../Table";
import { GridColDef } from "@mui/x-data-grid";
import OrangeGradientButton from "../OrangeGradientButton";
import images from "assets/images/images";
import { ImageOutlined } from "@mui/icons-material";

const GameImageTable = (props: FormikProps<IAddGame>) => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = props;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "NAME",
        minWidth: 300,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <Box sx={{ ml: 3 }}>
              <Typography fontSize="16px" fontWeight={400} color="#3c3c3c">
                {params?.value}
              </Typography>
              {Boolean(touched) &&
                touched.hasOwnProperty(params.row.id) &&
                Boolean(touched[params.row.id]) &&
                Boolean(errors) &&
                errors.hasOwnProperty(params.row.id) &&
                Boolean(errors[params.row.id]) && (
                  <Typography fontSize="12px" color="red">
                    {Boolean(errors[params.row.id])
                      ? errors[params.row.id].toString()
                      : ""}
                  </Typography>
                )}
            </Box>
          );
        }
      },
      {
        field: "thumbnail",
        headerName: "THUMBNAIL",
        minWidth: 350,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          if (params.value === null) {
            return (
              <Box
                sx={{
                  backgroundColor: "#ffe4cb70",
                  height:
                    params?.api.getRowIndex(params.row.id) === 2
                      ? "40px"
                      : "55px",
                  width:
                    params?.api.getRowIndex(params.row.id) === 0
                      ? "53px"
                      : params?.api.getRowIndex(params.row.id) === 1
                      ? "187px"
                      : params?.api.getRowIndex(params.row.id) === 2
                      ? "40px"
                      : "55px",
                  borderRadius:
                    params?.api.getRowIndex(params.row.id) === 3
                      ? "27.5px"
                      : "7px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <ImageOutlined sx={{ color: "#8C8E8F" }} />
              </Box>
            );
          } else {
            const url = URL.createObjectURL(values[params.row.id]);
            return (
              <img
                src={url}
                style={{
                  height:
                    params?.api.getRowIndex(params.row.id) === 2
                      ? "40px"
                      : "55px",
                  width:
                    params?.api.getRowIndex(params.row.id) === 0
                      ? "53px"
                      : params?.api.getRowIndex(params.row.id) === 1
                      ? "187px"
                      : params?.api.getRowIndex(params.row.id) === 2
                      ? "40px"
                      : "55px",
                  borderRadius:
                    params?.api.getRowIndex(params.row.id) === 3
                      ? "27.5px"
                      : "7px"
                }}
              />
            );
          }
        }
      },
      {
        field: "dimension",
        headerName: "DIMENSION REQ.",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={400}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.value}
          </Typography>
        )
      },
      {
        field: "size",
        headerName: "SIZE (MAX. 1 MB)",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Typography
            fontSize="16px"
            fontWeight={400}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.value}
          </Typography>
        )
      },
      {
        field: "reference",
        headerName: "REFERENCE",
        flex: 1,
        sortable: false,
        renderCell: (params) => <img src={params.value} width="60px" />
      },
      {
        field: "todo",
        headerName: "ACTIONS",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Upload
            accept=".gif, .jpg, .jpeg, .png, .svg"
            onStart={() => setFieldTouched(params.row.id)}
            beforeUpload={(file: RcFile): Promise<void> => {
              setFieldTouched(params.row.id);
              if (file.size <= 1024 * 1024) {
                setFieldValue(params.row.id, file);
                return Promise.resolve();
              } else {
                alert("File size exceeds the maximum allowed (1MB)");
                return Promise.reject(
                  "File size exceeds the maximum allowed (1MB)"
                );
              }
            }}
          >
            <OrangeGradientButton
              title={values[params.row.id] !== null ? "Upload New" : "Upload"}
              style={{ marginLeft: 3, width: "122px" }}
            />
          </Upload>
        )
      }
    ],
    [values, errors, touched]
  );

  const rows = useMemo(
    () => [
      {
        id: "homeScreen",
        name: "Home Screen",
        thumbnail: values.homeScreen,
        dimension: "104x107",
        size: values.homeScreen
          ? `${(values.homeScreen.size / (1024 * 1024)).toFixed(4)}MB`
          : "1 MB",
        reference: images.ref1
      },
      {
        id: "tournamentScreen",
        name: "Tournament Screen",
        thumbnail: values.tournamentScreen,
        dimension: "343x100",
        size: values.tournamentScreen
          ? `${(values.tournamentScreen.size / (1024 * 1024)).toFixed(4)}MB`
          : "1 MB",
        reference: images.ref2
      },
      {
        id: "bootScreen",
        name: "Boot Screen",
        thumbnail: values.bootScreen,
        dimension: "40x40",
        size: values.bootScreen
          ? `${(values.bootScreen.size / (1024 * 1024)).toFixed(4)}MB`
          : "1 MB",
        reference: images.ref3
      },
      {
        id: "winnerScreen",
        name: "Winner Screen",
        thumbnail: values.winnerScreen,
        dimension: "64x64",
        size: values.winnerScreen
          ? `${(values.winnerScreen.size / (1024 * 1024)).toFixed(4)}MB`
          : "1 MB",
        reference: images.ref5
      }
    ],
    [values]
  );

  return (
    <Box
      sx={{
        backgroundColor: colors.backgroundBoxLight,
        p: 3,
        borderRadius: "4px"
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            color={colors.lightGreyIcon}
            fontSize="16px"
            fontWeight={700}
          >
            ASSET DETAILS - IMAGES <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Table
            rows={rows}
            columns={columns}
            headerStyle={{
              backgroundColor: "#FEF7EE",
              color: "#3c3c3c",
              fontSize: "14px",
              textAlign: "left",
              fontWeight: 600,
              cellBackground: "transparent",
              pl: 2
            }}
            disableSearch
            hideFooter
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GameImageTable;
