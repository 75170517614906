import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import ColumChartStack from "../ColumnChartStack";
import { formatNumberToK } from "utils/helpers";

const TournamentAnalysisChart = (props) => {
  const { chartData } = props;
  const type = [
    {
      title: "Existing Users",
      color: "#FF9D39"
    },
    {
      title: "New Users",
      color: "#9F7FFC"
    }
  ];

  const xAxisLabels =
    chartData && chartData?.length > 0
      ? chartData?.map((item) => item?.name)
      : [];

  const data = chartData?.map((item) => ({
    name: item?.name || "",
    y: item?.value || 0,
    color: colors.orange,
    revenue: item?.revenue ? formatNumberToK(item?.revenue, "money") : ""
  }));
  const graphData = [
    {
      name: "Boot Count",
      colorByPoint: true,
      pointWidth: 35,
      data: data
    }
  ];

  const uniqueTournamentWinnersPercentage = 23.4;
  const uniqueBootWinnersPercentage = -13.4;

  const tournament: string = sessionStorage.getItem("tournament") || "";

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          borderRadius: "15px",
          padding: 2,
          m: 1,
          height: "auto"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              {`${tournament} Boot Analysis`}
            </Typography>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              Live Boot Updates
            </Typography>
          </div>
        </div>
        <ColumChartStack
          properties={{
            heigth: 100,
            width: 1100,
            showRevenue: true
          }}
          xAxisLabels={xAxisLabels || []}
          data={graphData}
          partColors={["#FF9D39"]}
        />
      </Grid>
    </Grid>
  );
};

export default TournamentAnalysisChart;
