import * as React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { colors } from "../../styles/colors";
import dayjs from "dayjs";

interface IDynamicDateRangePickerProps {
  startDate: Date | null | any;
  setStartDate: any;
  endDate: Date | null | any;
  setEndDate: any;
}

const getDateInFormat = (date, format) => {
  return dayjs(date).format(format);
};

function DynamicDateRangePicker(props: IDynamicDateRangePickerProps) {
  const { startDate, setStartDate, endDate, setEndDate } = props;
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  return (
    <Box
      display="flex"
      sx={{
        backgroundColor: "#F7F7F7",
        py: 0.3,
        px: 2,
        borderRadius: "4px",
        alignItems: "center"
      }}
    >
      <Typography fontSize="14px" color={colors.fontLight} sx={{ mt: "6px" }}>
        {`${getDateInFormat(startDate, "DD MMM YYYY")} - ${getDateInFormat(
          endDate,
          "DD MMM YYYY"
        )}`}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          open={open1}
          onClose={() => setOpen1(false)}
          value={startDate}
          onChange={(value) => {
            setStartDate(value);
            setEndDate(dayjs(value).add(6, "day"));
          }}
          PopperProps={{ anchorEl: anchorEl1 }}
          renderInput={({
            ref,
            inputProps,
            disabled,
            onChange,
            value,
            ...other
          }) => (
            <div ref={ref}>
              <input
                style={{ display: "none" }}
                // value={value}
                onChange={onChange}
                disabled={disabled}
                {...inputProps}
              />
              <IconButton
                size="small"
                onClick={(e) => {
                  handleClick1(e);
                  setOpen1(true);
                }}
              >
                <CalendarTodayOutlined
                  fontSize="small"
                  sx={{ color: colors.fontLight }}
                />
              </IconButton>
            </div>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default DynamicDateRangePicker;
