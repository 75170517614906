import React from 'react';
import { Helmet } from 'react-helmet-async';
import MiniDrawer from 'app/components/Drawer';
import AddRewards from 'app/components/RewardsAddEdit';

const RewardsPage = () => {
  const pageHeader = {
    title: 'Rewards Details',
    breadCrumb: 'Rewards/ Add rewards',
  };
  return (
    <>
      <Helmet>
        <title>Rewards</title>
      </Helmet>
      <MiniDrawer
        content={<AddRewards />}
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default RewardsPage;
