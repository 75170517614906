import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography
} from "@mui/material";
import images from "assets/images/images";
import { colors } from "../../../styles/colors";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { acceptImageTypes } from "utils/constants";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FilePicker } from "react-file-picker";

const AssetTable = (props) => {
  const { tournamentType, setFieldValue, values } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [thumbnail, setThumbnail] = React.useState<any>(null);
  const [bootCarousel1, setbootCarousel1] = React.useState<any>(null);
  const [bootCarousel2, setbootCarousel2] = React.useState<any>(null);
  const [bootCarousel3, setbootCarousel3] = React.useState<any>(null);
  var rows: any = [];

  if (tournamentType === "Blitz") {
    rows.push(
      {
        id: "bootCarousel1",
        name: "Boot Carousel 1",
        thumbnail: bootCarousel1
          ? bootCarousel1
          : values?.bootCarousel1
          ? values?.bootCarousel1
          : values?.bootCarousel1ImageUrl
          ? values?.bootCarousel1ImageUrl
          : images.tn6,
        dimensionReq: "343x168",
        actucalDimension: "125x55",
        size: "2 MB",
        reference: images.ref6
      },
      {
        id: "bootCarousel2",
        name: "Boot Carousel 2",
        thumbnail: bootCarousel2
          ? bootCarousel2
          : values?.bootCarousel2
          ? values?.bootCarousel2
          : values?.bootCarousel2ImageUrl
          ? values?.bootCarousel2ImageUrl
          : images.tn6,
        dimensionReq: "343x168",
        actucalDimension: "125x55",
        size: "2 MB",
        reference: images.ref6
      },
      {
        id: "bootCarousel3",
        name: "Boot Carousel 3",
        thumbnail: bootCarousel3
          ? bootCarousel3
          : values?.bootCarousel3
          ? values?.bootCarousel3
          : values?.bootCarousel3ImageUrl
          ? values?.bootCarousel3ImageUrl
          : images.tn6,
        dimensionReq: "343x168",
        actucalDimension: "125x55",
        size: "2 MB",
        reference: images.ref6
      }
    );
  } else {
    rows.push({
      id: "tournamentCard",
      name: "Tournament Card",
      thumbnail: thumbnail
        ? thumbnail
        : values?.thumbnailImage
        ? values?.thumbnailImage
        : values?.thumbnailImageUrl
        ? values?.thumbnailImageUrl
        : images.tn6,
      dimensionReq: "800 x 600",
      actucalDimension: "125x55",
      size: "2 MB",
      reference: images.ref6
    });
  }

  const handleFileUpload = (file, id): any => {
    if (id === "tournamentCard") {
      setFieldValue("thumbnailImage", file);
      const temp = URL.createObjectURL(file);
      setThumbnail(temp);
    } else if (id === "bootCarousel1") {
      setFieldValue("bootCarousel1", file);
      const temp = URL.createObjectURL(file);
      setbootCarousel1(temp);
    } else if (id === "bootCarousel2") {
      setFieldValue("bootCarousel2", file);
      const temp = URL.createObjectURL(file);
      setbootCarousel2(temp);
    } else if (id === "bootCarousel3") {
      setFieldValue("bootCarousel3", file);
      const temp = URL.createObjectURL(file);
      setbootCarousel3(temp);
    }
  };

  React.useEffect(() => {
    if (values.thumbnailImage) {
      const temp = URL.createObjectURL(values.thumbnailImage);
      setThumbnail(temp);
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: colors.backgroundBoxLight,
        borderRadius: "8px",
        p: "30px",
        width: "100%"
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "17px",
          letterSpacing: "0.15em",
          textAlign: "left",
          mb: 2,
          display: "inline-block"
        }}
      >
        ASSET DETAILS - IMAGES
      </Typography>
      <Typography
        sx={{
          color: colors.redAsterisk,
          display: "inline-block"
        }}
      >
        *
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: colors.FieldBackgroundLight,
          borderRadius: "4px"
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: colors.TableHead }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell sx={{ mx: 2 }}>Thumbnail</TableCell>
              <TableCell>Dimension REQ</TableCell>
              <TableCell>SIZE (Max 2 MB)</TableCell>
              <TableCell>REFERENCE</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <img
                    src={item.thumbnail}
                    alt="Tournament thumbnail"
                    width={
                      item.actucalDimension
                        .replace(/\s/g, "")
                        .split("x")
                        .map(Number)[0]
                    }
                    height={
                      item.actucalDimension
                        .replace(/\s/g, "")
                        .split("x")
                        .map(Number)[1]
                    }
                  />
                </TableCell>
                <TableCell>{item.dimensionReq}</TableCell>
                <TableCell>{item.size}</TableCell>
                <TableCell>
                  <img
                    src={item.reference}
                    alt="Game thumbnail"
                    width="50"
                    height="50"
                  />
                </TableCell>
                <TableCell>
                  <FilePicker
                    extensions={["pdf", "jpg", "jpeg", "png"]}
                    onChange={(file) => {
                      handleFileUpload(file, item?.id);
                    }}
                    onError={(errMsg) => alert(errMsg)}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        width: "90%",
                        background:
                          "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                        borderRadius: "36px",
                        height: "35px",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#FFF9F2"
                      }}
                    >
                      {thumbnail ||
                      values?.thumbnailImage ||
                      values?.thumbnailImageUrl
                        ? "Upload New"
                        : "Upload"}
                    </Button>
                  </FilePicker>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AssetTable;
