import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Chip,
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  MenuItem,
  Divider,
  Popover
} from "@mui/material";
import Statistics from "./statistics";
import UserGrowthRate from "./growthRate";
import UserGameAnalysis from "./gameAnalysis";
import Table from "../Table";
import { colors } from "styles/colors";
import images from "assets/images/images";
import {
  GET_REGISTERED_USERS,
  GET_USERS_GAME_DASHBOARD_DATA,
  GET_USERS_GROWTH_DASHBOARD_DATA,
  GET_USER_DASHBOARD_DATA
} from "app/graphql/users";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import DateFilter from "../DateFilter";
import moment from "moment";
import { getDateInFormat } from "utils/helpers";

const Users = () => {
  const history = useHistory();

  const todayDate = new Date();
  const oneWeekAgo = new Date(todayDate);
  oneWeekAgo.setDate(todayDate.getDate() - 30);
  const [statisticsData, setStatisticsData] = useState<any>([]);
  const [usersData, setUsersData] = useState<any>();
  const [startDateForStatistics, setStartDateForStatistics] =
    useState(oneWeekAgo);
  const [endDateForStatistics, setEndDateForStatistics] = useState(todayDate);
  const [startDateForTable, setStartDateForTable] = useState(oneWeekAgo);
  const [endDateForTable, setEndDateForTable] = useState(todayDate);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchText, setSearchText] = React.useState("");
  const [searchTextTemp, setSearchTextTemp] = React.useState("");

  const [startDateForGrowthData, setStartDateForGrowthData] =
    useState(oneWeekAgo);
  const [endDateForGrowthData, setEndDateForGrowthData] = useState(todayDate);
  const [usersGrowthData, setUsersGrowthData] = useState<any>();

  const [startDateForGameData, setStartDateForGameData] = useState(oneWeekAgo);
  const [endDateForGameData, setEndDateForGameData] = useState(todayDate);
  const [usersGameData, setUsersGameData] = useState<any>();

  const fireRequestSearchAPICall = (searchValue) => {
    setSearchText(searchValue);
  };
  const [timer, setTimer] = React.useState<any>(null);

  const requestSearch = (searchValue: string) => {
    setSearchTextTemp(searchValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fireRequestSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const { data: userDashboardData } = useQuery(GET_USER_DASHBOARD_DATA, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (userDashboardData) {
      console.log(userDashboardData);
      setStatisticsData(
        userDashboardData?.userDashboard?.overallUserStatistics
      );
    }
  }, [userDashboardData]);

  const { data: userGrowthDashboardData } = useQuery(
    GET_USERS_GROWTH_DASHBOARD_DATA,
    {
      variables: {
        from: startDateForGrowthData,
        to: endDateForGrowthData
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  React.useEffect(() => {
    if (userGrowthDashboardData) {
      console.log(userGrowthDashboardData);
      setUsersGrowthData(userGrowthDashboardData?.userGrowthDashboard || {});
    }
  }, [userGrowthDashboardData]);

  const { data: userGameDashboardData } = useQuery(
    GET_USERS_GAME_DASHBOARD_DATA,
    {
      variables: {
        from: startDateForGameData,
        to: endDateForGameData
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    }
  );

  React.useEffect(() => {
    if (userGameDashboardData) {
      console.log(userGameDashboardData);
      setUsersGameData(
        userGameDashboardData?.userGameDashboard?.gameDashboardStatistics || {}
      );
    }
  }, [userGameDashboardData]);

  const { data: registeredUsersData } = useQuery(GET_REGISTERED_USERS, {
    variables: {
      page: page,
      limit: pageSize,
      from: startDateForTable,
      to: endDateForTable,
      search: searchText
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (registeredUsersData) {
      console.log(registeredUsersData);
      sessionStorage.setItem(
        "totalUsers",
        registeredUsersData?.registeredUsers?.count
      );
      setUsersData(registeredUsersData?.registeredUsers);
    }
  }, [registeredUsersData]);

  const requestMapping = {
    Active: {
      name: "Active",
      fill: "#CBF0EE",
      text: "#00CCCC"
    },
    Inactive: {
      name: "Inactive",
      fill: "#FDF2D6",
      text: "#E2B33A"
    },
    Suspended: {
      name: "Suspended",
      fill: "#FFF2F1",
      text: "#F95F53"
    },
    Expired: {
      name: "Expired",
      fill: "#E9E8E8",
      text: "#8A8A8A"
    }
  };

  const columns: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 1
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            sx={{ cursor: "pointer" }}
            onClick={() => history.push(`/users/${params.row.id}`)}
          >
            <Typography fontSize={"14px"}>
              {params.row.nickname || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "joiningDate",
        headerName: "DATE JOINED",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          const DateAndTime: any = params?.row?.joinedOn
            ? getDateInFormat(
                parseInt(params?.row?.joinedOn),
                "DD/MM/YYYY",
                "changeFormat"
              )
            : null;
          console.log(DateAndTime);
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "bootPlayed",
        headerName: "BOOT PLAYED",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.bootTournaments}
            </Typography>
          </Box>
        )
      },
      {
        field: "tournPlayed",
        headerName: "TOURN. PLAYED",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {params.row.leaderboardTournaments || 0}
            </Typography>
          </Box>
        )
      },
      {
        field: "bootDeposit",
        headerName: "BOOT DEPOSIT",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.depositsBoot || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "tournDeposit",
        headerName: "TOURN. DEPOSIT",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {`₹ ${params.row.depositsLeaderboard || 0}`}
            </Typography>
          </Box>
        )
      },
      {
        field: "bootWinnings",
        headerName: "BOOT WINIINGS",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.winningsBoot || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "tournWinnings",
        headerName: "TOURN. WINIINGS",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>
              {`₹ ${params.row.winningsLeaderboard || 0}`}
            </Typography>
          </Box>
        )
      },
      {
        field: "revenue",
        headerName: "REVENUE",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.revenue || 0
            }`}</Typography>
          </Box>
        )
      },
      {
        field: "payOut",
        headerName: "WINNINGS",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{`₹ ${
              params.row.payout || 0
            }`}</Typography>
          </Box>
        )
      },
      // {
      //   field: "tickets",
      //   headerName: "TICKETS",
      //   // minWidth: 200,
      //   flex: 1
      // },
      {
        field: "status",
        headerName: "STATUS",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          return (
            <Chip
              label={
                <Typography fontSize={"11px"} fontWeight={600}>
                  {requestMapping[params.value].name}
                </Typography>
              }
              size="small"
              sx={{
                width: "90px",
                height: "26px",
                borderRadius: "8px",
                color: requestMapping[params.value].text,
                backgroundColor: requestMapping[params.value].fill
              }}
              variant={"filled"}
            />
          );
        }
      }
    ],
    []
  );
  const [selectedRequests, setSelectedRequests] = React.useState<number[]>([]);
  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

  const handleRowOver = (e) => {
    const rowId = Number(e.currentTarget.dataset.id);
    setHoveredRow(rowId);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Statistics data={statisticsData} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <UserGrowthRate
          // data={statisticsData?.userTrends || []}
          data={usersGrowthData?.growthDashboardStatistics?.userTrends || []}
          registrationsPerMonth={usersGrowthData?.registrationsPerMonth}
          registrationsPerDay={usersGrowthData?.registrationsPerDay}
          startDate={startDateForGrowthData}
          endDate={endDateForGrowthData}
          setStartDate={setStartDateForGrowthData}
          setEndDate={setEndDateForGrowthData}
        />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <UserGameAnalysis
          // averageNumberOfGames={statisticsData?.averageNumberOfGames || ""}
          // data={statisticsData?.gamesHistogram || []}
          data={usersGameData || []}
          startDate={startDateForStatistics}
          endDate={endDateForStatistics}
          setStartDate={setStartDateForGameData}
          setEndDate={setEndDateForGameData}
        />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.backgroundLight,
          m: 1.5,
          p: 3,
          borderRadius: "15px"
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography color="#3C3C3C" fontSize="13px" fontWeight={400} mb={1}>
            {" "}
            {/* Updated 23 seconds ago */}
          </Typography>
        </Box>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Typography
              color="#3C3C3C"
              fontSize="16px"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              User details
            </Typography>
            <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
              {`Displaying details of ${usersData?.count || 0} users`}
            </Typography>
          </div>

          <DateFilter
            startDate={startDateForTable}
            endDate={endDateForTable}
            disableFutureForStart={true}
            disableFutureForEnd={true}
            onChangeStartDate={(newDate) => setStartDateForTable(newDate)}
            onChangeEndDate={(newDate) => setEndDateForTable(newDate)}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          {/* <span style={{ margin: 7 }}>
            <IconButton
              onClick={(event) => {
                handleClick(event);
              }}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <img src={images.Filter_Rectangle} alt="" />
              <img
                src={images.DownArrow}
                alt=""
                style={{ marginLeft: "5px" }}
              />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 10,
                horizontal: -50
              }}
              sx={{ maxHeight: "none" }}
            >
              <MenuItem
                sx={{
                  color: colors.lightGreyIcon
                }}
              >
                <Typography fontSize={16} color={colors.fontPrimary}>
                  Option 1
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                sx={{
                  color: colors.lightGreyIcon,
                  mt: -0.5,
                  mb: 1
                }}
              >
                <Typography fontSize={16} color={colors.fontPrimary}>
                  Option 2
                </Typography>
              </MenuItem>
            </Popover>
          </span> */}
          <span
            style={{
              margin: 15,
              cursor: "pointer"
              // opacity: selectedRequests?.length > 0 ? 1 : 0.5
            }}
          >
            <img
              src={images.SwitchView}
              alt=""
              onClick={() => {
                history.push("/users/analytics");
              }}
            />
          </span>
          {/* <span style={{ margin: 15 }}>
            <img src={images.ExportIcon} alt="" />
          </span> */}
        </div>
        <Table
          rows={usersData?.users || []}
          columns={columns}
          paginationMode="server"
          page={page}
          onPageChange={(page) => setPage(page)}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
            setPage(0);
          }}
          rowCount={usersData?.count || 10}
          loading={false}
          pagination={true}
          // checkboxSelection
          selectedRows={selectedRequests}
          setSelectedRows={setSelectedRequests}
          onRowHover={handleRowOver}
          onRowLeave={handleRowLeave}
          searchText={searchTextTemp}
          requestSearch={requestSearch}
          disableSearch={false}
          placeholderTextForSearch="Search by name"
        />
      </Grid>
    </Grid>
  );
};

export default Users;
