import { gql } from "@apollo/client";

export const ADD_GAME = gql`
  mutation createGame(
    $input: createGameInput!
    $gamePackage: Upload
    $homeScreen: Upload!
    $tournamentScreen: Upload
    $bootScreen: Upload
    $winnerScreen: Upload
    $gameTutorial: Upload
    $gameTournamentScreen: Upload
  ) {
    createGame(
      input: $input
      gamePackage: $gamePackage
      homeScreen: $homeScreen
      tournamentScreen: $tournamentScreen
      bootScreen: $bootScreen
      winnerScreen: $winnerScreen
      gameTutorial: $gameTutorial
      gameTournamentScreen: $gameTournamentScreen
    ) {
      status
      message
    }
  }
`;

export const UPDATE_GAME = gql`
  mutation updateGame(
    $input: updateGameInput!
    $gamePackage: Upload
    $homeScreen: Upload
    $tournamentScreen: Upload
    $bootScreen: Upload
    $winnerScreen: Upload
    $gameTutorial: Upload
    $gameTournamentScreen: Upload
  ) {
    updateGame(
      input: $input
      gamePackage: $gamePackage
      homeScreen: $homeScreen
      tournamentScreen: $tournamentScreen
      bootScreen: $bootScreen
      winnerScreen: $winnerScreen
      gameTutorial: $gameTutorial
      gameTournamentScreen: $gameTournamentScreen
    ) {
      status
      message
    }
  }
`;

export const DELETE_GAME = gql`
  mutation deleteGame($id: Int!) {
    deleteGame(input: $id) {
      status
      message
    }
  }
`;

export const GET_GAME_STATISTICS = gql`
  query gameDashboard($from: String!, $to: String!) {
    gameDashboard(from: $from, to: $to) {
      lastUpdated
      games
      totalActiveGames
      mostClickedGame
      averageMostClickedGame
      leastClickedGame
      averageLeastClickedGame
      mostPaidoutGame
      averageMostPaidoutGame
      mostCommissionGame
      averageMostCommissionGame
      gamesHistogram {
        name
        value
      }
      status
    }
  }
`;

export const GET_ALL_GAMES = gql`
  query getAllGames($page: Int, $limit: Int, $search: String) {
    allGames(page: $page, limit: $limit, search: $search) {
      status
      message
      games {
        id
        title
        description
        details
        status
        tutorialUrl
        bundleUrl
        thumbnailImageUrl
        bannerImageUrl
        videoUrl
        gameRevenue
        leaderboardPayout
        bootPayout
        hostedBoots
        hostedLeaderboardTournaments
        activeLeaderboardTournaments
        createdAt
      }
    }
  }
`;

export const GET_ALL_GAMES_FOR_DROPDOWN = gql`
  query getAllGames(
    $page: Int
    $limit: Int
    $search: String
    $status: [String]
  ) {
    allGames(page: $page, limit: $limit, search: $search, status: $status) {
      status
      message
      games {
        id
        title
        description
        status
        bootCount
      }
    }
  }
`;

export const GET_GAME_DETAILS = gql`
  query gameDashboardDetails($id: Int!, $filter: String!) {
    gameDashboardDetails(id: $id, filter: $filter) {
      lastUpdated
      owner
      gameClicksThisMonth
      averageGameClicksThisMonth
      uniqueUsersThisMonth
      uniqueLeaderboardWinners
      uniqueBootWinners
      payoutsTrend {
        name
        data {
          name
          value
        }
      }
      game {
        id
        title
        description
        details {
          bootScreenUrl
          winnerScreenUrl
          tournamentScreenUrl
        }
        status
        tutorialUrl
        bundleUrl
        thumbnailImageUrl
        bannerImageUrl
        videoUrl
        gameCategory {
          id
          category
        }
        orientation
        createdAt
      }
      status
    }
  }
`;

export const GET_GAME_TYPES = gql`
  query gameCategories {
    gameCategories {
      status
      message
      categories {
        id
        category
      }
    }
  }
`;

export const GAME_LEADERBOARD_TOURNAMENTS = gql`
  query singleGameLeaderboardDetails(
    $gameId: Int!
    $page: Int!
    $limit: Int!
    $from: String!
    $to: String!
    $search: String
  ) {
    singleGameLeaderboardDetails(
      gameId: $gameId
      page: $page
      limit: $limit
      from: $from
      to: $to
      search: $search
    ) {
      status
      total
      lastUpdated
      tournaments {
        id
        title
        start
        end
        prizePool
        entry
        slots
        filled
        revenue
        winners
        status
      }
    }
  }
`;

export const GAME_BOOT_TOURNAMENTS = gql`
  query singleGameBootDetails(
    $gameId: Int!
    $page: Int!
    $limit: Int!
    $from: String!
    $to: String!
    $search: String
  ) {
    singleGameBootDetails(
      gameId: $gameId
      page: $page
      limit: $limit
      from: $from
      to: $to
      search: $search
    ) {
      status
      total
      lastUpdated
      tournaments {
        id
        start
        end
        pool
        entry
        players
        commission
        winners
        winningAmount
        status
        tournamentId
      }
    }
  }
`;

export const GET_GAME_REVENUE_DETAILS = gql`
  query gameRevenueDetails($id: Int!, $filter: String!) {
    gameRevenueDetails(id: $id, filter: $filter) {
      bootTournamentIncome
      leaderboardTournamentIncome
      revenueTrend {
        name
        data {
          name
          value
        }
      }
      status
    }
  }
`;

export const GET_GAME_HOSTED_TOURNAMENT_DETAILS = gql`
  query gameHostedTournamentDetails($id: Int!, $filter: String!) {
    gameHostedTournamentDetails(id: $id, filter: $filter) {
      totalTournaments
      hostingTrend {
        name
        value
      }
      status
    }
  }
`;
