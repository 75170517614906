import jwt_decode from "jwt-decode";

export const getRedirectPathAfterLogin = (token: string) => {
  const decoded: any = jwt_decode(token);
  let rolePermissions: any = null;
  if (Boolean(decoded)) {
    if (Boolean(decoded.rolePermissions)) {
      if (Boolean(JSON.parse(decoded.rolePermissions))) {
        rolePermissions = JSON.parse(decoded.rolePermissions);
      }
    }
  }
  if (rolePermissions !== null) {
    if (
      rolePermissions.DASHBOARD.DASHBOARD_OVERVIEW.VIEW ||
      rolePermissions.DASHBOARD.DASHBOARD_OVERVIEW.VIEW_AND_EDIT
    ) {
      return "/dashboard";
    }
    if (
      rolePermissions.GAMES.GAMES_DASHBOARD.VIEW ||
      rolePermissions.GAMES.EDIT_GAMES.VIEW ||
      rolePermissions.GAMES.GAMES_DASHBOARD.VIEW_AND_EDIT ||
      rolePermissions.GAMES.EDIT_GAMES.VIEW_AND_EDIT
    ) {
      return "/games";
    }
    if (
      rolePermissions.TOURNAMENTS.TOURNAMENT_ANALYTICS.VIEW ||
      rolePermissions.TOURNAMENTS.TOURNAMENT_ANALYTICS.VIEW_AND_EDIT
    ) {
      return "/tournaments-analytics";
    }
    if (
      rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT.VIEW ||
      rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD.VIEW ||
      rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT.VIEW_AND_EDIT ||
      rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD
        .VIEW_AND_EDIT
    ) {
      return "/tournaments-configuration";
    }
    if (
      rolePermissions.USERS.USERS_ANALYTICS.VIEW ||
      rolePermissions.USERS.USERS_ANALYTICS.VIEW_AND_EDIT
    ) {
      return "/users";
    }
    if (
      rolePermissions.REWARDS.REWARDS_ANALYTICS.VIEW ||
      rolePermissions.REWARDS.REFERRALS_ANALYTICS.VIEW ||
      rolePermissions.REWARDS.REWARDS_ANALYTICS.VIEW_AND_EDIT ||
      rolePermissions.REWARDS.REFERRALS_ANALYTICS.VIEW_AND_EDIT
    ) {
      return "/rewards";
    }
    if (
      rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
      rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW ||
      rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW_AND_EDIT ||
      rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW_AND_EDIT
    ) {
      return "/rewards/add";
    }
    if (
      rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW ||
      rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW ||
      rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW_AND_EDIT ||
      rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW_AND_EDIT
    ) {
      return "/user-management";
    }
  }
  return "/dashboard";
};
