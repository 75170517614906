import React from "react";
import { Button, Grid, Typography, Chip } from "@mui/material";
import images from "assets/images/images";
import { getDateInFormat } from "utils/helpers";
import { colors } from "styles/colors";

const Introduction = (props) => {
  const { userDetails } = props;

  const data = [
    {
      title: "Games Played",
      count: userDetails?.gamesPlayed || 0
    },
    {
      title: "Tournaments",
      count: userDetails?.tournamentsPlayed || 0
    },
    {
      title: "Total Deposit",
      count: `₹ ${userDetails?.deposits || 0}`
    },
    {
      title: "Total Cash won",
      count: `₹ ${userDetails?.winnings || 0}`
    },
    {
      title: "Total Withdrawals",
      count: `₹ ${userDetails?.withdrawals || 0}`
    },
    {
      title: "Net Revenue",
      count: `₹ ${userDetails?.revenue || 0}`
    }
  ];

  const dateOfJoining: any = getDateInFormat(
    parseInt(userDetails?.dateOfJoining),
    "D MMM YYYY",
    "changeFormat"
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ borderTop: "0.5px solid rgba(168, 168, 168, 0.5)", py: 0.5 }}
      ></Grid>
      <Grid
        xs={12}
        sm={4}
        md={4}
        lg={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            height: 135,
            width: 135,
            borderRadius: 75,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors.FieldBackgroundLight
          }}
        >
          {userDetails?.profilePic ? (
            <img
              src={userDetails?.profilePic}
              style={{ width: 135, height: 135, borderRadius: 50 }}
            />
          ) : (
            <Typography fontSize={13} fontWeight={400} color={colors.lightGray}>
              No Profile Photo
            </Typography>
          )}
        </div>
        <div style={{ marginTop: "8px" }}>
          <Chip
            label={
              <Typography fontSize={"11px"} fontWeight={600}>
                {userDetails?.status || "-"}
              </Typography>
            }
            size="small"
            sx={{
              width: "90px",
              height: "26px",
              borderRadius: "8px",
              color: "#00CCCC",
              backgroundColor: "#CBF0EE"
            }}
          />
        </div>
      </Grid>
      <Grid xs={12} sm={8} md={8} lg={10}>
        <Grid container xs={12} sm={12} md={12} lg={12} sx={{ py: 3 }}>
          <Grid xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
            <Typography
              color="#3C3C3C"
              fontSize="24px"
              fontWeight={600}
              sx={{ px: 0.5, py: 0.5 }}
            >
              {userDetails?.nickname || "-"}
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={64} lg={2}>
            <Typography
              color="#8C8E8F"
              fontSize="13px"
              fontWeight={400}
              sx={{ px: 0.5, py: 0.5 }}
            >
              User id:{" "}
              <span style={{ color: "#3C3C3C", padding: 1, fontWeight: 800 }}>
                {userDetails?.id || "-"}
              </span>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={3}>
            <Typography
              color="#8C8E8F"
              fontSize="13px"
              fontWeight={400}
              sx={{ px: 0.5, py: 0.5 }}
            >
              Mobile number:{" "}
              <span style={{ color: "#3C3C3C", padding: 1, fontWeight: 800 }}>
                {userDetails?.mobileNo || "-"}
              </span>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={10} lg={3}>
            <Typography
              color="#8C8E8F"
              fontSize="13px"
              fontWeight={400}
              sx={{ px: 0.5, py: 0.5 }}
            >
              Email:{" "}
              <span style={{ color: "#3C3C3C", padding: 1, fontWeight: 800 }}>
                {userDetails?.email || "-"}
              </span>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={3}>
            <Typography
              color="#8C8E8F"
              fontSize="13px"
              fontWeight={400}
              sx={{ px: 0.5, py: 0.5 }}
            >
              Date joined:{" "}
              <span style={{ color: "#3C3C3C", padding: 1, fontWeight: 800 }}>
                {dateOfJoining || "-"}
              </span>
            </Typography>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid
            xs={12}
            sx={{ borderTop: "0.5px solid rgba(168, 168, 168, 0.5)", my: 3 }}
          ></Grid>
          <Grid xs={12} sm={12} md={12} sx={{ display: "flex" }}>
            {data?.map((item) => {
              return (
                <Grid
                  xs={6}
                  sm={4}
                  lg={2}
                  md={3}
                  sx={{ py: 0.5, padding: 0.5, display: "flex" }}
                >
                  <div style={{ width: "100%" }}>
                    <Typography
                      color="#8C8E8F"
                      fontSize="13px"
                      fontWeight={400}
                      sx={{ px: 0.5, py: 0.5 }}
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      color="#3C3C3C"
                      fontSize="24px"
                      fontWeight={600}
                      sx={{ px: 0.5, py: 0.5 }}
                    >
                      {item?.count}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Introduction;
