import React from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Autocomplete,
  TextField
} from "@mui/material";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import DonutPieChart from "../DonutPieChart";
import ColumChartStack from "../ColumnChartStack";

const GamesStatisticsChart = (props) => {
  const { chartData } = props;

  const gamesHistogramColors = {
    0: "#517DD1",
    1: "#FBCB20",
    2: "#9EDA89",
    3: "#C487E1",
    4: "#62F9CC",
    5: "#EAE459",
    6: "#F39679",
    7: "#B4C77F",
    8: "#5E6BAD",
    9: "#ED38F1"
  };

  const tempGames = chartData?.map((item, index) => ({
    label: item.name
  }));

  const gamesArr = tempGames ? [...tempGames] : [];

  const [selectedGames, setSelectedGames] = React.useState(gamesArr);
  const [selectedGameNamesForChart, setSelectedGameNamesForChart] =
    React.useState<any>([]);
  const [selectedGameNamesForDropDown, setSelectedGameNamesForDropDown] =
    React.useState<any>([]);
  const [xAxisLabels, setXAxisLabels] = React.useState<any>([]);
  const [graphData, setGraphData] = React.useState<any>([]);

  React.useEffect(() => {
    const data = selectedGameNamesForChart?.map((item1, index) => {
      console.log(gamesHistogramColors[index]);
      const temp = chartData?.find((item2) => item2?.name === item1);
      return {
        name: temp?.name || "",
        y: temp?.value || "",
        color: gamesHistogramColors[index]
      };
    });
    console.log(data);
    const xAxisLabelsTemp = data?.map((item) => item.name);
    setXAxisLabels(xAxisLabelsTemp);
    setGraphData({
      name: "Count",
      colorByPoint: true,
      pointWidth: 40,
      data: data
    });
  }, [selectedGameNamesForChart, chartData]);

  React.useEffect(() => {
    const gameNames = chartData?.map((item) => item.name);

    setSelectedGameNamesForChart(gameNames);
  }, [chartData]);

  const GamesArr = [
    {
      Id: 1,
      Name: "All games"
    },
    {
      Id: 2,
      Name: "Knife hit"
    },
    {
      Id: 3,
      Name: "Est eros"
    },
    {
      Id: 4,
      Name: "Pulvinar urna"
    },
    {
      Id: 1,
      Name: "Golf"
    }
  ];

  const handleGamesSelection = (e, list) => {
    let arr = list?.map((item) => item.label);
    setSelectedGameNamesForChart(arr);
    setSelectedGameNamesForDropDown(arr);
    console.log(list);
    setSelectedGames(list);
    if (list?.length === 0) {
      // const tempGames = chartData?.map((item, index) => ({
      //   label: item.name
      // }));

      // const gamesArr = tempGames ? [...tempGames] : [];
      // setSelectedGames(gamesArr);
      const gameNames = chartData?.map((item) => item.name);

      setSelectedGameNamesForChart(gameNames);
    }
  };

  const filterSelectedOptions = (options, { inputValue }) => {
    const filteredOptions = options.filter(
      (option) => !selectedGameNamesForDropDown.includes(option.label)
    );
    return filteredOptions;
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.backgroundLight,
        p: 3,
        borderRadius: "4px"
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            fontSize="18px"
            fontWeight={600}
            color={colors.lightGreyIcon}
          >
            Most played game statistics
          </Typography>
          <Typography
            fontSize="14px"
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Game statistics
          </Typography>
        </Box>
        <Autocomplete
          disableClearable={true}
          disableCloseOnSelect={false}
          options={gamesArr || []}
          getOptionLabel={(option) => option?.label}
          value={selectedGames}
          multiple={true}
          // filterSelectedOptions={true}
          filterOptions={filterSelectedOptions}
          onChange={(e, list) => {
            handleGamesSelection(e, list);
          }}
          sx={{ minWidth: 150 }}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                selectedGameNamesForDropDown?.length > 0 ? "" : "All Games"
              }
            />
          )}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <ColumChartStack
          properties={{
            heigth: 100,
            width: 1100
          }}
          xAxisLabels={xAxisLabels || []}
          yAxis={{
            title: "Count"
          }}
          data={graphData}
          partColors={[
            "#517DD1",
            "#FBCB20",
            "#9EDA89",
            "#C487E1",
            "#62F9CC",
            "#EAE459",
            "#F39679",
            "#B4C77F",
            "#5E6BAD",
            "#ED38F1",
            "#98E6A9"
          ]}
        />
      </Box>
    </Box>
  );
};

export default GamesStatisticsChart;
