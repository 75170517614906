import React, { SetStateAction, useEffect, useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import {
  DeleteOutline,
  EditOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import OrangeGradientButton from "../OrangeGradientButton";
import Table from "../Table";
import AddEditNewRole from "./AddEditNewRole";
import { useQuery } from "@apollo/client";
import { GET_ALL_ROLES, GET_CONFIG_DATA } from "app/graphql/userManagement";
import images from "assets/images/images";
import { getJwtTokenDetails } from "utils/helpers";

interface IRolesProps {
  setSuccessRoleModal: React.Dispatch<SetStateAction<boolean>>;
  addEditRole: boolean;
  setAddEditRole: any;
}

const Roles = (props: IRolesProps) => {
  const { setSuccessRoleModal, addEditRole, setAddEditRole } = props;

  const { rolePermissions } = getJwtTokenDetails();

  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [configDetails, setConfigDetails] = React.useState([]);

  const {
    data: allRolesData,
    loading: allRolesDataLoading,
    refetch: refetchAllRolesData,
  } = useQuery(GET_ALL_ROLES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      allRolesData &&
      allRolesData?.allRoles &&
      allRolesData?.allRoles?.status
    ) {
      const data = allRolesData?.allRoles?.roles;
      setRoles(data || []);
    }
  }, [allRolesData]);

  const {
    data: configData,
    loading: configDataLoading,
    refetch: refetchConfigData,
  } = useQuery(GET_CONFIG_DATA, {
    variables: {
      key: "ROLES",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      configData &&
      configData?.getConfigDetails &&
      configData?.getConfigDetails?.status
    ) {
      const temp = configData?.getConfigDetails?.config;

      // const data = temp?.value ? JSON.parse(temp?.value) : ""
      let data;
      try {
        data = temp?.value ? JSON.parse(temp?.value) : "";
      } catch (error) {
        console.error("An error occurred while parsing JSON:", error);
        alert(error);
        data = "";
      }
      setConfigDetails(data || {});
    }
  }, [configData]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "role",
        headerName: "ROLE",
        flex: 0.5,
        sortable: false,
        // headerAlign: "center",
        // align: "center",
        renderCell: (params) => (
          <Typography
            fontSize="14px"
            fontWeight={400}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.row?.role}
          </Typography>
        ),
      },
      {
        field: "users",
        headerName: "USERS",
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Typography
            fontSize="14px"
            fontWeight={400}
            color="#3c3c3c"
            sx={{ ml: 3 }}
          >
            {params?.row?.users}
          </Typography>
        ),
      },
      // {
      //   field: "permissions",
      //   headerName: "PERMISSIONS",
      //   flex: 1,
      //   sortable: false,
      //   renderCell: (params) => (
      //     <Typography
      //       fontSize="14px"
      //       fontWeight={400}
      //       color="#3c3c3c"
      //       sx={{ ml: 3 }}
      //     >
      //       {params?.value}
      //     </Typography>
      //   )
      // },
      {
        field: "todo",
        headerName: "ACTIONS",
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box display="flex" sx={{ ml: 3 }}>
            <IconButton
              size="small"
              onClick={() => {
                setAddEditRole(true);
                setSelectedRowId(params.row.id);
              }}
            >
              {rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT ? (
                <img src={images.EditIcon} alt="" />
              ) : (
                <VisibilityOutlined fontSize="small" />
              )}
            </IconButton>

            {/* <IconButton size="small" onClick={() => {}}>
              <DeleteOutline fontSize="small" sx={{ color: "#3c3c3c" }} />
            </IconButton> */}
          </Box>
        ),
      },
    ],
    [addEditRole]
  );

  if (addEditRole) {
    return (
      <AddEditNewRole
        setSuccessRoleModal={setSuccessRoleModal}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        configDetails={configDetails}
        refetchAllRolesData={refetchAllRolesData}
        setAddEditRole={setAddEditRole}
      />
    );
  } else {
    return (
      <>
        <Box
          sx={{
            backgroundColor: "#FEF7EE",
            width: "100%",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            p: 2,
          }}
        >
          <Grid container alignItems="center">
            <Grid item lg={10} md={9} sm={6} xs={12}>
              <Typography color="#3c3c3c" fontWeight={700} fontSize="16px">
                CURRENT ROLES
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              sm={6}
              xs={12}
              display="flex"
              justifyContent={{ sm: "flex-end", xs: "flex-start" }}
            >
              {rolePermissions.USERS_PERMISSION.EDIT_ROLES?.VIEW_AND_EDIT && (
                <OrangeGradientButton
                  title="Add New Role"
                  style={{ width: "180px" }}
                  onClick={() => setAddEditRole(true)}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Table
          rows={roles ? roles : []}
          columns={columns}
          headerStyle={{
            backgroundColor: "#FFE4CB",
            color: "#3c3c3c",
            fontSize: "14px",
            textAlign: "left",
            fontWeight: 700,
            cellBackground: "#FEF7EE",
            pl: 2,
          }}
          disableSearch
          hideFooter
        />
      </>
    );
  }
};

export default Roles;
