import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ActivityOverview from "./activityOverview";
import Statistics from "./statistics";
import images from "assets/images/images";
import Users from "./users";
import Revenue from "./revenue";
import { GET_DASHBOARD_DATA } from "app/graphql/dashboard";
import { useMutation, useQuery, useSubscription } from "@apollo/client";

const Dashboard = () => {
  const [data, setData] = useState();
  const [filter, setFilter] = useState("1d");
  const { data: dashboardData } = useQuery(GET_DASHBOARD_DATA, {
    variables: {
      filter: filter
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (dashboardData && dashboardData?.dashboard) {
      console.log(dashboardData);
      setData(dashboardData?.dashboard);
    }
  }, [dashboardData]);

  return (
    <Grid container>
      {/* <Grid item lg={3} md={12} sm={12} xs={12}>
        <ActivityOverview />
      </Grid> */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Statistics data={data} />
      </Grid>
      <Grid item lg={5.5} md={5.5} sm={12} xs={12}>
        <Users data={data} />
      </Grid>
      <Grid item lg={6.5} md={6.5} sm={12} xs={12}>
        <Revenue data={data} setFilter={setFilter} />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
