import React from "react";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Chip,
  Grid,
  Typography,
  Box,
  Button,
  MenuItem,
  Divider,
  Popover,
  IconButton
} from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BootDashboard from "./BootDashboard";
import TournamentDashboard from "./TournamentDashboard";
import Table from "../Table";
import { colors } from "styles/colors";
import images from "assets/images/images";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  GET_TOURNAMENTS,
  GET_TOURNAMENT_STATISTICS
} from "app/graphql/tournament";
import DateFilter from "../DateFilter";
import moment from "moment";
import { getDateInFormat } from "utils/helpers";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const TournamentsAnalytics = () => {
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [tournamentListArr, setTournamentListArr] = React.useState([]);
  const todayDate = new Date();
  const oneWeekAgo = new Date(todayDate);
  oneWeekAgo.setDate(todayDate.getDate() - 30);

  const [tableStartDate, setTableStartDate] = useState(oneWeekAgo);
  const [tableEndDate, setTableEndDate] = useState(todayDate);

  const requestMapping = {
    Active: {
      status: "Live",
      text: "Live",
      fill: "rgba(255, 59, 48, 0.06)",
      color: colors.redAsterisk
    },
    Completed: {
      status: "Completed",
      text: "Completed",
      fill: "rgba(67, 224, 111, 0.06)",
      color: colors.green
    }
  };

  const [bootGraphFilter, setBootGraphFilter] = useState("1d");
  const [leaderboardGraphFilter, setLeaderboardGraphFilter] = useState("1d");
  const [tournamentStatistics, setTournamentStatistics] = useState<any>({});
  const [filter, setFilter] = React.useState([
    "Normal",
    "Unlimited",
    "Free",
    "Triple"
  ]);
  const [searchText, setSearchText] = React.useState("");
  const [searchTextTemp, setSearchTextTemp] = React.useState("");

  const fireRequestSearchAPICall = (searchValue) => {
    setSearchText(searchValue);
  };
  const [timer, setTimer] = React.useState<any>(null);

  const requestSearch = (searchValue: string) => {
    setSearchTextTemp(searchValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fireRequestSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const { data: tournamentsListData } = useQuery(GET_TOURNAMENTS, {
    variables: {
      filter: {
        type: filter,
        title: searchText
      },
      page: page,
      limit: pageSize,
      from: tableStartDate,
      to: tableEndDate
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      tournamentsListData &&
      tournamentsListData?.allTournaments &&
      tournamentsListData?.allTournaments?.status
    ) {
      console.log(tournamentsListData);
      const data = tournamentsListData?.allTournaments?.tournaments;
      setTournamentListArr(data || []);
    }
  }, [tournamentsListData]);

  const {
    loading: getTournamentStatisticsLoading,
    refetch: refetchGetTournamentStatistics
  } = useQuery(GET_TOURNAMENT_STATISTICS, {
    variables: {
      bootFilter: bootGraphFilter,
      leaderboardFilter: leaderboardGraphFilter
    },
    onCompleted: (data) => {
      const { tournamentDashboard } = data;
      console.log(tournamentDashboard);
      const { status } = tournamentDashboard;
      if (status) {
        const totalBootTournaments = tournamentDashboard?.bootTournaments;
        const totalLeaderboardTournaments =
          tournamentDashboard?.leaderboardTournaments;
        const totalTournaments =
          totalBootTournaments + totalLeaderboardTournaments;
        console.log(totalTournaments);
        sessionStorage.setItem("totalTournaments", totalTournaments);
        setTournamentStatistics(tournamentDashboard);
      } else {
        setTournamentStatistics({});
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const columnsForTournaments: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 0.5
      },
      {
        field: "title",
        headerName: "TITLE",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            onClick={() => {
              sessionStorage.setItem("tournament", params.row.title);
              history.push(`/tournaments/${params.row.id}`);
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography fontSize={"14px"}>{params.row?.title}</Typography>
          </Box>
        )
      },
      {
        field: "game",
        headerName: "GAME",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.game?.title}</Typography>
          </Box>
        )
      },
      {
        field: "type",
        headerName: "TOUR. TYPE",
        minWidth: 100,
        flex: 1
      },
      {
        field: "start",
        headerName: "START D&T",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.start),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "end",
        headerName: "END D&T",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.end),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "pool",
        headerName: "PRIZE POOL",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{details?.prizePool}</Typography>
            </Box>
          );
        }
      },
      {
        field: "entry",
        headerName: "ENTRY",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{details?.entry}</Typography>
            </Box>
          );
        }
      },
      {
        field: "slot",
        headerName: "SLOT",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{details?.capacity}</Typography>
            </Box>
          );
        }
      },
      {
        field: "filled",
        headerName: "FILLED",
        // minWidth: 200,
        flex: 1
      },
      {
        field: "revenue",
        headerName: "REVENUE",
        // minWidth: 200,
        flex: 1
      },
      {
        field: "winners",
        headerName: "WINNERS",
        // minWidth: 200,
        flex: 1
      },
      {
        field: "status",
        headerName: "STATUS",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          if (params.row.tournamentStatus === "Active") {
            return (
              <Box display={"flex"} alignItems={"center"}>
                <Box
                  sx={{
                    height: "5px",
                    width: "5px",
                    borderRadius: "50px",
                    backgroundColor:
                      requestMapping[params.row.tournamentStatus]?.color,
                    mr: 1
                  }}
                ></Box>
                <Typography
                  fontSize={"11px"}
                  fontWeight={600}
                  color={colors.redAsterisk}
                >
                  {requestMapping[
                    params.row.tournamentStatus
                  ]?.text.toUpperCase()}
                </Typography>
              </Box>
            );
          } else {
            return (
              <Typography
                fontSize={"11px"}
                fontWeight={600}
                color={colors.lighterGreen}
              >
                {requestMapping[
                  params.row.tournamentStatus
                ]?.text.toUpperCase()}
              </Typography>
            );
          }
        }
      }
    ],
    []
  );

  const columnsForBoot: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 0.5
      },
      // {
      //   field: "title",
      //   headerName: "TITLE",
      //   minWidth: 100,
      //   flex: 1,
      //   renderCell: (params) => (
      //     <Box
      //       display={"flex"}
      //       justifyContent={"center"}
      //       alignItems={"baseline"}
      //       fontSize={"14px"}
      //       onClick={() => {
      //         sessionStorage.setItem("tournament", params.row.title);
      //         history.push(`/tournaments/boot/${params.row.id}`);
      //       }}
      //       sx={{ cursor: "pointer" }}
      //     >
      //       <Typography fontSize={"14px"}>{params.row?.title}</Typography>
      //     </Box>
      //   )
      // },
      {
        field: "game",
        headerName: "GAME",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            onClick={() => {
              sessionStorage.setItem("tournament", params.row.game?.title);
              history.push(`/tournaments/boot/${params.row.id}`);
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography fontSize={"14px"}>{params.row.game?.title}</Typography>
          </Box>
        )
      },
      {
        field: "start",
        headerName: "START D&T",
        minWidth: 150,
        flex: 1.5,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.start),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "end",
        headerName: "END D&T",
        minWidth: 150,
        flex: 1.5,
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.end),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "pool",
        headerName: "BOOT POOL",
        // minWidth: 200,
        flex: 1
      },
      {
        field: "entry",
        headerName: "ENTRY",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.entryFee}</Typography>
          </Box>
        )
      },
      {
        field: "players",
        headerName: "PLAYERS",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
          >
            <Typography fontSize={"14px"}>{params.row.players}</Typography>
          </Box>
        )
      },
      {
        field: "gdCom",
        headerName: "GD COM.",
        // minWidth: 200,
        flex: 1,
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>
                {details?.gdCommission || ""}
              </Typography>
            </Box>
          );
        }
      },
      {
        field: "winningAmount",
        headerName: "WIN AMT",
        // minWidth: 200,
        flex: 1
      },
      {
        field: "status",
        headerName: "STATUS",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          if (params.row.tournamentStatus === "Active") {
            return (
              <Box display={"flex"} alignItems={"center"}>
                <Box
                  sx={{
                    height: "5px",
                    width: "5px",
                    borderRadius: "50px",
                    backgroundColor:
                      requestMapping[params.row.tournamentStatus]?.color,
                    mr: 1
                  }}
                ></Box>
                <Typography
                  fontSize={"11px"}
                  fontWeight={600}
                  color={colors.redAsterisk}
                >
                  {requestMapping[
                    params.row.tournamentStatus
                  ]?.text.toUpperCase()}
                </Typography>
              </Box>
            );
          } else {
            return (
              <Typography
                fontSize={"11px"}
                fontWeight={600}
                color={colors.lighterGreen}
              >
                {requestMapping[
                  params.row.tournamentStatus
                ]?.text.toUpperCase()}
              </Typography>
            );
          }
        }
      }
    ],
    []
  );

  const [selectedRequests, setSelectedRequests] = React.useState<number[]>([]);
  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

  const handleRowOver = (e) => {
    const rowId = Number(e.currentTarget.dataset.id);
    setHoveredRow(rowId);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const [value, setValue] = React.useState(0);
  const [selectedTab, setSelectedTab] = React.useState("TOURNAMENT");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(0);
    setSearchTextTemp("");
    setSearchText("");
    if (newValue === 0) {
      setFilter(["Normal", "Unlimited", "Free"]);
      // setSelectedTab("TOURNAMENT");
    } else if (newValue === 1) {
      setFilter(["Blitz"]);
      // setSelectedTab("Blitz");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const lastUpdatedInfo: any = getDateInFormat(
    parseInt(tournamentStatistics?.lastUpdated),
    "DD/MM/YYYY hh:mm A",
    "changeFormat"
  );
  return (
    <Grid container>
      <Grid item lg={5.8} md={5.8} sm={12} xs={12} mr={1}>
        <TournamentDashboard
          tournamentStatistics={tournamentStatistics}
          setLeaderboardGraphFilter={setLeaderboardGraphFilter}
        />
      </Grid>
      <Grid item lg={5.8} md={5.8} sm={12} xs={12} ml={1}>
        <BootDashboard
          tournamentStatistics={tournamentStatistics}
          setBootGraphFilter={setBootGraphFilter}
        />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ color: colors.invalidInput }}
      >
        <Tabs
          textColor="inherit"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: colors.invalidInput
            }
          }}
          value={value}
          onChange={handleChange}
        >
          <Tab
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 500,
              fontSize: "14px"
            }}
            label="Tournament Details"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 500,
              fontSize: "14px"
            }}
            label="Boot Details"
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TabPanel value={value} index={0}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: colors.backgroundLight,
              m: 1.5,
              p: 3,
              borderRadius: "15px"
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography
                color="#3C3C3C"
                fontSize="13px"
                fontWeight={400}
                mb={1}
              >
                {`Last Updated on ${lastUpdatedInfo}`}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 30
              }}
            >
              <Box>
                <Typography
                  color="#3C3C3C"
                  fontSize="16px"
                  fontWeight={600}
                  sx={{ py: 1 }}
                >
                  Tournament Details
                </Typography>
                <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                  {tournamentListArr?.length > 0
                    ? `Displaying details of ${tournamentListArr?.length} tournaments`
                    : ""}
                </Typography>
              </Box>
              <DateFilter
                startDate={tableStartDate}
                endDate={tableEndDate}
                onChangeStartDate={(newDate) => setTableStartDate(newDate)}
                onChangeEndDate={(newDate) => setTableEndDate(newDate)}
                disableFutureForStart={true}
                disableFutureForEnd={true}
              />
            </Box>
            {/* <div style={{ marginTop: 15 }}>
              <span style={{ margin: 7 }}>
                <IconButton
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <img src={images.Filter_Rectangle} alt="" />
                  <img
                    src={images.DownArrow}
                    alt=""
                    style={{ marginLeft: "5px" }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 10,
                    horizontal: -50
                  }}
                  sx={{ maxHeight: "none" }}
                >
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Option 1
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon,
                      mt: -0.5,
                      mb: 1
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Option 2
                    </Typography>
                  </MenuItem>
                </Popover>
              </span>
              <span style={{ margin: 15 }}>
                <img src={images.ExportIcon} alt="" />
              </span>
            </div> */}
            <Table
              rows={tournamentListArr || []}
              columns={columnsForTournaments}
              paginationMode="server"
              page={page}
              onPageChange={(page) => setPage(page)}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize);
                setPage(0);
              }}
              rowCount={tournamentStatistics?.leaderboardTournaments || 100}
              loading={false}
              pagination={true}
              // checkboxSelection
              selectedRows={selectedRequests}
              setSelectedRows={setSelectedRequests}
              // onRowHover={handleRowOver}
              // onRowLeave={handleRowLeave}
              searchText={searchTextTemp}
              requestSearch={requestSearch}
              disableSearch={false}
              placeholderTextForSearch="Search by game"
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: colors.backgroundLight,
              m: 1.5,
              p: 3,
              borderRadius: "15px"
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography
                color="#3C3C3C"
                fontSize="13px"
                fontWeight={400}
                mb={1}
              >
                {`Last Updated on ${lastUpdatedInfo}`}
              </Typography>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 30
              }}
            >
              <div>
                <Typography
                  color="#3C3C3C"
                  fontSize="16px"
                  fontWeight={600}
                  sx={{ py: 1 }}
                >
                  Boot Details
                </Typography>
                <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                  {tournamentListArr?.length > 0
                    ? `Displaying details of ${tournamentListArr?.length} tournaments`
                    : ""}
                </Typography>
              </div>
              <DateFilter
                startDate={tableStartDate}
                endDate={tableEndDate}
                onChangeStartDate={(newDate) => setTableStartDate(newDate)}
                onChangeEndDate={(newDate) => setTableEndDate(newDate)}
                disableFutureForStart={true}
                disableFutureForEnd={true}
              />
            </div>
            {/* <div style={{ marginTop: 15 }}>
              <span style={{ margin: 7 }}>
                <IconButton
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <img src={images.Filter_Rectangle} alt="" />
                  <img
                    src={images.DownArrow}
                    alt=""
                    style={{ marginLeft: "5px" }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 10,
                    horizontal: -50
                  }}
                  sx={{ maxHeight: "none" }}
                >
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Option 1
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon,
                      mt: -0.5,
                      mb: 1
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Option 2
                    </Typography>
                  </MenuItem>
                </Popover>
              </span>
              <span style={{ margin: 15 }}>
                <img src={images.ExportIcon} alt="" />
              </span>
            </div> */}
            <Table
              rows={tournamentListArr || []}
              columns={columnsForBoot}
              paginationMode="server"
              page={page}
              onPageChange={(page) => setPage(page)}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize);
                setPage(0);
              }}
              rowCount={tournamentStatistics?.bootTournaments || 100}
              loading={false}
              pagination={true}
              // checkboxSelection
              selectedRows={selectedRequests}
              setSelectedRows={setSelectedRequests}
              // onRowHover={handleRowOver}
              // onRowLeave={handleRowLeave}
              searchText={searchTextTemp}
              requestSearch={requestSearch}
              disableSearch={false}
              placeholderTextForSearch="Search by game"
            />
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default TournamentsAnalytics;
