import React, { SetStateAction } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import OrangeGradientButton from "../OrangeGradientButton";

interface IModalProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  inviteuserEmailId: string;
}

const SuccessInviteModal = (props: IModalProps) => {
  const { open, setOpen, inviteuserEmailId } = props;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} scroll={"paper"}>
      <DialogTitle
        sx={{
          backgroundColor: "#FEF7EE",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography fontSize="16px" fontWeight={600} color="#3c3c3c">
          Invitation Sent
        </Typography>
        <IconButton size="small" onClick={() => setOpen(false)}>
          <Close fontSize="small" sx={{ color: "#3c3c3c" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{ backgroundColor: "#FEF7EE", pb: 10 }}
      >
        <Typography fontSize="14px" fontWeight={600} color="#3c3c3c">
          {`The sign up invitation has been sent to ${inviteuserEmailId}`}
        </Typography>
      </DialogContent>
      <DialogTitle sx={{ backgroundColor: "#FFE4CB" }}>
        <OrangeGradientButton
          title="Okay"
          size="small"
          style={{ width: "128px" }}
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
    </Dialog>
  );
};

export default SuccessInviteModal;
