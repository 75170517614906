import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import DefaultDetails from "../DefaultDetails";
import Tabs from "../../Tabs";
import Prioritization from "./Prioritization";
import AlgorithmComponent from "./Algorithm";
import RewardCategory from "./RewardCategory";
import images from "../../../../assets/images/images";
import { colors } from "../../../../styles/colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_REWARD_CATEGORIES,
  REWARDS_DATA_ACCESS_AUTHENTICATION
} from "app/graphql/rewards";
import MainScreen from "./MainScreen";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const RewardsData = () => {
  const [rewardCategory, setRewardCategory] = React.useState("spinTheWheel");
  const [loginSuccessful, setLoginSuccessful] = React.useState(false);

  return (
    <>
      {!loginSuccessful && (
        <AuthenticationForm setLoginSuccessful={setLoginSuccessful} />
      )}
      {loginSuccessful && (
        <MainScreen
          rewardCategory={rewardCategory}
          setRewardCategory={setRewardCategory}
        />
      )}
    </>
  );
};

export default RewardsData;

const AuthenticationFormContent = (props) => {
  const { values, setFieldValue, touched, errors, handleBlur, handleSubmit } =
    props;

  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <>
      <Grid>
        <Box
          sx={{
            width: "100%",
            height: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              width: "380px",
              height: "310px",
              backgroundColor: colors.backgroundBoxLight,
              borderRadius: "4px",
              boxShadow: "-3px 4px 9px rgba(170, 170, 170, 0.25)",
              p: 3
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  height: "52px",
                  width: "52px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: " rgba(255, 129, 0, 0.35)",
                  borderRadius: "2px"
                }}
              >
                <img src={images.key_icon} alt="" height="25px" width="25px" />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2.5 }}>
              <Box>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={colors.lightGray}
                >
                  You need password to access
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 0.8 }}>
              <Box>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  color={colors.lightGreyIcon}
                >
                  Rewards Data
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Box sx={{ width: "100%" }}>
                <TextField
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  size="small"
                  fullWidth={true}
                  value={values.password}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("password", e.target.value);
                    }
                  }}
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: "#020202" },
                    display: "inline-block"
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {!showPassword ? (
                            <Visibility />
                          ) : (
                            <img src={images.eye_closed_icon} alt="" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {touched?.password && errors?.password && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600
                    }}
                  >
                    {errors?.password}
                  </Typography>
                )}
                {values.loading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "130%",
                      left: "55%",
                      zIndex: 1005,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: colors.invalidInput
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2.5 }}>
              <Box sx={{ width: "100%" }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: "38px",
                    background:
                      "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                    borderRadius: "4px",
                    fontWeight: 600,
                    fontSize: "14px",
                    marginRight: 2
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "57%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

const AuthenticationForm = (props: any) => {
  const { setLoginSuccessful } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [authenticateUser, { loading: authenticateUserLoading }] = useMutation(
    REWARDS_DATA_ACCESS_AUTHENTICATION,
    {
      onCompleted: (data) => {
        console.log(data);
        const { checkPassword } = data;
        const { message, status } = checkPassword;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          setLoginSuccessful(true);
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      },
      fetchPolicy: "network-only"
    }
  );

  const AuthenticationFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        password: "",
        loading: authenticateUserLoading
      };
    },

    validationSchema: Yup.object().shape({
      password: Yup.string().required("Please enter the password")
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      // console.log(">>>>>>>>>>>>>values", values, props)
      authenticateUser({
        variables: {
          password: values.password
        }
      });
    }
  })(AuthenticationFormContent);
  return <AuthenticationFormWrapped {...props} />;
};
