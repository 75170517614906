import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { IGame } from "app/models/games";
import { colors } from "styles/colors";
import ColumChart from "../ColumnChartForBugReports";
import { formatNumberToK } from "utils/helpers";

interface IStatisticsProps {
  gameDetails: any;
}

const Statistics = (props: IStatisticsProps) => {
  const { gameDetails } = props;

  return (
    <Grid container spacing={2}>
      <Grid item lg={7} md={7} sm={6} xs={12}>
        <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            borderRadius: "4px",
            p: 3,
            pb: 6,
            height: "auto"
          }}
        >
          <img
            src={gameDetails?.game?.thumbnailImageUrl}
            width="150px"
            height="150px"
          />
          <Typography
            fontSize="16px"
            color={colors.lightGreyIcon}
            fontWeight={400}
            sx={{ mt: 3 }}
          >
            Onboarded on:{" "}
            <Typography
              fontSize="16px"
              color={colors.lightGreyIcon}
              fontWeight={600}
            >
              6th Aug 2022, 13:00 PM
            </Typography>
          </Typography>
          <Typography
            fontSize="16px"
            color={colors.lightGreyIcon}
            fontWeight={400}
            sx={{ mt: 3 }}
          >
            Owner:{" "}
            <Typography
              fontSize="16px"
              color={colors.lightGreyIcon}
              fontWeight={600}
            >
              {gameDetails?.owner || "-"}
            </Typography>
          </Typography>
          <Typography
            fontSize="16px"
            color={colors.lightGreyIcon}
            fontWeight={400}
            sx={{ mt: 3 }}
          >
            Size:{" "}
            <Typography
              fontSize="16px"
              color={colors.lightGreyIcon}
              fontWeight={600}
            >
              12 MB
            </Typography>
          </Typography>
        </Box>
        {/* <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            borderRadius: "4px",
            p: 3,
            mt: 2,
          }}
        >
          <Typography
            fontSize="18px"
            color={colors.lightGreyIcon}
            fontWeight={600}
          >
            0 Bug Reports
          </Typography>
          <Typography
            fontSize="12px"
            color={colors.lightGreyIcon}
            fontWeight={400}
          >
            Monthly View
          </Typography>
          <ColumChart title="" xAxis={xAxis} yAxis={yAxis} data={data} />
        </Box> */}
      </Grid>
      <Grid item lg={5} md={5} sm={6} xs={12}>
        <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            px: 3,
            pt: 3,
            pb: 6,
            borderRadius: "4px",
            position: "relative"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              fontSize="20px"
              fontWeight={600}
              color={colors.lightGreyIcon}
            >
              {gameDetails?.gameClicksThisMonth
                ? formatNumberToK(gameDetails?.gameClicksThisMonth, "clicks")
                : 0}
            </Typography>
            <Typography
              color="#CD7F32"
              fontSize="12px"
              fontWeight={300}
              fontStyle="italic"
            >
              Monthly
            </Typography>
          </Box>
          <Typography
            fontSize="16px"
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Total Game Clicks
          </Typography>
          {/* <Typography
            fontSize="14px"
            fontWeight={400}
            color={colors.lightGreyIcon}
            sx={{ position: "absolute", bottom: 3 }}
          >
            2.3hrs average game time
          </Typography> */}
        </Box>
        <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            px: 3,
            pb: 6,
            pt: 3,
            borderRadius: "4px",
            mt: 2
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              fontSize="20px"
              fontWeight={600}
              color={colors.lightGreyIcon}
            >
              {gameDetails?.averageGameClicksThisMonth
                ? formatNumberToK(
                    gameDetails?.averageGameClicksThisMonth,
                    "clicks"
                  )
                : 0}
            </Typography>
            <Typography
              color="#CD7F32"
              fontSize="12px"
              fontWeight={300}
              fontStyle="italic"
            >
              Monthly
            </Typography>
          </Box>
          <Typography
            fontSize="16px"
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Avg. Game Clicks Per Day
          </Typography>
        </Box>
        {/* <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            px: 3,
            pt: 3,
            pb: 8.5,
            borderRadius: "4px",
            position: "relative",
            mt: 2
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              fontSize="20px"
              fontWeight={600}
              color={colors.lightGreyIcon}
            >
              350k
            </Typography>
            <Typography
              color="#CD7F32"
              fontSize="12px"
              fontWeight={300}
              fontStyle="italic"
            >
              Monthly
            </Typography>
          </Box>
          <Typography
            fontSize="16px"
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Total Game Clicks
          </Typography>
          <Typography
            fontSize="14px"
            fontWeight={400}
            color={colors.lightGreyIcon}
            sx={{ position: "absolute", bottom: 3 }}
          >
            25 average game clicks
          </Typography>
        </Box> */}
        <Box
          sx={{
            backgroundColor: colors.backgroundLight,
            px: 3,
            pb: 6.5,
            pt: 3,
            borderRadius: "4px",
            mt: 2
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              fontSize="20px"
              fontWeight={600}
              color={colors.lightGreyIcon}
            >
              {gameDetails?.uniqueUsersThisMonth
                ? formatNumberToK(gameDetails?.uniqueUsersThisMonth, "users")
                : 0}
            </Typography>
            <Typography
              color="#CD7F32"
              fontSize="12px"
              fontWeight={300}
              fontStyle="italic"
            >
              Monthly
            </Typography>
          </Box>
          <Typography
            fontSize="16px"
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Unique User Base
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Statistics;
