import * as React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ICurvedGraphProps {
  xAxisLabels: string[];
  yAxis?: any;
  data: any;
  partColors?: string[];
  height?: number;
  properties?: any;
}

const BarChartGraph = (props: ICurvedGraphProps) => {
  const { xAxisLabels, yAxis, data, partColors, height, properties } = props;

  const containerStyle: any = {
    width: properties?.width || "100%",
    height: properties?.height || 250,
    overflowX: "auto"
  };

  const tooltipOptions: Highcharts.TooltipOptions = {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormatter: function () {
      const series: any = this.series;
      const point = this as any;
      if (properties?.showRevenue) {
        return (
          '<tr><td style="color:' +
          point.color +
          ';padding:0">' +
          series.name +
          ": " +
          point.y +
          "</td></tr>" +
          '<tr><td style="color:' +
          point.color +
          ';padding:0">Revenue: ' +
          point.revenue +
          "</td></tr>"
        );
      }
      return (
        '<tr><td style="color:' +
        point.color +
        ';padding:0">' +
        series.name +
        ": " +
        point.y +
        "</td></tr>"
      );
    },
    footerFormat: "</table>",
    shared: true,
    useHTML: true
  };

  const options = {
    chart: {
      height: properties?.height ? properties?.height : 250,
      ...(properties?.width && {
        width: properties?.width && properties?.width
      }),
      type: "column",
      backgroundColor: "",
      marginTop: 30,
      spacingTop: 0
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: xAxisLabels
    },
    yAxis: { min: 0, title: { text: yAxis?.title || "" } },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false
        }
      }
    },
    tooltip: tooltipOptions,
    colors: partColors,
    series: data,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    }
  };
  return (
    <div style={containerStyle}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarChartGraph;
