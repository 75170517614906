export const checkAtleastOnePermission = (values) => {
  if (
    values.overViewDasboard_View ||
    values.overViewDasboard_ViewAndEdit ||
    values.editGames_View ||
    values.editGames_ViewAndEdit ||
    values.overallGamesDashboard_View ||
    values.overallGamesDashboard_ViewAndEdit ||
    values.referralsAnalytics_View ||
    values.referralsAnalytics_ViewAndEdit ||
    values.rewardsAnalytics_View ||
    values.rewardsAnalytics_ViewAndEdit ||
    values.addRewardsConfiguration_View ||
    values.addRewardsConfiguration_ViewAndEdit ||
    values.rewardsDataConfiguration_View ||
    values.rewardsDataConfiguration_ViewAndEdit ||
    values.tournamentAnalytics_View ||
    values.tournamentAnalytics_ViewAndEdit ||
    values.tournamentConfigurationBoot_View ||
    values.tournamentConfigurationBoot_ViewAndEdit ||
    values.tournamentConfigurationLeaderBoard_View ||
    values.tournamentConfigurationLeaderBoard_ViewAndEdit ||
    values.usersAnalytics_View ||
    values.usersAnalytics_ViewAndEdit ||
    values.editRoles_View ||
    values.editRoles_ViewAndEdit ||
    values.editUsers_View ||
    values.editUsers_ViewAndEdit
  ) {
    return true;
  }
  return false;
};
