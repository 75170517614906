import React, { useState } from "react";
import TournamentDetails from "./AddTournament";
import PrizeDistribution from "./PrizeDistribution";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { withFormik } from "formik";
import * as Yup from "yup";
import { getCreateTournamentPayload, getFormValues } from "utils/helpers";
import {
  CREATE_TOURNAMENT,
  GET_TOURNAMENT_DETAILS,
  UPDATE_TOURNAMENT,
} from "app/graphql/tournament";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { GET_ALL_GAMES, GET_ALL_GAMES_FOR_DROPDOWN } from "app/graphql/game";
import { colors } from "styles/colors";
import dayjs from "dayjs";

const AddTournamentComponent = (props) => {
  const {
    values,
    handleSubmit,
    prizeDIstribution,
    setPrizeDistribution,
    setGoToPrizeDistributionClicked,
    // disableSubmitButton,
    // setDisableSubmitButton
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = React.useState(false);
  return (
    <>
      {!prizeDIstribution && <TournamentDetails {...props} />}
      {prizeDIstribution && (
        <PrizeDistribution
          {...props}
          disableSubmitButton={disableSubmitButton}
          setDisableSubmitButton={setDisableSubmitButton}
        />
      )}
      {prizeDIstribution && (
        <Grid container>
          <Button
            size="small"
            variant="contained"
            disabled={disableSubmitButton}
            sx={{
              width: { lg: "15%", md: "19%", sm: "23%", xs: "23%" },
              background: "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
              borderRadius: "36px",
              height: "38px",
              fontWeight: 400,
              fontSize: "16px",
              margin: "20px 0px",
              ml: "20px",
              color: "#FFF9F2",
              opacity: disableSubmitButton ? 0.7 : 1,
              "&:disabled": {
                color: "rgb(255, 249, 242,0.7 )",
              },
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {values?.id ? "Update tournament" : "Add tournament"}
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{
              width: { lg: "15%", md: "19%", sm: "23%", xs: "23%" },
              background: "#bbb5af",
              borderRadius: "36px",
              height: "38px",
              fontWeight: 400,
              fontSize: "16px",
              letterSpacing: "0.02em",
              margin: "20px 20px",
              color: "#FFF9F2",
            }}
            onClick={() => {
              setPrizeDistribution(false);
              setGoToPrizeDistributionClicked(false);
            }}
          >
            Cancel
          </Button>
        </Grid>
      )}
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

const AddTournamentForm = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const { tournamentId }: any = useParams();

  const [prizeDIstribution, setPrizeDistribution] = useState(false);
  const [goToPrizeDistributionClicked, setGoToPrizeDistributionClicked] =
    useState(false);
  const [formValues, setFormValues] = React.useState<any>({});
  const [gamesArr, setGamesArr] = React.useState([]);

  const { data: gamesListData } = useQuery(GET_ALL_GAMES_FOR_DROPDOWN, {
    variables: {
      page: 0,
      limit: 1000,
      status: ["Active"],
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (
      gamesListData &&
      gamesListData?.allGames &&
      gamesListData?.allGames?.status
    ) {
      const data = gamesListData?.allGames?.games;
      setGamesArr(data || []);
    }
  }, [gamesListData]);

  const { data: tournamentDetails, loading: getTournamentDetailsLoading } =
    useQuery(GET_TOURNAMENT_DETAILS, {
      variables: {
        id: parseInt(tournamentId),
      },
      skip: !tournamentId || tournamentId === "new",
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

  React.useEffect(() => {
    if (tournamentDetails && tournamentDetails.getTournamentDetails) {
      const temp = getFormValues(
        tournamentDetails?.getTournamentDetails?.tournament
      );
      setFormValues(temp);
    }
  }, [tournamentDetails]);

  const [createTournament, { loading: createTournamentLoading }] = useMutation(
    CREATE_TOURNAMENT,
    {
      onCompleted: (data) => {
        console.log(data);
        const { createTournament } = data;
        const { status, message } = createTournament;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          history.push("/tournaments-configuration");
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
    }
  );

  const [updateTournament, { loading: updateTournamentLoading }] = useMutation(
    UPDATE_TOURNAMENT,
    {
      onCompleted: (data) => {
        console.log(data);
        const { updateTournament } = data;
        const { status, message } = updateTournament;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          history.push("/tournaments-configuration");
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
    }
  );

  const AddTournamentFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      console.log(formValues);
      return {
        id: parseInt(tournamentId) || null,
        game: formValues?.game || "",
        tournamentType: formValues?.tournamentType || "",
        useMegaCard: formValues?.thumbnailImageUrl
          ? 1
          : formValues?.useMegaCard
          ? formValues?.useMegaCard
          : 2,
        title: formValues?.title || "",
        subTitle: formValues?.subTitle || "",
        slots: formValues?.slots || "",
        startDateAndTime: formValues?.startDateAndTime || null,
        endDateAndTime: formValues?.endDateAndTime || null,
        entryFee: formValues?.entryFee || "",
        prizePool: formValues?.prizePool || "",
        // frequencyType: formValues?.frequencyType || "",
        rulesTemp: formValues?.rules || "",
        rules: formValues?.rules || "",
        winnerPercentage: formValues?.winnerPercentage || "",
        gdCommission: formValues?.gdCommission || "",
        alphaValue: formValues?.alphaValue || "",
        initialWinners: formValues?.initialWinners || "",
        multiples: formValues?.multiples || "",
        initialWinnerPrizes: formValues?.initialWinnerPrizes || [],
        lastPrize: formValues?.lastPrize || "",
        prizes: formValues?.prizes || [],
        // includeFreePlay: formValues?.includeFreePlay || 2,
        bootEntry1: formValues?.bootEntry1 || "",
        bootEntries: formValues?.bootEntries || [null, null, null, null],
        bannerImage: formValues?.bannerImage || null,
        thumbnailImage: formValues?.thumbnailImage || null,
        thumbnailImageUrl: formValues?.thumbnailImageUrl || null,
        bootCarousel1: formValues?.bootCarousel1 || null,
        bootCarousel1ImageUrl: formValues?.bootCarousel1ImageUrl || null,
        bootCarousel2: formValues?.bootCarousel2 || null,
        bootCarousel2ImageUrl: formValues?.bootCarousel2ImageUrl || null,
        bootCarousel3: formValues?.bootCarousel3 || null,
        bootCarousel3ImageUrl: formValues?.bootCarousel3ImageUrl || null,
        goToPrizeDistributionClicked: goToPrizeDistributionClicked,
        loading:
          createTournamentLoading ||
          updateTournamentLoading ||
          getTournamentDetailsLoading,
        bootCount: 0,
      };
    },

    validationSchema: Yup.object().shape({
      game: Yup.number().required("This is a required field"),
      tournamentType: Yup.string()
        .required("This is a required field")
        .typeError("Please select a valid tournament type"),
      title: Yup.string().when("tournamentType", {
        is: (type) => type !== "Blitz",
        then: Yup.string()
          .required("This is a required field")
          .max(8, "Maximum 8 characters are allowed"),
      }),
      slots: Yup.number().when("tournamentType", {
        is: (type) => type === "Blitz",
        then: Yup.number()
          .min(2, "Minimum 2 is required")
          .max(5, "Maximum 5 is allowed")
          .required("This is a required field")
          .typeError("Please enter a valid input in numbers"),
        otherwise: Yup.number().when("tournamentType", {
          is: (type) => type === "Unlimited" || type === "Triple",
          then: Yup.number()
            .min(1, "Minimum 1 is required")
            .required("This is a required field")
            .typeError("Please enter a valid input in numbers"),
        }),
      }),
      startDateAndTime: Yup.date()
        .nullable()
        .when("tournamentType", {
          is: (type) => type !== "Blitz" && type !== "Free",
          then: Yup.date()
            .min(
              dayjs(new Date(), { utc: true }).format(),
              "Please enter a valid date and time"
            )
            .typeError("Please enter a valid date and time")
            .required("This is a required field")
            .nullable(),
        }),

      endDateAndTime: Yup.date()
        .nullable()
        .when("tournamentType", {
          is: (type) => type !== "Blitz" && type !== "Free",
          then: Yup.date()
            .min(
              Yup.ref("startDateAndTime"),
              "End date and time cannot be before Start date and time"
            )
            .typeError("Please enter a valid date and time")
            .required("This is a required field")
            .nullable(),
        }),
      entryFee: Yup.number().when("tournamentType", {
        is: (type) => type !== "Blitz" && type !== "Free",
        then: Yup.number()
          .min(0, "Minimum possible value is 0")
          .required("This is a required field")
          .typeError("Please enter a valid input in numbers"),
      }),
      prizePool: Yup.number().when("tournamentType", {
        is: (type) => type !== "Blitz" && type !== "Free",
        then: Yup.number()
          .min(0, "Please enter a valid input")
          .required("This is a required field")
          .typeError("Please enter a valid input in numbers"),
      }),
      // frequencyType: Yup.string().when("tournamentType", {
      //   is: (type) => type !== "Blitz" && type !== "Free",
      //   then: Yup.string().required("This is a required field")
      // }),
      rules: Yup.string().when("tournamentType", {
        is: (type) => type !== "Blitz" && type !== "Free",
        then: Yup.string().required("This is a required field"),
      }),
      winnerPercentage: Yup.number().when(
        ["tournamentType", "goToPrizeDistributionClicked"],
        {
          is: (tournamentType, goToPrizeDistributionClicked) =>
            tournamentType !== "Blitz" && goToPrizeDistributionClicked,
          then: Yup.number()
            .min(1, "Minimum 1 is required")
            .max(100, "Maximum allowed is 100")
            .required("This is a required field")
            .typeError("Please enter a valid input in numbers"),
        }
      ),
      gdCommission: Yup.number().when(
        ["tournamentType", "goToPrizeDistributionClicked"],
        {
          is: (tournamentType, goToPrizeDistributionClicked) =>
            (tournamentType !== "Blitz" && goToPrizeDistributionClicked) ||
            tournamentType === "Blitz",
          then: Yup.number()
            .min(1, "Minimum 1 is required")
            .max(100, "Maximum allowed is 100")
            .required("This is a required field")
            .typeError("Please enter a valid input in numbers"),
        }
      ),
      alphaValue: Yup.number().when(
        ["tournamentType", "goToPrizeDistributionClicked"],
        {
          is: (tournamentType, goToPrizeDistributionClicked) =>
            tournamentType !== "Blitz" && goToPrizeDistributionClicked,
          then: Yup.number()
            .min(1, "Minimum 1 is required")
            .required("This is a required field")
            .typeError("Please enter a valid input in numbers"),
        }
      ),
      initialWinners: Yup.number().when(
        ["tournamentType", "goToPrizeDistributionClicked"],
        {
          is: (tournamentType, goToPrizeDistributionClicked) =>
            tournamentType !== "Blitz" && goToPrizeDistributionClicked,
          then: Yup.number()
            .required("This is a required field")
            .min(1, "Minimum 1 is required"),
        }
      ),
      multiples: Yup.number().when(
        ["tournamentType", "goToPrizeDistributionClicked"],
        {
          is: (tournamentType, goToPrizeDistributionClicked) =>
            tournamentType !== "Blitz" && goToPrizeDistributionClicked,
          then: Yup.number()
            .min(1, "Minimum 1 is required")
            .required("This is a required field")
            .typeError("Please enter a valid input in numbers"),
        }
      ),
      initialWinnerPrizes: Yup.array().when(
        ["tournamentType", "goToPrizeDistributionClicked"],
        {
          is: (tournamentType, goToPrizeDistributionClicked) => {
            return tournamentType !== "Blitz" && goToPrizeDistributionClicked;
          },
          then: Yup.array()
            .of(
              Yup.number()
                .min(1, "Minimum 1 is required")
                .required("This is a required field")
                .typeError("Please enter a valid input")
            )
            .min(1, "Atleast 1 prize is required"),
        }
      ),
      lastPrize: Yup.number().when(
        ["tournamentType", "goToPrizeDistributionClicked"],
        {
          is: (tournamentType, goToPrizeDistributionClicked) =>
            tournamentType !== "Blitz" && goToPrizeDistributionClicked,
          then: Yup.number()
            .min(1, "Minimum 1 is required")
            .required("This is a required field")
            .typeError("Please enter a valid input in numbers"),
        }
      ),
      bootEntry1: Yup.number().when("tournamentType", {
        is: (type) => type === "Blitz" && type !== "Free",
        then: Yup.number()
          .min(0, "Minimum possible value is 0")
          .required("This is a required field")
          .typeError("Please enter a valid input in numbers"),
      }),
      bootEntries: Yup.array().when("tournamentType", {
        is: (type) => type === "Blitz" && type !== "Free",
        then: Yup.array().of(
          Yup.number()
            .min(1, "Minimum 1 is required")
            .nullable()
            .typeError("Please enter a valid input")
        ),
      }),
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);
      const input = getCreateTournamentPayload(values);
      // console.log(input);
      // console.log(values.thumbnailImage);
      if (values.tournamentType === "Free") {
        if (values.id) {
          updateTournament({
            variables: {
              input: { id: values.id, ...input },
              ...(values.bannerImage && { bannerImage: values.bannerImage }),
              ...(values.thumbnailImage && {
                thumbnailImage: values.thumbnailImage,
              }),
              ...(values.bootCarousel1 && {
                bootCarousel1: values.bootCarousel1,
              }),
              ...(values.bootCarousel2 && {
                bootCarousel2: values.bootCarousel2,
              }),
              ...(values.bootCarousel3 && {
                bootCarousel3: values.bootCarousel3,
              }),
            },
          });
        } else {
          createTournament({
            variables: {
              input: input,
              ...(values.bannerImage && { bannerImage: values.bannerImage }),
              ...(values.thumbnailImage && {
                thumbnailImage: values.thumbnailImage,
              }),
              ...(values.bootCarousel1 && {
                bootCarousel1: values.bootCarousel1,
              }),
              ...(values.bootCarousel2 && {
                bootCarousel2: values.bootCarousel2,
              }),
              ...(values.bootCarousel3 && {
                bootCarousel3: values.bootCarousel3,
              }),
            },
          });
        }
      } else if (
        values.tournamentType === "Blitz" ||
        values.tournamentType === "Blitz"
      ) {
        if (
          (values.bootCarousel1 &&
            values.bootCarousel2 &&
            values.bootCarousel3) ||
          (values.bootCarousel1ImageUrl &&
            values.bootCarousel2ImageUrl &&
            values.bootCarousel3ImageUrl)
        ) {
          if (values.id) {
            updateTournament({
              variables: {
                input: { id: values.id, ...input },
                ...(values.bannerImage && { bannerImage: values.bannerImage }),
                ...(values.thumbnailImage && {
                  thumbnailImage: values.thumbnailImage,
                }),
                ...(values.bootCarousel1 && {
                  bootCarousel1: values.bootCarousel1,
                }),
                ...(values.bootCarousel2 && {
                  bootCarousel2: values.bootCarousel2,
                }),
                ...(values.bootCarousel3 && {
                  bootCarousel3: values.bootCarousel3,
                }),
              },
            });
          } else {
            createTournament({
              variables: {
                input: input,
                ...(values.bannerImage && { bannerImage: values.bannerImage }),
                ...(values.thumbnailImage && {
                  thumbnailImage: values.thumbnailImage,
                }),
                ...(values.bootCarousel1 && {
                  bootCarousel1: values.bootCarousel1,
                }),
                ...(values.bootCarousel2 && {
                  bootCarousel2: values.bootCarousel2,
                }),
                ...(values.bootCarousel3 && {
                  bootCarousel3: values.bootCarousel3,
                }),
              },
            });
          }
        } else {
          enqueueSnackbar("Please upload all three Boot Carousal Images", {
            variant: "error",
          });
        }
      } else {
        if (goToPrizeDistributionClicked === true) {
          if (values.id) {
            updateTournament({
              variables: {
                input: { id: values.id, ...input },
                ...(values.bannerImage && { bannerImage: values.bannerImage }),
                ...(values.thumbnailImage && {
                  thumbnailImage: values.thumbnailImage,
                }),
              },
            });
          } else {
            createTournament({
              variables: {
                input: input,
                ...(values.bannerImage && { bannerImage: values.bannerImage }),
                ...(values.thumbnailImage && {
                  thumbnailImage: values.thumbnailImage,
                }),
              },
            });
          }
        } else {
          setFormValues(values);
          if (values.useMegaCard === 1) {
            if (values.thumbnailImage || values.thumbnailImageUrl) {
              setGoToPrizeDistributionClicked(true);
              setPrizeDistribution(true);
            } else {
              enqueueSnackbar("Please upload Mega Card", {
                variant: "error",
              });
            }
          } else {
            setGoToPrizeDistributionClicked(true);
            setPrizeDistribution(true);
          }
        }
      }
    },
  })(AddTournamentComponent);
  return (
    <AddTournamentFormWrapped
      {...props}
      prizeDIstribution={prizeDIstribution}
      setPrizeDistribution={setPrizeDistribution}
      goToPrizeDistributionClicked={goToPrizeDistributionClicked}
      setGoToPrizeDistributionClicked={setGoToPrizeDistributionClicked}
      gamesArr={gamesArr}
    />
  );
};

export default AddTournamentForm;
