import React, { useEffect } from "react";
import Prioritization from "./Prioritization";
import AlgorithmComponent from "./Algorithm";
import RewardCategory from "./RewardCategory";
import { Box, Grid } from "@mui/material";
import { colors } from "../../../../styles/colors";
import Tabs from "../../Tabs";
import DefaultDetails from "../DefaultDetails";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_REWARD_CATEGORY,
  GET_ALL_REWARD_CATEGORIES
} from "app/graphql/rewards";
import {
  GET_ALGORITHM_DATA,
  GET_CONFIG_DATA
} from "app/graphql/userManagement";
import { useSnackbar } from "notistack";

const MainScreen = (props) => {
  const { rewardCategory, setRewardCategory } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [allRewardCategories, setAllRewardCategories] = React.useState([]);
  const [prioritizationConfigDetails, setPrioritizationConfigDetails] =
    React.useState(null);
  const [algorithmConfigDetails, setAlgorithmConfigDetails] =
    React.useState<any>(null);
  const [algorithmConfigKey, setAlgorithmConfigKey] = React.useState(
    "spinTheWheelAlgorithm"
  );

  const {
    data: allRewardCategoriesData,
    loading: getAllRewardCategoriesDataLoading,
    refetch: refetchAllRewardCategoriesData
  } = useQuery(GET_ALL_REWARD_CATEGORIES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      allRewardCategoriesData &&
      allRewardCategoriesData?.rewardCategories &&
      allRewardCategoriesData?.rewardCategories?.status
    ) {
      console.log(allRewardCategoriesData);
      const data = allRewardCategoriesData?.rewardCategories?.rewardCategories;
      setAllRewardCategories(data || []);
    }
  }, [allRewardCategoriesData]);

  const [deleteRewardCategory, { loading: deleteRewardCategoryLoading }] =
    useMutation(DELETE_REWARD_CATEGORY, {
      onCompleted: (data) => {
        console.log(data);
        const { deleteRewardCategory } = data;
        const { status, message } = deleteRewardCategory;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refetchAllRewardCategoriesData();
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    });

  const {
    data: rewardLevelConfigData,
    loading: rewardLevelConfigDataLoading,
    refetch: refetchRewardLevelConfigData
  } = useQuery(GET_CONFIG_DATA, {
    variables: {
      key: "REWARD_LEVELS"
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      rewardLevelConfigData &&
      rewardLevelConfigData?.getConfigDetails &&
      rewardLevelConfigData?.getConfigDetails?.status
    ) {
      console.log("rewardLevelConfigData", rewardLevelConfigData);
      const temp = rewardLevelConfigData?.getConfigDetails?.config;

      let data: any;
      try {
        data = temp?.value ? JSON.parse(temp?.value) : "";
      } catch (error) {
        alert(error);
        data = "";
      }
      setPrioritizationConfigDetails(data || {});
    }
  }, [rewardLevelConfigData]);

  const {
    data: algorithmConfigData,
    loading: algorithmConfigDataLoading,
    refetch: refetchAlgorithmConfigData
  } = useQuery(GET_ALGORITHM_DATA, {
    variables: {
      key: algorithmConfigKey,
      isSpinTheWheel:
        algorithmConfigKey === "spinTheWheelAlgorithm" ? true : false
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      algorithmConfigData &&
      algorithmConfigData?.getSpinTheWheelAndMysterBoxAlgorithm &&
      algorithmConfigData?.getSpinTheWheelAndMysterBoxAlgorithm?.status
    ) {
      console.log("algorithmConfigData", algorithmConfigData);
      const temp =
        algorithmConfigData?.getSpinTheWheelAndMysterBoxAlgorithm
          ?.algorithmDetails;

      let data: any;
      let headers: any;
      try {
        data = temp?.value ? JSON.parse(temp?.value) : "";
        headers = temp?.value ? JSON.parse(temp?.algorithmHeaders) : "";
      } catch (error) {
        alert(error);
        data = {};
      }
      const alogorithmConfig = {
        data: data,
        levels: headers
      };
      setAlgorithmConfigDetails(alogorithmConfig);
    }
  }, [algorithmConfigData]);

  React.useEffect(() => {
    refetchAlgorithmConfigData();
  }, [algorithmConfigKey]);

  const handleTabChange = (value) => {
    console.log(value);
    if (value === 0) {
      refetchRewardLevelConfigData();
    } else if (value === 2) {
      setAlgorithmConfigKey("spinTheWheelAlgorithm");
    }
  };
  return (
    <Grid>
      {/* <Box>
        <DefaultDetails
          rewardCategory={rewardCategory}
          setRewardCategory={setRewardCategory}
        />
      </Box> */}
      {rewardCategory && (
        <Box mt={2}>
          <Tabs
            tabs={[
              {
                label: "LEVEL SETUP"
              },
              {
                label: "REWARD CATEGORY"
              },
              {
                label: "ALGORITHM"
              }
            ]}
            tabContent={[
              <Prioritization
                prioritizationConfigDetails={prioritizationConfigDetails}
                refetchConfigData={refetchRewardLevelConfigData}
              />,
              <RewardCategory
                allRewardCategories={allRewardCategories}
                refetchAllRewardCategoriesData={refetchAllRewardCategoriesData}
                deleteRewardCategory={deleteRewardCategory}
              />,
              <AlgorithmComponent
                rewardCategory={rewardCategory}
                algorithmConfigDetails={algorithmConfigDetails}
                refetchConfigData={refetchAlgorithmConfigData}
                algorithmConfigKey={algorithmConfigKey}
                setAlgorithmConfigKey={setAlgorithmConfigKey}
              />
            ]}
            tabStyle={{
              borderBottom: 4,
              borderColor: colors.TabLightOrange,
              backgroundColor: colors.TabLightOrange
            }}
            onChangeTab={handleTabChange}
          />
        </Box>
      )}
    </Grid>
  );
};

export default MainScreen;
