import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Collapse,
  Box,
  Typography
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from "../../../../../styles/colors";

interface ITableWithExpandProps {
  algorithmConfigKey: any;
  rewardCategory: any;
  algorithmConfigDetails: any;
}

const data = [
  {
    id: 1,
    CRITERIA: "Criteria 1",
    INSTANCES: "Player spends on their first tournament"
  },
  {
    id: 2,
    CRITERIA: "Criteria 2",
    INSTANCES: "Player don’t have daily spent"
  },
  {
    id: 3,
    CRITERIA: "Criteria 3",
    INSTANCES: "Player spends ₹100"
  },
  {
    id: 4,
    CRITERIA: "Criteria 4",
    INSTANCES: "Player spends ₹1000 in 70 days Duration"
  },
  {
    id: 5,
    CRITERIA: "Criteria 5",
    INSTANCES: "Player spends ₹5000 in 70 days Duration"
  }
];

const columns = [
  { field: "CRITERIA", headerName: "CRITERIA" },
  { field: "INSTANCES", headerName: "INSTANCES" }
];

const TableWithExpand = (props: ITableWithExpandProps) => {
  const { algorithmConfigKey, rewardCategory, algorithmConfigDetails } = props;
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => setExpanded(!expanded);

  const getInstaceDetail = (item, index) => {
    const detail =
      index === 1
        ? "Player spends on their first tournament"
        : index === 2
        ? "Player don’t have daily spent"
        : index === 3
        ? `Player spends ₹${item?.maxSpend}`
        : index === 4
        ? `Player spends ₹${item?.maxSpend} in ${algorithmConfigDetails?.data?.algorithmDuration?.value} ${algorithmConfigDetails?.data?.algorithmDuration?.duration} Duration`
        : `Player spends ₹${item?.maxSpend} in ${algorithmConfigDetails?.data?.algorithmDuration?.value} ${algorithmConfigDetails?.data?.algorithmDuration?.duration} Duration`;
    return detail;
  };

  // console.log(algorithmConfigDetails);
  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.TableHead,
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          borderRadius: "4px 4px 0px 0px",
          pl: "20px,",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          p: 0.5
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            textAlign: "left",
            display: "inline-block",
            pl: 2
          }}
        >
          {algorithmConfigKey === "spinTheWheelAlgorithm"
            ? "Spin the wheel Reward Criteria"
            : "Mystery Box Reward Criteria"}
        </Typography>

        <Box sx={{ alignContent: "right" }}>
          <IconButton onClick={handleExpand}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded}>
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: colors.backgroundBoxLight,
            borderRadius: "0px 0px 4px 4px "
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: colors.TableHeadDark }}>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell key={column.field}>{column.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {algorithmConfigDetails?.data?.distributionTable?.map(
                (item, index) => (
                  <React.Fragment key={item.id}>
                    <TableRow>
                      <TableCell sx={{ py: 4 }}>{`Criteria ${
                        index + 1
                      }`}</TableCell>
                      <TableCell sx={{ py: 4 }}>
                        <Typography fontSize={14}>
                          {getInstaceDetail(item, index + 1)}
                        </Typography>
                        <Box display={"flex"} mt={1}>
                          <Typography
                            fontSize={14}
                          >{`A = ${item?.levels[0]}%`}</Typography>
                          <Typography fontSize={14} ml={1}>
                            {"|"}
                          </Typography>
                          <Typography fontSize={14} ml={1}>
                            {` B = ${item?.levels[1]}% `}
                          </Typography>
                          <Typography fontSize={14} ml={1}>
                            {"|"}
                          </Typography>
                          <Typography fontSize={14} ml={1}>
                            {`C = ${item?.levels[2]}%  `}
                          </Typography>
                          <Typography fontSize={14} ml={1}>
                            {" |"}
                          </Typography>
                          <Typography fontSize={14} ml={1}>
                            {` D = ${item?.levels[3]}%`}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
};

export default TableWithExpand;
