import * as React from "react";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridSearchIcon,
  GridSelectionModel
} from "@mui/x-data-grid";
import { CloseOutlined, MoreHorizOutlined } from "@mui/icons-material";
import { IconButton, Stack, TextField, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../../styles/colors";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="standard"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      shape={"rounded"}
      size="small"
      sx={{
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: "#ff8100",
          color: "#ffffff"
        },
        height: "24px"
      }}
    />
  );
}

function CustomFooterComponent(props) {
  const { page, rowsPerPage, rowCount } = props;

  const firstRow = page * rowsPerPage + 1;
  const lastRow = Math.min((page + 1) * rowsPerPage, rowCount);
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ padding: "10px", display: "flex" }}>
        <Typography color="#A8A8A8" fontSize={12} fontWeight={500}>
          {`Showing ${firstRow} - ${lastRow} of ${rowCount} Entries`}
        </Typography>
      </Box>
      <Box>
        <CustomPagination />
      </Box>
    </Box>
  );
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-iconSeparator": {
    display: "none"
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: 13,
    color: colors.tableHeader,
    textAlign: "center"
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
    borderBottom: "none"
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
    borderBottom: "none"
  },
  "& .MuiDataGrid-row": {
    "$&hover": {
      backgroundColor: "#002239",
      opacity: "5%",
      borderBottom: "none"
    }
  },
  "& div div div div >.MuiDataGrid-cell": {
    borderBottom: "none"
  },
  "& .inactive": {
    // backgroundColor: "#e6e6e6",
    "&:hover": {
      backgroundColor: "#e6e6e6 !important"
    }
  }
}));

interface ITableProps {
  getRowId?: any;
  rows: any;
  columns: GridColDef[];
  headerStyle?: any;
  paginationMode?: "server" | "client" | undefined;
  page?: number | undefined;
  onPageChange?: ((param: any) => void) | undefined;
  pageSize?: number | undefined;
  onPageSizeChange?: ((param: any) => void) | undefined;
  rowCount?: number | undefined;
  rowsPerPageOptions?: number[];
  loading?: boolean;
  pagination?: any;
  hideFooter?: boolean;
  hideFooterPagination?: boolean | undefined;
  hideFooterRowCount?: boolean | true;
  checkboxSelection?: boolean;
  selectedRows?: any;
  setSelectedRows?: any;
  onRowHover?: any;
  onRowLeave?: any;
  searchText?: any;
  requestSearch?: any;
  disableSearch?: boolean;
  density?: any;
  placeholderTextForSearch?: string;
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  placeholder: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  return (
    <div style={{ float: "right", marginTop: -20 }}>
      <TextField
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
        placeholder={props?.placeholder || "Search by title"}
        sx={{
          backgroundColor: colors.white,
          background: "#FFFEFC",
          border: "0.4px solid #ECECEC",
          borderRadius: "6px"
        }}
        inputProps={{
          style: {
            fontSize: 14,
            backgroundColor: colors.white,
            padding: "8px"
          }
        }}
        InputProps={{
          startAdornment: <GridSearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <CloseOutlined fontSize="small" />
            </IconButton>
          )
        }}
      />
    </div>
  );
}

const Table = (props: ITableProps) => {
  const {
    getRowId,
    rows,
    columns,
    headerStyle,
    paginationMode,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    rowCount,
    rowsPerPageOptions,
    loading,
    pagination,
    hideFooterPagination,
    hideFooterRowCount,
    hideFooter,
    checkboxSelection,
    selectedRows,
    setSelectedRows,
    onRowHover,
    onRowLeave,
    searchText,
    requestSearch,
    disableSearch,
    density,
    placeholderTextForSearch
  } = props;

  return (
    <StyledDataGrid
      density={density || "standard"}
      getRowId={getRowId ? getRowId : (row) => row.id}
      checkboxSelection={checkboxSelection || false}
      getRowHeight={() => "auto"}
      autoHeight
      disableSelectionOnClick
      disableColumnMenu={true}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
          pl: "2px"
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
          pl: "5px"
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "20px",
          pl: "10px"
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: headerStyle?.backgroundColor && colors.TableHeadDark,
          pl: headerStyle?.pl || 0
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontSize: headerStyle?.fontSize || 13,
          color: headerStyle?.color ? headerStyle?.color : colors.tableHeader,
          textAlign: headerStyle?.textAlign || "center",
          fontWeight: headerStyle?.fontWeight || 400
        },
        "& .MuiDataGrid-cell": {
          backgroundColor: headerStyle?.cellBackground || ""
        }
      }}
      pagination={pagination}
      paginationMode={paginationMode}
      page={page}
      onPageChange={onPageChange}
      pageSize={pageSize || 5}
      onPageSizeChange={onPageSizeChange}
      rowCount={rowCount}
      rowsPerPageOptions={rowsPerPageOptions || [5, 10, 50, 100]}
      loading={loading}
      onSelectionModelChange={(
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
      ) => setSelectedRows && setSelectedRows(selectionModel)}
      selectionModel={selectedRows}
      hideFooterPagination={hideFooterPagination}
      // hideFooterRowCount={hideFooterRowCount}
      hideFooterSelectedRowCount
      hideFooter={hideFooter}
      rows={rows}
      columns={columns}
      components={{
        Pagination: CustomPagination,
        Footer: CustomFooterComponent,
        Toolbar: !disableSearch ? QuickSearchToolbar : null,
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No records
          </Stack>
        ),
        NoResultsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            Filter returns no result
          </Stack>
        ),
        MoreActionsIcon: MoreHorizOutlined
      }}
      componentsProps={{
        row: {
          ...(onRowHover && { onMouseOver: onRowHover }),
          ...(onRowLeave && { onMouseLeave: onRowLeave }),
          ...(onRowHover && { onTouchStart: onRowHover }),
          ...(onRowLeave && { onTouchEnd: onRowLeave })
        },
        toolbar: !disableSearch
          ? {
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
              placeholder: placeholderTextForSearch
            }
          : null,
        footer: {
          page: page,
          rowsPerPage: pageSize,
          rowCount: rowCount
        }
      }}
      getRowClassName={(params) =>
        params.row.status === "InActive" ? "inactive" : ""
      }
    />
  );
};

export default Table;
