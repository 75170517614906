import create from "zustand";

interface IStore {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const getStore = create<IStore>((set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),
}));

export default getStore;
