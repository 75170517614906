import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import MiniDrawer from "../Drawer";
import Users from "./Users";
import InviteModal from "./InviteModal";
import SuccessInviteModal from "./SuccessInviteModal";
import DeleteUserModal from "./DeleteUserModal";
import ResendInviteModal from "./ResendInviteModal";
import RevokeInviteModal from "./RevokeInviteModal";
import Roles from "./Roles";
import SuccessRoleModal from "./SuccessRoleModal";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_USER,
  GET_ALL_ROLES,
  GET_ALL_USERS,
  INVITE_USER,
  RESEND_INVITE,
} from "app/graphql/userManagement";
import { useSnackbar } from "notistack";
import { UPDATE_USER } from "app/graphql/users";
import { colors } from "styles/colors";
import { getJwtTokenDetails } from "utils/helpers";

interface IUserManagementProps {
  roles: any;
  activeUsers: any;
  inActiveUsers: any;
  selectedUser: any;
  setSelectedUser: any;
  setInviteModal: React.Dispatch<SetStateAction<boolean>>;
  setSuccessInviteModal: React.Dispatch<SetStateAction<boolean>>;
  setDeleteUserModal: React.Dispatch<SetStateAction<boolean>>;
  setResendInviteModal: React.Dispatch<SetStateAction<boolean>>;
  setRevokeInviteModal: React.Dispatch<SetStateAction<boolean>>;
  setSuccessRoleModal: React.Dispatch<SetStateAction<boolean>>;
  updateUserRole: any;
  addEditRole: boolean;
  setAddEditRole: any;
}

const UserManagementTabs = (props: IUserManagementProps) => {
  const { setSuccessRoleModal, addEditRole, setAddEditRole, ...rest } = props;

  const { rolePermissions } = getJwtTokenDetails();

  const [selectedTab, setSelectedTab] = useState("USERS");
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (
      !(
        rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW ||
        rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW_AND_EDIT
      ) &&
      (rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW ||
        rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW_AND_EDIT)
    ) {
      setSelectedTab("ROLES");
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={(_, value) => {
          setValue(value);
          if (value === 0) {
            setSelectedTab("USERS");
          } else if (value === 1) {
            setSelectedTab("ROLES");
          }
        }}
        TabIndicatorProps={{ style: { backgroundColor: "#ff8100" } }}
      >
        {(rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW ||
          rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW_AND_EDIT) && (
          <Tab
            label="USERS"
            style={{
              color: value === 0 ? "#3c3c3c" : "#a8a8a8",
              fontSize: "15px",
              fontWeight: 500,
            }}
          />
        )}
        {(rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW ||
          rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW_AND_EDIT) && (
          <Tab
            label="ROLES"
            style={{
              color: value === 1 ? "#3c3c3c" : "#a8a8a8",
              fontSize: "15px",
              fontWeight: 500,
            }}
          />
        )}
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {selectedTab === "USERS" && <Users {...rest} />}
        {selectedTab === "ROLES" && (
          <Roles
            setSuccessRoleModal={setSuccessRoleModal}
            addEditRole={addEditRole}
            setAddEditRole={setAddEditRole}
          />
        )}
      </Box>
    </Box>
  );
};

const UserManagement = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [addEditRole, setAddEditRole] = React.useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [successInviteModal, setSuccessInviteModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [resendInviteModal, setResendInviteModal] = useState(false);
  const [revokeInviteModal, setRevokeInviteModal] = useState(false);
  const [successRoleModal, setSuccessRoleModal] = useState(false);
  const [roles, setRoles] = React.useState([]);
  const [activeUsers, setActiveUsers] = React.useState([]);
  const [inActiveUsers, setInActiveUsers] = React.useState([]);

  const [selectedUser, setSelectedUser] = React.useState({});
  const [inviteuserEmailId, setInviteuserEmailId] = React.useState("");

  const {
    data: allRolesData,
    loading: allRolesDataLoading,
    refetch: refetchAllRolesData,
  } = useQuery(GET_ALL_ROLES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      allRolesData &&
      allRolesData?.allRoles &&
      allRolesData?.allRoles?.status
    ) {
      console.log("allRolesData", allRolesData);
      const data = allRolesData?.allRoles?.roles;
      setRoles(data || []);
    }
  }, [allRolesData]);

  const {
    data: activeUsersData,
    loading: activeUsersDataLoading,
    refetch: refetchActiveUsersData,
  } = useQuery(GET_ALL_USERS, {
    variables: {
      filter: {
        status: "Active",
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      activeUsersData &&
      activeUsersData?.allUsers &&
      activeUsersData?.allUsers?.status
    ) {
      console.log("activeUsersData", activeUsersData);
      const data = activeUsersData?.allUsers?.users;
      setActiveUsers(data || []);
    }
  }, [activeUsersData]);

  const {
    data: inActiveUsersData,
    loading: inActiveUsersDataLoading,
    refetch: refetchInActiveUsersData,
  } = useQuery(GET_ALL_USERS, {
    variables: {
      filter: {
        status: "InActive",
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      inActiveUsersData &&
      inActiveUsersData?.allUsers &&
      inActiveUsersData?.allUsers?.status
    ) {
      console.log("inActiveUsersData", inActiveUsersData);
      const data = inActiveUsersData?.allUsers?.users;
      setInActiveUsers(data || []);
    }
  }, [inActiveUsersData]);

  const [deleteUser, { loading: deleteUserLoading }] = useMutation(
    DELETE_USER,
    {
      onCompleted: (data) => {
        console.log(data);
        const { deleteUser } = data;
        const { status, message } = deleteUser;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          refetchActiveUsersData();
          refetchInActiveUsersData();
          setDeleteUserModal(false);
          setRevokeInviteModal(false);
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
    }
  );

  const [inviteUser, { loading: inviteUserLoading }] = useMutation(
    INVITE_USER,
    {
      onCompleted: (data) => {
        console.log(data);
        const { inviteUsers } = data;
        const { status, message } = inviteUsers;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          //   refetchActiveUsersData();
          refetchInActiveUsersData();
          setInviteModal(false);
          setSuccessInviteModal(true);
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
    }
  );

  const [updateUserRole, { loading: updateUserRoleLoading }] = useMutation(
    UPDATE_USER,
    {
      onCompleted: (data) => {
        console.log(data);
        const { updateUser } = data;
        const { status, message } = updateUser;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          refetchActiveUsersData();
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
    }
  );

  const [resendInvite, { loading: resendInviteLoading }] = useMutation(
    RESEND_INVITE,
    {
      onCompleted: (data) => {
        console.log(data);
        const { resendWelcomeEmail } = data;
        const { status, message } = resendWelcomeEmail;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          setResendInviteModal(false);
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
    }
  );

  return (
    <>
      <UserManagementTabs
        roles={roles}
        activeUsers={activeUsers}
        inActiveUsers={inActiveUsers}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        setInviteModal={setInviteModal}
        setSuccessInviteModal={setSuccessInviteModal}
        setDeleteUserModal={setDeleteUserModal}
        setResendInviteModal={setResendInviteModal}
        setRevokeInviteModal={setRevokeInviteModal}
        setSuccessRoleModal={setSuccessRoleModal}
        updateUserRole={updateUserRole}
        addEditRole={addEditRole}
        setAddEditRole={setAddEditRole}
      />
      {inviteModal && (
        <InviteModal
          open={inviteModal}
          setOpen={setInviteModal}
          setSuccessModal={setSuccessInviteModal}
          inviteUser={inviteUser}
          setInviteuserEmailId={setInviteuserEmailId}
          roles={roles}
        />
      )}
      {successInviteModal && (
        <SuccessInviteModal
          open={successInviteModal}
          setOpen={setSuccessInviteModal}
          inviteuserEmailId={inviteuserEmailId}
        />
      )}
      {deleteUserModal && (
        <DeleteUserModal
          open={deleteUserModal}
          setOpen={setDeleteUserModal}
          selectedUser={selectedUser}
          deleteUser={deleteUser}
        />
      )}
      {resendInviteModal && (
        <ResendInviteModal
          open={resendInviteModal}
          setOpen={setResendInviteModal}
          selectedUser={selectedUser}
          resendInvite={resendInvite}
        />
      )}
      {revokeInviteModal && (
        <RevokeInviteModal
          open={revokeInviteModal}
          setOpen={setRevokeInviteModal}
          selectedUser={selectedUser}
          deleteUser={deleteUser}
        />
      )}
      {successRoleModal && (
        <SuccessRoleModal
          open={successRoleModal}
          setOpen={setSuccessRoleModal}
          setAddEditRole={setAddEditRole}
        />
      )}
      {(activeUsersDataLoading || inActiveUsersDataLoading) && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default UserManagement;
