import React from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Box,
} from '@mui/material';
import { colors } from '../../../../../styles/colors';
import { useMutation } from '@apollo/client';
import { withFormik } from 'formik';
import * as Yup from 'yup';
interface IDeleteRewardProps {
  setItem: any;
}
const options = [
  {
    Id: 1,
    Name: 'Option 1',
  },
  {
    Id: 2,
    Name: 'Option 2',
  },
];

const DeleteRewardComponent = (props) => {
  const {
    setItem,
    values,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleSubmit,
  } = props;

  const [showTextField, setShowTextField] = React.useState(false);
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: '#FEF7EE',
          borderRadius: '15px',
          padding: 2,
          m: 1,
        }}
      >
        <Typography
          color="#3C3C3C"
          fontSize="18px"
          fontWeight={600}
          sx={{ p: 1 }}
        >
          Are you sure you want to delete the reward?
        </Typography>
        <Typography
          color="#3C3C3C"
          fontSize="15px"
          fontWeight={400}
          sx={{ px: 1 }}
        >
          This will remove the reward from the spin the wheel. Make sure you
          have another reward for users.
        </Typography>
        <br />
        <div>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid mb={2} item xs={3}>
              <Box sx={{ display: 'flex' }}>
                <Typography fontSize="16px" fontWeight={450} pl={1}>
                  Select a reason for deletion
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                options={options}
                getOptionLabel={(option) => option.Name}
                value={options?.find((x) => x.Id === values.reason)}
                onChange={(event: any, value: any) => {
                  if (value.Name === 'Option 2') {
                    setShowTextField(true);
                    setFieldValue('reason', value.Id);
                  } else {
                    setShowTextField(false);
                    setFieldValue('reason', value.Id);
                  }
                }}
                sx={{ width: '90%', backgroundColor: '#FFF1E4' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="reason"
                    sx={{ backgroundColor: '#FFF1E4' }}
                    size="small"
                    variant="outlined"
                    placeholder="Choose..."
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14, height: 20 },
                    }}
                  />
                )}
              />
              {touched?.reason && errors?.reason && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: '11px',
                    fontWeight: 600,
                  }}
                >
                  {errors?.reason}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
        {showTextField && (
          <Grid
            mt={-3}
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3} sx={{ mb: 2 }}>
              <Typography fontSize="16px" fontWeight={450} pt={1} px={1} mt={3}>
                If others, mention it here
              </Typography>
            </Grid>
            <Grid item xs={9} sx={{ height: 40 }}>
              <TextField
                size="small"
                sx={{
                  width: '90%',
                  borderRadius: '4px',
                  backgroundColor: '#FFF1E4',
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        )}
        <Button
          size="small"
          variant="contained"
          sx={{
            width: '12%',
            background: 'linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)',
            borderRadius: '36px',
            height: '38px',
            fontWeight: 400,
            fontSize: '16px',
            margin: '20px 0px',
            color: '#FFF9F2',
          }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Delete
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{
            width: '12%',
            background: '#bbb5af',
            borderRadius: '36px',
            height: '38px',
            fontWeight: 400,
            fontSize: '16px',
            margin: '20px 20px',
            color: '#FFF9F2',
          }}
          onClick={() => setItem('rewardsTable')}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

const DeleteRewardForm = (props: any) => {
  const DeleteRewardFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        reason: '',
        others: '',
      };
    },

    validationSchema: Yup.object().shape({
      reason: Yup.number().required('This is a required field'),
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log('>>>>>>>>>>>>>values', values, props);
    },
  })(DeleteRewardComponent);
  return <DeleteRewardFormWrapped {...props} />;
};

export default DeleteRewardForm;
