import * as React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Collapse,
  Box,
  Drawer as MuiDrawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  IconButton,
  Divider,
  Breadcrumbs
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  ExpandLess,
  ExpandMore,
  CircleOutlined as CircleOutlinedIcon,
  Circle
} from "@mui/icons-material";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import {
  getBreadcrumbs,
  getDrawerItems,
  getJwtTokenDetails,
  getPage,
  getPageTitle
} from "utils/helpers";
import images from "../../assets/images/images";
import AppHeaderIcons from "./AppHeaderIcons";

const drawerWidth = 240;

// const drawerItems = [
//   { name: "Dashboard", path: "/dashboard" },
//   { name: "Games", path: "/games" },
//   { name: "Tournaments", path: "/tournaments" },
//   { name: "Users", path: "/users" },
//   { name: "Revenue", path: "/dashboard" },
// ];

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden",
  border: "none"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  border: "none",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

interface MiniDrawerProps {
  content: any;
  isHomepage?: any;
  header?: any;
}

export default function MiniDrawer(props: MiniDrawerProps) {
  const { content, isHomepage, header } = props;

  const history = useHistory();
  const location = useLocation();

  const { rolePermissions, name } = getJwtTokenDetails();

  const [open, setOpen] = React.useState(false);
  const [selectedDrawerItem, setSelectedDrawerItem] = React.useState(0);
  const [startDate, setStartDate] = React.useState(dayjs().subtract(6, "day"));
  const [endDate, setEndDate] = React.useState(dayjs());
  const [openTournamentsSubSections, setOpenTournamentsSubSections] =
    React.useState(false);
  const [openRewardsSubSections, setOpenRewardsSubSections] =
    React.useState(false);
  const [openAdminControlSubSections, setOpenAdminControlSubSections] =
    React.useState(false);

  const handleRewardsClick = () => {
    if (!open) {
      setOpen(true);
    }
    setOpenRewardsSubSections(!openRewardsSubSections);
  };

  const handleTournamentsClick = () => {
    if (!open) {
      setOpen(true);
    }
    setOpenTournamentsSubSections(!openTournamentsSubSections);
  };

  const handleClickAdminControl = () => {
    if (!open) {
      setOpen(true);
    }
    setOpenAdminControlSubSections(!openAdminControlSubSections);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    sessionStorage.setItem("drawerOpen", "1");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    sessionStorage.setItem("drawerOpen", "0");
  };

  React.useEffect(() => {
    const drawerOpen = sessionStorage.getItem("drawerOpen");
    const drawerItem: any = sessionStorage.getItem("drawerItem");
    if (drawerOpen === "1") {
      setOpen(true);
    }
    setSelectedDrawerItem(parseInt(drawerItem));
    const rewardsSubSectionExpanded: any = sessionStorage.getItem(
      "rewardsSubSectionExpanded"
    );
    setOpenRewardsSubSections(rewardsSubSectionExpanded || false);
    const adminControlSubSectionExpanded: any = sessionStorage.getItem(
      "adminControlSubSectionExpanded"
    );
    setOpenAdminControlSubSections(adminControlSubSectionExpanded || false);
    const tournamentsSubSectionExpanded: any = sessionStorage.getItem(
      "tournamentsSubSectionExpanded"
    );
    setOpenTournamentsSubSections(tournamentsSubSectionExpanded || false);
  }, []);

  const [breadcrumbs, setBreadcrumbs] = React.useState<
    { id: number; name: string; path: string; color: string }[]
  >([]);

  React.useEffect(() => {
    setBreadcrumbs(getBreadcrumbs(location));
  }, [location]);

  React.useEffect(() => {
    const temp: any = getPage(location);
    setSelectedDrawerItem(temp);
  }, [location]);

  const drawerItems = React.useMemo(
    () => getDrawerItems(rolePermissions),
    [rolePermissions]
  );

  React.useEffect(() => {
    console.log({ rolePermissions });
  }, [rolePermissions]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="inherit" elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" })
            }}
          >
            <img
              src={images.drawer_small_logo}
              alt="logo"
              onClick={handleDrawerClose}
            />
          </IconButton>
          <Grid container sx={{ pt: 2, pb: 1, pl: 1, pr: 1 }}>
            {isHomepage ? (
              <Grid item xs={8}>
                <Typography color="#8C8E8F" fontSize="24px" fontWeight="500">
                  Good Morning, <span style={{ color: "black" }}>{name}</span>
                </Typography>
                <Typography color="#8C8E8F" fontSize="16px" fontWeight="400">
                  Here's GD app performance summary
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={8}>
                <Typography color="#3C3C3C" fontSize="24px" fontWeight="600">
                  {getPageTitle(location)}
                </Typography>
                <Breadcrumbs separator={"/"} aria-label="breadcrumb">
                  {breadcrumbs.map((crumb) => (
                    <NavLink
                      key={crumb.id}
                      role="button"
                      to={crumb.path}
                      style={{
                        textDecoration: "none",
                        color: crumb.color,
                        fontSize: "16px"
                      }}
                    >
                      {crumb.name}
                    </NavLink>
                  ))}
                </Breadcrumbs>
              </Grid>
            )}
            <AppHeaderIcons
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            src={images.drawerLogo}
            alt="logo"
            onClick={handleDrawerClose}
            style={{ cursor: "pointer" }}
          />
        </DrawerHeader>
        {open && (
          <Typography
            color="#3C3C3C"
            fontSize="12px"
            fontWeight={600}
            marginLeft={3}
            marginTop={3}
          >
            APP ANALYSIS
          </Typography>
        )}
        <List>
          {drawerItems?.map((item, index) => {
            if (index === 2 && item.show) {
              return (
                <>
                  <ListItem key={2} disablePadding sx={{ display: "block" }}>
                    <ListItemButton onClick={handleTournamentsClick}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center"
                        }}
                      >
                        <img src={images.tournaments} alt="" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              ml: 0.5,
                              color: "#3C3C3C"
                            }}
                          >
                            Tournaments
                          </Typography>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          pl: open ? 8 : "auto",
                          color: "#3C3C3C"
                        }}
                      >
                        {openTournamentsSubSections ? (
                          <ExpandLess fontSize="medium" color="inherit" />
                        ) : (
                          <ExpandMore fontSize="medium" color="inherit" />
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  {open && (
                    <Collapse
                      in={openTournamentsSubSections}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {(rolePermissions.TOURNAMENTS.TOURNAMENT_ANALYTICS
                          .VIEW ||
                          rolePermissions.TOURNAMENTS.TOURNAMENT_ANALYTICS
                            .VIEW_AND_EDIT) && (
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              width: open ? 220 : 60,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                              background:
                                selectedDrawerItem === 2
                                  ? "rgba(0, 0, 0, 0.04)"
                                  : "",
                              borderRadius:
                                selectedDrawerItem === 2
                                  ? "0px 20px 20px 0px"
                                  : ""
                            }}
                            onClick={() => {
                              sessionStorage.setItem(
                                "tournamentsSubSectionExpanded",
                                openTournamentsSubSections ? "true" : "false"
                              );
                              setSelectedDrawerItem(2);
                              sessionStorage.setItem("drawerItem", "2");
                              setOpen(open || false);
                              history.push("/tournaments-analytics");
                            }}
                            selected={selectedDrawerItem === 2}
                          >
                            <ListItemIcon sx={{ ml: 0.5 }}>
                              <CircleOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color:
                                      selectedDrawerItem === 2
                                        ? "#FF8100"
                                        : "#3C3C3C",
                                    ml: -1
                                  }}
                                >
                                  Analytics
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        )}
                        {(rolePermissions.TOURNAMENTS
                          .TOURNAMENT_CONFIGURATION_BOOT.VIEW ||
                          rolePermissions.TOURNAMENTS
                            .TOURNAMENT_CONFIGURATION_LEADERBOARD.VIEW ||
                          rolePermissions.TOURNAMENTS
                            .TOURNAMENT_CONFIGURATION_BOOT.VIEW_AND_EDIT ||
                          rolePermissions.TOURNAMENTS
                            .TOURNAMENT_CONFIGURATION_LEADERBOARD
                            .VIEW_AND_EDIT) && (
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              width: open ? 220 : 60,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                              background:
                                selectedDrawerItem === 8
                                  ? "rgba(0, 0, 0, 0.04)"
                                  : "",
                              borderRadius:
                                selectedDrawerItem === 8
                                  ? "0px 20px 20px 0px"
                                  : ""
                            }}
                            onClick={() => {
                              sessionStorage.setItem(
                                "tournamentsSubSectionExpanded",
                                openTournamentsSubSections ? "true" : "false"
                              );
                              setSelectedDrawerItem(8);
                              sessionStorage.setItem("drawerItem", "8");
                              setOpen(open || false);
                              history.push("/tournaments-configuration");
                            }}
                            selected={selectedDrawerItem === 8}
                          >
                            <ListItemIcon sx={{ ml: 0.5 }}>
                              <CircleOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color:
                                      selectedDrawerItem === 8
                                        ? "#FF8100"
                                        : "#3C3C3C",
                                    ml: -1
                                  }}
                                >
                                  Configuration
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        )}
                      </List>
                    </Collapse>
                  )}
                </>
              );
            }
            if (index === 4 && item.show) {
              return (
                <>
                  <ListItem key={6} disablePadding sx={{ display: "block" }}>
                    <ListItemButton onClick={handleRewardsClick}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center"
                        }}
                      >
                        <img src={images.rewards} alt="" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              ml: 0.5,
                              color: "#3C3C3C"
                            }}
                          >
                            Rewards
                          </Typography>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          pl: open ? 8 : "auto",
                          color: "#3C3C3C"
                        }}
                      >
                        {openRewardsSubSections ? (
                          <ExpandLess fontSize="medium" color="inherit" />
                        ) : (
                          <ExpandMore fontSize="medium" color="inherit" />
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  {open && (
                    <Collapse
                      in={openRewardsSubSections}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {(rolePermissions.REWARDS.REFERRALS_ANALYTICS.VIEW ||
                          rolePermissions.REWARDS.REWARDS_ANALYTICS.VIEW ||
                          rolePermissions.REWARDS.REFERRALS_ANALYTICS
                            .VIEW_AND_EDIT ||
                          rolePermissions.REWARDS.REWARDS_ANALYTICS
                            .VIEW_AND_EDIT) && (
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              width: open ? 220 : 60,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                              background:
                                selectedDrawerItem === 6
                                  ? "rgba(0, 0, 0, 0.04)"
                                  : "",
                              borderRadius:
                                selectedDrawerItem === 6
                                  ? "0px 20px 20px 0px"
                                  : ""
                            }}
                            onClick={() => {
                              sessionStorage.setItem(
                                "rewardsSubSectionExpanded",
                                openRewardsSubSections ? "true" : "false"
                              );
                              setSelectedDrawerItem(6);
                              sessionStorage.setItem("drawerItem", "6");
                              setOpen(open || false);
                              history.push("/rewards");
                            }}
                            selected={selectedDrawerItem === 6}
                          >
                            <ListItemIcon sx={{ ml: 0.5 }}>
                              <CircleOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color:
                                      selectedDrawerItem === 6
                                        ? "#FF8100"
                                        : "#3C3C3C",
                                    ml: -1
                                  }}
                                >
                                  Analytics
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        )}
                        {(rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
                          rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
                            .VIEW ||
                          rolePermissions.REWARDS.REWARDS_CONFIGURATION
                            .VIEW_AND_EDIT ||
                          rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
                            .VIEW_AND_EDIT) && (
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              width: open ? 220 : 60,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                              background:
                                selectedDrawerItem === 7
                                  ? "rgba(0, 0, 0, 0.04)"
                                  : "",
                              borderRadius:
                                selectedDrawerItem === 7
                                  ? "0px 20px 20px 0px"
                                  : ""
                            }}
                            onClick={() => {
                              sessionStorage.setItem(
                                "rewardsSubSectionExpanded",
                                openRewardsSubSections ? "true" : "false"
                              );
                              setSelectedDrawerItem(7);
                              sessionStorage.setItem("drawerItem", "7");
                              setOpen(open || false);
                              history.push("/rewards/add");
                            }}
                            selected={selectedDrawerItem === 7}
                          >
                            <ListItemIcon sx={{ ml: 0.5 }}>
                              <CircleOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color:
                                      selectedDrawerItem === 7
                                        ? "#FF8100"
                                        : "#3C3C3C",
                                    ml: -1
                                  }}
                                >
                                  Add Rewards
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        )}
                      </List>
                    </Collapse>
                  )}
                </>
              );
            }
            if (item.show) {
              return (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      width: open ? 220 : 60,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      background:
                        index === selectedDrawerItem
                          ? "rgba(0, 0, 0, 0.04)"
                          : "",
                      borderRadius:
                        index === selectedDrawerItem ? "0px 20px 20px 0px" : ""
                    }}
                    onClick={() => {
                      setSelectedDrawerItem(index);
                      sessionStorage.setItem("drawerItem", index.toString());
                      setOpen(open || false);
                      history.push(item.path);
                    }}
                    selected={index === selectedDrawerItem}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center"
                      }}
                    >
                      {index === 0 && <img src={images.dashboard} alt="" />}
                      {index === 1 && <img src={images.games} alt="" />}
                      {index === 3 && <img src={images.users} alt="" />}
                      {/* {index === 4 && <img src={images.revenue} alt="" />} */}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color:
                              index === selectedDrawerItem
                                ? "#FF8100"
                                : "#3C3C3C"
                          }}
                        >
                          {item.name}
                        </Typography>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            }
            return null;
          })}
        </List>
        {!open &&
          (rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW ||
            rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW ||
            rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW_AND_EDIT ||
            rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW_AND_EDIT) && (
            <Divider />
          )}
        {open &&
          (rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW ||
            rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW ||
            rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW_AND_EDIT ||
            rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW_AND_EDIT) && (
            <Typography
              color="#3C3C3C"
              fontSize="12px"
              fontWeight={600}
              marginLeft={3}
              marginTop={3}
            >
              USER MANAGEMENT
            </Typography>
          )}
        {(rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW ||
          rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW ||
          rolePermissions.USERS_PERMISSION.EDIT_ROLES.VIEW_AND_EDIT ||
          rolePermissions.USERS_PERMISSION.EDIT_USERS.VIEW_AND_EDIT) && (
          <List>
            <ListItem
              key={8}
              disablePadding
              sx={{ display: "block", marginLeft: 0.5 }}
            >
              <ListItemButton onClick={handleClickAdminControl}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center"
                  }}
                >
                  <img src={images.user_management} alt="" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "#3C3C3C"
                      }}
                    >
                      Admin Control
                    </Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    pl: open ? 8 : "auto",
                    color: "#3C3C3C"
                  }}
                >
                  {openAdminControlSubSections ? (
                    <ExpandLess fontSize="medium" color="inherit" />
                  ) : (
                    <ExpandMore fontSize="medium" color="inherit" />
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            {open && (
              <Collapse
                in={openAdminControlSubSections}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      width: open ? 220 : 60,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      background:
                        selectedDrawerItem === 9 ? "rgba(0, 0, 0, 0.04)" : "",
                      borderRadius:
                        selectedDrawerItem === 9 ? "0px 20px 20px 0px" : ""
                    }}
                    onClick={() => {
                      sessionStorage.setItem(
                        "adminControlSubSectionExpanded",
                        openAdminControlSubSections ? "true" : "false"
                      );
                      setSelectedDrawerItem(9);
                      sessionStorage.setItem("drawerItem", "9");
                      setOpen(open || false);
                      history.push("/user-management");
                    }}
                    selected={selectedDrawerItem === 9}
                  >
                    <ListItemIcon>
                      {selectedDrawerItem === 9 ? (
                        <Circle sx={{ color: "#FF8100", fontSize: 13 }} />
                      ) : (
                        <CircleOutlinedIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color:
                              selectedDrawerItem === 9 ? "#FF8100" : "#3C3C3C",
                            ml: -2
                          }}
                        >
                          User Management
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            )}
          </List>
        )}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {content}
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ textAlign: "center", mt: 2 }}
        >
          <Typography color="#A8A8A8" fontSize={12} fontWeight={500}>
            All rights reserved{" "}
            <img
              width={16}
              height={16}
              style={{ marginBottom: 2 }}
              src={images.copyright}
              alt="copyright"
            />{" "}
            2023
          </Typography>
        </Grid>
      </Box>
    </Box>
  );
}
