import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../../styles/colors";
import images from "assets/images/images";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatNumberToK } from "utils/helpers";

const EarningsReport = (props) => {
  const { tournamentDetails } = props;

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container>
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: colors.white,
          borderRadius: "21px",
          padding: 2.5,
          ml: matchesSm ? 0 : 2,
          mt: matchesSm ? 2 : 0
        }}
      >
        <Grid item container md={12} lg={12}>
          <Grid item md={7} lg={7}>
            <Box>
              <Typography
                color="#3C3C3C"
                fontSize="16px"
                fontWeight={600}
                sx={{ py: 1 }}
              >
                Earnings Report
              </Typography>
              <Typography color="#3C3C3C" fontSize="12px" fontWeight={400}>
                Tournament Sales Overview
              </Typography>
            </Box>
            <Box>
              <Typography
                color="#3C3C3C"
                fontSize="32px"
                fontWeight={700}
                sx={{ py: 1 }}
              >
                {tournamentDetails
                  ? formatNumberToK(tournamentDetails?.prizePool, "money")
                  : ""}
              </Typography>
              <Typography color="#3C3C3C" fontSize="12px" fontWeight={400}>
                Total Prize pool of the tournament
              </Typography>
            </Box>
          </Grid>
          <Grid item md={5} lg={5} sx={{ display: "flex", alignItems: "end" }}>
            <Box
              sx={{
                ml: 5,
                mt: 1,
                mr: 2,
                p: 0.5,
                borderLeft: "2px solid gainsboro",
                borderBottom: "2px solid gainsboro"
              }}
            >
              <img
                src={images.curve2}
                alt="curve"
                style={{
                  height: "90px",
                  width: "210px",
                  marginLeft: "4px",
                  marginBottom: -1
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          md={12}
          lg={12}
          p={3}
          my={2}
          sx={{
            border: "0.5px solid gainsboro",
            borderRadius: "8px",
            mt: 3
          }}
        >
          <Grid item xs={12} sm={4} md={4} lg={4} pl={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  fontSize: "20px",
                  backgroundColor: "rgba(101, 115, 247, 0.26)",
                  color: "#6573F6",
                  height: "26px",
                  width: "26px",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CurrencyRupeeIcon fontSize="inherit" color="inherit" />
              </Box>

              <Typography
                color={colors.lightGray}
                fontSize="16px"
                fontWeight={500}
                sx={{ ml: 1 }}
              >
                Revenue
              </Typography>
            </Box>
            <Typography
              color="#3C3C3C"
              fontSize="20px"
              fontWeight={600}
              mt={1.5}
            >
              {tournamentDetails
                ? formatNumberToK(tournamentDetails?.revenue, "money")
                : ""}
            </Typography>
            <Box sx={{ color: colors.blue, mt: 1.5, width: "80%" }}>
              <LinearProgress
                variant="determinate"
                value={50}
                color={"inherit"}
                sx={{ height: 10, borderRadius: 5 }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} pl={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  fontSize: "20px",
                  backgroundColor: "rgba(45, 188, 102, 0.26)",
                  color: "#2DBC66",
                  height: "26px",
                  width: "26px",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <span
                  style={{
                    margin: 15,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <img
                    src={images.commissionIcon}
                    alt=""
                    color="#2DBC66"
                    height="18px"
                    width="18px"
                  />
                </span>
              </Box>

              <Typography
                color={colors.lightGray}
                fontSize="16px"
                fontWeight={500}
                sx={{ ml: 1 }}
              >
                Commission
              </Typography>
            </Box>
            <Typography
              color="#3C3C3C"
              fontSize="20px"
              fontWeight={600}
              mt={1.5}
            >
              {tournamentDetails
                ? formatNumberToK(tournamentDetails?.commission, "money")
                : ""}
            </Typography>
            <Box sx={{ color: colors.green, mt: 1.5, width: "80%" }}>
              <LinearProgress
                variant="determinate"
                value={50}
                color={"inherit"}
                sx={{ height: 10, borderRadius: 5 }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} pl={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  fontSize: "20px",
                  backgroundColor: "rgba(239, 0, 0, 0.26)",
                  color: "#EF0000",
                  height: "26px",
                  width: "26px",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <ShowChartIcon fontSize="inherit" color="inherit" />
              </Box>

              <Typography
                color={colors.lightGray}
                fontSize="16px"
                fontWeight={500}
                sx={{ ml: 1 }}
              >
                Pay Out
              </Typography>
            </Box>
            <Typography
              color="#3C3C3C"
              fontSize="20px"
              fontWeight={600}
              mt={1.5}
            >
              {tournamentDetails
                ? formatNumberToK(tournamentDetails?.payout, "money")
                : ""}
            </Typography>
            <Box sx={{ color: colors.red, mt: 1.5, width: "80%" }}>
              <LinearProgress
                variant="determinate"
                value={50}
                color={"inherit"}
                sx={{ height: 10, borderRadius: 5 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EarningsReport;
