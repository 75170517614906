import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment
} from "@mui/material";
import { colors } from "../../../../styles/colors";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMutation } from "@apollo/client";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
  CREATE_REWARD_CATEGORY,
  UPDATE_REWARD_CATEGORY
} from "app/graphql/rewards";
import { getJwtTokenDetails } from "utils/helpers";
import { withStyles } from "@mui/styles";

const addBrandDetailsOptions = [
  { id: 1, label: "Yes" },
  { id: 2, label: "No" }
];

const AddRewardCategoryComponent = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setAddCategory,
    setSelctedRow
  } = props;

  const { rolePermissions } = getJwtTokenDetails();

  const OrangeRadio = withStyles((theme) => ({
    root: {
      color: theme.palette.grey[400],
      "&$checked": {
        color: colors.invalidInput
      }
    },
    checked: {}
  }))(Radio);

  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.TableHead,
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          borderRadius: "4px 4px 0px 0px",
          pl: "20px,",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "49px",
            letterSpacing: "0.15em",
            textAlign: "left",
            display: "inline-block",
            pl: 2
          }}
        >
          {values?.id
            ? rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION?.VIEW_AND_EDIT
              ? "Edit Reward Category"
              : "View Reward Category Details"
            : "Add New Reward Category"}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.backgroundBoxLight,
          borderRadius: "4px",
          p: 3
        }}
      >
        <Grid container spacing={1}>
          {values.id && (
            <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5}>
              <Grid
                item
                container
                xs={12}
                sm={3.5}
                md={3.5}
                lg={3.5}
                alignItems={"center"}
              >
                <Typography fontSize={15} fontWeight={400}>
                  Reward No
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    borderRadius: "4px",
                    height: 40,
                    width: "90%",
                    justifyContent: "start",
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    pl: 2
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={colors.lightGreyIcon}
                  >
                    {values.id}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
            <Grid
              item
              container
              xs={12}
              sm={3.5}
              md={3.5}
              lg={3.5}
              alignItems={"center"}
            >
              <Typography fontSize={15} fontWeight={400}>
                Reward Category
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
              <TextField
                id="rewardType"
                size="small"
                fullWidth
                value={values.rewardType}
                placeholder="eg: Coupons, Gift Box etc"
                onChange={(e) => {
                  if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                    setFieldValue("rewardType", e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography fontSize={13}>{`${
                        values.rewardType?.length || 0
                      }/40`}</Typography>
                    </InputAdornment>
                  )
                }}
                sx={{
                  backgroundColor: colors.FieldBackgroundLight,
                  input: { color: "#020202" },
                  display: "inline-block",
                  width: "90%"
                  // border: "0.5px solid rgba(168, 168, 168, 0.5)"
                }}
                onBlur={handleBlur}
                disabled={
                  !rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
                    ?.VIEW_AND_EDIT
                }
              />
              {touched?.rewardType && errors?.rewardType && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  {errors?.rewardType}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} mt={3} px={2}>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                <Typography fontSize={15} fontWeight={400}>
                  Brand Details
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={colors.redAsterisk}
                >
                  *
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5} mt={-1}>
                <RadioGroup
                  row
                  id="brand"
                  defaultValue={2}
                  value={values.brand}
                  onChange={(event) => {
                    setFieldValue("brand", parseInt(event.currentTarget.value));
                  }}
                >
                  <FormControlLabel
                    value={1}
                    control={<OrangeRadio size="small" />}
                    label={
                      <Typography fontSize={15} fontWeight={400}>
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={2}
                    control={<OrangeRadio size="small" />}
                    label={
                      <Typography fontSize={15} fontWeight={400}>
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Typography fontSize={15} fontWeight={400}>
                Min. Reward Value
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
              <TextField
                id="minRewardValue"
                size="small"
                fullWidth
                value={values.minRewardValue}
                onChange={(e) => {
                  if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                    setFieldValue("minRewardValue", e.target.value);
                  }
                }}
                sx={{
                  backgroundColor: colors.FieldBackgroundLight,
                  input: { color: "#020202" },
                  display: "inline-block",
                  width: "90%"
                  // border: "0.5px solid rgba(168, 168, 168, 0.5)"
                }}
                onBlur={handleBlur}
                disabled={
                  !rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
                    ?.VIEW_AND_EDIT
                }
              />
              {touched?.minRewardValue && errors?.minRewardValue && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  {errors?.minRewardValue}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Typography fontSize={15} fontWeight={400}>
                Add Brand Details
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
              <Autocomplete
                options={addBrandDetailsOptions}
                getOptionLabel={(option) => option.label}
                disableClearable={true}
                value={addBrandDetailsOptions?.find(
                  (x) => x.label === values.addBrandDetails
                )}
                onChange={(event: any, value: any) => {
                  setFieldValue("addBrandDetails", value.label);
                }}
                sx={{
                  backgroundColor: colors.FieldBackgroundLight,
                  width: "90%"
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="addBrandDetails"
                    size="small"
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: "#020202" },
                      display: "inline-block"
                      // width: "90%"
                      // border: "0.5px solid rgba(168, 168, 168, 0.5)"
                    }}
                  />
                )}
                disabled={
                  !rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
                    ?.VIEW_AND_EDIT
                }
              />
              {touched?.addBrandDetails && errors?.addBrandDetails && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  {errors?.addBrandDetails}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
            <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
              <Typography fontSize={15} fontWeight={400}>
                Max. Reward Value
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
              <TextField
                id="maxRewardValue"
                size="small"
                fullWidth
                value={values.maxRewardValue}
                onChange={(e) => {
                  if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                    setFieldValue("maxRewardValue", e.target.value);
                  }
                }}
                sx={{
                  backgroundColor: colors.FieldBackgroundLight,
                  input: { color: "#020202" },
                  display: "inline-block",
                  width: "90%"
                  // border: "0.5px solid rgba(168, 168, 168, 0.5)"
                }}
                onBlur={handleBlur}
                disabled={
                  !rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
                    ?.VIEW_AND_EDIT
                }
              />
              {touched?.maxRewardValue && errors?.maxRewardValue && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  {errors?.maxRewardValue}
                </Typography>
              )}
            </Grid>
          </Grid> */}
        </Grid>

        <Grid container>
          {rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
            ?.VIEW_AND_EDIT && (
            <Button
              size="small"
              variant="contained"
              sx={{
                width: { lg: "12%", md: "14%", sm: "17%", xs: "17%" },
                background:
                  "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                borderRadius: "36px",
                height: "38px",
                fontWeight: 400,
                fontSize: "14px",
                letterSpacing: "0.02em",
                margin: "20px 0px",
                // ml: "20px",
                color: "#FFF9F2"
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {values.id ? "Update Category" : "Add Category"}
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            sx={{
              width: { lg: "12%", md: "14%", sm: "17%", xs: "17%" },
              background: "#bbb5af",
              borderRadius: "36px",
              height: "38px",
              fontWeight: 400,
              fontSize: "16px",
              letterSpacing: "0.02em",
              margin: "20px 20px",
              color: "#FFF9F2"
            }}
            onClick={() => {
              setSelctedRow({});
              setAddCategory(false);
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "130%",
            left: "55%",
            zIndex: 1005,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

const AddRewardCategoryForm = (props: any) => {
  const {
    rewardCategoryDetail,
    setAddCategory,
    refetchAllRewardCategoriesData,
    setSelctedRow,
    rewardCategoryDataLoading
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [createRewardCategory, { loading: createRewardCategoryLoading }] =
    useMutation(CREATE_REWARD_CATEGORY, {
      onCompleted: (data) => {
        console.log(data);
        const { createRewardCategory } = data;
        const { status, message } = createRewardCategory;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refetchAllRewardCategoriesData();
          setAddCategory(false);
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    });

  const [updateRewardCategory, { loading: updateRewardCategoryLoading }] =
    useMutation(UPDATE_REWARD_CATEGORY, {
      onCompleted: (data) => {
        console.log(data);
        const { updateRewardCategory } = data;
        const { status, message } = updateRewardCategory;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });

          refetchAllRewardCategoriesData();
          setSelctedRow({});
          setAddCategory(false);
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    });

  const AddRewardCategoryFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      const brand = rewardCategoryDetail?.brand
        ? JSON.parse(rewardCategoryDetail?.brand)
        : {};
      return {
        id: rewardCategoryDetail?.id || null,
        rewardType: rewardCategoryDetail?.type || "",
        brand: brand?.brand === "Yes" ? 1 : 2,
        // minRewardValue: rewardCategoryDetail?.minRewardValue || "",
        // maxRewardValue: rewardCategoryDetail?.maxRewardValue || "",
        // addBrandDetails: brandDetails?.addBrandDetails
        //   ? brandDetails?.addBrandDetails
        //   : "",
        loading:
          createRewardCategoryLoading ||
          updateRewardCategoryLoading ||
          rewardCategoryDataLoading
      };
    },

    validationSchema: Yup.object().shape({
      rewardType: Yup.string()
        .required("This is a required field")
        .max(40, "Maximum 40 characters are allowed")
      // minRewardValue: Yup.number()
      //   .required("This is a required field")
      //   .typeError("Please enter a valid number")
      //   .test(
      //     "minimum",
      //     "Minimum value must be less than or equal to maximum value",
      //     function (value: any) {
      //       const { maxRewardValue } = this.parent;
      //       if (maxRewardValue) {
      //         return parseInt(value) <= parseInt(maxRewardValue);
      //       } else {
      //         return true;
      //       }
      //     }
      //   ),
      // maxRewardValue: Yup.number()
      //   .required("This is a required field")
      //   .typeError("Please enter a valid number")
      //   .test(
      //     "maximum",
      //     "Maximum value must be greater than or equal to minimum value",
      //     function (value: any) {
      //       const { minRewardValue } = this.parent;
      //       return parseInt(value) >= parseInt(minRewardValue);
      //     }
      //   ),
      // addBrandDetails: Yup.string().required("This is a required field")
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);
      if (values.id) {
        updateRewardCategory({
          variables: {
            input: {
              id: values.id,
              type: values.rewardType,
              brand: JSON.stringify({
                brand: values?.brand === 1 ? "Yes" : "No"
              })
              // minRewardValue: parseInt(values.minRewardValue),
              // maxRewardValue: parseInt(values.maxRewardValue),
            }
          }
        });
      } else {
        createRewardCategory({
          variables: {
            input: {
              type: values.rewardType,
              brand: JSON.stringify({
                brand: values?.brand === 1 ? "Yes" : "No"
              })
              // minRewardValue: parseInt(values.minRewardValue),
              // maxRewardValue: parseInt(values.maxRewardValue),
            }
          }
        });
      }
    }
  })(AddRewardCategoryComponent);
  return <AddRewardCategoryFormWrapped {...props} />;
};

export default AddRewardCategoryForm;
