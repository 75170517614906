import React from "react";
import { Grid, Box, Typography, TextField, Autocomplete } from "@mui/material";
import { colors } from "../../../../../styles/colors";
import images from "../../../../../assets/images/images";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import PieChart from "../../../PieChart";
import { useMutation } from "@apollo/client";
import { withFormik } from "formik";
import * as Yup from "yup";
interface IDefaultDetails {
  rewardCategory: string;
  setRewardCategory: any;
}

const DefaultDetailsComponent = (props) => {
  const {
    rewardCategory,
    setRewardCategory,
    values,
    setFieldValue,
    touched,
    errors,
    setAlgorithmConfigKey
    // handleBlur,
    // handleSubmit
  } = props;

  const rewardCategoriesArr = [
    {
      Id: 1,
      Name: "Spin The Wheel",
      category: "spinTheWheelAlgorithm"
    },
    {
      Id: 2,
      Name: "Mystery Box",
      category: "mysteryBoxAlgorithm"
    }
  ];

  const numberOfSlicesArr = [
    {
      Id: 1,
      Name: "4",
      Value: 4
    },
    {
      Id: 2,
      Name: "8",
      Value: 8
    },
    {
      Id: 3,
      Name: "12",
      Value: 12
    },
    {
      Id: 4,
      Name: "16",
      Value: 16
    }
  ];

  const [dataForPieChart, setDataForPieChart] = React.useState<any>([]);

  const getChartData = (numberOfSlices: number) => {
    const tempArr: any = [];
    for (let i = 0; i < numberOfSlices; i++) {
      tempArr.push({
        name: "Chrome",
        y: 100 / numberOfSlices,
        label: i + 1
      });
    }
    setDataForPieChart([
      {
        name: "Spin The Wheel",
        data: tempArr
      }
    ]);
  };

  React.useEffect(() => {
    getChartData(8);
  }, []);

  const pieChartSectionColors = [
    "#FF7E7E",
    "#F0EA60",
    "#90D8F7",
    "#94F0B3",
    "#FF7E7E",
    "#F0EA60",
    "#90D8F7",
    "#94F0B3",
    "#FF7E7E",
    "#F0EA60",
    "#90D8F7",
    "#94F0B3",
    "#FF7E7E",
    "#F0EA60",
    "#90D8F7",
    "#94F0B3"
  ];

  // console.log("reward category id", values.rewardCategory);

  return (
    <Grid
      container
      sx={{
        backgroundColor: colors.backgroundBoxLight,
        borderRadius: "4px",
        p: 3,
        mt: 2,
        mb: 1
      }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box sx={{ mb: 2 }}>
          <Typography fontSize={14} fontWeight={700}>
            DEFAULT DETAILS
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={12} sm={12} md={12} lg={12} sx={{ my: 2 }}>
        <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
          <Box sx={{ display: "flex" }}>
            <Typography fontSize={15} fontWeight={400}>
              Choose the rewards category
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
          <Autocomplete
            options={rewardCategoriesArr || []}
            getOptionLabel={(option) => option?.Name}
            disableClearable={true}
            value={rewardCategoriesArr?.find(
              (x) => x.Id === values.rewardCategory
            )}
            onChange={(e, value: any) => {
              setAlgorithmConfigKey(value?.category);
              // setRewardCategory(value?.category);
              setFieldValue("rewardCategory", value.Id);
            }}
            sx={{
              backgroundColor: colors.FieldBackgroundLight,
              width: "100%",
              height: 20
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="rewardCategory"
                size="small"
                sx={{
                  backgroundColor: colors.FieldBackgroundLight,
                  input: { color: "#020202" },
                  display: "inline-block",
                  border: "0.5px",
                  borderColor: "#A8A8A850"
                }}
              />
            )}
          />
          {touched?.rewardCategory && errors?.rewardCategory && (
            <Typography
              sx={{ color: colors.danger, fontSize: "11px", fontWeight: 600 }}
            >
              {errors?.rewardCategory}
            </Typography>
          )}
        </Grid>
      </Grid>
      {values.rewardCategory === 1 && (
        <Grid item container xs={12} sm={12} md={12} lg={12} sx={{ my: 3 }}>
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Box sx={{ display: "flex" }}>
              <Typography fontSize={15} fontWeight={400}>
                No of Slices
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={colors.redAsterisk}
              >
                *
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Autocomplete
              options={numberOfSlicesArr || []}
              getOptionLabel={(option: any) => option.Name}
              readOnly={true}
              value={numberOfSlicesArr?.find(
                (x) => x.Id === values.numberOfSlices
              )}
              onChange={(e, value: any) => {
                setFieldValue("numberOfSlices", value.Id);
                getChartData(value.Value || 8);
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                width: "100%",
                height: 20
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="numberOfSlices"
                  size="small"
                  sx={{
                    backgroundColor: colors.FieldBackgroundLight,
                    input: { color: "#020202" },
                    display: "inline-block",
                    border: "0.5px",
                    borderColor: "#A8A8A850"
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {values.rewardCategory === 1 && (
        <Grid item container xs={12} sm={12} md={12} lg={12} sx={{ my: 2 }}>
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}></Grid>
          <Grid
            item
            container
            xs={12}
            sm={8.5}
            md={8.5}
            lg={8.5}
            sx={{
              backgroundColor: colors.FieldBackgroundLight,
              borderRadius: "4px",
              p: 2
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                fontSize={14}
                fontWeight={700}
                color={colors.tableHeader}
              >
                NOTE
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12}>
              <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} mt={1} pl={2}>
                <PieChart
                  title={""}
                  subtitle={""}
                  data={dataForPieChart}
                  partColors={pieChartSectionColors}
                  properties={{
                    height: 200,
                    width: 200
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
                <Box mt={1}>
                  <Typography fontSize={13} fontWeight={400}>
                    - In this image, each color signifies different prizes. So
                    for each prizes, there will be 2 slots in the wheel.
                  </Typography>
                </Box>
                <Box ml={1}>
                  <Typography fontSize={13} fontWeight={400}>
                    A. Red (1,5)
                  </Typography>
                </Box>
                <Box ml={1}>
                  <Typography fontSize={13} fontWeight={400}>
                    B. Yellow (2,6)
                  </Typography>
                </Box>
                <Box ml={1}>
                  <Typography fontSize={13} fontWeight={400}>
                    C. Blue (3,7)
                  </Typography>
                </Box>
                <Box ml={1}>
                  <Typography fontSize={13} fontWeight={400}>
                    D. Green (4,8)
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography fontSize={13} fontWeight={400}>
                    - The outcome of the spin depends on different variables of
                    the games.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography fontSize={13} fontWeight={400}>
                    - The variables of the spin is being decided on the spending
                    pattern of the player.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography fontSize={13} fontWeight={400}>
                    - In each variable, chances of getting a specific Prize will
                    be higher.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {values.rewardCategory === 2 && (
        <Grid item container xs={12} sm={12} md={12} lg={12} sx={{ my: 2 }}>
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}></Grid>
          <Grid
            item
            container
            xs={12}
            sm={8.5}
            md={8.5}
            lg={8.5}
            sx={{
              backgroundColor: colors.FieldBackgroundLight,
              borderRadius: "4px",
              p: 2
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                fontSize={14}
                fontWeight={700}
                color={colors.tableHeader}
              >
                NOTE
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12}>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Box
                  sx={{
                    width: "100%",
                    mt: 3
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      height: "150px",
                      width: "150px"
                    }}
                  >
                    <Grid container xs={12} sm={12} md={12} lg={12}>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxDarkPink
                          }}
                        ></Box>
                      </Grid>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxYellow
                          }}
                        ></Box>
                      </Grid>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxLightBlue
                          }}
                        ></Box>
                      </Grid>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxLightGreen
                          }}
                        ></Box>
                      </Grid>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxLightOrange,
                            color: colors.white
                          }}
                        >
                          <QuestionMarkOutlinedIcon
                            fontSize="medium"
                            color="inherit"
                          />
                        </Box>
                      </Grid>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxPurple
                          }}
                        ></Box>
                      </Grid>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxBlue
                          }}
                        ></Box>
                      </Grid>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxGreen
                          }}
                        ></Box>
                      </Grid>
                      <Grid item container xs={4} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            height: "44px",
                            width: "100%",
                            mr: 0.5,
                            mt: 0.5,
                            backgroundColor: colors.MysteryBoxLightPink
                          }}
                        ></Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9}>
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    mt: 6
                  }}
                >
                  <Box>
                    <Typography fontSize={13} fontWeight={400}>
                      - There will be 9 boxes on the screen, player will select
                      1.
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography fontSize={13} fontWeight={400}>
                      - The prizes on the box will directly dependent on the
                      spending pattern of the player.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={13} fontWeight={400}>
                      - The prizes will be categorized in five tiers, Tier 1
                      being the most attractive gifts, and
                    </Typography>
                    <Typography fontSize={13} fontWeight={400} ml={"12px"}>
                      tier 5 being the free items.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const DefaultDetailsForm = (props: any) => {
  const { algorithmConfigKey } = props;

  const DefaultDetailsFormWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        rewardCategory: algorithmConfigKey === "mysteryBoxAlgorithm" ? 2 : 1,
        numberOfSlices: 2
      };
    },

    // validationSchema: Yup.object().shape({
    //   rewardCategory: Yup.number().required("This is a required field")
    // }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);
    }
  })(DefaultDetailsComponent);
  return <DefaultDetailsFormWrapped {...props} />;
};

export default DefaultDetailsForm;
