import React from "react";
import { Grid, Typography, Box, Divider } from "@mui/material";
import { Square } from "@mui/icons-material";
import { colors } from "styles/colors";
import ColumChartStack from "../ColumnChartStack";
import { formatNumberToK } from "utils/helpers";

const TotalReturnsChart = (props) => {
  const {
    chartData,
    setFilter,
    bootTournamentIncome,
    leaderboardTournamentIncome
  } = props;

  const xAxisLabels =
    chartData && chartData?.length > 0
      ? chartData[0]?.data?.map((item) => item?.name)
      : [];

  const graphData = chartData?.map((item1, index) => ({
    name: item1?.name,
    pointWidth: 25,
    data: item1?.data?.map((item2) => ({
      name: item2?.name || "",
      y: item2?.value || 0,
      color: index === 1 ? colors.orange : colors.purple
    }))
  }));

  const duration = ["1D", "5D", "1M", "6M", "YTD"];

  return (
    <Box
      sx={{
        backgroundColor: colors.backgroundLight,
        p: 3,
        borderRadius: "4px"
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            fontSize="18px"
            color={colors.lightGreyIcon}
            fontWeight={600}
          >
            Total Returns Analysis
          </Typography>
          <Typography
            fontSize="14px"
            color={colors.lightGreyIcon}
            fontWeight={400}
          >
            Tournament-Boot Return Analysis
          </Typography>
        </Box>
        <Box display="flex">
          {duration?.map((item, index) => {
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  color="#3C3C3C"
                  fontSize="13px"
                  fontWeight={600}
                  sx={{ px: 2.5, py: 1, cursor: "pointer" }}
                  onClick={() => setFilter(item?.toLowerCase())}
                >
                  {item}
                </Typography>
                {index < duration.length - 1 && (
                  <Box
                    sx={{
                      height: 20,
                      width: 1.5,
                      backgroundColor: "rgba(168, 168, 168, 0.5)"
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ my: 1 }}>
        <ColumChartStack
          xAxisLabels={xAxisLabels}
          data={graphData}
          partColors={["#9F7FFC", "#FF9D39"]}
          properties={{ height: 350 }}
        />
      </Box>
      <Grid container spacing={1}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Box display="flex" justifyContent="center">
            <Square fontSize="small" sx={{ color: "#FF9D39", mr: 1 }} />
            <Box>
              <Typography color="#8C8E8F" fontSize="14px" fontWeight={500}>
                Total Boot Income
              </Typography>
              <Typography
                color={colors.lightGreyIcon}
                fontSize="18px"
                fontWeight={700}
              >
                {bootTournamentIncome
                  ? formatNumberToK(bootTournamentIncome, "money")
                  : "₹ 0"}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Box display="flex">
            <Square fontSize="small" sx={{ color: "#9F7FFC", mr: 1 }} />
            <Box>
              <Typography color="#8C8E8F" fontSize="14px" fontWeight={500}>
                Total Tournament Income
              </Typography>
              <Typography
                color={colors.lightGreyIcon}
                fontSize="18px"
                fontWeight={700}
              >
                {leaderboardTournamentIncome
                  ? formatNumberToK(leaderboardTournamentIncome, "money")
                  : "₹ 0"}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Box display="flex" alignItems="center">
            <Square sx={{ color: "#FF9D39", fontSize: 15, mr: 0.5 }} />
            <Typography
              color={colors.lightGreyIcon}
              fontSize="12px"
              fontWeight={400}
            >
              Boot
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Square sx={{ color: "#9F7FFC", fontSize: 15, mr: 0.5 }} />
            <Typography
              color={colors.lightGreyIcon}
              fontSize="12px"
              fontWeight={400}
            >
              Tournament
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TotalReturnsChart;
