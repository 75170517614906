import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
  MenuItem,
  Popover
} from "@mui/material";
import images from "assets/images/images";
import { makeStyles } from "@mui/material";
import { colors } from "../../../../styles/colors";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddRewardCategory from "./AddRewardCategory";
import { getDateInFormat, getJwtTokenDetails } from "utils/helpers";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_REWARD_CATEGORY,
  GET_REWARD_CATEGORY_DETAIL
} from "app/graphql/rewards";
import { VisibilityOutlined } from "@mui/icons-material";

const RewardCategory = (props) => {
  const {
    allRewardCategories,
    refetchAllRewardCategoriesData,
    deleteRewardCategory
  } = props;

  const { rolePermissions } = getJwtTokenDetails();

  const [addCategory, setAddCategory] = useState(false);
  // const handeladdCategory = () => setAddCategory(!addCategory);

  const options2 = [
    { Id: 1, Name: "UG" },
    { Id: 2, Name: "PG" }
  ];
  const [rewardCategoryDetail, setRewardCategoryDetail] = useState({});
  const [selectedRow, setSelctedRow] = useState<any>({});

  const {
    data: rewardCategoryData,
    loading: rewardCategoryDataLoading,
    refetch: refetchRewardCategoryData
  } = useQuery(GET_REWARD_CATEGORY_DETAIL, {
    variables: {
      id: selectedRow?.id
    },
    skip: !selectedRow?.id,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      rewardCategoryData &&
      rewardCategoryData?.getRewardCategoryById &&
      rewardCategoryData?.getRewardCategoryById?.status
    ) {
      const data = rewardCategoryData?.getRewardCategoryById?.rewardCategory;
      setRewardCategoryDetail(data || []);
      setAddCategory(true);
    }
  }, [rewardCategoryData]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleEdit = (data) => {
    setSelctedRow(data);
  };

  return (
    <>
      {addCategory ? (
        <>
          <AddRewardCategory
            rewardCategoryDetail={rewardCategoryDetail}
            setAddCategory={setAddCategory}
            refetchAllRewardCategoriesData={refetchAllRewardCategoriesData}
            setSelctedRow={setSelctedRow}
            rewardCategoryDataLoading={rewardCategoryDataLoading}
          />
        </>
      ) : (
        <Box mt={2}>
          <Box
            sx={{
              backgroundColor: colors.TableHead,
              border: "0.5px solid rgba(168, 168, 168, 0.5)",
              borderRadius: "4px 4px 0px 0px",
              pl: "20px,",
              py: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 600,
                  textAlign: "left",
                  display: "inline-block",
                  pl: 2
                }}
              >
                Reward Categories
              </Typography>
              {/* <HelpOutlineIcon fontSize="small" sx={{ ml: 2, mt: "1px" }} /> */}
            </Box>
            <Box sx={{ alignContent: "right", mr: 3 }}>
              {rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
                ?.VIEW_AND_EDIT && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setRewardCategoryDetail({});
                    setAddCategory(true);
                  }}
                  sx={{
                    width: "180px",
                    height: "38px",
                    background:
                      "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                    // boxShadow:
                    //   '-6px -6px 12px rgba(193, 193, 193, 0.08), 6px 6px 12px rgba(4, 4, 4, 0.45)',
                    borderRadius: "30px",
                    fontWeight: 500,
                    fontSize: "14px"
                  }}
                >
                  New Reward Category
                </Button>
              )}
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: colors.backgroundBoxLight,
              borderRadius: "0px 0px 4px 4px "
            }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: colors.TableHeadDark }}>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>DATE ADDED</TableCell>
                  {/* <TableCell>VALUE RANGE (MIN. - MAX.)</TableCell> */}
                  {/* <TableCell>COUNT</TableCell> */}
                  <TableCell>ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allRewardCategories &&
                  allRewardCategories?.length > 0 &&
                  allRewardCategories?.map((item) => {
                    const dateAdded = getDateInFormat(
                      parseInt(item.createdAt),
                      "DD/MM/YYYY",
                      "changeFormat"
                    );
                    return (
                      <TableRow key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>{dateAdded}</TableCell>
                        {/* <TableCell>{`${item.minRewardValue} - ${item.maxRewardValue}`}</TableCell> */}
                        {/* <TableCell>{item.Count}</TableCell> */}
                        <TableCell>
                          {rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION
                            .VIEW_AND_EDIT ? (
                            <Grid container spacing={0}>
                              <Grid item>
                                <span
                                  style={{
                                    display: "flex",
                                    alignContent: "center",
                                    marginRight: "16px",
                                    marginTop: "8px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    src={images.EditIcon}
                                    alt=""
                                    onClick={() => {
                                      handleEdit(item);
                                    }}
                                  />
                                </span>
                              </Grid>
                              <Grid item>
                                <span
                                  style={{
                                    display: "flex",
                                    alignContent: "center",
                                    // marginRight: "8px",
                                    marginTop: "9px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    src={images.Delete_Icon}
                                    alt=""
                                    onClick={() => {
                                      deleteRewardCategory({
                                        variables: {
                                          id: item.id
                                        }
                                      });
                                    }}
                                  />
                                </span>
                              </Grid>
                              {/* <Grid item>
                                <IconButton
                                  onClick={(event) => {
                                    handleClick(event);
                                  }}
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                >
                                  <MoreVertOutlinedIcon
                                    fontSize="small"
                                    sx={{ color: colors.lightGreyIcon }}
                                  />
                                </IconButton>
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: 30,
                                    horizontal: -50
                                  }}
                                  sx={{ maxHeight: "none" }}
                                >
                                  <MenuItem
                                    sx={{
                                      color: colors.lightGreyIcon
                                    }}
                                  >
                                    <Typography
                                      fontSize={16}
                                      color={colors.fontPrimary}
                                    >
                                      View
                                    </Typography>
                                  </MenuItem>
                                </Popover>
                              </Grid> */}
                            </Grid>
                          ) : (
                            <Grid container spacing={0}>
                              <Grid item>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default RewardCategory;
