import React from "react";
import { Grid, Typography, Box, Divider } from "@mui/material";
import { colors } from "../../../styles/colors";
import ColumChart from "../ColumnChart";
import { Circle } from "@mui/icons-material";

const PayChart = (props) => {
  const { chartData, setFilter } = props;

  const xAxis = {
    crosshair: true,
    categories:
      chartData && chartData?.length > 0
        ? chartData[0]?.data?.map((item) => item?.name)
        : []
  };

  const dataModified = chartData?.map((item1, index) => ({
    name: item1?.name,
    pointWidth: 30,
    data: item1?.data?.map((item2) => ({
      name: item2?.name || "",
      y: item2?.value || 0,
      color: index === 0 ? "#FF8100" : index === 1 ? "#A283FA" : "#5ADE7E"
    }))
  }));

  const yAxis = {
    // min: -30,
    title: {
      text: ""
    }
  };

  const legends = [
    {
      title: "Deposits",
      color: "#FF8100"
    },
    {
      title: "Pay Out",
      color: "#A283FA"
    },
    {
      title: "Commission",
      color: "#5ADE7E"
    }
  ];

  const days = ["1D", "5D", "1M", "6M", "YTD"];

  return (
    <Box
      sx={{
        backgroundColor: colors.backgroundLight,
        borderRadius: "4px",
        p: 3
      }}
    >
      <Typography fontSize="18px" color={colors.lightGreyIcon} fontWeight={600}>
        Total Pay out - Pay in Chart
      </Typography>
      <Typography fontSize="14px" color={colors.lightGreyIcon} fontWeight={400}>
        Sales analytics for pay-in and pay-out
      </Typography>
      <Box my={3} display="flex" alignItems="center">
        <Box display="flex" justifyContent="center" sx={{ flex: 1 }}>
          {days?.map((item, index) => {
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  color="#3C3C3C"
                  fontSize="13px"
                  fontWeight={600}
                  sx={{ px: 2.5, py: 1, cursor: "pointer" }}
                  onClick={() => setFilter(item?.toLowerCase())}
                >
                  {item}
                </Typography>
                {index < days.length - 1 && (
                  <Box
                    sx={{
                      height: 20,
                      width: 1.5,
                      backgroundColor: "rgba(168, 168, 168, 0.5)"
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
        <Box display="flex" justifyContent="flex-end" sx={{ flex: 1 }}>
          {legends.map((x, index) => (
            <Box display="flex" alignItems="center">
              <Circle sx={{ color: x.color, fontSize: 18 }} />
              <Typography
                fontSize="12px"
                fontWeight={500}
                color="#404040"
                sx={{ mr: index !== legends.length - 1 ? 1 : 0 }}
              >
                {x.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <ColumChart
        title={""}
        xAxis={xAxis}
        yAxis={yAxis}
        data={dataModified || []}
        properties={{ height: 276 }}
      />
    </Box>
  );
};

export default PayChart;
