import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface ICurvedGraphProps {
  title: any;
  subtitle?: any;
  xAxis: any;
  yAxis: any;
  data: any;
  plotOptions?: any;
  properties?: any;
}

const ColumChart = (props: any) => {
  const { title, subtitle, xAxis, yAxis, data, properties } = props;

  const optionsNew = {
    chart: {
      type: 'line',
      height: properties?.height && properties?.height,
      width: properties?.width && properties?.width,
      backgroundColor:
        properties?.backgroundColor && properties?.backgroundColor,
    },
    title: {
      text: title,
    },
    xAxis: xAxis,
    yAxis: yAxis,
    series: data,
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={optionsNew} />;
};

export default ColumChart;
