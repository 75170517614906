import React, { useState } from "react";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { FormikProps } from "formik";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_GAME_TYPES } from "app/graphql/game";
import { colors } from "styles/colors";
import OrangeGradientButton from "../OrangeGradientButton";
import { IEditGame } from ".";
import { CheckBox, ModeEditOutline } from "@mui/icons-material";

const GameDetails = (props: FormikProps<IEditGame>) => {
  const {
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setFieldTouched,
    errors,
    touched
  } = props;

  const [gameTypes, setGameTypes] = useState([]);
  const [portrait, setPortrait] = useState(values.portrait);
  const [landscape, setLandscape] = useState(values.landscape);

  const { loading } = useQuery(GET_GAME_TYPES, {
    onCompleted: (data) => {
      const { gameCategories: rawCategories } = data;
      const { status, categories } = rawCategories;
      if (status) {
        setGameTypes(categories);
      } else {
        setGameTypes([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const handlePackageUpload = (file: RcFile): Promise<void> => {
    setFieldValue("gamePackage", file);
    return Promise.resolve();
  };

  const getPackageFileName = () => {
    const index = values.gamePackage.lastIndexOf("/");
    if (index > -1) {
      return values.gamePackage.substring(index + 1);
    }
    return "";
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.backgroundBoxLight,
        p: 3,
        borderRadius: "4px"
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 3 }}>
          <Typography
            fontSize="14px"
            fontWeight={700}
            color={colors.lightGreyIcon}
          >
            DEFAULT DETAILS
          </Typography>
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={6}>
          <Typography
            fontSize={16}
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Package <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={10} md={9} sm={8} xs={6}>
          {values.gamePackage === null ? (
            <Upload
              accept=".zip"
              beforeUpload={handlePackageUpload}
              onStart={() => setFieldTouched("gamePackage")}
            >
              <OrangeGradientButton title="Upload" style={{ width: "122px" }} />
            </Upload>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography fontSize={15}>
                {typeof values.gamePackage === "string"
                  ? getPackageFileName()
                  : values.gamePackage.name}
              </Typography>
              <Upload beforeUpload={handlePackageUpload}>
                <IconButton size="small" sx={{ ml: 1 }}>
                  <ModeEditOutline sx={{ fontSize: 18 }} />
                </IconButton>
              </Upload>
            </Box>
          )}
          {Boolean(touched?.gamePackage) && Boolean(errors?.gamePackage) && (
            <Typography fontSize="12px" color="red">
              {Boolean(errors?.gamePackage)
                ? errors?.gamePackage?.toString()
                : ""}
            </Typography>
          )}
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Typography
            fontSize={16}
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Name <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={8} xs={6}>
          <TextField
            id="title"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            size="small"
            sx={{ backgroundColor: colors.FieldBackgroundLight }}
          />
          {Boolean(touched?.title) && Boolean(errors?.title) && (
            <Typography fontSize="12px" color="red">
              {Boolean(errors?.title) ? errors?.title?.toString() : ""}
            </Typography>
          )}
        </Grid>
        {/* <Grid item lg={1.5} md={2} sm={3} xs={6}>
          <Typography
            fontSize="18px"
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Onboarded on <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={4.5} md={4} sm={9} xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={new Date()}
              onChange={(val) => {
                setFieldValue("onBoardedOn", val);
              }}
              renderInput={(params) => (
                <TextField
                  id="onBoardedOn"
                  fullWidth
                  size="small"
                  {...params}
                  sx={{ width: { sm: "75%" } }}
                  onBlur={handleBlur}
                />
              )}
            />
          </LocalizationProvider>
        </Grid> */}
        <Grid lg={6} md={6} display={{ md: "block", sm: "none" }} />
        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Typography
            fontSize={16}
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Description <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={8} xs={6}>
          <TextField
            id="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            size="small"
            multiline
            minRows={4}
            sx={{ backgroundColor: colors.FieldBackgroundLight }}
          />
          {Boolean(touched?.description) && Boolean(errors?.description) && (
            <Typography fontSize="12px" color="red">
              {Boolean(errors?.description)
                ? errors?.description?.toString()
                : ""}
            </Typography>
          )}
        </Grid>
        <Grid lg={6} md={6} display={{ md: "block", sm: "none" }} />
        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Typography
            fontSize={16}
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Type <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={8} xs={6}>
          <Autocomplete
            id="category"
            options={gameTypes}
            getOptionLabel={(option: any) => option.category}
            loading={loading}
            disableClearable
            value={
              gameTypes.length > 0
                ? gameTypes.find(
                    (x: any) =>
                      x.category.toString() === values.category.toString()
                  )
                : null
            }
            onChange={(_, value) => {
              if (value) {
                setFieldValue("category", value.category);
              }
            }}
            onBlur={handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{ backgroundColor: colors.FieldBackgroundLight }}
              />
            )}
          />
          {Boolean(touched?.category) && Boolean(errors?.category) && (
            <Typography fontSize="12px" color="red">
              {Boolean(errors?.category) ? errors?.category?.toString() : ""}
            </Typography>
          )}
        </Grid>
        <Grid lg={6} md={6} display={{ md: "block", sm: "none" }} />
        <Grid item lg={2} md={2} sm={4} xs={6}>
          <Typography
            fontSize={16}
            fontWeight={400}
            color={colors.lightGreyIcon}
          >
            Orientation
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={8} xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="portrait"
                defaultChecked={portrait}
                checkedIcon={<CheckBox sx={{ color: colors.invalidInput }} />}
                checked={portrait}
                onChange={(e) => {
                  setFieldValue("portrait", e.target.checked);
                  setFieldValue("landscape", !e.target.checked);
                  setPortrait(e.target.checked === true ? true : false);
                  setLandscape(e.target.checked === true ? false : true);
                }}
              />
            }
            label={<Typography sx={{ fontSize: 16 }}>Portrait</Typography>}
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 400
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="landscape"
                defaultChecked={landscape}
                checkedIcon={<CheckBox sx={{ color: colors.invalidInput }} />}
                checked={landscape}
                onChange={(e) => {
                  setFieldValue("landscape", e.target.checked);
                  setFieldValue("portrait", !e.target.checked);
                  setLandscape(e.target.checked === true ? true : false);
                  setPortrait(e.target.checked === true ? false : true);
                }}
              />
            }
            label={<Typography sx={{ fontSize: 16 }}>Landscape</Typography>}
            sx={{
              color: colors.lightGreyIcon,
              fontWeight: 400
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GameDetails;
