import React from "react";
import { Grid, Typography } from "@mui/material";
import images from "assets/images/images";

interface IStatisticsProps {
  data: any;
}

const Statistics = (props: IStatisticsProps) => {
  const { data } = props;

  const statistics = [
    {
      title: "Online Users",
      count: data?.onlineUsers
      // percentage: data?.percActiveUsers,
    },
    {
      title: "Daily Online Users",
      count: data?.dailyOnlineUsers
      // percentage: data?.percDailyActiveUsers,
    },
    {
      title: "Weekly Online Users",
      count: data?.weeklyOnlineUsers
      // percentage: data?.percWeeklyActiveUsers,
    },
    {
      title: "Monthly Online Users",
      count: data?.monthlyOnlineUsers
      // percentage: data?.percMonthlyActiveUsers,
    },
    {
      title: "Offline Users",
      count: data?.offlineUsers
      // percentage: data?.percInActiveUsers,
    },
    {
      title: "Zero Activity Users",
      count: data?.zeroActivityUsers
      // percentage: data?.percExpiredUsers,
    }
    // {
    //   title: "Dead Users",
    //   count: data?.deadUsers || ""
    //   // percentage: data?.percTotalUsers,
    // }
  ];
  return (
    <Grid container>
      <Grid
        xs={12}
        sx={{ borderTop: "0.5px solid rgba(168, 168, 168, 0.5)", py: 1 }}
      ></Grid>
      {statistics?.map((item, index) => {
        return (
          <Grid xs={4} lg={2} md={3} sx={{ padding: 0.5, display: "flex" }}>
            <div style={{ width: "100%" }}>
              <Typography
                color="#3C3C3C"
                fontSize="13px"
                fontWeight={400}
                sx={{ px: 0.5, py: 0.5 }}
              >
                {item.title}
              </Typography>
              <Typography
                color="#1C1C1C"
                fontSize="20px"
                fontWeight={600}
                sx={{ px: 0.5, py: 0.5 }}
              >
                {item.count}
              </Typography>
            </div>
            {index !== statistics.length - 1 && (
              <div
                style={{
                  height: 60,
                  borderRight: "0.5px solid rgba(168, 168, 168, 0.5)",
                  margin: "5px 10px 10px 10px"
                }}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Statistics;
