import React from "react";
import { Helmet } from "react-helmet-async";
import MiniDrawer from "app/components/Drawer";
import UserManagement from "app/components/UserManagement";

const UserManagementPage = () => {
  const pageHeader = {
    title: "User Management",
    breadCrumb: "User Management"
  };
  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <MiniDrawer
        content={<UserManagement />}
        isHomepage={false}
        header={pageHeader}
      />
    </>
  );
};

export default UserManagementPage;
