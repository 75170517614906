import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import UserSpendingAnalysis from "./userSpending";
import MostPlayedGames from "./mostPlayedGames";
import UserDemographics from "./userDemographics";
import { colors } from "styles/colors";
import CurvedGraph from "../CurvedGraph";
import DateFilter from "../DateFilterCharts";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  USER_DETAIL_DEMOGRAPHIC_DATA,
  USER_DETAIL_HOURLY_DATA,
  USER_DETAIL_SPEND_DATA
} from "app/graphql/users";

const graphData = [
  {
    name: "Number of users",
    data: [38, 37, 378, 36, 36, 160, 249, 156, 156, 35, 6]
  }
];

const UserAnalytics = () => {
  const todayDate = new Date();
  const oneWeekAgo = new Date(todayDate);
  oneWeekAgo.setDate(todayDate.getDate() - 30);

  const [startDateForHourlyData, setStartDateForHourlyData] =
    useState(oneWeekAgo);
  const [endDateForHourlyData, setEndDateForHourlyData] = useState(todayDate);

  const [startDateForSpendData, setStartDateForSpendData] =
    useState(oneWeekAgo);
  const [endDateForSpendData, setEndDateForSpendData] = useState(todayDate);

  const [userHourlyDetail, setUserHourlyDetail] = useState<any>([]);
  const [userSpendDetail, setUserSpendDetail] = useState<any>({});
  const [userDemographicDetail, setUserDemographicDetail] = useState<any>({});

  const { data: userHourlyData } = useQuery(USER_DETAIL_HOURLY_DATA, {
    variables: {
      from: startDateForHourlyData,
      to: endDateForHourlyData
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (userHourlyData) {
      console.log(userHourlyData);
      setUserHourlyDetail(
        userHourlyData?.userDetailsHourlyGraph?.hourlyActivity || []
      );
    }
  }, [userHourlyData]);

  const { data: userSpendData } = useQuery(USER_DETAIL_SPEND_DATA, {
    variables: {
      from: startDateForSpendData,
      to: endDateForSpendData
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (userSpendData) {
      console.log(userSpendData);
      setUserSpendDetail(userSpendData?.userDetailsSpend || {});
    }
  }, [userSpendData]);

  const { data: userDemographicData } = useQuery(USER_DETAIL_DEMOGRAPHIC_DATA, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (userDemographicData) {
      console.log(userDemographicData);
      setUserDemographicDetail(
        userDemographicData?.userDetailsDemographic || {}
      );
    }
  }, [userDemographicData]);

  const hourlyData =
    userHourlyDetail &&
    userHourlyDetail?.length > 0 &&
    userHourlyDetail?.map((item) => ({
      name: item?.name || "",
      y: item?.value || 0
      // color: colors.purple
    }));
  const graphData = [
    {
      name: "No. of Users",
      // colorByPoint: true,
      // pointWidth: 30,
      data: hourlyData
    }
  ];

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ backgroundColor: "#FFFAF4" }}
      >
        <div
          style={{
            padding: 10
          }}
        >
          <Typography
            color="#464646"
            fontSize="16px"
            fontWeight={600}
            sx={{ p: 1 }}
          >
            User analysis
          </Typography>
          <Typography
            color="#464646"
            fontSize="13px"
            fontWeight={400}
            sx={{ p: 1 }}
          >
            {/* Detailed analysis of selected users <b>(5800 Users)</b> */}
            Detailed analysis of users
          </Typography>
        </div>
        <Grid container>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div
              style={{
                backgroundColor: colors.white,
                width: "97%",
                borderRadius: 15,
                margin: "5px auto"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start"
                }}
              >
                <div>
                  <Typography
                    color="#464646"
                    fontSize="16px"
                    fontWeight={600}
                    sx={{ p: 1.5 }}
                  >
                    Hourly user activity
                  </Typography>
                  <Typography
                    color="#464646"
                    fontSize="13px"
                    fontWeight={400}
                    sx={{ p: 1.5 }}
                  >
                    Most user engagement in app
                  </Typography>
                </div>
                <div>
                  <DateFilter
                    startDate={startDateForHourlyData}
                    endDate={endDateForHourlyData}
                    onChangeStartDate={(newDate) =>
                      setStartDateForHourlyData(newDate)
                    }
                    onChangeEndDate={(newDate) =>
                      setEndDateForHourlyData(newDate)
                    }
                    disableFutureForStart={true}
                    disableFutureForEnd={true}
                  />
                </div>
              </div>
              <CurvedGraph
                xAxisLabels={[
                  "12 AM",
                  "02 AM",
                  "04 AM",
                  "06 AM",
                  "08 AM",
                  "10 AM",
                  "12 PM",
                  "02 PM",
                  "04 PM",
                  "06 PM",
                  "08 PM",
                  "10 PM"
                ]}
                data={graphData}
                partColors={[
                  "#FF8100",
                  "#FF8100",
                  "linear-gradient(180deg, rgba(255, 129, 0, 0.5) 0%, rgba(255, 129, 0, 0) 98.3%);" //orange
                ]}
              />
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <UserSpendingAnalysis
              userSpendDetail={userSpendDetail}
              startDateForSpendData={startDateForSpendData}
              endDateForSpendData={endDateForSpendData}
              setStartDateForSpendData={setStartDateForSpendData}
              setEndDateForSpendData={setEndDateForSpendData}
            />
          </Grid>
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <MostPlayedGames />
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <UserDemographics userDemographicDetail={userDemographicDetail} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserAnalytics;
