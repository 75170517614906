import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment
} from "@mui/material";
import { colors } from "../../../styles/colors";
import dayjs, { Dayjs } from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PercentIcon from "@mui/icons-material/Percent";
import { FieldArray } from "formik";
import { useLazyQuery } from "@apollo/client";
import { GET_PRIZE_DISTRIBUTION } from "app/graphql/tournament";
import { useSnackbar } from "notistack";

const PrizeDistribution = (props) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    handleBlur,
    setDisableSubmitButton
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [distributedPrizes, setDistributedPrizes] = useState(
    values.prizes?.length > 0 ? values.prizes : []
  );
  const [prizeDistributionTable, setPrizeDistributionTable] = useState(
    values.prizes?.length > 0 ? true : false
  );
  const [prizeDistributionPayload, setPrizeDistributionPayload] = useState({});

  const [getPrizeDistribution] = useLazyQuery(GET_PRIZE_DISTRIBUTION, {
    variables: {
      payload: prizeDistributionPayload
    },
    onCompleted: (responseData) => {
      console.log(responseData);
      const { getPrizeDistribution } = responseData;
      console.log(getPrizeDistribution);
      const { status, message, prizes } = getPrizeDistribution;
      if (status) {
        setFieldValue("prizes", prizes);
        setDistributedPrizes(prizes);
        setPrizeDistributionTable(true);
        setDisableSubmitButton(false);
      } else {
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const handleShowDistributionTable = () => {
    const prizesModified =
      values.initialWinnerPrizes?.length > 0
        ? values.initialWinnerPrizes?.map((item) => parseInt(item))
        : [];
    if (
      values.prizePool &&
      values.initialWinnerPrizes &&
      values.initialWinnerPrizes?.length > 0
    ) {
      const sumOfInitialWinnerPrizes = values.initialWinnerPrizes?.reduce(
        (acc, prize) => parseInt(acc) + parseInt(prize),
        0
      );
      const gdCommission =
        values?.prizePool && values.gdCommission
          ? Math.round(values?.prizePool * (values.gdCommission / 100))
          : 0;
      const sumOfPrizes =
        parseInt(sumOfInitialWinnerPrizes) +
        parseInt(values.lastPrize) +
        gdCommission;
      if (parseInt(values.prizePool) < sumOfPrizes) {
        enqueueSnackbar(
          `Prize Pool should be more than " commission + sum of prizes " which is Rs.${sumOfPrizes} `,
          {
            variant: "error"
          }
        );
      } else {
        setPrizeDistributionPayload({
          winningProbability: parseInt(values?.winnerPercentage),
          capacity: parseInt(values?.slots),
          prizepool: parseInt(values?.prizePool),
          alpha: parseInt(values?.alphaValue),
          lastPrize: parseInt(values?.lastPrize),
          prizes: prizesModified,
          commission:
            values?.prizePool && values.gdCommission
              ? Math.round(values?.prizePool * (values.gdCommission / 100))
              : 0
        });
        getPrizeDistribution();
      }
    }
  };

  const renderInitialWinnersInputFields = () => {
    return (
      <FieldArray
        name="initialWinnerPrizes"
        render={(arrayHelpers) => (
          <>
            {values.initialWinnerPrizes?.map((c, index) => (
              <Grid
                item
                container
                xs={12}
                sm={6}
                md={6}
                lg={6}
                mt={2.5}
                pl={index % 2 === 1 ? 0 : 2}
                pr={index % 2 === 0 ? 0 : 2}
              >
                <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
                  <Typography fontSize={15} fontWeight={400}>
                    {`${
                      index === 0
                        ? "1st"
                        : index === 1
                        ? "2nd"
                        : index === 2
                        ? "3rd"
                        : `${index + 1}th`
                    } Prize`}
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color={colors.redAsterisk}
                  >
                    *
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
                  <TextField
                    id={`initialWinnerPrizes[${index}]`}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                        </InputAdornment>
                      )
                    }}
                    value={values.initialWinnerPrizes[index] || ""}
                    onChange={(e) => {
                      if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                        setFieldValue(
                          `initialWinnerPrizes[${index}]`,
                          e.target.value
                        );
                        setPrizeDistributionTable(false);
                        setDisableSubmitButton(true);
                      }
                    }}
                    sx={{
                      backgroundColor: colors.FieldBackgroundLight,
                      input: { color: colors.lightGreyIcon },
                      width: "90%"
                    }}
                    onBlur={handleBlur}
                  />
                  {touched.initialWinnerPrizes &&
                    touched.initialWinnerPrizes?.length > 0 &&
                    errors.initialWinnerPrizes &&
                    errors.initialWinnerPrizes?.length > 0 &&
                    touched?.initialWinnerPrizes[index] && (
                      <Typography
                        sx={{
                          color: colors.danger,
                          fontSize: "11px",
                          fontWeight: 600
                        }}
                      >
                        {errors?.initialWinnerPrizes[index]}
                      </Typography>
                    )}
                </Grid>
              </Grid>
            ))}
          </>
        )}
      />
    );
  };
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: colors.backgroundBoxLight,
          borderRadius: "8px",
          pt: 4,
          pb: 3,
          mt: 1,
          mb: 1
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            backgroundColor: colors.TableHead,
            borderRadius: "4px 4px 0px 0px",
            mt: -4,
            mb: 2
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              textAlign: "left",
              p: 1
            }}
          >
            Tournament Prize Distribution
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={12} px={2}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "17px",
              letterSpacing: "0.15em",
              textAlign: "left",
              mb: 2
            }}
          >
            PRIZE DISTRIBUTION*
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Prize Pool
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="prizePool"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                  </InputAdornment>
                )
              }}
              value={values.prizePool}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("prizePool", e.target.value);
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.prizePool && errors?.prizePool && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.prizePool}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Entry Fee
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="entryFee"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                  </InputAdornment>
                )
              }}
              value={values.entryFee}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("entryFee", e.target.value);
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.entryFee && errors?.entryFee && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.entryFee}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              No. of Slots
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="slots"
              size="small"
              fullWidth
              value={values.slots}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("slots", e.target.value);
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.slots && errors?.slots && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.slots}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Winner %
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="winnerPercentage"
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon sx={{ fontSize: 15 }} />
                  </InputAdornment>
                )
              }}
              value={values.winnerPercentage}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("winnerPercentage", e.target.value);
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.winnerPercentage && errors?.winnerPercentage && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.winnerPercentage}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              GD Commission
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="gdCommission"
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon sx={{ fontSize: 15 }} />
                  </InputAdornment>
                )
              }}
              value={values.gdCommission}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("gdCommission", e.target.value);
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.gdCommission && errors?.gdCommission && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.gdCommission}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Alpha Value
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="alphaValue"
              size="small"
              fullWidth
              value={values.alphaValue}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("alphaValue", e.target.value);
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.alphaValue && errors?.alphaValue && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.alphaValue}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Initial Winners
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="initialWinners"
              size="small"
              fullWidth
              value={values.initialWinners}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("initialWinners", e.target.value);
                  setFieldValue(
                    "initialWinnerPrizes",
                    Array.apply("", Array(parseInt(e.target.value)))
                  );
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                  values.initialWinnerPrizes.forEach((_, index) => {
                    setFieldTouched(`initialWinnerPrizes[${index}]`, false);
                  });
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.initialWinners && errors?.initialWinners && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.initialWinners}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} mt={2.5} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Multiples
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="multiples"
              size="small"
              fullWidth
              value={values.multiples}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("multiples", e.target.value);
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.multiples && errors?.multiples && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.multiples}
              </Typography>
            )}
          </Grid>
        </Grid>
        {renderInitialWinnersInputFields()}
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={6}
          lg={6}
          mt={2.5}
          pr={values?.initialWinners % 2 === 0 ? 0 : 2}
          pl={values?.initialWinners % 2 === 0 ? 2 : 0}
        >
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Last Prize
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.redAsterisk}
            >
              *
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="lastPrize"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                  </InputAdornment>
                )
              }}
              value={values.lastPrize}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("lastPrize", e.target.value);
                  setPrizeDistributionTable(false);
                  setDisableSubmitButton(true);
                }
              }}
              sx={{
                backgroundColor: colors.FieldBackgroundLight,
                input: { color: colors.lightGreyIcon },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.lastPrize && errors?.lastPrize && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 600
                }}
              >
                {errors?.lastPrize}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container mt={2.5}>
          <Button
            size="small"
            variant="contained"
            // disabled={true}
            sx={{
              width: { lg: "25%", md: "25%", sm: "25%", xs: "50%" },
              background: "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
              borderRadius: "36px",
              height: "38px",
              fontWeight: 400,
              fontSize: "16px",
              letterSpacing: "0.02em",
              margin: "20px 0px",
              ml: "20px",
              color: "#FFF9F2"
            }}
            onClick={() => {
              props.validateForm().then(() => {
                values.initialWinnerPrizes.forEach((_, index) => {
                  setFieldTouched(`initialWinnerPrizes[${index}]`, true);
                });
                if (Object.keys(props.errors).length === 0) {
                  handleShowDistributionTable();
                }
              });
              // handleShowDistributionTable();
            }}
          >
            Distribute Prize Values
          </Button>
        </Grid>
        {prizeDistributionTable && (
          <>
            <Grid item container xs={12} sm={12} md={12} lg={12} mt={3}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  borderRadius: "4px",
                  border: "0.5px solid",
                  borderColor: "rgba(168, 168, 168, 0.5)"
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    backgroundColor: "#FFE4CB",
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    borderRadius: "4px"
                  }}
                >
                  <Typography fontSize="16px" fontWeight={700}>
                    DISTRIBUTED VALUE
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    borderRadius: "4px"
                  }}
                >
                  <Typography fontSize="16px" fontWeight={700}>
                    {`TOTAL PRIZE - ₹ ${
                      values?.prizePool -
                      Math.round(
                        values?.prizePool * (values.gdCommission / 100)
                      )
                    } | COMMISSION - ₹ ${Math.round(
                      values?.prizePool * (values.gdCommission / 100)
                    )}`}
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                        borderRadius: "4px",
                        backgroundColor: "#FFBF83",
                        mr: 0.5
                      }}
                    >
                      <Typography fontSize="16px" fontWeight={700}>
                        PLAYERS
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                        borderRadius: "4px",
                        backgroundColor: "#FFBF83",
                        ml: 0.5
                      }}
                    >
                      <Typography fontSize="16px" fontWeight={700}>
                        PRIZE
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {distributedPrizes?.length > 0 ? (
                  distributedPrizes?.map((item, index) => (
                    <Grid item container xs={12} sm={12} md={12} lg={12} mt={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            p: 1,
                            borderRadius: "4px",
                            backgroundColor: "#FFE4CB",
                            mr: 0.5
                          }}
                        >
                          <Typography fontSize="14px" fontWeight={400} mr={2}>
                            {`PLAYER ${index + 1}`}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            p: 1,
                            borderRadius: "4px",
                            backgroundColor: "#FFE4CB",
                            ml: 0.5
                          }}
                        >
                          <Typography fontSize="14px" fontWeight={400}>
                            {item || 0}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography fontSize="14px" fontWeight={400}>
                    No Data
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PrizeDistribution;
