import React from "react";
import { Grid } from "@mui/material";
import { colors } from "../../../styles/colors";
import Background from "../../components/loginBackground";
import SetNewPassword from "app/components/setNewPassword";

const SetNewPasswordForm = (props) => {
  return (
    <Grid container>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={6}
        sx={{
          display: { xs: "none", sm: "block" },
          backgroundColor: colors.background,
          minHeight: "110vh",
        }}
      >
        <Background />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        sx={{ maxHeight: "110vh", overflow: "auto"}}
      >
        <SetNewPassword />
      </Grid>
    </Grid>
  );
};

export default SetNewPasswordForm;
