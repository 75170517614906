import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const getDateInFormat = (timeStamp, format, action) => {
  if (action === "changeFormat") {
    const temp = dayjs(timeStamp).format(format);
    return temp;
  }
  return dayjs.utc(timeStamp).local().format();
};

export const getCreateTournamentPayload = (values) => {
  console.log(values);

  const startDateAndTime = values.startDateAndTime
    ? getDateInFormat(values.startDateAndTime, "YYYY-MM-DD HH:mm", null)
    : getDateInFormat(new Date(), "YYYY-MM-DD HH:mm", null);
  const endDateAndTime = values.endDateAndTime
    ? getDateInFormat(values.endDateAndTime, "YYYY-MM-DD HH:mm", null)
    : getDateInFormat(new Date(), "YYYY-MM-DD HH:mm", null);
  console.log("start time", startDateAndTime);
  console.log("end time", endDateAndTime);

  const bootEntriesModified = [values?.bootEntry1, ...values?.bootEntries];

  const bootEntriesModified2 = bootEntriesModified?.map((item) =>
    parseInt(item)
  );

  const prizesModified =
    values.prizes?.length > 0
      ? values.prizes?.map((item) => parseInt(item))
      : [];
  return {
    game: values.game,
    type: values.tournamentType,
    title: values.title || "Title",
    start: startDateAndTime,
    end: endDateAndTime,
    details: JSON.stringify({
      ...(values?.tournamentType === "Free" && { entry: 0 }),
      ...((values?.tournamentType === "Free" ||
        values?.tournamentType === "Unlimited") && { attempts: -1 }),
      ...(values?.tournamentType === "Free" && { capacity: 1000 }),
      ...(values?.tournamentType === "Free" && { frequency: "Weekly" }),
      ...(values?.tournamentType === "Free" && { winningProbability: 100 }),
      ...(values?.tournamentType === "Triple" && { attempts: 3 }),
      ...(values.subTitle && { subTitle: values.subTitle }),
      ...(values.slots && { capacity: parseInt(values.slots) }),
      ...(values.entryFee && { entry: parseInt(values.entryFee) }),
      ...(values.prizePool && { prizePool: parseInt(values.prizePool) }),
      ...(values.frequencyType && { frequency: values.frequencyType }),
      ...(values.rules && { rules: values.rules }),
      ...(values.winnerPercentage && {
        winningProbability: parseInt(values.winnerPercentage)
      }),
      ...(values.gdCommission && {
        gdCommission: parseInt(values.gdCommission)
      }),
      ...(values.alphaValue && { alphaValue: parseInt(values.alphaValue) }),
      ...(values.initialWinners && {
        initialWinners: parseInt(values.initialWinners)
      }),
      ...(values.multiples && { multiples: parseInt(values.multiples) }),
      ...(values.initialWinnerPrizes &&
        values.initialWinnerPrizes.length > 0 && {
          initialWinnerPrizes: values.initialWinnerPrizes
        }),
      ...(values.prizes &&
        values.prizes.length > 0 && { prizes: prizesModified }),
      ...(values.lastPrize && { lastPrize: parseInt(values.lastPrize) }),
      ...(values.includeFreePlay && {
        includeFreePlay: values.includeFreePlay
      }),
      ...(values?.bootEntry1 && { bootEntry1: parseInt(values?.bootEntry1) }),
      ...(values.bootEntries &&
        values?.bootEntry1 && { bootEntries: bootEntriesModified2 }),
      ...(values.bootCarousel1ImageUrl && {
        bootCarousel1Url: values.bootCarousel1ImageUrl
      }),
      ...(values.bootCarousel2ImageUrl && {
        bootCarousel2Url: values.bootCarousel2ImageUrl
      }),
      ...(values.bootCarousel3ImageUrl && {
        bootCarousel3Url: values.bootCarousel3ImageUrl
      })
      // ...(values.thumbnailImageUrl && {
      //   tournamentThumbnailUrl: values.thumbnailImageUrl
      // })
    })
  };
};

export const getFormValues = (data) => {
  const startDateAndTime2 = dayjs(parseInt(data?.start));

  const endDateAndTime2 = dayjs(parseInt(data?.end));

  const details = data?.details ? JSON.parse(data?.details) : "";

  console.log(data);
  const bootEntriesModified =
    details?.bootEntries && details?.bootEntries?.length > 0
      ? [...details?.bootEntries.slice(1)]
      : [" ", " ", " ", " "];

  return {
    game: data?.game?.id || "",
    tournamentType: data?.type || "",
    title: data?.title || "",
    subTitle: details?.subTitle || "",
    slots: details?.capacity || "",
    startDateAndTime: startDateAndTime2 || null,
    endDateAndTime: endDateAndTime2 || null,
    entryFee: details?.entry || "",
    prizePool: details?.prizePool || "",
    frequencyType: details?.frequency || "",
    rules: details?.rules || "",
    thumbnailImage: null,
    thumbnailImageUrl: data?.tournamentThumbnailUrl || "",
    winnerPercentage: details?.winningProbability || "",
    gdCommission: details?.gdCommission || "",
    alphaValue: details?.alphaValue || "",
    initialWinners: details?.initialWinners || "",
    multiples: details?.multiples || "",
    initialWinnerPrizes: details?.initialWinnerPrizes || [],
    prizes: details?.prizes || [],
    lastPrize: details?.lastPrize || "",
    includeFreePlay: details?.includeFreePlay ? details?.includeFreePlay : 2,
    bootEntry1: details?.bootEntry1 ? details?.bootEntry1 : "",
    bootEntries: bootEntriesModified,
    // bannerImage: data?.bannerImage || null,
    bootCarousel1: null,
    bootCarousel1ImageUrl: details?.bootCarousel1Url || "",
    bootCarousel2: null,
    bootCarousel2ImageUrl: details?.bootCarousel2Url || "",
    bootCarousel3: null,
    bootCarousel3ImageUrl: details?.bootCarousel3Url || ""
  };
};

export const getPageAction = (location) => {
  if (location.pathname?.includes("/tournaments/delete/")) {
    return "delete";
  } else if (location.pathname?.includes("/tournaments/deactivate/")) {
    return "deactivate";
  }
};

export const getJwtTokenDetails = () => {
  const token = sessionStorage.getItem("token") || "";
  let id: any = "";
  let role: string = "";
  let email: string = "";
  let name: string = "";
  let rolePermissions: any = null;
  let isTokenPresent: any = false;

  if (Boolean(token)) {
    isTokenPresent = true;
    const decoded: any = jwt_decode(token);

    console.log("decoded token", decoded);
    id = decoded.id;
    role = decoded.role;
    email = decoded.email;
    name = decoded.name;
    if (Boolean(decoded.rolePermissions)) {
      rolePermissions = JSON.parse(decoded.rolePermissions);
    }
  } else {
    const authToken = localStorage.getItem("authToken") || "";
    if (Boolean(authToken)) {
      isTokenPresent = true;
      const decoded: any = jwt_decode(authToken);
      console.log("decoded token", decoded);
      id = decoded.id;
      role = decoded.role;
      email = decoded.email;
      name = decoded.name;
      if (Boolean(decoded.rolePermissions)) {
        rolePermissions = JSON.parse(decoded.rolePermissions);
      }
    }
  }

  return {
    id,
    role,
    email,
    name,
    rolePermissions,
    isTokenPresent
  };
};

export const clearSession = () => {
  sessionStorage.removeItem("token");
  localStorage.removeItem("authToken");
};

export function getBreadcrumbs(location) {
  if (location.pathname === "/games") {
    return [
      {
        id: 1,
        name: "Games",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/games/add")) {
    return [
      {
        id: 1,
        name: "Games",
        path: "/games",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Add new",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/games/edit/")) {
    return [
      {
        id: 1,
        name: "Games",
        path: "/games",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Edit Game Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (
    location.pathname.includes("/games/delete/") ||
    location.pathname.includes("/games/deactivate/")
  ) {
    return [
      {
        id: 1,
        name: "Games",
        path: "/games",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/games/")) {
    const game: string = sessionStorage.getItem("game") || "";
    return [
      {
        id: 1,
        name: "Games",
        path: "/games",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: game?.toString(),
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/tournaments-analytics") {
    return [
      {
        id: 2,
        name: "Analytics",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/tournaments-configuration") {
    return [
      {
        id: 1,
        name: "Configuration",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/tournaments/add-edit/new")) {
    return [
      {
        id: 1,
        name: "Configuration",
        path: "/tournaments-configuration",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Add Tournament",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/tournaments/add-edit/")) {
    return [
      {
        id: 1,
        name: "Configuration",
        path: "/tournaments-configuration",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Edit Tournament Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (
    location.pathname.includes("/tournaments/deactivate/") ||
    location.pathname.includes("/tournaments/delete/")
  ) {
    return [
      {
        id: 1,
        name: "Configuration",
        path: "/tournaments-configuration",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname.includes("/tournaments/")) {
    const tournament: string = sessionStorage.getItem("tournament") || "";
    return [
      {
        id: 1,
        name: "Analytics",
        path: "/tournaments-analytics",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: tournament?.toString(),
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/users") {
    return [
      {
        id: 1,
        name: "Users",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }

  if (location.pathname === "/users/analytics") {
    return [
      {
        id: 1,
        name: "Users",
        path: "/users",
        color: "#A4A5A8"
      },
      {
        id: 1,
        name: "User Analytics",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname?.includes("/users/")) {
    return [
      {
        id: 1,
        name: "Users",
        path: "/users",
        color: "#A4A5A8"
      },
      {
        id: 1,
        name: "View Details",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/rewards") {
    return [
      {
        id: 1,
        name: "Rewards",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/rewards/add") {
    return [
      {
        id: 1,
        name: "Rewards",
        path: "/rewards",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Add rewards",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/user-management") {
    return [
      // {
      //   id: 1,
      //   name: "Admin Control",
      //   path: "#",
      //   color: "#A4A5A8"
      // },
      {
        id: 2,
        name: "User Management",
        path: "#",
        color: "#A4A5A8"
      }
    ];
  }
  return [];
}

export function getPageTitle(location) {
  var title = "";
  if (location.pathname.includes("/games")) {
    title = "Game Details";
  }
  if (
    location.pathname === "/tournaments-analytics" ||
    location.pathname === "/tournaments-configuration" ||
    location.pathname.includes("/tournaments/")
  ) {
    title = "Tournaments Details";
  }
  if (location.pathname.includes("/users")) {
    title = "User Details";
  }
  if (location.pathname.includes("/rewards")) {
    title = "Rewards Details";
  }
  if (location.pathname === "/user-management") {
    title = "User Management";
  }
  return title;
}

export function getPage(location) {
  if (location.pathname === "/tournaments-analytics") {
    return 2;
  }
  if (location.pathname === "/tournaments-configuration") {
    return 8;
  }
}

export const getCreateRoleDetails = (values) => {
  const payload = {
    DASHBOARD: {
      DASHBOARD_OVERVIEW: {
        DISPLAY_NAME: "Overview Dashboard",
        VIEW: values?.overViewDasboard_View,
        VIEW_AND_EDIT: values?.overViewDasboard_ViewAndEdit
      }
    },
    GAMES: {
      EDIT_GAMES: {
        DISPLAY_NAME: "Edit Games",
        VIEW: values?.editGames_View,
        VIEW_AND_EDIT: values?.editGames_ViewAndEdit
      },
      GAMES_DASHBOARD: {
        DISPLAY_NAME: "Overall Game Dashboard",
        VIEW: values?.overallGamesDashboard_View,
        VIEW_AND_EDIT: values?.overallGamesDashboard_ViewAndEdit
      }
    },
    REWARDS: {
      REFERRALS_ANALYTICS: {
        DISPLAY_NAME: "Referrals Analytics",
        VIEW: values?.referralsAnalytics_View,
        VIEW_AND_EDIT: values?.referralsAnalytics_ViewAndEdit
      },
      REWARDS_ANALYTICS: {
        DISPLAY_NAME: "Rewards Analytics",
        VIEW: values?.rewardsAnalytics_View,
        VIEW_AND_EDIT: values?.rewardsAnalytics_ViewAndEdit
      },
      REWARDS_CONFIGURATION: {
        DISPLAY_NAME: "Add Rewards Configuration",
        VIEW: values?.addRewardsConfiguration_View,
        VIEW_AND_EDIT: values?.addRewardsConfiguration_ViewAndEdit
      },
      REWARDS_DATA_CONFIGURATION: {
        DISPLAY_NAME: "Rewards Data Configuration",
        VIEW: values?.rewardsDataConfiguration_View,
        VIEW_AND_EDIT: values?.rewardsDataConfiguration_ViewAndEdit
      }
    },
    TOURNAMENTS: {
      TOURNAMENT_ANALYTICS: {
        DISPLAY_NAME: "Tournament Analytics",
        VIEW: values?.tournamentAnalytics_View,
        VIEW_AND_EDIT: values?.tournamentAnalytics_ViewAndEdit
      },
      TOURNAMENT_CONFIGURATION_BOOT: {
        DISPLAY_NAME: "Tournament Configuration - Boot",
        VIEW: values?.tournamentConfigurationBoot_View,
        VIEW_AND_EDIT: values?.tournamentConfigurationBoot_ViewAndEdit
      },
      TOURNAMENT_CONFIGURATION_LEADERBOARD: {
        DISPLAY_NAME: "Tournament Configuration - Leaderboard",
        VIEW: values?.tournamentConfigurationLeaderBoard_View,
        VIEW_AND_EDIT: values?.tournamentConfigurationLeaderBoard_ViewAndEdit
      }
    },
    USERS: {
      USERS_ANALYTICS: {
        DISPLAY_NAME: "Users Analytics",
        VIEW: values?.usersAnalytics_View,
        VIEW_AND_EDIT: values?.usersAnalytics_ViewAndEdit
      }
    },
    USERS_PERMISSION: {
      EDIT_ROLES: {
        DISPLAY_NAME: "Edit Roles",
        VIEW: values?.editRoles_View,
        VIEW_AND_EDIT: values?.editRoles_ViewAndEdit
      },
      EDIT_USERS: {
        DISPLAY_NAME: "Edit Users",
        VIEW: values?.editUsers_View,
        VIEW_AND_EDIT: values?.editUsers_ViewAndEdit
      }
    }
  };
  return payload;
};

export const getJavascriptObject = (data, section) => {
  var modifedData = {};
  if (section === "GAMES") {
    modifedData = data?.map((item) => {
      const dateAdded: any = item?.createdAt
        ? getDateInFormat(
            parseInt(item?.createdAt),
            "DD-MM-YYYY hh:mm A",
            "changeFormat"
          )
        : null;
      return {
        ID: item?.id || "",
        GAMES: item?.title || "",
        "DATE ADDED": dateAdded || "",
        "TOTAL REVENUE": item?.gameRevenue || "",
        "TOTAL BOOT PAYOUT": item?.bootPayout || "",
        "TOTAL LEADERBOARD PAYOUT": item?.leaderboardPayout || "",
        BOOTS: item?.hostedBoots || "",
        TOURNAMENTS: item?.hostedLeaderboardTournaments || "",
        "ACTIVE TOURNAMENTS": item?.activeLeaderboardTournaments || ""
      };
    });
  }
  if (section === "TOURNAMENTS") {
    modifedData = data?.map((item) => {
      const details = item.details ? JSON.parse(item.details) : {};
      const startDate = item?.start
        ? getDateInFormat(
            parseInt(item?.start),
            "DD/MM/YYYY HH:mm A",
            "changeFormat"
          )
        : "";
      const endDate = item?.end
        ? getDateInFormat(
            parseInt(item?.end),
            "DD/MM/YYYY HH:mm A",
            "changeFormat"
          )
        : "";
      return {
        ID: item?.id || "",
        GAMES: item?.game?.title || "",
        "TOURNAMENT TYPE": item?.type || "",
        "START DATE": startDate,
        "END DATE": endDate,
        "PRIZE POOL": details?.prizePool || "",
        "ENTRY FEE": details?.entry || "",
        "TOTAL SLOTS": details?.capacity || "",
        "SLOTS FILLED": item?.filled || "",
        "BOOT POOL": item?.pool || "",
        "TOTAL BOOT PLAYERS": item?.players || "",
        "BOOT WIN AMOUNT": item?.winningAmount || "",
        WINNERS: item?.winners || "",
        REVENUE: item?.revenue || "",
        STATUS: item?.tournamentStatus || ""
      };
    });
  }
  if (section === "USERS") {
    modifedData = data?.map((item) => {
      const dateJoined = item?.joinedOn
        ? getDateInFormat(
            parseInt(item?.joinedOn),
            "DD/MM/YYYY HH:mm A",
            "changeFormat"
          )
        : "";
      return {
        ID: item?.id || "",
        USERNAME: item?.firstName || "",
        "DATE JOINED": dateJoined || "",
        "BOOT TOURNAMENTS PLAYED": item?.bootTournaments || "",
        "LEADERBOARD TOURNAMENTS PLAYED": item?.leaderboardTournaments || "",
        "ALL BOOT DEPOSITS": item?.depositsBoot || "",
        "ALL LEADERBOARD DEPOSITS": item?.depositsLeaderboard || "",
        "ALL BOOT WINNINGS": item?.winningsBoot || "",
        "ALL LEADERBOARD WINNINGS": item?.winningsLeaderboard || "",
        "TOTAL REVENUE": item?.revenue || "",
        "TOTAL PAYOUTS": item?.payout || "",
        STATUS: item?.status || ""
      };
    });
  }
  return modifedData;
};

export const getUpdateConfigPayload = (values) => {
  const payload = {
    sliceDetails: {
      divisions: {
        1: {
          color: "RED",
          display_name: "A",
          slots: {
            1: {
              color: "RED",
              value: values.slot1,
              rewardId: values.slot1RewardId
            },
            5: {
              color: "RED",
              value: values.slot5,
              rewardId: values.slot5RewardId
            }
          }
        },
        2: {
          color: "YELLOW",
          display_name: "B",
          slots: {
            2: {
              color: "YELLOW",
              value: values.slot2,
              rewardId: values.slot2RewardId
            },
            6: {
              color: "YELLOW",
              value: values.slot6,
              rewardId: values.slot6RewardId
            }
          }
        },
        3: {
          color: "BLUE",
          display_name: "C",
          slots: {
            3: {
              color: "BLUE",
              value: values.slot3,
              rewardId: values.slot3RewardId
            },
            7: {
              color: "BLUE",
              value: values.slot7,
              rewardId: values.slot7RewardId
            }
          }
        },
        4: {
          color: "GREEN",
          display_name: "D",
          slots: {
            4: {
              color: "GREEN",
              value: values.slot4,
              rewardId: values.slot4RewardId
            },
            8: {
              color: "GREEN",
              value: values.slot8,
              rewardId: values.slot8RewardId
            }
          }
        }
      },
      published: false
    }
  };
  return payload;
};

export const getDrawerItems = (rolePermissions: any) => {
  return [
    {
      name: "Dashboard",
      path: "/dashboard",
      show:
        rolePermissions.DASHBOARD.DASHBOARD_OVERVIEW.VIEW ||
        rolePermissions.DASHBOARD.DASHBOARD_OVERVIEW.VIEW_AND_EDIT
    },
    {
      name: "Games",
      path: "/games",
      show:
        rolePermissions.GAMES.GAMES_DASHBOARD.VIEW ||
        rolePermissions.GAMES.EDIT_GAMES.VIEW ||
        rolePermissions.GAMES.GAMES_DASHBOARD.VIEW_AND_EDIT ||
        rolePermissions.GAMES.EDIT_GAMES.VIEW_AND_EDIT
    },
    {
      name: "Tournaments",
      path: "/tournaments",
      show:
        rolePermissions.TOURNAMENTS.TOURNAMENT_ANALYTICS.VIEW ||
        rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT.VIEW ||
        rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD.VIEW ||
        rolePermissions.TOURNAMENTS.TOURNAMENT_ANALYTICS.VIEW_AND_EDIT ||
        rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT
          .VIEW_AND_EDIT ||
        rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD
          .VIEW_AND_EDIT
    },
    {
      name: "Users",
      path: "/users",
      show:
        rolePermissions.USERS.USERS_ANALYTICS.VIEW ||
        rolePermissions.USERS.USERS_ANALYTICS.VIEW_AND_EDIT
    },
    // { name: "Revenue", path: "/dashboard", show: true },
    {
      name: "Rewards",
      path: "/rewards",
      show:
        rolePermissions.REWARDS.REWARDS_ANALYTICS.VIEW ||
        rolePermissions.REWARDS.REFERRALS_ANALYTICS.VIEW ||
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW ||
        rolePermissions.REWARDS.REWARDS_ANALYTICS.VIEW_AND_EDIT ||
        rolePermissions.REWARDS.REFERRALS_ANALYTICS.VIEW_AND_EDIT ||
        rolePermissions.REWARDS.REWARDS_CONFIGURATION.VIEW_AND_EDIT ||
        rolePermissions.REWARDS.REWARDS_DATA_CONFIGURATION.VIEW_AND_EDIT
    }
  ];
};

export const getUpsertRewardPayload = (values) => {
  const expiryDate = values?.expiryDate
    ? getDateInFormat(values?.expiryDate, "YYYY-MM-DD", null)
    : null;
  return {
    type: values?.rewardCategory,
    value: parseInt(values?.internalRewardCost),
    priority: values?.priority,
    sliceDisplayText: values?.subTitle,
    cardDisplayText: values?.subHeading,
    subText: values?.subText,
    totalStock: parseInt(values?.totalStock),
    expiryDate: expiryDate,
    brand: JSON.stringify({
      addBrandDetails: values?.addBrandDetails,
      brandName: values?.brandName,
      title: values?.title,
      addExpiryDateToReward: values?.addExpiryDateToReward === 1 ? "Yes" : "No",
      rewardSummary: values?.rewardSummary,
      couponIdsType: values?.couponIdsType,
      // numberOfCouponCode: values?.numberOfCouponCode,
      universalCouponCode:
        values?.couponIdsType === 1 ? values?.universalCouponCode : "",
      uniqueCouponCodes:
        values?.couponIdsType === 2 ? values?.uniqueCouponCodes : [],
      rewardDetails: values?.rewardDetails,
      ...(values?.logoCard1Url && { logoCard1Url: values?.logoCard1Url }),
      ...(values?.logoCard2Url && { logoCard2Url: values?.logoCard2Url })
    }),
    ...(values?.id && { id: values?.id })
  };
};

export const formatNumberToK = (number, category) => {
  if (number >= 1000) {
    const units = ["", " k", " M", " B", " T"];
    const magnitude = Math.floor(Math.log10(number) / 3);
    const roundedNumber = Math.ceil(number / Math.pow(1000, magnitude));
    return category === "money"
      ? "₹ " + roundedNumber + units[magnitude]
      : roundedNumber + units[magnitude];
  } else {
    return category === "money"
      ? "₹ " + number?.toString()
      : number?.toString();
  }
};
