import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Button,
  MenuItem,
  Divider,
  Popover,
  IconButton,
  CircularProgress
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "../Table";
import { colors } from "styles/colors";
import images from "assets/images/images";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useQuery } from "@apollo/client";
import {
  GET_TOURNAMENTS,
  GET_TOURNAMENT_STATISTICS_FOR_CONGIRATION_TABLE
} from "app/graphql/tournament";
import { getDateInFormat, getJwtTokenDetails } from "utils/helpers";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const TournamentsConfiguration = () => {
  const history = useHistory();
  const { rolePermissions } = getJwtTokenDetails();

  const [tournamentListArr, setTournamentListArr] = React.useState([]);
  const [tournamentStatistics, setTournamentStatistics] = React.useState<any>(
    {}
  );
  const [filter, setFilter] = React.useState([
    "Normal",
    "Unlimited",
    "Free",
    "Triple"
  ]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const [searchText, setSearchText] = React.useState("");
  const [searchTextTemp, setSearchTextTemp] = React.useState("");

  const fireRequestSearchAPICall = (searchValue) => {
    setSearchText(searchValue);
  };
  const [timer, setTimer] = React.useState<any>(null);

  const requestSearch = (searchValue: string) => {
    setSearchTextTemp(searchValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fireRequestSearchAPICall(searchValue);
      }, 1000)
    );
  };

  const {
    data: tournamentsListData,
    loading: getTournamentsListDataLoading,
    refetch: refetchTournamentsListData
  } = useQuery(GET_TOURNAMENTS, {
    variables: {
      filter: {
        type: filter,
        title: searchText
      },
      page: page,
      limit: pageSize
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (
      tournamentsListData &&
      tournamentsListData?.allTournaments &&
      tournamentsListData?.allTournaments?.status
    ) {
      const data = tournamentsListData?.allTournaments?.tournaments;
      setTournamentListArr(data || []);
    }
  }, [tournamentsListData]);

  const {
    loading: getTournamentStatisticsLoading,
    refetch: refetchGetTournamentStatistics
  } = useQuery(GET_TOURNAMENT_STATISTICS_FOR_CONGIRATION_TABLE, {
    variables: {
      bootFilter: "ytd",
      leaderboardFilter: "ytd"
    },
    onCompleted: (data) => {
      const { tournamentDashboard } = data;
      console.log(tournamentDashboard);
      const { status } = tournamentDashboard;
      if (status) {
        setTournamentStatistics(tournamentDashboard);
      } else {
        setTournamentStatistics({});
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const columnsForTournaments: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 0.5,
        headerAlign: "center",
        align: "center"
      },
      {
        field: "title",
        headerName: "TITLE",
        minWidth: 100,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            // onClick={() => history.push(`/tournaments/${params.row.id}`)}
          >
            <Typography fontSize={"14px"}>{params.row.title}</Typography>
          </Box>
        )
      },
      {
        field: "game",
        headerName: "GAME",
        minWidth: 100,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            // onClick={() => history.push(`/tournaments/${params.row.id}`)}
          >
            <Typography fontSize={"14px"}>{params.row.game?.title}</Typography>
          </Box>
        )
      },
      {
        field: "type",
        headerName: "TOUR. TYPE",
        minWidth: 100,
        flex: 1,
        headerAlign: "center",
        align: "center"
      },
      {
        field: "start",
        headerName: "START D&T",
        minWidth: 150,
        flex: 1.4,
        headerAlign: "center",
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.start),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "end",
        headerName: "END D&T",
        minWidth: 150,
        flex: 1.4,
        headerAlign: "center",
        renderCell: (params) => {
          const DateAndTime: any = getDateInFormat(
            parseInt(params?.row?.end),
            "DD/MM/YYYY hh:mm A",
            "changeFormat"
          );
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{DateAndTime}</Typography>
            </Box>
          );
        }
      },
      {
        field: "pool",
        headerName: "PRIZE POOL",
        // minWidth: 200,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{details?.prizePool}</Typography>
            </Box>
          );
        }
      },
      {
        field: "slot",
        headerName: "SLOTS",
        // minWidth: 200,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{details?.capacity}</Typography>
            </Box>
          );
        }
      },
      {
        field: "entry",
        headerName: "ENTRY",
        // minWidth: 200,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>{details?.entry}</Typography>
            </Box>
          );
        }
      },
      {
        field: "actions",
        type: "actions",
        headerName: "ACTIONS",
        minWidth: 170,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };

          const handleClose = () => {
            setAnchorEl(null);
          };

          const open = Boolean(anchorEl);
          const id = open ? "simple-popover" : undefined;
          if (
            rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD
              ?.VIEW_AND_EDIT &&
            params.row.status === "InActive"
          ) {
            return [
              <Grid item md={1}>
                <IconButton
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MoreVertOutlinedIcon
                    fontSize="small"
                    sx={{ color: colors.lightGreyIcon }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 30,
                    horizontal: -100
                  }}
                  sx={{ maxHeight: "none" }}
                >
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon,
                      mt: -0.5,
                      mb: 1
                    }}
                    onClick={() =>
                      history.push(`/tournaments/delete/${params.row.id}`)
                    }
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Delete
                    </Typography>
                  </MenuItem>
                </Popover>
              </Grid>
            ];
          } else if (
            rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD
              ?.VIEW_AND_EDIT
          ) {
            const currentDateAndTime = new Date();
            const startDateAndTime = new Date(parseInt(params?.row?.start));
            const endDateAndTime = new Date(parseInt(params?.row?.end));
            if (
              currentDateAndTime < startDateAndTime &&
              currentDateAndTime < endDateAndTime &&
              !params.row.slotsFilled
            ) {
              return [
                <GridActionsCellItem
                  icon={
                    <span
                      style={{
                        display: "flex",
                        alignContent: "center",
                        marginRight: "5px"
                      }}
                    >
                      <img
                        src={images.EditIcon}
                        alt=""
                        onClick={() => {
                          history.push(
                            `/tournaments/add-edit/${params.row.id}`
                          );
                        }}
                      />
                    </span>
                  }
                  label="Edit"
                />,
                <GridActionsCellItem
                  icon={
                    <span
                      style={{ display: "flex", alignContent: "center" }}
                      onClick={() => {
                        sessionStorage.setItem("tournament", params.row.title);
                        history.push(`/tournaments/${params.row.id}`);
                      }}
                    >
                      <VisibilityOutlinedIcon fontSize="small" />
                    </span>
                  }
                  label="View"
                />,
                <Grid item md={1}>
                  <IconButton
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertOutlinedIcon
                      fontSize="small"
                      sx={{ color: colors.lightGreyIcon }}
                    />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 30,
                      horizontal: -100
                    }}
                    sx={{ maxHeight: "none" }}
                  >
                    <MenuItem
                      sx={{
                        color: colors.lightGreyIcon
                      }}
                      onClick={() =>
                        history.push(`/tournaments/deactivate/${params.row.id}`)
                      }
                    >
                      <Typography fontSize={16} color={colors.fontPrimary}>
                        Deactivate
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      sx={{
                        color: colors.lightGreyIcon,
                        mt: -0.5,
                        mb: 1
                      }}
                      onClick={() =>
                        history.push(`/tournaments/delete/${params.row.id}`)
                      }
                    >
                      <Typography fontSize={16} color={colors.fontPrimary}>
                        Delete
                      </Typography>
                    </MenuItem>
                  </Popover>
                </Grid>
              ];
            } else {
              return [
                <GridActionsCellItem
                  icon={
                    <span
                      style={{ display: "flex", alignContent: "center" }}
                      onClick={() => {
                        sessionStorage.setItem("tournament", params.row.title);
                        history.push(`/tournaments/${params.row.id}`);
                      }}
                    >
                      <VisibilityOutlinedIcon fontSize="small" />
                    </span>
                  }
                  label="View"
                />,
                <Grid item md={1}>
                  <IconButton
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertOutlinedIcon
                      fontSize="small"
                      sx={{ color: colors.lightGreyIcon }}
                    />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 30,
                      horizontal: -100
                    }}
                    sx={{ maxHeight: "none" }}
                  >
                    <MenuItem
                      sx={{
                        color: colors.lightGreyIcon
                      }}
                      onClick={() =>
                        history.push(`/tournaments/deactivate/${params.row.id}`)
                      }
                    >
                      <Typography fontSize={16} color={colors.fontPrimary}>
                        Deactivate
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      sx={{
                        color: colors.lightGreyIcon,
                        mt: -0.5,
                        mb: 1
                      }}
                      onClick={() =>
                        history.push(`/tournaments/delete/${params.row.id}`)
                      }
                    >
                      <Typography fontSize={16} color={colors.fontPrimary}>
                        Delete
                      </Typography>
                    </MenuItem>
                  </Popover>
                </Grid>
              ];
            }
          }
          return [
            <GridActionsCellItem
              icon={
                <span
                  style={{ display: "flex", alignContent: "center" }}
                  onClick={() => {
                    sessionStorage.setItem("tournament", params.row.title);
                    history.push(`/tournaments/${params.row.id}`);
                  }}
                >
                  <VisibilityOutlinedIcon fontSize="small" />
                </span>
              }
              label="View"
            />
          ];
        }
      }
    ],
    [rolePermissions]
  );

  const columnsForBoot: any = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 50,
        flex: 0.5,
        headerAlign: "center",
        align: "center"
      },
      {
        field: "game",
        headerName: "GAME",
        minWidth: 100,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            fontSize={"14px"}
            onClick={() => history.push(`/tournaments/boot/${params.row.id}`)}
          >
            <Typography fontSize={"14px"}>{params.row.game?.title}</Typography>
          </Box>
        )
      },
      // {
      //   field: "title",
      //   headerName: "TITLE",
      //   minWidth: 100,
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      //   renderCell: (params) => (
      //     <Box
      //       display={"flex"}
      //       justifyContent={"center"}
      //       alignItems={"baseline"}
      //       fontSize={"14px"}
      //       // onClick={() => history.push(`/tournaments/boot/${params.row.id}`)}
      //     >
      //       <Typography fontSize={"14px"}>{params.row?.title}</Typography>
      //     </Box>
      //   )
      // },
      {
        field: "playerLimit",
        headerName: "PLAYER LIMIT",
        // minWidth: 200,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>
                {details?.capacity || ""}
              </Typography>
            </Box>
          );
        }
      },
      {
        field: "bootValues",
        headerName: "BOOT VALUES",
        // minWidth: 200,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          const bootEntries = details?.bootEntries;
          const joinedEntries = bootEntries?.filter(Boolean).join(",");
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>
                {joinedEntries ? joinedEntries : ""}
              </Typography>
            </Box>
          );
        }
      },
      {
        field: "gdCom",
        headerName: "GD COMMISSION",
        // minWidth: 200,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const details = params.row.details
            ? JSON.parse(params.row.details)
            : {};
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"baseline"}
              fontSize={"14px"}
            >
              <Typography fontSize={"14px"}>
                {details?.gdCommission || ""}
              </Typography>
            </Box>
          );
        }
      },
      {
        field: "actions",
        type: "actions",
        headerName: "ACTIONS",
        minWidth: 170,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };

          const handleClose = () => {
            setAnchorEl(null);
          };
          const open = Boolean(anchorEl);
          const id = open ? "simple-popover" : undefined;
          if (
            rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT
              ?.VIEW_AND_EDIT
          ) {
            return [
              <GridActionsCellItem
                icon={
                  <span
                    style={{
                      display: "flex",
                      alignContent: "center",
                      marginRight: "5px"
                    }}
                  >
                    <img
                      src={images.EditIcon}
                      alt=""
                      onClick={() => {
                        history.push(`/tournaments/add-edit/${params.row.id}`);
                      }}
                    />
                  </span>
                }
                label="Edit"
              />,
              <GridActionsCellItem
                icon={
                  <span
                    style={{ display: "flex", alignContent: "center" }}
                    onClick={() => {
                      sessionStorage.setItem("tournament", "View Boot Detail");
                      history.push(`/tournaments/boot/${params.row.id}`);
                    }}
                  >
                    <VisibilityOutlinedIcon fontSize="small" />
                  </span>
                }
                label="View"
              />,
              <Grid item md={1}>
                <IconButton
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MoreVertOutlinedIcon
                    fontSize="small"
                    sx={{ color: colors.lightGreyIcon }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 30,
                    horizontal: -100
                  }}
                  sx={{ maxHeight: "none" }}
                >
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon,
                      mt: 1,
                      mb: -0.5
                    }}
                    onClick={() =>
                      history.push(`/tournaments/deactivate/${params.row.id}`)
                    }
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Deactivate
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon,
                      mt: -0.5,
                      mb: 1
                    }}
                    onClick={() =>
                      history.push(`/tournaments/delete/${params.row.id}`)
                    }
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Delete
                    </Typography>
                  </MenuItem>
                </Popover>
              </Grid>
            ];
          }
          return [
            <GridActionsCellItem
              icon={
                <span
                  style={{ display: "flex", alignContent: "center" }}
                  onClick={() => {
                    sessionStorage.setItem("tournament", "View Boot Detail");
                    history.push(`/tournaments/${params.row.id}`);
                  }}
                >
                  <VisibilityOutlinedIcon fontSize="small" />
                </span>
              }
              label="View"
            />
          ];
        }
      }
    ],
    [rolePermissions]
  );

  const [selectedRequests, setSelectedRequests] = React.useState<number[]>([]);
  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

  const handleRowOver = (e) => {
    const rowId = Number(e.currentTarget.dataset.id);
    setHoveredRow(rowId);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const [value, setValue] = React.useState(0);
  const [selectedTab, setSelectedTab] = React.useState("TOURNAMENT");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(0);
    if (newValue === 0) {
      setFilter(["Normal", "Unlimited", "Free"]);
      setSelectedTab("TOURNAMENT");
    } else if (newValue === 1) {
      setFilter(["Blitz"]);
      setSelectedTab("BOOT");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (
      !(
        rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD.VIEW ||
        rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD
          .VIEW_AND_EDIT
      ) &&
      (rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT.VIEW ||
        rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT.VIEW_AND_EDIT)
    ) {
      setSelectedTab("BOOT");
      setFilter(["Blitz"]);
    }
  }, []);

  const lastUpdatedInfo: any = getDateInFormat(
    tournamentStatistics?.lastUpdated,
    "DD/MM/YYYY hh:mm A",
    "changeFormat"
  );
  console.log(lastUpdatedInfo);

  return (
    <Grid container>
      {getTournamentsListDataLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.invalidInput
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ color: colors.invalidInput }}
      >
        <Tabs
          textColor="inherit"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: colors.invalidInput
            }
          }}
          value={value}
          onChange={handleChange}
        >
          {(rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD
            ?.VIEW ||
            rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD
              ?.VIEW_AND_EDIT) && (
            <Tab
              sx={{
                color: colors.lightGreyIcon,
                fontWeight: 500,
                fontSize: "14px"
              }}
              label="Tournament Details"
              {...a11yProps(0)}
            />
          )}
          {(rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT?.VIEW ||
            rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT
              ?.VIEW_AND_EDIT) && (
            <Tab
              sx={{
                color: colors.lightGreyIcon,
                fontWeight: 500,
                fontSize: "14px"
              }}
              label="Boot Details"
              {...a11yProps(1)}
            />
          )}
        </Tabs>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {selectedTab === "TOURNAMENT" && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: colors.backgroundLight,
              m: 1.5,
              p: 3,
              borderRadius: "15px"
            }}
          >
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography
                  color="#3C3C3C"
                  fontSize="16px"
                  fontWeight={600}
                  sx={{ py: 1 }}
                >
                  All Tournaments
                </Typography>
                <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                  {tournamentListArr?.length > 0
                    ? `Displaying details of ${tournamentListArr?.length} tournaments`
                    : ""}
                </Typography>
              </Box>
              {rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_LEADERBOARD
                ?.VIEW_AND_EDIT && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 4
                  }}
                >
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={400}
                    mr={2}
                  >
                    {`Last Updated on ${lastUpdatedInfo}`}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      width: "150px",
                      height: "38px",
                      background:
                        "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                      borderRadius: "5px",
                      fontWeight: 700,
                      fontSize: "16px",
                      letterSpacing: "0.02em",
                      marginRight: 2
                    }}
                    onClick={() => {
                      history.push("/tournaments/add-edit/new");
                    }}
                  >
                    Add new
                  </Button>
                  {/* <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: "150px",
                    height: "38px",
                    background:
                      "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                    borderRadius: "5px",
                    fontWeight: 700,
                    fontSize: "16px",
                    letterSpacing: "0.02em",
                    marginRight: 2
                  }}
                  onClick={() => {
                    console.log("export clicked");
                    downloadCSV();
                  }}
                >
                  Export
                </Button> */}
                </Box>
              )}
            </Box>
            {/* <div style={{ marginTop: 15 }}>
              <span style={{ margin: 7 }}>
                <IconButton
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <img src={images.Filter_Rectangle} alt="" />
                  <img
                    src={images.DownArrow}
                    alt=""
                    style={{ marginLeft: "5px" }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 10,
                    horizontal: -50
                  }}
                  sx={{ maxHeight: "none" }}
                >
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Option 1
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon,
                      mt: -0.5,
                      mb: 1
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Option 2
                    </Typography>
                  </MenuItem>
                </Popover>
              </span>
              <span style={{ margin: 15 }}>
                <img src={images.ExportIcon} alt="" />
              </span>
            </div> */}
            <Table
              rows={tournamentListArr || []}
              columns={columnsForTournaments}
              paginationMode="server"
              page={page}
              onPageChange={(page) => setPage(page)}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize);
                setPage(0);
              }}
              rowCount={tournamentStatistics?.leaderboardTournaments || 100}
              loading={false}
              pagination={true}
              // checkboxSelection
              selectedRows={selectedRequests}
              setSelectedRows={setSelectedRequests}
              // onRowHover={handleRowOver}
              // onRowLeave={handleRowLeave}
              searchText={searchTextTemp}
              requestSearch={requestSearch}
              disableSearch={false}
              placeholderTextForSearch="Search by game"
            />
          </Grid>
        )}
        {selectedTab === "BOOT" && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: colors.backgroundLight,
              m: 1.5,
              p: 3,
              borderRadius: "15px"
            }}
          >
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography
                  color="#3C3C3C"
                  fontSize="16px"
                  fontWeight={600}
                  sx={{ py: 1 }}
                >
                  All Tournaments
                </Typography>
                <Typography color="#3C3C3C" fontSize="13px" fontWeight={400}>
                  {tournamentListArr?.length > 0
                    ? `Displaying details of ${tournamentListArr?.length} tournaments`
                    : ""}
                </Typography>
              </Box>
              {rolePermissions.TOURNAMENTS.TOURNAMENT_CONFIGURATION_BOOT
                ?.VIEW_AND_EDIT && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 4
                  }}
                >
                  <Typography
                    color="#3C3C3C"
                    fontSize="13px"
                    fontWeight={400}
                    mr={2}
                  >
                    {`Last Updated on ${lastUpdatedInfo}`}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      width: "150px",
                      height: "38px",
                      background:
                        "linear-gradient(180deg, #F2A14F 0%, #FF8100 53.57%)",
                      // boxShadow:
                      //   '-6px -6px 12px rgba(193, 193, 193, 0.08), 6px 6px 12px rgba(4, 4, 4, 0.45)',
                      borderRadius: "5px",
                      fontWeight: 700,
                      fontSize: "16px",
                      letterSpacing: "0.02em",
                      marginRight: 2
                    }}
                    onClick={() => {
                      history.push("/tournaments/add-edit/new");
                    }}
                  >
                    Add new
                  </Button>
                </Box>
              )}
            </Box>
            {/* <div style={{ marginTop: 15 }}>
              <span style={{ margin: 7 }}>
                <IconButton
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <img src={images.Filter_Rectangle} alt="" />
                  <img
                    src={images.DownArrow}
                    alt=""
                    style={{ marginLeft: "5px" }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 10,
                    horizontal: -50
                  }}
                  sx={{ maxHeight: "none" }}
                >
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Option 1
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    sx={{
                      color: colors.lightGreyIcon,
                      mt: -0.5,
                      mb: 1
                    }}
                  >
                    <Typography fontSize={16} color={colors.fontPrimary}>
                      Option 2
                    </Typography>
                  </MenuItem>
                </Popover>
              </span>
              <span style={{ margin: 15 }}>
                <img src={images.ExportIcon} alt="" />
              </span>
            </div> */}
            <Table
              rows={tournamentListArr || []}
              columns={columnsForBoot}
              paginationMode="server"
              page={page}
              onPageChange={(page) => setPage(page)}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize);
                setPage(0);
              }}
              rowCount={tournamentStatistics?.bootTournaments || 100}
              loading={false}
              pagination={true}
              // checkboxSelection
              selectedRows={selectedRequests}
              setSelectedRows={setSelectedRequests}
              // onRowHover={handleRowOver}
              // onRowLeave={handleRowLeave}
              searchText={searchTextTemp}
              // onChange = {setSearchText(e.target.value)}
              requestSearch={requestSearch}
              disableSearch={false}
              placeholderTextForSearch="Search by game"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TournamentsConfiguration;
