import * as React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ICurvedGraphProps {
  title: any;
  subtitle?: any;
  xAxis: any;
  yAxis: any;
  data: any;
  plotOptions?: any;
  properties?: any;
}

const ColumChart = (props: any) => {
  const { title, subtitle, xAxis, yAxis, data, properties } = props;

  const tooltipOptions: Highcharts.TooltipOptions = {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormatter: function () {
      const series: any = this.series;
      const point = this as any; // Explicitly cast 'this' to 'any' to access extra properties
      return (
        '<tr><td style="color:' +
        point.color +
        ';padding:0">' +
        series.name +
        ": " +
        point.y +
        "</td></tr>"
      );
    },
    footerFormat: "</table>",
    shared: true,
    useHTML: true
  };

  const optionsNew = {
    chart: {
      type: "column",
      backgroundColor: "",
      height: properties?.height ? properties?.height : 300,
      width: properties?.width ? properties?.width : 600
    },
    title: {
      text: title
    },
    xAxis: xAxis,
    yAxis: yAxis,
    tooltip: tooltipOptions,
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      },
      series: {
        groupPadding: properties?.groupPadding ? properties?.groupPadding : 0.2,
        pointPadding: properties?.pointPadding ? properties?.pointPadding : 0.1
      }
    },
    legend: {
      enabled: false
    },
    series: data,
    credits: {
      enabled: false
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={optionsNew} />;
};

export default ColumChart;
