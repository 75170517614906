import React from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

const getToken = () => {
  const token = sessionStorage.getItem("token");
  console.log(">>>>>>>token", token);
  if (token) {
    return { authorization: `JWT ${token}` };
  }
  return {};
};

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...getToken(),
    },
  }));

  return forward(operation);
});

const uploadLink = createUploadLink({
  headers: { "Apollo-Require-Preflight": "true" },
  uri: process.env.REACT_APP_GRAPHQL_HTTP_URI,
}) as unknown as ApolloLink;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: ApolloLink.from([authMiddleware, errorLink, uploadLink]),
  cache: new InMemoryCache(),
});

const AppApolloprovider = ({ children }: { children: React.ReactNode }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default AppApolloprovider;
